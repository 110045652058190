import {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  patchSetStatus,
} from '../../client/notification';
import * as types from '../constants';
import { NOTIFICATIONS_PER_PAGE, NOTIFICATION_STATUS } from '../../constants';

export const getNotificationsSuccess = (notifications, rowCount, initial) => {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    payload: {
      notifications,
      rowCount,
      initial,
    },
  };
};

export const getUnreadNotificationsCountSuccess = (unreadCount) => {
  return {
    type: types.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
    payload: {
      unreadCount,
    },
  };
};

export const setNotificationConnectionStatusSuccess = (connected) => {
  return {
    type: types.CONNECTION_STATUS_CHANGE_SUCCESS,
    payload: {
      connected,
    },
  };
};

export const updateNotificationStatusSuccess = (
  notification,
  status,
  unreadCount
) => {
  return {
    type: types.UPDATE_NOTIFICATION_STATUS_SUCCESS,
    payload: {
      notification,
      status,
      unreadCount,
    },
  };
};

export const markAllNotificationsAsReadSuccess = (unreadCount) => {
  return {
    type: types.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
    payload: {
      unreadCount,
    },
  };
};

export const getNotifications = ({ page = 1, initial = false }) => {
  return async (dispatch) => {
    const { data } = await fetchNotifications({
      PageNumber: page,
      PageSize: NOTIFICATIONS_PER_PAGE,
    });
    dispatch(getNotificationsSuccess(data?.data, data.rowCount, initial));
  };
};

export const updateNotificationStatus = (notification, status) => {
  return async (dispatch) => {
    const {
      data: { unreadNotificationsCount },
    } = await patchSetStatus({ notifications: [notification], status });
    dispatch(
      updateNotificationStatusSuccess(
        notification,
        status,
        unreadNotificationsCount
      )
    );
  };
};

export const getUnreadNotificationsCount = () => {
  return async (dispatch) => {
    const { data } = await fetchUnreadNotificationsCount();
    dispatch(getUnreadNotificationsCountSuccess(data.unreadNotificationsCount));
  };
};

export const setNotificationConnectionStatus = (connected) => {
  return (dispatch) => {
    dispatch(setNotificationConnectionStatusSuccess(connected));
  };
};

export const markAllNotificationsAsRead = () => {
  return async (dispatch) => {
    const {
      data: { unreadNotificationsCount },
    } = await patchSetStatus({
      ignoreIfCurrentStatusIs: [NOTIFICATION_STATUS.INTERACTED_WITH],
    });
    dispatch(markAllNotificationsAsReadSuccess(unreadNotificationsCount));
  };
};
