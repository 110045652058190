import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Auth0Provider } from './react-auth0-spa';
import { history } from './routes/Routes';
import App from './App';

import './assets/scss/app.scss';

import {
  DOMAIN as domain,
  AUTH0_CLIENT_ID as clientId,
  BACKEND_AUD as audience,
} from './config';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// TODO Remove audience when default audience of Auth0 becomes the same

ReactDOM.render(
  <HttpsRedirect>
    <Auth0Provider
      domain={domain}
      client_id={clientId}
      redirect_uri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);
