import React from 'react';

import PropTypes from 'prop-types';

import BoxInReconditioningProcessInfoCard from './BoxInReconditioningProcessInfoCard';
import OrderedBoxInfoCard from './OrderedBoxInfoCard';
import ActiveBoxInfoCard from './ActiveBoxInfoCard';
import { checkIsBoxInOrder } from '../../utils/box';
import { BOX_STATUSES } from '../../containers/ReconditioningContainers/constants';

const BoxInfoCard = ({ box }) => (
  <>
    {checkIsBoxInOrder(box) && <BoxInReconditioningProcessInfoCard box={box} />}
    {box.status === BOX_STATUSES.ORDERED && <OrderedBoxInfoCard box={box} />}
    {(box.status === BOX_STATUSES.ACTIVE_EMPTY ||
      box.status === BOX_STATUSES.ACTIVE_NOT_EMPTY) && (
      <ActiveBoxInfoCard box={box} />
    )}
  </>
);

BoxInfoCard.propTypes = {
  box: PropTypes.object.isRequired,
};

export default BoxInfoCard;
