import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Star } from 'react-feather';

const Stars = ({
  max = 5,
  readOnly,
  onChange,
  size = 'medium',
  value,
  onClick,
  className = '',
}) => {
  const [hoverStars, setHoverStars] = useState(-1);

  return (
    <div
      className={`stars-wrapper ${className}`}
      {...(!readOnly && {
        onMouseLeave: () => setHoverStars(-1),
      })}
    >
      {[...Array(max)].map((e, i) => (
        <Star
          key={i}
          className={`stars ${size} ${readOnly && 'read-only'} ${
            i <= hoverStars ? 'filled' : 'outlined'
          } ${i + 1 <= value && 'filled'} `}
          {...(!readOnly && {
            onMouseEnter: () => setHoverStars(i),
            onClick: () => onClick(i + 1),
            onChange,
          })}
        />
      ))}
    </div>
  );
};

Stars.propTypes = {
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  max: PropTypes.number,
  size: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Stars;
