import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BackIcon } from '../../../assets/icon/iconfinder_triangle-left_293631.svg';

const GoBack = ({ handleGoBack, goBackOptions }) => {
  const { size = 26 } = goBackOptions;
  return (
    <BackIcon
      className='icon-tertiary hoverable'
      width={size}
      height={size}
      onClick={handleGoBack}
    />
  );
};

GoBack.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  goBackOptions: PropTypes.object,
};

export default GoBack;
