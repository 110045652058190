import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Shared/Loader';

const Loading = ({
  title,
  subtitle,
  noLoaderPadding = false,
  noTitlePadding = false,
}) => {
  return (
    <div className='d-flex flex-column h-100'>
      <div
        className={`modal-title text-center ${
          noTitlePadding ? '' : 'pt-5 pb-5'
        }`}
      >
        {title}
      </div>
      <div className='d-flex flex-column flex-grow-1 justify-content-center pt-5'>
        <div
          className={`text-center ${
            !noLoaderPadding && 'modal-loader-padding'
          }`}
        >
          <Loader />
          <div className='modal-title modal-loader-title text-center pt-5'>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  noLoaderPadding: PropTypes.bool,
  noTitlePadding: PropTypes.bool,
};

export default Loading;
