import React from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { findMetadata } from '../../utils/user';

const ShippingInfo = ({
  shippingDetails,
  className = '',
  titleClassName = 'mb-3',
  countries,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`d-flex flex-column ${className}`}>
      <p
        className={`font-weight-bold font-size-md text-txt-primary ${titleClassName}`}
      >
        {t('modal.pickupAddress').toUpperCase()}
      </p>
      <p className='mb-0 text-txt-secondary'>{`${
        shippingDetails.organizationName
      }, ${findMetadata(countries, shippingDetails.country)}, ${
        shippingDetails.street
      }, ${shippingDetails.postalCode}, ${shippingDetails.city}${
        shippingDetails.customerNumber
          ? `, ${shippingDetails.customerNumber}`
          : ''
      }`}</p>
      <p className='text-txt-secondary mb-1'>{`${shippingDetails.name}, ${
        shippingDetails.phoneNumber ? `${shippingDetails.phoneNumber}, ` : ''
      }${shippingDetails.email}`}</p>
    </div>
  );
};

ShippingInfo.propTypes = {
  shippingDetails: PropTypes.object,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  countries: PropTypes.array,
};

export default connect(({ application }) => ({
  countries: application.countries,
}))(ShippingInfo);
