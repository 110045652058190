import React from 'react';

import PropTypes from 'prop-types';
import { Plus, X } from 'react-feather';
import cs from 'classnames';

import { COLORS } from '../../constants/theme';

const AddNewButton = ({
  handleClick,
  size = 36,
  optionalClassName = '',
  disabled = false,
  plusColor = COLORS.SECONDARY,
  hasX = false,
  xColor = COLORS.SECONDARY,
}) => {
  return (
    <div
      className={cs(
        `d-flex hover-active cursor-pointer justify-content-center align-items-center add-new-circle ${optionalClassName}`,
        { disabled }
      )}
      onClick={disabled ? null : handleClick}
    >
      {hasX ? (
        <X color={xColor} size={size} />
      ) : (
        <Plus color={plusColor} size={size} />
      )}
    </div>
  );
};

AddNewButton.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.number,
  optionalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  plusColor: PropTypes.string,
  hasX: PropTypes.bool,
  xColor: PropTypes.string,
};

export default AddNewButton;
