export const getDevMode = () => {
  return !!localStorage.getItem('devMode');
};

export const setDevMode = (mode) => {
  if (!mode) {
    localStorage.removeItem('devMode');
  } else {
    localStorage.setItem('devMode', mode);
  }
};
