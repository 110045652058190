import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import Modal from '../Modal';
import { NewLoading, NewModalStep } from '../Shared';
import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import { FIREBASE_EVENTS, ROUTES } from '../../../constants';
import WarningStep from '../Shared/WarningStep';
import { navigateWithState } from '../../../utils/routes';
import { addToolToBox } from '../../../redux/actions/inspectionActions';
import { getMappedErrorMessage } from '../../../utils/error';
import { handleAnalytics } from '../../../utils/analytics';
import { openExternalProductInNewTab } from '../../../utils/product';

const debug = _debug('Bridge:AddToolToBoxInInspectionModal');

const STEPS = {
  WARNING: 'WARNING',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

const AddToolToBoxInInspectionModal = ({
  handleClose,
  orderDetails,
  inspectionDetails,
  addToolToBox,
  scannedTagData,
  handleOpenTagOnReconCenterSide,
  setTagIsOutsideOfCurrentBoxModalOpen,
  addingToolFromBrandsDatabase = false,
  scannedDataMatrixTagData = {},
}) => {
  const { t } = useTranslation();
  const { orderId, boxId } = useParams();
  const history = useHistory();

  const [step, setStep] = useState(STEPS.WARNING);
  const [error, setError] = useState('');

  const handleAddToolToBoxOnInspectionScreen = async () => {
    try {
      setStep(STEPS.LOADING);
      const productInstanceId = await addToolToBox(
        inspectionDetails.id,
        addingToolFromBrandsDatabase
          ? scannedDataMatrixTagData
          : { tag: scannedTagData.tag }
      );
      handleAnalytics(FIREBASE_EVENTS.RC_INSTANCE_ADDED_DURING_INSPECTION);
      handleClose();
      navigateWithState(history, `${ROUTES.RC}/order/${orderId}/box/${boxId}`, {
        highlightedInstanceId: productInstanceId,
      });
    } catch (e) {
      const response = getMappedErrorMessage(e);
      setError(response);
      setStep(STEPS.ERROR);
    }
  };

  const handleOpenExternalProductPage = ({
    code,
    dataProviderName,
    externalId,
  }) => {
    handleAnalytics(FIREBASE_EVENTS.PRODUCT_TEMPLATE_VIEW_EXTERNAL_WEB);
    openExternalProductInNewTab(code, dataProviderName, externalId);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.WARNING:
        return (
          <WarningStep
            title={
              <Trans
                i18nKey={
                  addingToolFromBrandsDatabase
                    ? 'modal.youAreInspectingTheReconditioningOrderFromOrganizationThisToolIsNotConnectedToAnyCustomerLibrary'
                    : 'modal.theToolYouScannedIsAvailableInCustomerOrganization'
                }
                values={{
                  customerOrganization: orderDetails.shipment.organizationName,
                }}
              />
            }
            text1={
              <Trans
                i18nKey={
                  addingToolFromBrandsDatabase
                    ? 'modal.clickOnAddToolToSyncThisToolToCustomerOrganizationAndAddItToThisReconditioningOrder'
                    : 'modal.thisToolIsPropertyOfCustomerOrganizationHoweverItIsNotConnectedToThisReconditioningOrder'
                }
                values={{
                  customerOrganization: orderDetails.shipment.organizationName,
                }}
              />
            }
            text2={
              <Trans
                i18nKey={
                  addingToolFromBrandsDatabase
                    ? ''
                    : 'modal.clickOnAddToolForAddingThisToolToTheCustomerOrder'
                }
                values={{
                  orderNumber: orderDetails.orderNumber,
                }}
              />
            }
            leftButtonContent={t('button.moreDetails')}
            rightButtonContent={t('button.addTool')}
            handleClickLeftButton={() => {
              if (addingToolFromBrandsDatabase) {
                handleOpenExternalProductPage(scannedDataMatrixTagData);
              } else {
                handleOpenTagOnReconCenterSide(scannedTagData, true);
              }
              setTagIsOutsideOfCurrentBoxModalOpen(false);
            }}
            handleClickRightButton={() =>
              handleAddToolToBoxOnInspectionScreen()
            }
            handleClose={handleClose}
          />
        );

      case STEPS.ERROR:
        return (
          <NewModalStep
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column text-txt-primary pb-5 justify-content-center'
            rightButtonContent={t('button.cancel')}
            handleClickRightButton={handleClose}
          >
            <WarningIcon
              width={150}
              height={150}
              className='warning-icon icon-danger mb-5'
            />
            <div className='mb-5'>
              {t(error) || t('error.somethingWentWrong')}
            </div>
          </NewModalStep>
        );
      case STEPS.LOADING:
        return <NewLoading value={t('modal.addingToolToBox')} />;
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal isOpened className='d-flex flex-column justify-content-center p-0'>
      {renderStep()}
    </Modal>
  );
};

AddToolToBoxInInspectionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpenTagOnReconCenterSide: PropTypes.func.isRequired,
  setTagIsOutsideOfCurrentBoxModalOpen: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
  inspectionDetails: PropTypes.object.isRequired,
  scannedTagData: PropTypes.object.isRequired,
  addToolToBox: PropTypes.func.isRequired,
  addingToolFromBrandsDatabase: PropTypes.bool,
  scannedDataMatrixTagData: PropTypes.object,
};

export default connect(
  ({ order, inspection }) => ({
    orderDetails: order.details,
    inspectionDetails: inspection.inspectionDetails,
  }),
  {
    addToolToBox,
  }
)(AddToolToBoxInInspectionModal);
