export const SEARCH_FILTER_OPTIONS = {
  PRODUCT: { LABEL: 'components.product', KEY: 'PRODUCT' },
  PRODUCTS_BRIDGE_DATABASE: {
    LABEL: 'containers.myTools',
    KEY: 'PRODUCTS_BRIDGE_DATABASE',
  },
  PRODUCTS_EXTERNAL_DATABASE: {
    LABEL: 'containers.productCatalog',
    KEY: 'PRODUCTS_EXTERNAL_DATABASE',
  },
  ASSEMBLY: { LABEL: 'modal.assembly', KEY: 'ASSEMBLY' },
  ASSEMBLIES_BRIDGE_DATABASE: {
    LABEL: 'containers.myTools',
    KEY: 'ASSEMBLIES_BRIDGE_DATABASE',
  },
  CREATE_ASSEMBLY_RECIPE: {
    LABEL: 'containers.createAssemblyRecipe',
    KEY: 'CREATE_ASSEMBLY_RECIPE',
  },
};

export const SELECTED_OPTION_QUERY = 'selected';
