import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as TriangleUp } from '../../assets/icon/ic_triangle_up.svg';
import { THEME_COLORS_NAMES } from '../../constants';

const TriangleUpIcon = ({
  onClick,
  className = '',
  id,
  color = THEME_COLORS_NAMES.TEXT_PRIMARY,
}) => (
  <TriangleUp
    className={`icon-${color} cursor-pointer ${className}`}
    onClick={onClick}
    width={30}
    height={30}
    id={id}
  />
);

TriangleUpIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.oneOf(THEME_COLORS_NAMES),
};

export default TriangleUpIcon;
