import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import CookieSettings from '../Modals/CookieSettings';
import {
  acceptCookies,
  getUserConsent,
  rejectCookies,
  setUserConsent,
} from '../../utils/cookies';
import { URLS } from '../../constants';
import { initializeFirebaseAnalytics } from '../../utils/analytics';
import {
  getUserSettings,
  updateUserSettings,
} from '../../redux/actions/userActions';
import { openUrlInNewTab } from '../../utils/shared';
import { SecondaryButton } from '../Shared';

const CookieBanner = ({ settings, updateUserSettings }) => {
  const consent = getUserConsent();
  const { t } = useTranslation();
  const [cookiesConsent, setCookiesConsent] = useState(!!consent);
  const [settingsModal, setSettingsModal] = useState(false);

  useEffect(() => {
    getUserSettings();
  }, [settings]);

  const handleReject = () => {
    rejectCookies();
    setCookiesConsent(true);
    setSettingsModal(false);
    updateUserSettings({
      ...settings,
      cookie: { useMarketingAndAnalytics: false, usePreferences: false },
    });
  };

  const handleSave = (options) => {
    setUserConsent(options);
    initializeFirebaseAnalytics();
    setCookiesConsent(true);
    setSettingsModal(false);
    updateUserSettings({
      ...settings,
      cookie: {
        useMarketingAndAnalytics: options.analytics,
        usePreferences: options.preferences,
      },
    });
  };

  const handleAcceptAll = () => {
    acceptCookies();
    initializeFirebaseAnalytics();
    setCookiesConsent(true);
    updateUserSettings({
      ...settings,
      cookie: { useMarketingAndAnalytics: true, usePreferences: true },
    });
  };

  return (
    <>
      {!cookiesConsent &&
        createPortal(
          <div className='cookie-banner-container'>
            <div className='cookie-banner'>
              <Row className='m-0'>
                <Col>
                  <div className='mb-0 pl-2 cookie-banner-text'>
                    <Trans
                      i18nKey='containers.weUseCookiesToHelpTheSiteProvideABetterUserExperienceLearnMore'
                      components={[
                        <span
                          className='text-secondary cursor-pointer'
                          onClick={() => openUrlInNewTab(URLS.BRIDGE_COOKIES)}
                          rel='noopener noreferrer'
                        />,
                      ]}
                    />
                  </div>
                </Col>
                <div className='d-flex pl-4'>
                  <div className='pr-4'>
                    <SecondaryButton outline onClick={handleReject}>
                      {t('button.rejectAll')}
                    </SecondaryButton>
                  </div>
                  <div className='pr-4'>
                    <SecondaryButton
                      outline
                      onClick={() => setSettingsModal(true)}
                    >
                      {t('button.settings')}
                    </SecondaryButton>
                  </div>
                  <div className='pr-4'>
                    <SecondaryButton onClick={handleAcceptAll}>
                      {t('button.acceptAll')}
                    </SecondaryButton>
                  </div>
                </div>
              </Row>
            </div>
          </div>,
          document.getElementById('root')
        )}
      {settingsModal && (
        <CookieSettings
          isOpened={settingsModal}
          handleSave={(options) => handleSave(options)}
          handleReject={handleReject}
          cookieOptions={consent}
        />
      )}
    </>
  );
};

CookieBanner.propTypes = {
  settings: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
};

export default connect(
  ({ user }) => ({
    settings: user.settings,
  }),
  { updateUserSettings }
)(CookieBanner);
