import React, { useEffect, useState } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';

import { ReactComponent as SmallArrow } from '../../assets/icon/ic-small-arrow.svg';

const LineDropdown = ({
  items,
  placeholder,
  select = '',
  optionalClassname,
  disabled = false,
  onChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(select || placeholder);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (select) {
      setSelected(select);
    }
  }, [select]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        disabled={disabled}
        className={`btn-square w-100 p-0 d-flex justify-content-between align-items-baseline btn-white btn-outline-white line-dropdown ${optionalClassname}`}
      >
        {selected || placeholder}
        <SmallArrow className='icon-secondary' />
      </DropdownToggle>
      <DropdownMenu className='line-dropdown-menu'>
        {items.map(({ name, value }) => (
          <DropdownItem
            onClick={() => {
              onChange(value);
              setSelected(name);
            }}
            className='line-dropdown-item'
          >
            {name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LineDropdown;

LineDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  select: PropTypes.string,
  optionalClassname: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
