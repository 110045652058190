import axios from './axiosClient';
import { DB_API_URL } from '../config';
import { RESPONSE_TYPES } from '../constants';

export const postUploadFile = async (file) => {
  return axios.post(`${DB_API_URL}/api/v1/File/Upload`, file);
};

export const updateFile = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/File/${id}`, data);
};

export const deleteFile = async (id) => {
  return axios.delete(`${DB_API_URL}/api/v1/File/${id}`);
};

export const fetchFileById = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/File/download`, {
    params: {
      id,
    },
    responseType: RESPONSE_TYPES.BLOB,
  });
};

export const fetchFile = async (url) => {
  return axios.get(url, {
    responseType: RESPONSE_TYPES.BLOB,
  });
};
