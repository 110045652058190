import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { NewDone, NewLoading, NewModalStep } from '.';
import { ErrorAlert, OutlinedField } from '../../Shared';
import {
  FIELD_TYPE,
  FIREBASE_EVENTS,
  MAX_NUMBER_OF_CHARACTERS,
} from '../../../constants';
import { postFeedback } from '../../../client/application';
import UploadFiles from '../../Shared/UploadFiles';
import { renameDuplicates } from '../../../utils/file';
import { handleAnalytics } from '../../../utils/analytics';
import { getMappedErrorMessage } from '../../../utils/error';
import { errorIcon } from '../../../assets/icon';

const debug = _debug('Bridge:FeedbackModal');

const STEPS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

const FeedbackModal = ({ isOpened, handleClose }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(STEPS.INITIAL);
  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSend = async () => {
    try {
      setStep(STEPS.LOADING);
      const formData = new FormData();
      formData.append('title', title);
      formData.append('note', notes);

      const modifiedFiles = renameDuplicates(files);

      for (let i = 0; i < modifiedFiles.length; i += 1) {
        formData.append('files', modifiedFiles[i]);
      }

      await postFeedback(formData);
      handleAnalytics(FIREBASE_EVENTS.FEEDBACK_SEND);
      setStep(STEPS.DONE);
    } catch (e) {
      const error = getMappedErrorMessage(e, t);
      setErrorMessage(error);
      setStep(STEPS.ERROR);
    }
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return (
          <NewModalStep
            title={t('modal.feedback')}
            subtitle={t(
              'modal.weWouldReallyAppreciateGettingYourFeedbackPleaseSendOneByReflectingOnTheApplicationOrSpecificFeatures'
            )}
            bodyClassName='d-flex flex-column align-items-center'
            handleCancel={handleClose}
            rightButtonContent={t('button.send')}
            rightButtonDisabled={!title.trim() || !notes.trim()}
            handleClickRightButton={handleSend}
          >
            <div className='w-75 my-2'>
              <div>{t('components.description')}</div>
              <OutlinedField
                placeholder={t('sharedComponents.title')}
                value={title}
                handleClearInput={() => setTitle('')}
                handleChange={(e) => setTitle(e.target.value)}
              />
              <OutlinedField
                placeholder={t('components.notes')}
                value={notes}
                fieldType={FIELD_TYPE.TEXTAREA}
                handleClearInput={() => setNotes('')}
                handleChange={(e) => setNotes(e.target.value)}
                hasCharacterCounter
                maxNumberOfCharacters={MAX_NUMBER_OF_CHARACTERS.TEXT_AREA}
              />
              <UploadFiles
                className='mt-3 mb-5'
                files={files}
                setFiles={setFiles}
                maxNumberOfFiles={3}
                addErrorMessage={setErrorMessage}
              />
            </div>
            {errorMessage && (
              <ErrorAlert
                error={errorMessage}
                setError={setErrorMessage}
                containerClassName='mb-2 w-50'
                className='w-100'
              />
            )}
          </NewModalStep>
        );
      case STEPS.LOADING:
        return <NewLoading value={t('modal.sending')} />;
      case STEPS.DONE:
        return (
          <NewModalStep handleCancel={handleClose}>
            <NewDone title={t('modal.feedbackSent')} height={96} width={96} />
          </NewModalStep>
        );
      case STEPS.ERROR:
        return (
          <NewModalStep handleCancel={handleClose}>
            <NewDone
              title={errorMessage}
              image={errorIcon}
              height={96}
              width={96}
            />
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal
      className='d-flex flex-column justify-content-between p-0'
      isOpened={isOpened}
    >
      {renderStep()}
    </Modal>
  );
};

FeedbackModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FeedbackModal;
