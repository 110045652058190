import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.util, action) {
  const { type } = action;

  switch (type) {
    case types.SET_TRIGGER_REFETCHING_OF_DATA:
      return {
        ...state,
        triggerRefetchingOfData: action.payload.method,
      };
    default:
      return state;
  }
}
