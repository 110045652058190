import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const GroupItemWithCounts = ({ name, productsCount, assembliesCount }) => {
  return (
    <Card>
      <CardBody className='p-3'>
        <Container>
          <Row>
            <Col xs={12} className='pb-2 font-weight-bold'>
              {name}
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              className='text-secondary'
            >{`Products: ${productsCount}`}</Col>
          </Row>
          <Row>
            <Col xs={12} className='text-secondary'>
              {`Assemblies: ${assembliesCount}`}
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

GroupItemWithCounts.propTypes = {
  name: PropTypes.string.isRequired,
  productsCount: PropTypes.number.isRequired,
  assembliesCount: PropTypes.number.isRequired,
};

export default GroupItemWithCounts;
