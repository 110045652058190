import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import { ReactComponent as ReconditionRegrinds } from '../../assets/icon/ic-recondition-instance.svg';
import { displayCalculatedRegrinds } from '../../utils/product';

const ICON_SIZES = {
  sm: 24,
  md: 27,
};

const InstanceReconditionColumn = ({
  reconditionData,
  iconSize = 'md',
  className = '',
}) => {
  const isReconditionWarningActive = () =>
    reconditionData.reconditionCount >=
    (reconditionData.calculatedRegrinds || 0);

  return (
    !!reconditionData && (
      <div className={`instance-recondition-column mr-1 ${className}`}>
        <div>
          <ReconditionRegrinds
            className={cs('recondition-instances-icon', {
              warning: isReconditionWarningActive(),
            })}
            height={ICON_SIZES[iconSize]}
            width={ICON_SIZES[iconSize]}
          />
        </div>
        <span
          className={cs('recondition-label', {
            warning: isReconditionWarningActive(),
          })}
        >
          {reconditionData.reconditionCount}/
          {displayCalculatedRegrinds(reconditionData.calculatedRegrinds)}
        </span>
      </div>
    )
  );
};

InstanceReconditionColumn.propTypes = {
  reconditionData: PropTypes.object,
  iconSize: PropTypes.oneOf(['sm', 'md']),
  className: PropTypes.string,
};

export default InstanceReconditionColumn;
