import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { REGEX } from '../../constants';
import {
  Button,
  ControlledOutlinedField,
  Loader,
  ControlledSelectDropdown,
} from '../Shared';
import { fetchUser } from '../../client/user';
import { findMetadata } from '../../utils/user';

import { getCountries } from '../../redux/actions/applicationActions';

const ShippingDetailsForm = ({
  addressLabel = 'modal.deliveryAddress',
  formRef,
  onSubmitSuccess,
  setFormState = () => {},
  initialValues, // should be undefined first time to fetch organization and users data
  organization,
  countries,
  getCountries,
}) => {
  const [loading, setLoading] = useState(!initialValues || !countries.length);

  const { t } = useTranslation();
  const shippingValidationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .trim()
      .required(t('error.thisFieldIsRequired')),
    street: Yup.string().trim().required(t('error.thisFieldIsRequired')),
    postalCode: Yup.string()
      .trim()
      .required(t('error.thisFieldIsRequired'))
      .matches(REGEX.POSTAL_CODE, t('error.invalidPostalCode')),
    city: Yup.string().trim().required(t('error.thisFieldIsRequired')),
    country: Yup.string().required(t('error.thisFieldIsRequired')),
    customerNumber: Yup.string().nullable(),
    name: Yup.string().trim().required(t('error.thisFieldIsRequired')),
    phoneNumber: Yup.string()
      .trim()
      .nullable()
      .transform((curr, orig) => (orig.trim() === '' ? null : curr))
      .matches(REGEX.PHONE, t('error.invalidPhoneNumber')),
    email: Yup.string()
      .trim()
      .required(t('error.thisFieldIsRequired'))
      .matches(REGEX.EMAIL, t('error.invalidEmail')),
  });

  const { handleSubmit, control, setValue, watch } = useForm({
    resolver: yupResolver(shippingValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    (async () => {
      if (!countries.length) {
        await getCountries(i18next.language === 'se' ? 'sv' : i18next.language);
      }

      if (!initialValues) {
        const { data } = await fetchUser();
        setValue('organizationName', organization.name);
        setValue('street', organization.street);
        setValue('postalCode', organization.postalCode);
        setValue('country', organization.country);
        setValue('city', organization.city);
        setValue('customerNumber', organization.customerNumber || '');
        setValue(
          'name',
          `${data.given_name?.trim()} ${data.family_name?.trim()}`
        );
        setValue('email', data.email);
      }
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to have access to the form values in parent component
  useEffect(() => {
    const subscription = watch((value) => {
      setFormState(value);
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return loading ? (
    <Loader />
  ) : (
    <Form
      className='mx-4 mt-3'
      onSubmit={handleSubmit(onSubmitSuccess)}
      innerRef={formRef}
      noValidate
    >
      <p className='font-weight-bold font-size-md text-txt-primary'>
        {t(addressLabel).toUpperCase()}
      </p>
      <ControlledOutlinedField
        control={control}
        placeholder={t('modal.companyName')}
        name='organizationName'
        required
      />
      <div className='d-flex'>
        <ControlledOutlinedField
          control={control}
          name='street'
          placeholder={t('modal.street')}
          required
          className='w-50 mr-2'
        />
        <ControlledOutlinedField
          control={control}
          name='city'
          placeholder={t('modal.city')}
          required
          className='w-50'
        />
      </div>
      <div className='d-flex'>
        <ControlledSelectDropdown
          control={control}
          name='country'
          getSelectedValue={(value) => findMetadata(countries, value)}
          dropdownOptions={countries.map((country) => ({
            label: country.label,
            value: country.value,
          }))}
          toggle={t('modal.country')}
          containerClassName='w-50 mr-2 mt-3'
          withFloatingLabel
          required
        />
        <ControlledOutlinedField
          control={control}
          name='postalCode'
          placeholder={t('modal.postalCode')}
          required
          className='w-50'
        />
      </div>
      <ControlledOutlinedField
        control={control}
        placeholder={t('modal.customerNumber')}
        name='customerNumber'
      />
      <p className='font-weight-bold font-size-md text-txt-primary mt-4 mb-0'>
        {t('modal.contactInformation').toUpperCase()}
      </p>
      <div className='d-flex'>
        <ControlledOutlinedField
          control={control}
          name='name'
          placeholder={t('modal.firstAndLastName')}
          required
          className='w-50 mr-2'
        />
        <ControlledOutlinedField
          control={control}
          name='phoneNumber'
          placeholder={t('modal.phone')}
          className='w-50'
        />
      </div>
      <ControlledOutlinedField
        control={control}
        name='email'
        placeholder={t('modal.email')}
        required
      />
      <Button type='submit' className='d-none' />
    </Form>
  );
};

ShippingDetailsForm.propTypes = {
  addressLabel: PropTypes.string,
  formRef: PropTypes.any,
  onSubmitSuccess: PropTypes.func.isRequired,
  setFormState: PropTypes.func,
  initialValues: PropTypes.object,
  organization: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  getCountries: PropTypes.func.isRequired,
};

export default connect(
  ({ user, application }) => ({
    organization: user.organization,
    countries: application.countries,
  }),
  { getCountries }
)(ShippingDetailsForm);
