import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import GoBack from './GoBack';
import { nonHookTranslate } from '../../../i18n';
import { back } from '../../../assets/icon';
import { PrimaryButton } from '../../Shared';

const ModalStep = ({
  title,
  subtitle,
  handleGoBack,
  handleCancel,
  cancelButtonText = nonHookTranslate('button.cancel'),
  handleNext,
  nextButtonText,
  nextButtonDisabled = false,
  children,
  isCentered = false,
  goBackIconSize = 26,
  goBackIcon = back,
  stepButtons = [],
}) => {
  return (
    <div className='d-flex flex-column h-100'>
      {!!handleGoBack && (
        <GoBack
          handleGoBack={handleGoBack}
          goBackOptions={{ size: goBackIconSize, icon: goBackIcon }}
        />
      )}
      <div className='modal-title text-center pt-4'>{title}</div>
      {!!subtitle && (
        <div className='modal-subtitle text-center pt-1 pb-5'>{subtitle}</div>
      )}
      <div
        className={`d-flex flex-column flex-grow-1 align-items-center ${
          isCentered ? 'justify-content-center' : ''
        }`}
      >
        {children}
      </div>
      <div className='d-flex align-items-end pb-2 pt-5 justify-content-between'>
        <div className={cs({ 'button-missing-div': !handleCancel })}>
          {!!handleCancel && (
            <PrimaryButton outline onClick={handleCancel}>
              {cancelButtonText}
            </PrimaryButton>
          )}
        </div>
        <div className='d-flex align-items-center h-100'>
          {stepButtons.map(({ active }, index) => (
            <div
              key={index}
              className={cs({
                'modal-step-active': active,
                'modal-step': !active,
                'mr-3': index !== stepButtons.length - 1,
              })}
            />
          ))}
        </div>
        <div className={cs({ 'button-missing-div': !handleNext })}>
          {!!handleNext && (
            <PrimaryButton onClick={handleNext} disabled={nextButtonDisabled}>
              {nextButtonText}
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

ModalStep.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleGoBack: PropTypes.func,
  handleCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  handleNext: PropTypes.func,
  nextButtonText: PropTypes.string,
  nextButtonDisabled: PropTypes.bool,
  isCentered: PropTypes.bool,
  goBackIconSize: PropTypes.number,
  goBackIcon: PropTypes.string,
  stepButtons: PropTypes.array,
  children: PropTypes.any,
};

export default ModalStep;
