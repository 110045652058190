import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ColumnResizer from 'react-column-resizer';

import { PRODUCTS_TABLE_HEADER_MIN_WIDTH } from '../../constants';
import IdemCatalogueRow from './IdemCatalogueRow';

function IdemCatalogueTable({ data, handleCreateIdemCatalogueProduct }) {
  const { t } = useTranslation();

  return (
    <table className='idem-catalogue-table resizable-table'>
      <thead className='header'>
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className='image-column-width' />
          <th className='product-name-initial-width'>
            {t('components.productName')}{' '}
          </th>
          <ColumnResizer
            className='table-resizer'
            minWidth={PRODUCTS_TABLE_HEADER_MIN_WIDTH}
          />
          <th>{t('components.productNumber')}</th>
          <ColumnResizer
            className='table-resizer'
            minWidth={PRODUCTS_TABLE_HEADER_MIN_WIDTH}
          />
          <th>{t('sharedComponents.brand')}</th>
          <ColumnResizer
            className='table-resizer'
            minWidth={PRODUCTS_TABLE_HEADER_MIN_WIDTH}
          />
          <th className='initial-width'>
            {t('sharedComponents.dataProvider')}
          </th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className='data-provider-column-width' />
        </tr>
        <tr>
          <td className='pb-4' />
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({
            externalId,
            name,
            code,
            brand,
            imageUrl,
            nickname,
            dataProviderName,
            dataProviderId,
          }) => {
            return (
              <IdemCatalogueRow
                key={externalId}
                handleClick={async () => {
                  await handleCreateIdemCatalogueProduct({
                    externalId,
                    dataProviderId,
                    nickname,
                    name,
                    imageUrl,
                    code,
                  });
                }}
                name={name}
                number={code}
                brand={brand}
                image={imageUrl}
                dataProviderName={dataProviderName}
              />
            );
          }
        )}
      </tbody>
    </table>
  );
}

IdemCatalogueTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleCreateIdemCatalogueProduct: PropTypes.func.isRequired,
};

export default IdemCatalogueTable;
