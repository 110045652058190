import variables from '../assets/scss/_variables.scss';

export const APP_NAME_STORAGE_KEY = 'app_name';

export const COLORS = {
  PRIMARY: variables.primary,
  SECONDARY: variables.secondary,
  TERTIARY: variables.tertiary,
  WHITE: variables.white,
  BLACK: variables.black,
  BUTTON_DISABLED: variables.buttonDisabled,
  TEXT_PRIMARY: variables.textPrimary,
  TEXT_DISABLED: variables.textDisabled,
  BORDER: variables.borderColor,
  GREEN: variables.slateGreen,
  ORANGE: variables.terraCotta,
};
export const DEFAULT_APP_TITLE = 'Bridge';
