import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import L from 'leaflet';
import { Circle, Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import FullscreenControl from 'react-leaflet-fullscreen';
import MarkerCluster from './MarkerCluster';

import { locationIcon, activeLocationIcon } from '../../assets/icon';
import { MAP } from '../../constants';
import { getMaxBounds } from '../../utils/map';
import { COLORS } from '../../constants/theme';

const unselectedMarker = new L.Icon({
  iconRetinaUrl: locationIcon,
  iconUrl: locationIcon,
  iconSize: [40, 55],
  iconAnchor: [20, 51],
  shadowUrl: markerShadow,
});

const selectedMarker = new L.Icon({
  iconRetinaUrl: activeLocationIcon,
  iconUrl: activeLocationIcon,
  iconSize: [40, 55],
  iconAnchor: [20, 51],
  shadowUrl: markerShadow,
});

const pathOptions = {
  color: COLORS.SECONDARY,
  fill: COLORS.SECONDARY,
  fillOpacity: 0.3,
  stroke: false,
};

const Map = ({
  locations,
  selectedTag,
  handleTagClick,
  selectedPosition,
  selectedAccuracy,
  containerClassName = '',
  className = 'map-dimensions',
}) => {
  const [bounds, setBounds] = useState([
    [-180, -180],
    [180, 180],
  ]);
  const [startLocation, setStartLocation] = useState(null);
  const circleRef = useRef();
  const mapRef = useRef(null);

  const maxBounds = getMaxBounds();

  useEffect(() => {
    // Default bounds will be overridden if there is at least one location
    if (locations.length) {
      // Define positions for deciding boundaries for map initial render (to include all markers in viewpoint)

      const positions = locations
        .filter(
          ({ positionLatitude, positionLongitude }) =>
            positionLatitude !== 0 && positionLongitude !== 0
        )
        .map(({ positionLatitude, positionLongitude }) => [
          positionLatitude,
          positionLongitude,
        ]);

      // If there are no locations, then startLocation will remain null
      if (locations.length) {
        setStartLocation([
          locations[0].positionLatitude,
          locations[0].positionLongitude,
        ]);
      }
      // If there is only 1 location to show, then bounds must remain null, otherwise map will crash
      // since it can't create bounds for 1 point
      if (locations.length !== 1 && positions.length !== 0) {
        setBounds(L.latLngBounds([...positions]));
      }
    }
  }, [locations]);

  useEffect(() => {
    const map = mapRef?.current?.contextValue?.map;
    if (map) {
      // eslint-disable-next-line no-underscore-dangle
      map._onResize();
      // disabling scrolling of other contents on the page when zooming in or out
      map.getContainer().focus = () => {};
    }
  }, []);

  useEffect(() => {
    const map = mapRef?.current?.contextValue?.map;
    if (map && selectedPosition.length) {
      map.setView(selectedPosition, MAP.MAX_ZOOM);
    }
  }, [selectedPosition]);

  return (
    <div className={containerClassName}>
      <LeafletMap
        ref={mapRef}
        center={startLocation}
        bounds={bounds}
        zoom={MAP.ZOOM_LEVEL}
        maxZoom={MAP.MAX_ZOOM}
        minZoom={MAP.MIN_ZOOM}
        className={className}
        maxBounds={maxBounds}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        {selectedTag && selectedAccuracy && !!selectedPosition?.length && (
          <Circle
            ref={circleRef}
            center={selectedPosition}
            radius={selectedAccuracy}
            {...pathOptions}
          />
        )}
        {!!locations.length && (
          <MarkerCluster>
            {locations.map(
              ({
                positionLatitude,
                positionLongitude,
                positionAccuracy,
                tag,
              }) => (
                <Marker
                  key={tag}
                  position={[positionLatitude, positionLongitude]}
                  icon={tag === selectedTag ? selectedMarker : unselectedMarker}
                  shadowPane='shadowPane'
                  onClick={() =>
                    handleTagClick(
                      tag,
                      [positionLatitude, positionLongitude],
                      positionAccuracy
                    )
                  }
                />
              )
            )}
          </MarkerCluster>
        )}
        <FullscreenControl position='topright' forceSeparateButton />
      </LeafletMap>
    </div>
  );
};

Map.propTypes = {
  locations: PropTypes.array.isRequired,
  selectedTag: PropTypes.string,
  handleTagClick: PropTypes.func,
  selectedPosition: PropTypes.array,
  selectedAccuracy: PropTypes.number,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export default Map;
