import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'qs';
import { Auth0 } from '../react-auth0-spa';

import { BACKEND_AUD as API } from '../config';

export const getToken = async () => {
  // TODO: Implement verify JWT
  try {
    return await Auth0.client.getTokenSilently();
  } catch (error) {
    if (
      error.error === 'consent_required' ||
      error.error === 'login_required'
    ) {
      // handle consent required with a getTokenWithPopup
      return Auth0.client.getTokenWithPopup();
    }
    throw error;
  }
};

const axios = () => {
  const client = Axios.create({
    baseURL: API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  const refreshToken = async (failedRequest) => {
    const token = await getToken();
    localStorage.setItem('token', token);

    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
  };

  createAuthRefreshInterceptor(client, refreshToken, {
    statusCodes: [401, 403],
  });

  client.interceptors.request.use((request) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return request;
  });

  return client;
};
const instance = axios();

export default instance;
