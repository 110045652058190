import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CrossIcon } from '../../assets/icon/ic-round-cross.svg';

const ErrorAlert = ({
  error,
  setError,
  containerClassName = '',
  className = '',
}) => {
  const getError = () => {
    return typeof error === 'object' ? error.props.i18nKey : error;
  };

  return (
    getError() && (
      <div
        className={`import-product-data-error-container ${containerClassName}`}
      >
        <div className={`import-product-data-error ${className}`}>
          <div className='h-100 d-flex align-items-center text-break'>
            {error}
          </div>
          <CrossIcon
            onClick={() => setError('')}
            className='icon-txt-primary ml-4 cursor-pointer'
            width={24}
            height={24}
          />
        </div>
      </div>
    )
  );
};

ErrorAlert.propTypes = {
  error: PropTypes.any.isRequired,
  setError: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default ErrorAlert;
