import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.pagination, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PAGINATION_DETAILS_SUCCESS:
      return {
        ...state,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
      };
    case types.RESET_PAGINATION_DETAILS_SUCCESS:
      return {
        ...state,
        currentPage: 1,
        totalPages: 1,
      };
    case types.SET_CURRENT_PAGE_SUCCESS:
      return {
        ...state,
        currentPage: payload.currentPage,
      };
    default:
      return state;
  }
}
