import avatar from './avatar.jpg';
import bridgeLogoSidebar from './BR_logo.svg';
import browse from './img-browse-blue.svg';
import dormerPrametLogo from './Dormer_Pramet_logo.svg';
import dormerPrametLogoSidebar from './DP_logo.svg';
import handScan from './group-2.svg';
import logo from './logo.png';
import masterCamLogo from './mastercamLogo.svg';
import noImage from './noImage.svg';
import sandvikLogo from './sandvik_logo.svg';
import sandvikCoromantLogo from './sandvik-coromant_logo.svg';
import secoLogo from './Seco_logo.svg';
import secoLogoSidebar from './Seco_logo_sidebar.svg';
import stars from './stars.svg';
import toolsUnitedLogo from './TOOLS_UNITED.png';
import walterLogo from './Walter_logo.svg';
import walterLogoSidebar from './Walter.svg';

export {
  avatar,
  bridgeLogoSidebar,
  browse,
  dormerPrametLogo,
  dormerPrametLogoSidebar,
  handScan,
  logo,
  masterCamLogo,
  noImage,
  sandvikLogo,
  sandvikCoromantLogo,
  secoLogo,
  secoLogoSidebar,
  stars,
  toolsUnitedLogo,
  walterLogo,
  walterLogoSidebar,
};
