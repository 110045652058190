import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Modal from '../Modal';
import { NewLoading, NewModalStep } from '../Shared';
import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import { postBoxProductInstances } from '../../../client/box';
import { getMappedErrorMessage } from '../../../utils/error';
import { ROUTES } from '../../../constants';

const debug = _debug(
  'Bridge:AddProductInstanceToBoxFromReconditioningTabModal'
);

const STEPS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

const AddProductInstanceToBoxFromReconditioningTabModal = ({
  handleClose,
  selectedBoxId,
  productInstanceId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [step, setStep] = useState(STEPS.LOADING);
  const [error, setError] = useState('');

  const handleOpenBox = () =>
    history.push(`${ROUTES.RECONDITIONING_BOX}/${selectedBoxId}`, {
      highlightedProductInstanceId: productInstanceId,
    });

  const handleAddProductInstanceToTheBox = async () => {
    try {
      setStep(STEPS.LOADING);
      await postBoxProductInstances(selectedBoxId, [productInstanceId]);
      handleOpenBox();
    } catch (e) {
      const response = getMappedErrorMessage(e);

      setError(response);

      debug(`Couldn't add product instance to the box. Reason: ${e}`);
      setStep(STEPS.ERROR);
    }
  };

  useEffect(() => {
    (async () => {
      handleAddProductInstanceToTheBox();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStep = () => {
    switch (step) {
      case STEPS.LOADING:
        return <NewLoading />;
      case STEPS.ERROR:
        return (
          <NewModalStep
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column text-txt-primary pb-5 justify-content-center'
            rightButtonContent={t('button.cancel')}
            handleClickRightButton={handleClose}
          >
            <WarningIcon
              width={150}
              height={150}
              className='warning-icon icon-danger mb-5'
            />
            <div className='mb-5 px-3'>
              {t(error) || t('error.somethingWentWrong')}
            </div>
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal isOpened className='d-flex flex-column justify-content-center p-0'>
      {renderStep()}
    </Modal>
  );
};

AddProductInstanceToBoxFromReconditioningTabModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedBoxId: PropTypes.string,
  productInstanceId: PropTypes.string.isRequired,
};

export default AddProductInstanceToBoxFromReconditioningTabModal;
