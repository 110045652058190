import React from 'react';

import PropTypes from 'prop-types';
import Img from 'react-image';
import { useTranslation } from 'react-i18next';

import {
  DATA_PROVIDERS,
  EXTERNAL_PROVIDERS_LOGOS,
  URLS,
} from '../../constants';
import { openUrlInNewTab } from '../../utils/shared';

const LogoRow = ({ dataProviderName, code, id }) => {
  const { t } = useTranslation();

  const seco = {
    logo: <Img width={180} height={45} src={EXTERNAL_PROVIDERS_LOGOS.SECO} />,
    url: `${URLS.SECO_TOOLS_ARTICLE}${code}`,
  };

  const toolsUnited = {
    logo: (
      <Img
        width={180}
        height={45}
        src={EXTERNAL_PROVIDERS_LOGOS.TOOLS_UNITED}
      />
    ),
    url: `${URLS.TOOLS_UNITED_ARTICLE}${id}`,
  };

  const walter = {
    logo: <Img width={180} height={45} src={EXTERNAL_PROVIDERS_LOGOS.WALTER} />,
    url: `${URLS.WALTER_ARTICLE}${code}`,
  };

  const sandvikCoromant = {
    logo: (
      <Img
        width={180}
        height={45}
        src={EXTERNAL_PROVIDERS_LOGOS.SANDVIK_COROMANT}
      />
    ),
    url: `${URLS.SANDVIK_COROMANT_ARTICLE}${code}`,
  };

  const dormerPramet = {
    logo: (
      <Img
        width={180}
        height={45}
        src={EXTERNAL_PROVIDERS_LOGOS.DORMER_PRAMET}
      />
    ),
    url: `${URLS.DORMER_PRAMET_ARTICLE}${code}`,
  };

  const renderLogo = () => {
    const logoOptions = [];

    if (dataProviderName === DATA_PROVIDERS.SECO) {
      logoOptions.push(seco);
    } else if (dataProviderName === DATA_PROVIDERS.WALTER) {
      logoOptions.push(walter);
    } else if (dataProviderName === DATA_PROVIDERS.SANDVIK_COROMANT) {
      logoOptions.push(sandvikCoromant);
    } else if (dataProviderName === DATA_PROVIDERS.DORMER_PRAMET) {
      logoOptions.push(dormerPramet);
    } else {
      logoOptions.push(toolsUnited);
    }

    return logoOptions;
  };

  return (
    <div className='pt-4 pb-3 d-flex flex-column logo-row-wrapper'>
      <div className='pb-4 text'>
        {t('modal.viewAdditionalInformationAboutFeaturesClickOnTheCompanyLogo')}
      </div>
      <div className='d-flex flex-wrap logo-row'>
        {renderLogo().map(({ logo, url }) => {
          return (
            <div
              key={id}
              className='mr-2 p-3 cursor-pointer logo-wrapper'
              onClick={() => openUrlInNewTab(url)}
            >
              {logo}
            </div>
          );
        })}
      </div>
    </div>
  );
};

LogoRow.propTypes = {
  dataProviderName: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default LogoRow;
