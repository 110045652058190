import axios from './axiosClient';

import { DB_API_URL, RBAC_API } from '../config';

export const fetchGroups = async () => {
  return axios.get(`${RBAC_API}/ciam/organization/groups`);
};

export const postGroups = async (data) => {
  return axios.post(`${RBAC_API}/ciam/organization/groups`, data);
};

export const patchGroups = async (id, data) => {
  return axios.patch(`${RBAC_API}/ciam/groups/${id}`, data);
};

export const patchOrganization = async (data) => {
  return axios.patch(`${RBAC_API}/ciam/organization`, data);
};

export const postInviteToOrganization = async (data) => {
  return axios.post(`${RBAC_API}/ciam/organization/invite`, data);
};

export const postInvite = async (groupId, data) => {
  return axios.patch(`${RBAC_API}/ciam/groups/${groupId}/members`, data);
};

export const fetchOrganizationDetails = async () => {
  return axios.get(`${RBAC_API}/ciam/organization/`);
};

export const fetchConnectedOrganizations = async () => {
  return axios.get(`${RBAC_API}/ciam/organization/connectedOrganizations`);
};

export const fetchJoinGroup = async (id) => {
  return axios.get(`${RBAC_API}/ciam/groups/join/${id}/`);
};

export const fetchOrganizationMembers = async ({ params }) => {
  return axios.get(`${RBAC_API}/ciam/organization/members/`, { params });
};

export const fetchOrganizationAdmins = async ({ params }) => {
  return axios.get(`${RBAC_API}/ciam/organization/admins/`, { params });
};

export const fetchLeaveGroup = async (id, params) => {
  return axios.get(`${RBAC_API}/ciam/groups/leave/${id}`, { params });
};

export const deleteGroupMembers = async (members, groupId) => {
  return axios.delete(`${RBAC_API}/ciam/groups/${groupId}/members`, {
    data: members,
  });
};

export const deleteGroupAdminRights = async (members, groupId) => {
  return axios.delete(`${RBAC_API}/ciam/groups/${groupId}/admins`, {
    data: members,
  });
};

export const patchGroupAdminRights = async (members, groupId) => {
  return axios.patch(`${RBAC_API}/ciam/groups/${groupId}/admins`, members);
};

export const deleteOrganizationMembers = async (data, params) => {
  return axios.delete(`${RBAC_API}/ciam/organization/members`, {
    params,
    data,
  });
};

export const deleteOrganizationAdminRights = async (members) => {
  return axios.delete(`${RBAC_API}/ciam/organization/admins`, {
    data: members,
  });
};

export const patchOrganizationAdminRights = async (members) => {
  return axios.patch(`${RBAC_API}/ciam/organization/admins`, members);
};

export const deleteCustomerGroup = async (id) => {
  return axios.delete(`${RBAC_API}/ciam/groups/${id}`);
};

export const testLastConnection = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Cribwise/Account/${id}/status`);
};

export const createCribwiseAccount = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Cribwise/Account`, data);
};

export const getProductStockLevelFromCribwise = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Cribwise/Product/${id}`);
};

export const fetchCribwiseAccount = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Cribwise/Account/${id}`);
};

export const putCribwiseAccount = async (data) => {
  return axios.put(`${DB_API_URL}/api/v1/Cribwise/Account`, data);
};
