import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getProductInstances } from '../../redux/actions/productActions';
import { formatDate } from '../../utils/date';
import { Loader, Map, TriangleDownIcon, TriangleUpIcon } from '../Shared';
import {
  FIREBASE_EVENTS,
  HORIZONTAL_TAB_QUERY,
  PERMISSIONS,
  ROUTES,
  VERTICAL_TAB_QUERY,
} from '../../constants';
import { TAB_LABELS } from '../../containers/ProductContainers/const';
import { handleAnalytics } from '../../utils/analytics';
import InstanceReconditionColumn from './InstanceReconditionColumn';
import { generateQueryString } from '../../utils/tabs';
import ReconditionableProductInstanceLocationTagRow from '../ReconditioningComponents/ReconditionableProductInstanceLocationTagRow';
import { getPermissions } from '../../utils/user';

const ReconditionsTab = ({
  id,
  tag,
  getProductInstances,
  instances,
  productNickname,
  productName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = getPermissions();

  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedAccuracy, setSelectedAccuracy] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('_asc');

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getProductInstances(id);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setSelectedTag(tag);
  }, [tag]);

  const handleTagClick = (tag, position, accuracy) => {
    setSelectedTag(tag);
    setSelectedPosition(position);
    setSelectedAccuracy(accuracy);
  };

  const handleSort = async (name) => {
    try {
      window.scroll(0, 0);
      if (sortOrder === '_asc') {
        setSortOrder(`_desc`);
        await getProductInstances(id, `${name}_desc`);
      } else {
        setSortOrder(`_asc`);
        await getProductInstances(id, `${name}_asc`);
      }
      setSortColumn(name);
    } catch (e) {
      // Swallow error
    }
  };

  const handleRowClick = (productId, tag) => {
    handleAnalytics(
      FIREBASE_EVENTS.PRODUCT_INSTANCE_ACCESS_VIA_RECONITIONS_TAB
    );
    history.push({
      pathname: `${ROUTES.DATABASE_PRODUCTS}/${productId}/tag/${tag}`,
      search: generateQueryString({
        [HORIZONTAL_TAB_QUERY]: TAB_LABELS.RECONDITIONING,
        [VERTICAL_TAB_QUERY]: TAB_LABELS.RECONDITIONING,
      }),
    });
  };

  const headers = [
    {
      name: 'scanned_by',
      value: t('components.name'),
      size: 8,
      className: 'name pl-4',
    },
    {
      size: 4,
      className: 'last-scanned pr-3',
      name: 'reconditioning',
      value: `# ${t('containers.reconditionings')}`,
    },
  ];

  const renderTriangleDownIcon = (name) => (
    <TriangleDownIcon onClick={() => handleSort(name)} className='pl-2' />
  );

  const renderTriangleUpIcon = (name) => (
    <TriangleUpIcon onClick={() => handleSort(name)} className='pl-2' />
  );

  return loading ? (
    <Loader />
  ) : (
    <Row className='instances-tab white-background'>
      {permissions.includes(PERMISSIONS.VIEW_LOCATION) && (
        <Col xs={5} className='p-0'>
          <Map
            locations={instances.filter(
              ({ positionLatitude, positionLongitude }) =>
                positionLatitude !== null && positionLongitude !== null
            )}
            selectedTag={selectedTag}
            handleTagClick={(tag, position, accuracy) =>
              handleTagClick(tag, position, accuracy)
            }
            selectedPosition={selectedPosition}
            selectedAccuracy={selectedAccuracy}
            containerClassName='pr-2'
          />
        </Col>
      )}
      <Col xs={7} className='instances-table p-0'>
        <div className='title'>
          <Trans
            i18nKey='sharedComponents.items'
            values={{
              number: instances.length,
            }}
          />
        </div>
        {instances?.length > 0 && (
          <Row className='header d-flex align-items-end justify-content-between px-3 border-bottom m-0'>
            {headers.map(({ className, value, name, sort, size }, i) => (
              <Col key={i} xs={size} className={`p-0 ${className}`}>
                {t(value)}
                {sort &&
                  (name === sortColumn
                    ? sortOrder === '_asc'
                      ? renderTriangleUpIcon(name)
                      : renderTriangleDownIcon(name)
                    : renderTriangleUpIcon(name))}
              </Col>
            ))}
          </Row>
        )}
        <div className='instances-container'>
          {instances.map(
            ({
              productId,
              assemblyInstanceId,
              tag,
              lastScannedAt,
              nickname: instanceNickname,
              positionLatitude,
              positionLongitude,
              positionAccuracy,
              reconditionData,
              isLatestReconditionDataMissing,
              boxId,
            }) => (
              <ReconditionableProductInstanceLocationTagRow
                key={tag}
                assemblyInstanceId={assemblyInstanceId}
                isSelected={tag === selectedTag}
                hasLocation={
                  positionLatitude !== null && positionLongitude !== null
                }
                handleTagClick={() =>
                  handleTagClick(
                    tag,
                    [positionLatitude, positionLongitude],
                    positionAccuracy
                  )
                }
                name={instanceNickname || productNickname || productName}
                tag={tag}
                handleInfoClick={() => handleRowClick(productId, tag)}
                date={
                  formatDate(lastScannedAt) || t('components.itemNotScannedYet')
                }
                column={
                  <InstanceReconditionColumn
                    reconditionData={reconditionData}
                  />
                }
                isLatestReconditionDataMissing={isLatestReconditionDataMissing}
                isProductInstanceInBox={boxId}
              />
            )
          )}
        </div>
      </Col>
    </Row>
  );
};

ReconditionsTab.propTypes = {
  instances: PropTypes.array.isRequired,
  getProductInstances: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  tag: PropTypes.string,
  productNickname: PropTypes.string,
  productName: PropTypes.string,
};

export default connect(
  ({ product }) => ({
    instances: product.instances,
    productNickname: product.details?.nickname,
    productName: product.details?.name,
  }),
  {
    getProductInstances,
  }
)(ReconditionsTab);
