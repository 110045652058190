import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, MoreHorizontal } from 'react-feather';
import {
  Row,
  Col,
  Collapse,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import _get from 'lodash.get';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/date';
import ImageModal from '../Modals/Shared/ImageModal';
import { fetchSupportMessage } from '../../client/messages';
import { SUPPORT_MESSAGE_STATUSES } from '../../constants';
import { getMessageStatus } from '../../utils/product';
import Image from './Image';
import { COLORS } from '../../constants/theme';

const debug = _debug('Bridge:SupportMessage');

const MessageItem = ({
  id,
  name,
  statusCode,
  targetEmail,
  date,
  description,
  updateProductMessage,
  isProductMessage,
  isHighlighted,
}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState(null);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    (async () => {
      if (!images && expanded) {
        const { data } = await fetchSupportMessage(id);
        if (data && data.images) {
          setImages(data.images);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleImageClick = (e, index) => {
    e.stopPropagation();
    setImageModal(true);
    setClickedImageIndex(index);
  };

  const handleChangeStatus = async (statusCode) => {
    try {
      await updateProductMessage(id, statusCode);
      setIsEdited(true);
    } catch (e) {
      debug(
        `Support message update failed. Reason: ${_get(
          e,
          'response.data.error',
          e
        )}`
      );
    }
  };

  return (
    <Container
      className={`cursor-pointer border-bottom align-middle ${
        isEdited || isHighlighted ? 'highlighted' : ''
      }`}
    >
      <Row>
        <Col
          sm={1}
          className='d-flex align-items-center justify-content-start p-0 pr-2'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <ChevronUp size={20} color={COLORS.SECONDARY} />
          ) : (
            <ChevronDown size={20} color={COLORS.TERTIARY} />
          )}
        </Col>
        <Col sm={10} className='p-0 d-flex align-items-center'>
          <Row className='w-100 d-flex' onClick={() => setExpanded(!expanded)}>
            <Col
              sm={3}
              className='d-flex align-items-center d-flex font-weight-bold'
            >
              {isProductMessage ? name : targetEmail}
            </Col>
            <Col sm={3} className='d-flex align-items-center font-weight-bold'>
              {isProductMessage ? t(getMessageStatus(statusCode)) : name}
            </Col>
            <Col sm={3} className='d-flex align-items-center d-flex'>
              {isProductMessage ? targetEmail : t(getMessageStatus(statusCode))}
            </Col>
            <Col
              sm={3}
              className='d-flex align-items-center p-0 font-weight-bold'
            >
              {formatDate(date)}
            </Col>
          </Row>
        </Col>
        <Col
          sm={1}
          className='p-0 d-flex justify-content-end align-items-center'
        >
          <UncontrolledDropdown>
            <DropdownToggle tag='div'>
              <MoreHorizontal
                stroke={COLORS.TEXT_PRIMARY}
                className='cursor-pointer'
                size={36}
              />
            </DropdownToggle>
            <DropdownMenu right>
              {SUPPORT_MESSAGE_STATUSES.map(({ code, title }) => (
                <DropdownItem
                  key={code}
                  onClick={() => handleChangeStatus(code)}
                >
                  {t(title)}
                  <span
                    className={`status-indicator float-right d-flex p-2 ${
                      code === statusCode ? 'bg-secondary' : ''
                    }`}
                  />
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Collapse isOpen={expanded} onClick={() => setExpanded(!expanded)}>
        <Row className='pb-3'>
          <Col
            sm={{ size: 10, offset: 1 }}
            className='p-0 font-weight-lighter font-italic collapsed-message-break'
          >
            {description}
          </Col>
          {images && !!images.length && (
            <Col sm={{ size: 11, offset: 1 }} className='p-0'>
              <div className='py-3 font-weight-bold font'>Attachments</div>
              <Row>
                {images.map((image, index) => (
                  <div key={index} className='pb-3 px-3'>
                    <div
                      onClick={(e) => handleImageClick(e, index)}
                      className='d-flex cursor-pointer support-message-image justify-content-center align-items-center'
                    >
                      <Image src={image.url} height={96} width={96} />
                    </div>
                  </div>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </Collapse>
      {images && !!images.length && (
        <ImageModal
          isOpened={imageModal}
          toggle={() => setImageModal(!imageModal)}
          handleClose={() => setImageModal(false)}
          images={images}
          index={clickedImageIndex}
        />
      )}
    </Container>
  );
};

MessageItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  statusCode: PropTypes.number.isRequired,
  targetEmail: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  description: PropTypes.string,
  isProductMessage: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  updateProductMessage: PropTypes.func.isRequired,
};

export default MessageItem;
