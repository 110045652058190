import React from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/icon/info-icon.svg';
import StepWithIcon from './StepWithIcon';

const InfoStep = (props) => {
  return (
    <StepWithIcon
      {...props}
      icon={<InfoIcon className='big-info-icon mb-3' />}
    />
  );
};

export default InfoStep;
