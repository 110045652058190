import axios from './axiosClient';
import { DB_API_URL } from '../config';

export const fetchSupportMessages = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/SupportMessage/`, { params });
};

export const fetchSupportMessage = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/SupportMessage/${id}`);
};

export const updateSupportMessageStatus = async (id, data) => {
  return axios.patch(`${DB_API_URL}/api/v1/SupportMessage/${id}`, data);
};

export const postSupportMessage = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/SupportMessage/`, data);
};
