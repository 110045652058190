import React from 'react';

import { LIMIT_REACHED_TYPE } from '../constants';
import { ReactComponent as MemberLimitReachedIcon } from '../assets/icon/img_member_limit_reached.svg';
import { ReactComponent as ProductLimitReachedIcon } from '../assets/icon/img_product_limit_reached.svg';
import { ReactComponent as AssemblyLimitReachedIcon } from '../assets/icon/img_assembly_limit_reached.svg';

export const getLimitReachedTitle = (limitReachedType) => {
  switch (limitReachedType) {
    case LIMIT_REACHED_TYPE.PRODUCT:
      return 'sharedComponents.productLimitReached';
    case LIMIT_REACHED_TYPE.ASSEMBLY:
      return 'sharedComponents.assemblyLimitReached';
    case LIMIT_REACHED_TYPE.ORGANISATION_MEMBERS:
      return 'modal.memberLimitReached';
    default:
      return null;
  }
};

export const getLimitReachedSubtitle = (limitReachedType) => {
  switch (limitReachedType) {
    case LIMIT_REACHED_TYPE.PRODUCT:
      return 'modal.youCannotCreateMoreProductsPleaseContactSecoSupportInOrderToChangeYourSubscriptionPlan';
    case LIMIT_REACHED_TYPE.ASSEMBLY:
      return 'modal.youCannotCreateMoreAssembliesPleaseContactSecoSupportInOrderToChangeYourSubscriptionPlan';
    case LIMIT_REACHED_TYPE.ORGANISATION_MEMBERS:
      return 'modal.youCannotAddMoreMembersToThisOrganizationPleaseContactSecoSupportInOrderToChangeYourSubscriptionPlan';
    default:
      return null;
  }
};

export const getLimitReachedImage = (limitReachedType) => {
  switch (limitReachedType) {
    case LIMIT_REACHED_TYPE.PRODUCT:
      return (
        <ProductLimitReachedIcon
          className='limit-reached-icon'
          height={212}
          width={212}
        />
      );
    case LIMIT_REACHED_TYPE.ASSEMBLY:
      return (
        <AssemblyLimitReachedIcon
          className='assembly-limit-reached-icon'
          height={212}
          width={212}
        />
      );
    case LIMIT_REACHED_TYPE.ORGANISATION_MEMBERS:
      return (
        <MemberLimitReachedIcon
          className='member-limit-reached-icon'
          height={212}
          width={212}
        />
      );
    default:
      return null;
  }
};
