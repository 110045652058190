import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.sidebar, actions) {
  switch (actions.type) {
    case types.SIDEBAR_VISIBILITY_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
}
