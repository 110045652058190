import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { changeReaderState } from '../../../redux/actions/readerActions';
import { READER_STATES } from '../../../constants';
import { setError } from '../../../redux/actions/tagActions';
import { removeModalOpenClass } from '../../../utils/domManipulation';
import ErrorStep from './ErrorStep';

const ErrorModal = ({ changeReaderState, error, setError }) => {
  const { t } = useTranslation();

  const handleCancelModal = () => {
    changeReaderState(READER_STATES.GLOBAL);
    setError({
      title: '',
      subtitle: '',
    });
    removeModalOpenClass();
  };

  return (
    <Modal
      className='p-0 d-flex flex-column justify-content-between'
      isOpened={!!error.title}
    >
      <ErrorStep
        handleClose={handleCancelModal}
        title={t(error.title)}
        subtitle={t(error.subtitle)}
        buttonContent={t('button.close')}
      />
    </Modal>
  );
};

ErrorModal.propTypes = {
  setError: PropTypes.func.isRequired,
  changeReaderState: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

export default connect(
  ({ tag }) => ({
    error: tag.error,
  }),
  {
    changeReaderState,
    setError,
  }
)(ErrorModal);
