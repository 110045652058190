import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import * as Yup from 'yup';
import _debug from 'debug';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { addDays, subDays } from 'date-fns';

import WarningStep from '../Shared/WarningStep';
import Modal from '../Modal';
import { ErrorStep, NewDone, NewLoading, NewModalStep } from '../Shared';
import {
  Image,
  DatePicker,
  Loader,
  ControlledOutlinedField,
} from '../../Shared';
import { getMappedErrorMessage } from '../../../utils/error';
import {
  addProductInstanceReconditioningInfo,
  checkProductInstanceReconditionData,
  editProductInstanceReconditioningInfo,
} from '../../../redux/actions/productActions';
import { TAB_LABELS } from '../../../containers/ProductContainers/const';
import {
  BOX_CONSTANTS,
  DATE_FORMATS,
  FIELD_TYPE,
  FIREBASE_EVENTS,
  HORIZONTAL_TAB_QUERY,
  REGEX,
  VERTICAL_TAB_QUERY,
} from '../../../constants';
import SelectDropdown from '../../Shared/SelectDropdown';
import { fetchReconditionDetails } from '../../../client/product';
import { formatDate } from '../../../utils/date';
import { generateQueryString } from '../../../utils/tabs';
import { displayCalculatedRegrinds } from '../../../utils/product';
import { handleAnalytics } from '../../../utils/analytics';

const debug = _debug('Bridge:AddOrEditReconditioningInfoModal');

const STEPS = {
  WARNING: 'WARNING',
  RECONDITIONING_INFO: 'ADD_RECONDITIONING_INFO',
  SAVING_VALUES: 'SAVING_VALUES',
  VALUES_SUCCESSFULLY_SAVED: 'VALUES_SUCCESSFULLY_SAVED',
  ERROR: 'ERROR',
};

const AddOrEditReconditioningInfoModal = ({
  isOpened = true,
  instance,
  editInfo = false,
  handleCancelModal,
  addProductInstanceReconditioningInfo,
  editProductInstanceReconditioningInfo,
  checkProductInstanceReconditionData,
  selectedReconditionData,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const addOrEditReconInfoFormRef = useRef();
  const [step, setStep] = useState(
    editInfo ? STEPS.RECONDITIONING_INFO : STEPS.WARNING
  );
  const [error, setError] = useState('');
  const [reconditionId, setReconditionId] = useState();
  const [reconditionHistory, setReconditionHistory] = useState(null);
  const [selectedRecondition, setSelectedRecondition] = useState(
    selectedReconditionData || null
  );

  const dimensionsValidation = Yup.number()
    .nullable(true)
    .typeError(t('error.thisFieldMustBeNumerical'))
    .transform((curr, orig) => (orig === '' ? null : curr))
    .positive(t('error.youMustEnterPositiveNumber'))
    .test(
      'maxDigitsAfterDecimal',
      t(
        'error.valueCanHaveMaximumOfThreeDecimalsAndIntegerPartOfTheValueNeedsToBeInTheHundredsRange'
      ),
      (number) => number === null || REGEX.DIMENSIONS_RECON_INFO.test(number)
    );

  const shippingValidationSchema = Yup.object().shape({
    length: dimensionsValidation,
    diameter: dimensionsValidation,
    reconditionDate: Yup.date().required(t('error.thisFieldIsRequired')),
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(shippingValidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    (async () => {
      const { data } = await fetchReconditionDetails({
        ProductInstanceId: instance.id,
      });
      setReconditionHistory(data.reconditionHistory.reverse());

      if (editInfo && !selectedReconditionData) {
        setSelectedRecondition(
          data.reconditionHistory[data.reconditionHistory.length - 1]
        );
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedRecondition) {
      (async () => {
        if (
          instance.reconditionData.reconditionHistory.length ===
            selectedRecondition?.reconditionNumber &&
          instance.isLatestReconditionDataMissing
        ) {
          await checkProductInstanceReconditionData(instance.id);
        }
      })();

      setValue('diameter', selectedRecondition.diameter);
      setValue('length', selectedRecondition.length);
      setValue(
        'reconditionDate',
        new Date(selectedRecondition.reconditionedAt)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecondition]);

  const indexOfSelectedRecondition =
    reconditionHistory && selectedRecondition
      ? reconditionHistory.findIndex(({ id }) => id === selectedRecondition.id)
      : null;

  const getReconditionMinDate = () =>
    indexOfSelectedRecondition !== 0 &&
    instance.reconditionData.reconditionCount !== 0
      ? addDays(
          reconditionHistory[
            editInfo
              ? indexOfSelectedRecondition - 1
              : instance.reconditionData.reconditionCount - 1
          ].reconditionedAt,
          1
        )
      : null;

  const getReconditionMaxDate = () =>
    indexOfSelectedRecondition !== null &&
    indexOfSelectedRecondition !== reconditionHistory.length - 1
      ? subDays(
          reconditionHistory[indexOfSelectedRecondition + 1].reconditionedAt,
          1
        )
      : new Date();

  const handleAddOrEditReconditioningInfo = async () => {
    try {
      setStep(STEPS.SAVING_VALUES);

      const values = getValues();
      const data = {
        reconditionedAt: values.reconditionDate.getTime(),
        length: values.length === '' ? null : values.length,
        diameter: values.diameter === '' ? null : values.diameter,
      };

      let reconId;

      if (editInfo) {
        await editProductInstanceReconditioningInfo(
          selectedRecondition.id,
          data,
          selectedRecondition.reconditionNumber
        );
        reconId = selectedRecondition.id;
        handleAnalytics(FIREBASE_EVENTS.RECONDITIONING_INFO_EDITED);
      } else {
        reconId = await addProductInstanceReconditioningInfo({
          productInstanceId: instance.id,
          ...data,
        });
        handleAnalytics(FIREBASE_EVENTS.RECONDITIONING_INFO_ADDED);
      }

      setReconditionId(reconId);

      setStep(STEPS.VALUES_SUCCESSFULLY_SAVED);
    } catch (e) {
      debug(`Couldn't add reconditiong info to the instance. Reason: ${e}`);
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
    }
  };

  const navigateToAsDisposedTab = () => {
    history.push(
      {
        search: generateQueryString({
          [HORIZONTAL_TAB_QUERY]: TAB_LABELS.RECONDITIONING,
          [VERTICAL_TAB_QUERY]: TAB_LABELS.RECONDITIONING,
        }),
      },
      {
        highlightedIds:
          editInfo &&
          (reconditionId !== instance.reconditionData.reconditionCount) !==
            selectedRecondition.reconditionNumber
            ? [reconditionId]
            : ['reconditioned', 'current-dimensions', reconditionId],
      }
    );
  };

  const handleSelectedReconditionChange = (recondition) => {
    if (!selectedRecondition.id || recondition.id !== selectedRecondition.id) {
      setSelectedRecondition(recondition);
    }
  };

  const getReconditioningLabel = (recondition) =>
    `${t('modal.reconditioning')} ${
      recondition.reconditionNumber
    }/${displayCalculatedRegrinds(
      instance.reconditionData.calculatedRegrinds
    )}`;

  const renderStep = () => {
    switch (step) {
      case STEPS.WARNING:
        return (
          <WarningStep
            handleClose={handleCancelModal}
            text1={t(
              'modal.proceedOnlyInCaseThisProductHasJustBeenReconditioned'
            )}
            leftButtonContent={t('button.cancel')}
            handleClickLeftButton={handleCancelModal}
            rightButtonContent={t('button.proceed')}
            handleClickRightButton={() => setStep(STEPS.RECONDITIONING_INFO)}
          />
        );
      case STEPS.RECONDITIONING_INFO:
        return (
          <NewModalStep
            title={t(
              editInfo
                ? 'modal.editReconditioningInfo'
                : 'modal.addReconditioningInfo'
            )}
            leftButtonContent={t('button.cancel')}
            rightButtonContent={t('button.save')}
            handleClickLeftButton={handleCancelModal}
            handleClickRightButton={() =>
              addOrEditReconInfoFormRef.current.requestSubmit()
            }
            rightButtonDisabled={editInfo && !isDirty}
            handleCancel={handleCancelModal}
            isCenteredVertically
            bodyClassName='d-flex flex-column'
          >
            {!reconditionHistory || (editInfo && !selectedRecondition) ? (
              <Loader />
            ) : (
              <>
                <div className='d-flex justify-content-center'>
                  <Image
                    src={instance.imageUrl}
                    hasBadge
                    height={60}
                    width={60}
                    badgeSize='sm'
                    className='text-center border border-secondary rounded-xl'
                    containerClassName='image-object-fit-content image-object-fit-height mt-1'
                  />
                  <div className='d-flex flex-column recon-info-font text-txt-primary ml-4'>
                    <span className='text-opacity-recon-info'>
                      {t('modal.productName')}
                    </span>
                    <span className='font-weight-bold'>
                      {instance.nickname ||
                        instance.productNickname ||
                        instance.productName}
                    </span>
                    <span className='text-opacity-recon-info'>
                      {t('modal.productNumber')}
                    </span>
                    <span className='font-weight-bold'>{instance.code}</span>
                  </div>
                </div>
                {!editInfo && (
                  <div className='d-flex w-95 align-self-center add-recon-info-warning justify-content-center py-2 mt-4'>
                    <div className='d-flex w-65 recon-info-font text-white text-center'>
                      {t(
                        'modal.continueOnlyInCaseThisProductHasJustBeenReconditionedAndNewToolDimensionsHaveToBeReported'
                      )}
                    </div>
                  </div>
                )}
                <div className='text-txt-primary justify-content-center my-3 w-70'>
                  <div className='flex-column'>
                    {editInfo && (
                      <SelectDropdown
                        dropdownOptions={reconditionHistory.map(
                          (recondition) => ({
                            label: getReconditioningLabel(recondition),
                            value: recondition.id,
                            handleClick: () =>
                              handleSelectedReconditionChange(recondition),
                            suffix: formatDate(
                              recondition.reconditionedAt,
                              DATE_FORMATS.DATE
                            ),
                            isSelected:
                              selectedRecondition &&
                              selectedRecondition.id === recondition.id,
                          })
                        )}
                        toggle={t('modal.selectReconditioning')}
                        containerClassName='w-100 mb-2'
                      />
                    )}
                    <span>{t('modal.reconditioning')}</span>
                    <div
                      className={cs(`reconditions-number-card mt-2`, {
                        'max-limit-reached':
                          (editInfo
                            ? selectedRecondition.reconditionNumber
                            : instance.reconditionData.reconditionCount + 1) >=
                          (instance.reconditionData.calculatedRegrinds || 0),
                      })}
                    >{`${
                      editInfo
                        ? selectedRecondition.reconditionNumber
                        : instance.reconditionData.reconditionCount + 1
                    }/${displayCalculatedRegrinds(
                      instance.reconditionData.calculatedRegrinds
                    )}`}</div>
                    <span className='my-2'>{t('modal.addToolDimensions')}</span>
                    <Form
                      innerRef={addOrEditReconInfoFormRef}
                      onSubmit={handleSubmit(handleAddOrEditReconditioningInfo)}
                      noValidate
                    >
                      <ControlledOutlinedField
                        control={control}
                        placeholder={`${t('modal.length')} (${
                          BOX_CONSTANTS.DIMENSION_UNIT
                        })`}
                        name='length'
                        inputClassName='recon-info-outlined-field'
                        fieldType={FIELD_TYPE.NUMBER}
                      />
                      <ControlledOutlinedField
                        placeholder={`${t('modal.diameter')} (${
                          BOX_CONSTANTS.DIMENSION_UNIT
                        })`}
                        name='diameter'
                        control={control}
                        className='mb-3'
                        inputClassName='recon-info-outlined-field'
                        fieldType={FIELD_TYPE.NUMBER}
                      />
                      <span className='mt-2'>{t('modal.date*')}</span>
                      <Controller
                        name='reconditionDate'
                        control={control}
                        onClick={(e) => e.preventDefault()}
                        error={errors.reconditionDate?.message}
                        defaultValue={new Date()}
                        render={({ field: { onChange, value, name } }) => (
                          <DatePicker
                            handleUpdateDate={onChange}
                            handleRemoveDate={() => setValue(name, null)}
                            value={value}
                            errorMessage={errors.reconditionDate?.message}
                            maxDate={getReconditionMaxDate()}
                            minDate={getReconditionMinDate()}
                            containerClassName='w-50 mt-3'
                          />
                        )}
                      />
                    </Form>
                  </div>
                </div>
              </>
            )}
          </NewModalStep>
        );
      case STEPS.SAVING_VALUES:
        return <NewLoading value={t('modal.savingValues')} />;
      case STEPS.VALUES_SUCCESSFULLY_SAVED:
        return (
          <NewModalStep
            handleCancel={() => {
              handleCancelModal();
              navigateToAsDisposedTab();
            }}
            rightButtonContent={t('button.done')}
            handleClickRightButton={() => {
              handleCancelModal();
              navigateToAsDisposedTab();
            }}
          >
            <NewDone
              title={t('modal.valuesSuccessfullySaved')}
              height={96}
              width={96}
            />
          </NewModalStep>
        );
      case STEPS.ERROR:
        return (
          <ErrorStep
            handleButton={handleCancelModal}
            handleClose={handleCancelModal}
            title={t(error)}
          />
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal
      className='d-flex flex-column justify-content-between p-0'
      isOpened={isOpened}
    >
      {renderStep()}
    </Modal>
  );
};

AddOrEditReconditioningInfoModal.propTypes = {
  handleCancelModal: PropTypes.func.isRequired,
  addProductInstanceReconditioningInfo: PropTypes.func.isRequired,
  checkProductInstanceReconditionData: PropTypes.func.isRequired,
  editProductInstanceReconditioningInfo: PropTypes.func.isRequired,
  editInfo: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  instance: PropTypes.object.isRequired,
  selectedReconditionData: PropTypes.object,
};

export default connect(
  ({ product }) => ({
    instance: product.instance,
  }),
  {
    addProductInstanceReconditioningInfo,
    editProductInstanceReconditioningInfo,
    checkProductInstanceReconditionData,
  }
)(AddOrEditReconditioningInfoModal);
