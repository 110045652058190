import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';

const Auth = ({ children }) => (
  <div className='main h-100 w-100'>
    <Container className='h-100'>
      <Row className='h-100'>
        <Col sm={10} md={8} lg={6} className='mx-auto d-table h-100'>
          <div className='d-table-cell align-middle'>{children}</div>
        </Col>
      </Row>
    </Container>
  </div>
);

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Auth;
