import { SEARCH_FILTER_OPTIONS } from '../../components/DatabaseComponents/constants';

export const DATABASE_DASHBOARD_TAB_LABELS = {
  PRODUCTS: 'products',
  ASSEMBLIES: 'assemblies',
};

export const DATABASE_DASHBOARD_TAB_LABELS_TRANS = {
  PRODUCTS: 'containers.products',
  ASSEMBLIES: 'containers.assemblies',
};

export const MY_PRODUCTS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'containers.couldNotLoadProducts',
    subtitle:
      'containers.thereWasAConnectionErrorWhileTryingToLoadYourProducts',
  },
  NO_ITEMS_FOUND: {
    title: 'containers.thereAreNoProductsToView',
    subtitle:
      'containers.selectProductCatalogInAdvancedSearchOptionsAndTypeTheNameOfTheProductNameOrNumberInTheSearchAndCreateFieldToAddTheProduct',
  },
  NO_SEARCH_RESULTS: {
    title: 'containers.noProductsFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyOfYourProducts',
  },
};

export const EXTERNAL_PRODUCTS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'containers.couldNotLoadProducts',
    subtitle:
      'containers.thereWasAConnectionErrorWhileTryingToLoadYourProducts',
  },
  NO_ITEMS_FOUND: {
    title: 'containers.searchAndAddProduct',
    subtitle:
      'containers.typeTheNameOfTheProductNameOrNumberInTheSearchAndCreateFieldToAddTheProduct',
  },
  NO_SEARCH_RESULTS: {
    title: 'containers.noProductsFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyOfYourProducts',
  },
};

export const ASSEMBLIES_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'containers.couldNotLoadAssemblies',
    subtitle:
      'containers.thereWasAConnectionErrorWhileTryingToLoadYourAssemblies',
  },
  NO_ITEMS_FOUND: {
    title: 'containers.thereAreNoAssembliesToView',
    subtitle:
      'containers.selectCreateAssemblyRecipeInSearchAndCreateFilterOptionsToDefineAnAssemblyOfProducts',
  },
  NO_SEARCH_RESULTS: {
    title: 'containers.noAssembliesFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyOfYourAssemblies',
  },
};

export const SELECTED_TAB_STORAGE_KEY = 'items-page-selected-tab';

export const PRODUCTS_SEARCH_FILTERS = [
  SEARCH_FILTER_OPTIONS.PRODUCTS_BRIDGE_DATABASE.KEY,
  SEARCH_FILTER_OPTIONS.PRODUCTS_EXTERNAL_DATABASE.KEY,
];

export const BRIDGE_SUPPORT_EMAIL = 'bridge.support@sandvik.com';
