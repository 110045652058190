import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { THEME_COLORS_NAMES } from '../../constants';

const Loader = ({ size = '', containerClassName = 'h-100' }) => (
  <div
    className={`${containerClassName} d-flex justify-content-center align-items-center`}
  >
    <Spinner size={size} color={THEME_COLORS_NAMES.TERTIARY} />
  </div>
);

Loader.propTypes = {
  size: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default Loader;
