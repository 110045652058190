import axios from './axiosClient';
import { DB_API_URL } from '../config';

export const fetchInspectionDetails = async (boxId, orderId) => {
  return axios.get(
    `${DB_API_URL}/api/v1/Inspection?orderId=${orderId}&boxId=${boxId}`
  );
};

export const inspectProductInstance = async (productInstanceId, data) => {
  return axios.put(
    `${DB_API_URL}/api/v1/Inspection/ProductInstance/${productInstanceId}`,
    data
  );
};

export const inspectBox = async (inspectionId, data) => {
  return axios.put(`${DB_API_URL}/api/v1/Inspection/${inspectionId}`, data);
};

export const verifyProductInstance = async (productInstanceId, data) => {
  return axios.put(
    `${DB_API_URL}/api/v1/Inspection/ProductInstance/${productInstanceId}/Verify`,
    data
  );
};

export const addProductInstanceToBoxInInspection = async (id, data) => {
  return axios.post(
    `${DB_API_URL}/api/v1/Inspection/ProductInstance/${id}`,
    data
  );
};

export const verifyBox = async (inspectionId, data) => {
  return axios.put(
    `${DB_API_URL}/api/v1/Inspection/${inspectionId}/Verify`,
    data
  );
};
