import i18next from 'i18next';
import FileSaver from 'file-saver';
import debug from 'debug';

import {
  CUSTOMER_ORDER_STATUSES,
  FALLBACK_STATUS,
} from '../containers/ReconditioningContainers/constants';
import {
  RECON_CENTER_ORDER_STATUSES,
  STATUSES_WITH_TRACKING_INFO_INCOMING_TAB,
  STATUSES_WITH_TRACKING_INFO_SHIPPING_TAB,
} from '../containers/ReconditioningCenterContainers/constants';
import { generateQueryString } from './tabs';
import { getInspectionReport } from '../client/order';
import { getFileNameFromHeaders } from './resource';
import { formatDate } from './date';
import { DATE_FORMATS, PERMISSIONS } from '../constants';
import { getPermissions } from './user';

export const findStatusDetails = (allStatuses, status) =>
  Object.values(allStatuses).find(
    (statusDetails) => status === statusDetails.KEY
  ) || FALLBACK_STATUS;

export const getOrderCustomerStatusDetails = (status) =>
  findStatusDetails(CUSTOMER_ORDER_STATUSES, status);

export const getOrderReconCenterStatusDetails = (status) =>
  findStatusDetails(RECON_CENTER_ORDER_STATUSES, status);

export const displayGeneralComment = (generalComment) =>
  generalComment?.notes || i18next.t('modal.noGeneralCommentAdded');

export const displayRcGeneralComment = (rcGeneralComment) =>
  rcGeneralComment?.notes || i18next.t('components.noReconCenterFeedbackAdded');

export const handleOpenParentRCOrdersListPageByStatus = (
  reconditionCenterStatus,
  history,
  tab
) => {
  const orderStatusDetails = getOrderReconCenterStatusDetails(
    reconditionCenterStatus
  );

  history.push({
    pathname: orderStatusDetails.SIDEBAR_OPTION.PATH,
    search: generateQueryString(tab || orderStatusDetails.TAB),
  });
};

export const exportSoultzInspectionReport = async (orderId, boxId) => {
  try {
    const { data, headers } = await getInspectionReport(
      orderId,
      boxId ? { FilterBox: [boxId] } : {}
    );

    FileSaver.saveAs(data, getFileNameFromHeaders(headers));
  } catch (e) {
    debug(`Couldn't download report. Reason: ${e}`);
  }
};

export const getReconCenterDateColumnDataByOrderStatus = (status) => {
  if (
    [
      ...STATUSES_WITH_TRACKING_INFO_INCOMING_TAB,
      ...STATUSES_WITH_TRACKING_INFO_SHIPPING_TAB,
    ].includes(status)
  ) {
    return {
      dateColumnNameTranslation: 'components.estRcArrivalDate',
      dateColumnValue: 'est_delivery',
    };
  }
  if (status === RECON_CENTER_ORDER_STATUSES.COMPLETED.KEY) {
    return {
      dateColumnNameTranslation: 'components.completedDate',
      dateColumnValue: 'completed_at',
    };
  }
  return {
    dateColumnNameTranslation: 'components.statusChange',
    dateColumnValue: 'status_updated_at',
  };
};

export const showReconCenterDateColumnValue = (status, order) => {
  if (
    [
      ...STATUSES_WITH_TRACKING_INFO_INCOMING_TAB,
      ...STATUSES_WITH_TRACKING_INFO_SHIPPING_TAB,
    ].includes(status)
  ) {
    if (!order.estimatedDeliveryDate) {
      return i18next.t('modal.noData');
    }
    return formatDate(order.estimatedDeliveryDate, DATE_FORMATS.DATE);
  }
  if (status === RECON_CENTER_ORDER_STATUSES.COMPLETED.KEY) {
    if (!order.completedAt) {
      return i18next.t('modal.noData');
    }
    return formatDate(order.completedAt, DATE_FORMATS.DATE);
  }

  if (!order.statusUpdatedAt) {
    return i18next.t('modal.noData');
  }
  return formatDate(order.statusUpdatedAt, DATE_FORMATS.DATE);
};

export const canUserUpdateOrderStatus = () => {
  const permissions = getPermissions();

  return permissions.includes(PERMISSIONS.UPDATE_RECONDITION_ORDER_STATUS);
};
