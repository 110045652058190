import {
  sandvikCoromantLogo,
  dormerPrametLogo,
  secoLogo,
  toolsUnitedLogo,
  walterLogo,
  bridgeLogoSidebar,
  dormerPrametLogoSidebar,
  walterLogoSidebar,
  secoLogoSidebar,
  masterCamLogo,
  sandvikLogo,
} from '../assets/img';
import { TAB_LABELS } from '../containers/ProductContainers/const';
import { COLORS } from './theme';
import { getThemeHexColor } from '../utils/theme';
import { DATABASE_DASHBOARD_TAB_LABELS } from '../containers/DatabaseContainers/constants';
import { RECON_DASHBOARD_TAB_LABELS } from '../containers/ReconditioningContainers/constants';

export const PERMISSIONS = {
  CREATE_GROUP: 'group:create',
  GROUP_INVITE: 'group:invite',
  ORGANIZATION_INVITE: 'organization:invite',
  PRODUCT_IMPORT: 'product:import',
  VIEW_LOCATION: 'location:view',
  VIEW_SHARE: 'share:all',
  TEST_SCAN: 'test:scan',
  PRODUCT_CREATE: 'product:create',
  ASSEMBLY_CREATE: 'assembly:create',
  WEBAPP_ACCESS: 'webapp:access',
  NOTIFICATION_ALL: 'notification:all',
  NOTIFICATION_THREADS: 'notification:threads',
  NOTIFICATION_CONDITION_RATING: 'notification:condition_rating',
  NOTIFICATION_TIME_TRACKER: 'notification:time_tracker',
  NOTIFICATION_ORDERS: 'notification:orders',
  DELETE_PRODUCT: 'product:delete',
  UPDATE_PRODUCT: 'product:update',
  UPDATE_TAG: 'tag:update',
  DELETE_TAG: 'tag:delete',
  TRANSFER_TAG: 'product_instance:transfer',
  DELETE_ASSEMBLY: 'assembly:delete',
  UPDATE_ASSEMBLY: 'assembly:update',
  VIEW_ASSEMBLY: 'assembly:view',
  VIEW_THREADS: 'thread:read',
  CREATE_THREAD: 'thread:create',
  UPDATE_THREAD: 'thread:update',
  DELETE_THREAD: 'thread:delete',
  VIEW_CREATE_THREAD_ON_THREADS_TAB: 'create_thread_on_threads_tab:ui',
  VIEW_UPDATE_THREAD_ON_THREADS_TAB: 'update_thread_on_threads_tab:ui',
  VIEW_DELETE_THREAD_ON_THREADS_TAB: 'delete_thread_on_threads_tab:ui',
  CRIBWISE: 'cribwise:ui',
  CREATE_SYNC: 'sync:create',
  READ_STATUS: 'status:read',
  SCAN: 'scan:all',
  WEB_CAMERA_SCAN: 'web_camera:scan',
  SEND_FEEDBACK: 'feedback:create',
  VIEW_RECYCLING: 'recycling:view',
  VIEW_CO2_FOOTPRINT: 'co2_footprint:view',
  VIEW_AS_PRODUCED: 'as_produced:view',
  VIEW_SIDEBAR_DESCRIPTION: 'homepage_description:view',

  VIEW_HISTORY: 'history:ui',
  READ_GROUP: 'group:read',
  UPDATE_CONDITION_RATING: 'condition_rating:update',
  UPDATE_TIME_TRACKER: 'time_tracker:update',
  UPDATE_FILE: 'file:update',

  // Sidebar
  VIEW_DATABASE_IN_SIDEBAR: 'database_sidebar:ui',
  VIEW_RECONDITIONING_IN_SIDEBAR: 'reconditioning_sidebar:ui',
  VIEW_INCOMING_IN_SIDEBAR: 'incoming_sidebar:ui',
  VIEW_INSPECTIONS_IN_SIDEBAR: 'inspections_sidebar:ui',
  VIEW_RC_RECONDITIONING_IN_SIDEBAR: 'rc_reconditioning_sidebar:ui',
  VIEW_SHIPPED_IN_SIDEBAR: 'shipped_sidebar:ui',
  VIEW_ARCHIVE_IN_SIDEBAR: 'archive_sidebar:ui',
  VIEW_HISTORY_IN_SIDEBAR: 'history_sidebar:ui',
  VIEW_GROUPS_IN_SIDEBAR: 'groups_sidebar:ui',
  VIEW_INBOX_IN_SIDEBAR: 'inbox_sidebar:ui',
  VIEW_LEGAL_INSPECTION_IN_SIDEBAR: 'legal_inspection_sidebar:ui',
  VIEW_RESPONSE_IN_SIDEBAR: 'response_sidebar:ui',
  VIEW_LEGAL_ARCHIVE_IN_SIDEBAR: 'legal_archive_sidebar:ui',

  // Customer reconditioning
  VIEW_RECONDITION: 'recondition:ui',
  CREATE_BOX: 'box:create',
  UPDATE_BOX: 'box:update',
  DELETE_BOX: 'box:delete',
  ORDER_BOX: 'box:order',
  RESET_BOX: 'box:reset',
  ADD_PRODUCT_TO_BOX: 'box:add_product_instance',
  REMOVE_PRODUCT_FROM_BOX: 'box:remove_product_instance',
  CREATE_ORDER: 'recondition_order:create',
  ADD_BOX_TO_ORDER: 'recondition_order:add_box',
  CREATE_RECONDITION: 'recondition:create',
  UPDATE_RECONDITION: 'recondition:update',

  // RC
  INSPECT_PRODUCT_INSTANCE: 'product_instance:inspect',
  VERIFY_PRODUCT_INSTANCE: 'product_instance:verify',
  INSPECT_BOX: 'box:inspect',
  VERIFY_BOX: 'box:verify',
  CREATE_ORDER_REPORT: 'recondition_order_report:create',
  UPDATE_RECONDITION_ORDER: 'recondition_order:update',
  UPDATE_RECONDITION_ORDER_STATUS: 'recondition_order_bulk_status:update',
  CREATE_RC_FEEDBACK: 'rc_feedback:create',

  // Counterfeit
  READ_COUNTERFEIT: 'counterfeit:read',
  CREATE_COUNTERFEIT: 'counterfeit:create',
  UPDATE_COUNTERFEIT: 'counterfeit:update',
  VIEW_ASSIGN_TO: 'assign_to:view',
  CONCLUSION_ACCESS: 'conclusion_access:all',

  // End customer
  VIEW_AUTHENTICATION_REQUESTS: 'authentication_requests:ui',
};

export const READER_STATES = {
  GLOBAL: 'GLOBAL',
  SYNC: 'SYNC',
  CREATE_ASSEMBLY: 'CREATE_ASSEMBLY',
  SYNC_PRODUCT: 'SYNC_PRODUCT',
  SYNC_BOX: 'SYNC_BOX',
  SYNC_ASSEMBLY: 'SYNC_ASSEMBLY',
  SYNC_NEW_TAG_TO_PRODUCT_INSTANCE: 'SYNC_NEW_TAG_TO_PRODUCT_INSTANCE',
  ERROR: 'ERROR',
  ADD_MESSAGE: 'ADD_MESSAGE',
  SCAN_ON_INSPECTION: 'SCAN_ON_INSPECTION',
  SCAN_ON_VERIFICATION: 'SCAN_ON_VERIFICATION',
  SCAN_CODE: 'SCAN_CODE',
};

export const SUPPORTED_IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/gif',
];

// When Cribwise becomes activated this should be removed
export const CRIBWISE_ACTIVATED = false;

export const PRODUCTS_PER_PAGE = 10;
export const EXTERNAL_PRODUCTS_PER_PAGE = 20;
export const ASSEMBLIES_PER_PAGE = 10;
export const TAGS_PER_PAGE = 25;
export const MESSAGES_PER_PAGE = 40;
export const PROPERTIES_PER_PAGE = 25;
export const BOXES_PER_PAGE = 30;
export const ORDERS_PER_PAGE = 30;
export const REQUESTS_PER_PAGE = 30;
export const BOX_INSTANCES_PER_PAGE = 30;
export const POST_REPORT_BOX_DETAILS_BOX_INSTANCES_PER_PAGE = 200;
export const NOTIFICATIONS_PER_PAGE = 10;
export const INSPECTION_PRODUCT_INSTANCES_PER_PAGE = 200;

export const MODAL_ACTION_WAIT_TIME = 2600;
export const MODAL_ACTION_WAIT_TIME_SHORT = 1200;
export const MODAL_CONTENT_ID = 'modal-content-div';

export const DATA_PROVIDERS = {
  TOOLS_UNITED: 'ToolsUnited',
  SECO: 'Seco',
  WALTER: 'Walter',
  SANDVIK_COROMANT: 'SandvikCoromant',
  DORMER_PRAMET: 'DormerPramet',
};

export const CAMERA_STATE = {
  LOADING: 'LOADING',
  ON: 'ON',
  OFF: 'OFF',
  REQUESTING_PERMISSION: 'REQUESTING_PERMISSION',
  NO_PERMISSION: 'NO_PERMISSION',
};

export const INSTANCE_CODE_TYPES = {
  DATA_MATRIX: 'DATA_MATRIX',
};

export const TEMPLATE_CODE_TYPES = {
  QR_CODE: 'QR Code',
  CODE_39: 'CODE_39',
  CODE_93: 'CODE_93',
  CODE_128: 'CODE_128',
  EAN_13: 'EAN_13',
  EAN_8: 'EAN_8',
  ITF: 'ITF',
};

export const GEOLOCATION_TIMEOUT = 1000;
export const GEOLOCATION_MAX_CACHE_AGE = 1000 * 30; // 30 seconds

export const INTERNATIONALIZATION = [
  {
    language: 'English',
    code: 'en',
  },
  {
    language: 'German',
    code: 'de',
  },
  {
    language: 'French',
    code: 'fr',
  },
  {
    language: 'Swedish',
    code: 'se',
    disabled: true,
  },
  {
    language: 'Spanish',
    code: 'es',
    disabled: true,
  },
  {
    language: 'Japanese',
    code: 'ja',
    disabled: true,
  },
  {
    language: 'Chinese',
    code: 'zh',
    disabled: true,
  },
  {
    language: 'Italian',
    code: 'it',
    disabled: true,
  },
  {
    language: 'Turkish',
    code: 'tr',
    disabled: true,
  },
];

export const ERROR_MESSAGES_BACKEND = {
  USER_NOT_MEMBER_OF_ANY_GROUP: 'User is not a member of any group.',
  USER_LAST_ADMIN_OF_GROUPS: 'User is last admin of groups',
  PRODUCT_MUST_BE_UNIQUE_IN_GROUP: 'Code and nickname must be unique in group.',
  GROUP_NAME_EXISTS: 'Validation error',
  USER_ALREADY_IN_ORGANIZATION: 'User is already part of this organization',
  SELECT_NEW_ADMIN: 'Please assign new admin and try again',
  COMMENT_LENGTH_INVALID: 'Comment length must be between 1 and 800.',
  TAG_IS_OCCUPIED: 'Entity identifier: Tag is occupied.',
  PRODUCT_NAME_ALREADY_EXISTS:
    'Product with the same name, code and nickname already exists in the group',
  NICKNAME_MUST_BE_UNIQUE_IN_GROUP: 'Nickname must be unique in group.',
  NICKNAME_MUST_BE_UNIQUE_IN_PRODUCT: 'Nickname must be unique in product.',
  ASSEMBLY_WITH_THE_SAME_NAME_ALREADY_EXISTS_IN_THIS_GROUP:
    'Assembly with the same name already exist in this group.',
  NAME_CANNOT_BE_EMPTY: 'Name cannot be empty!',
  ORGANISATION_NAME_LENGTH_INVALID: 'Length must be: 3 <= length <= 128',
  INVALID_EMAIL: 'Invalid email address.',
  GROUP_NAME_LENGTH_INVALID: 'Length must be: 3 <= length <= 50',
  GROUP_DESCRIPTION_INVALID: 'Length must be <= 500',
  FIRST_NAME_LENGTH_INVALID:
    'First name length must be less than 150 characters',
  LAST_NAME_LENGTH_INVALID: 'Last name length must be less than 150 characters',
  EMAIL_MUST_BE_VALID: 'Email must be valid',
  EMAIL_ALREADY_IN_USE: 'Email already in use.',
  SPECIFIED_GROUP_DOESNT_BELONG_TO_USERS_ORGANIZATION:
    "Specified group doesn't belong to user's organization!",
  PRODUCT_MESSAGE_DESCRIPTION_LENGTH_INVALID:
    'The field Description must be a string with a maximum length of 800.',
  INVALID_IMAGE_FILE_TYPE: 'Invalid image.',
  YOU_CANNOT_INVITE_USERS_TO_DEMO_ORGANISATION:
    'You can not invite user in DEMO organization.',
  MUST_CONFIRM_DELETION: 'You must confirm deletion of empty groups!',
  ORGANIZATION_WITH_THAT_NAME_ALREADY_EXISTS:
    'Organization with that name already exists',
  ASSEMBLY_NAME_LENGTH_INVALID_EDIT:
    'Name cannot be longer than 255 characters!',
  ASSEMBLY_NAME_LENGTH_INVALID_CREATE:
    "The field Name must be a string or array type with a maximum length of '255'.",
  INVALID_FILE_NAME: 'Invalid file name.',
  THE_SPECIFIED_NEW_USERNAME_ALREADY_EXISTS:
    'The specified new username already exists',
  EMPTY_GROUP_NAME: 'Group name is required!',
  INVALID_TITLE_LENGTH: 'Title length must be between 1 and 255.',
  INVALID_DUE_DATE: 'Invalid due date.',
  NO_CRIBWISE_ACCOUNT:
    'There is no cribwise account connected to the organization.',
  CANNOT_FIND_SHEET_DATA_TO_IMPORT_ERROR:
    "Cannot find sheet named 'Data to import' in xlsx file.",
  INVALID_FILE_TYPE: 'Unsupported file type.',
  INVALID_EXCEL_FILE: 'Invalid excel file.',
  MUST_BE_XLSX_FILE: "Unsupported file type. It must be '.xlsx' file.",
  INVALID_FILE_SIZE_CANNOT_BE_LARGER_THAN:
    'Invalid file size. Cannot be larger than 10485760 bytes.',
  CAN_NOT_ADD_REQUIRED_NUMERIC_PROPERTIES:
    'You cannot add new required numeric properties.',
  CAN_NOT_ADD_REQUIRED_TEXT_PROPERTIES:
    'You cannot add new required text properties.',
  CAN_NOT_ADD_REQUIRED_BOOL_PROPERTIES:
    'You cannot add new required boolean properties.',
  EMPTY_TEXT_VALUES_ARE_PRESENT_IN_RELATED_PRODUCTS:
    'Empty text values are present in related products!',
  EMPTY_NUMERIC_VALUES_ARE_PRESENT_IN_RELATED_PRODUCTS:
    'Empty numeric values are present in related products!',
  EMPTY_BOOL_VALUES_ARE_PRESENT_IN_RELATED_PRODUCTS:
    'Empty boolean values are present in related products!',
  ONLY_ONE_EMPTY_TAG_CAN_BE_ADDED: 'Only one empty tag can be added.',
  PRODUCT_ITEM_IS_PART_OF_ASSEMBLY_ITEM:
    'Product item is part of an assembly item.',
  NICKNAME_MUST_BE_UNIQUE_IN_ASSEMBLY: 'Nickname must be unique in assembly.',
  BOX_NOT_FOUND: 'Box not found!',
  SOME_OF_PRODUCT_ITEMS_DONT_EXIST_OR_YOU_CAN_NOT_ACCESS_THEM:
    "Some of product items don't exist or you can not access them.",
  SOME_PRODUCTS_ARE_NOT_RECONDITIONABLE:
    'Some products are not reconditionable.',
  PRODUCT_ITEM_CANNOT_BE_ADDED_TO_THE_INACTIVE_BOX:
    'Product item cannot be added to the inactive box.',
  PRODUCT_ITEM_ADDED_TO_A_BOX_CANT_BE_USED_FOR_ASSEMBLY_ITEM:
    "Product item added to a box can't be used for assembly item.",
  SOME_PRODUCT_ITEMS_ARE_PART_OF_AN_ASSEMBLY_ITEM:
    'Some product items are part of an assembly item.',
  SPECIAL_REQUEST_CAN_BE_ADDED_ONLY_TO_PRODUCTS_ALREADY_TO_THE_BOX:
    'Special request can be added only to products already added to the box.',
  PRODUCT_ITEM_CAN_HAVE_ONLY_ONE_ACTIVE_SPECIAL_REQUEST:
    'Product item can have only one active special request.',
  BOX_ALREADY_IN_THE_RECONDITIONING_PROCESS_CANT_BE_SENT_FOR_RECONDITIONING:
    "Box already in the reconditioning process can't be sent for reconditioning.",
  PRODUCT_IS_NOT_RECONDITIONABLE: 'Product is not reconditionable.',
  THE_PRODUCT_DIAMETER_IS_SMALLER_THAN_THE_MINIMUM_ALLOWED_DIAMETER:
    'The product diameter is smaller than the minimum allowed diameter.',
  THE_PRODUCT_LENGTH_IS_SMALLER_THAN_THE_MINIMUM_ALLOWED_LENGTH:
    'The product length is smaller than the minimum allowed length.',
  ONLY_SHIPPED_OR_DELIVERED_BOX_CAN_BE_RESET:
    'Only shipped or delivered box can be reset.',
  MAXIMUM_WEIGHT_EXCEEDED: 'Maximum weight exceeded.',
  PRODUCT_ITEM_CANNOT_BE_REMOVED_FROM_THE_INACTIVE_BOX:
    'Product item cannot be removed from the inactive box.',
  SOME_OF_PRODUCT_ITEMS_DONT_EXIST: "Some of product items don't exist.",
  ONLY_INACTIVE_BOXES_CAN_BE_ACTIVATED: 'Only inactive boxes can be activated.',
  AN_EMPTY_BOX_CAN_NOT_BE_SENT_FOR_RECONDITIONING:
    'An empty box can not be sent for reconditioning.',
  SOME_BOXES_DONT_BELONG_TO_THE_USERS_ORGANIZATION:
    "Some boxes don't belong to the user's organization.",
  INVALID_STATUS: 'Invalid status.',
  INVALID_ESTIMATED_DATE_VALUE: 'Invalid estimated date value.',
  INACTIVE_BOX_CANT_BE_ADDED_TO_RECONDITIONING_ORDER:
    "Inactive box can't be added to reconditioning order.",
  YOU_MUST_SEND_DATA_MATRIX_CODE: 'You must send data matrix code.',
  PRODUCT_MUST_BE_IN_THE_SAME_GROUP_AS_ASSEMBLY:
    'Product must be in the same group as assembly.',
  USER_IS_NOT_MEMBER_OF_TARGET_GROUP: 'User is not member of target group.',
  THE_ASSEMBLY_NOT_FOUND: 'The assembly not found.',
  ASSEMBLY_ALREADY_EXISTS_IN_THE_TARGET_GROUP:
    'Assembly already exists in the target group.',
  ASSEMBLY_WITH_THE_SAME_NAME_ALREADY_EXISTS_IN_THE_GROUP_BUT_A_LIST_OF_PRODUCTS_IN_THESE_TWO_ASSEMBLIES_IS_NOT_THE_SAME:
    'Assembly with the same name already exists in the group but a list of products in these two assemblies is not the same.',
  USER_IS_NOT_TARGET_GROUP_ADMIN: 'User is not target group admin.',
  USER_IS_NOT_SOURCE_GROUP_ADMIN: 'User is not source group admin.',
  THE_ASSEMBLY_ITEM_NOT_FOUND: 'The assembly item not found.',
  INVALID_UNIT: 'Invalid unit.',
  THIS_TAG_IS_USED_AS_PRODUCT_ITEM_TAG: 'This tag is used as product item tag.',
  ASSEMBLY_ITEM_CAN_NOT_HAVE_PROPERTIES_WITH_THE_SAME_NAME:
    'Assembly item can not have properties with the same name.',
  INVALID_IMAGE: 'Invalid image.',
  INSPECTED_BOX_CANNOT_BE_EDITED: 'Inspected box cannot be edited.',
  SPECIAL_REQUEST_CANNOT_BE_ACCEPTED_IF_ITEM_IS_NOT_AVAILABLE:
    'Special request cannot be accepted if item is not available.',
  INVALID_NON_RECONDITIONING_REASON: 'Invalid non reconditioning reason.',
  QUANTITY_INFO_NOT_AVAILABLE_FOR_ALL_PRODUCT_ITEMS:
    'Quantity info not available for all product items.',
  CODE_AND_NICKNAME_MUST_BE_UNIQUE_IN_GROUP:
    'Code and nickname must be unique in group.',
  THE_NICKNAME_IS_NOT_UNIQUE_WITHIN_THE_GROUP:
    'The nickname is not unique within the group.',
  PRODUCTS_HAVE_DIFFERENT_DATA_PROVIDER:
    'Products have different data provider.',
  THIS_TAG_IS_ALREADY_SYNCED_WITH_THIS_PRODUCT_DID_YOU_MEAN_TO_SYNC_IT_WITH_SOMETHING_ELSE:
    'This tag is already synced with this product. Did you mean to sync it with something else?',
  YOU_MUST_PROVIDE_AT_LEAST_TWO_CHARACTER_FOR_SEARCH_TERM:
    'You must provide at least 2 character for search term.',
  YOU_MUST_SEND_BARCODE: 'You must send barcode.',
  YOU_ARE_NOT_A_MEMBER_OF_THIS_GROUP: 'You are not a member of this group.',
  INVALID_FILE_SIZE: 'Invalid file size.',
  ONLY_EMPTY_BOX_CAN_BE_EDITED: 'Only empty box can be edited.',
  CANT_ADD_ITEM_IN_THE_BOX_DUE_TO_BOX_CONFIGURATION:
    'Unable to add product item due to the box configuration.',
  SOME_PRODUCT_ITEMS_ARE_ALREADY_IN_THE_BOX:
    'Some product items are already added to a box.',
  USERNAME_SHOULD_HAVE_BETWEEN_1_AND_32_CHARACTERS:
    'Username should have between 1 and 32 characters.',
  RISK_CODE_MUST_BE_UNIQUE_PER_ORGANIZATION:
    'Risk code must be unique per organization.',
  INVALID_FILES: 'Invalid files.',
  CANNOT_DELETE_PRODUCT_SOME_ITEMS_IN_THE_BOX:
    'Can not delete product because some of connected product item are in the box.',
  THE_ORDER_IS_NOT_IN_THE_INSPECTION_STATUS:
    'The order is not in the inspection status.',
  THE_INSTANCE_HAS_ALREADY_BEEN_ADDED_TO_THIS_BOX:
    'The instance has already been added to this box.',
  THE_INSTANCE_IS_PART_OF_ANOTHER_BOX: 'The instance is part of another box.',
  ORDER_NOT_IN_VERIFICATION_STATUS: 'Order not in verification status.',
  VERIFIED_BOX_CANT_BE_EDITED: "Verified box can't be edited.",
  MISSING_TOOL_CANT_BE_AVAILABLE_IN_THE_BOX:
    "Missing tool can't be available in the box.",
  THE_TOOL_THAT_WASNT_AVAILABLE_IN_THE_INSPECTION_CANNOT_HAVE_NRC:
    "The tool that wasn't available in the inspection cannot have NRC.",
  PRODUCT_NOT_RECONDITIONABLE: 'Product not reconditionable.',
};

export const ERROR_MESSAGES = {
  SOMETHING_WENT_WRONG: 'error.somethingWentWrong',
  USER_NOT_MEMBER_OF_ANY_GROUP:
    'error.youAreNotAMemberOfAnyGroupsPleaseCreateAGroupOrJoinAGroup',
  GROUP_NAME_EXISTS: 'error.groupWithThisNameAlreadyExists',
  ASSEMBLY_NAME_ALREADY_EXISTS:
    'error.anAssemblyAlreadyExistsWithThisNamePleaseEnterANewName',
  SELECT_NEW_ADMIN: 'error.pleaseAssignNewAdminAndTryAgain',
  USER_ALREADY_IN_ORGANIZATION: 'error.userIsAlreadyP artOfThisOrganization',
  COMMENT_LENGTH_INVALID: 'error.commentLengthMustBeBetween1And800',
  TAG_IS_OCCUPIED: 'error.tagIsOccupied',
  PRODUCT_NAME_ALREADY_EXISTS:
    'error.aProductAlreadyExistsWithThisNameCodeOrNickname',
  NICKNAME_MUST_BE_UNIQUE_IN_GROUP: 'error.nicknameMustBeUniqueInGroup',
  NICKNAME_MUST_BE_UNIQUE_IN_PRODUCT: 'error.nicknameMustBeUniqueInProduct',
  ASSEMBLY_WITH_THE_SAME_NAME_ALREADY_EXISTS_IN_THIS_GROUP:
    'error.assemblyWithTheSameNameAlreadyExistsInThisGroup',
  NAME_CANNOT_BE_EMPTY: 'error.nameCannotBeEmpty',
  ORGANISATION_NAME_LENGTH_INVALID:
    'error.organizationNameLengthMustBeBetween3And128',
  INVALID_EMAIL: 'error.emailMustBeValid',
  PRODUCT_MUST_BE_UNIQUE_IN_GROUP: 'modal.productMustBeUnique',
  PRODUCT_NAME_MUST_BE_UNIQUE_IN_GROUP:
    'error.aProductAlreadyExistsWithThisNameCodeOrNickname',
  GROUP_NAME_LENGTH_INVALID: 'error.groupNameLengthMustBeBetween3And50',
  GROUP_DESCRIPTION_INVALID:
    'error.groupDescriptionLengthMustBeLessOrEqualTo500',
  FIRST_NAME_LENGTH_INVALID: 'error.firstNameLengthMustBeLessOrEqualTo150',
  LAST_NAME_LENGTH_INVALID: 'error.lastNameLengthMustBeLessOrEqualTo150',
  EMAIL_MUST_BE_VALID: 'error.emailMustBeValid',
  EMAIL_ALREADY_IN_USE: 'error.emailAlreadyInUse',
  USER_LAST_ADMIN_OF_GROUPS: 'error.userLastAdminOfGroups',
  SPECIFIED_GROUP_DOESNT_BELONG_TO_USERS_ORGANIZATION:
    'error.specifiedGroupDoesntBelongToUsersOrganization',
  PRODUCT_MESSAGE_DESCRIPTION_LENGTH_INVALID:
    'error.productMessageDescriptionLengthInvalid',
  INVALID_IMAGE_FILE_TYPE: 'error.invalidImageFileType',
  FILE_SIZE_EXCEEDED: 'error.theSizeOfFileExceeds',
  FILE_TYPE_NOT_SUPPORTED: 'error.theTypeOfFileIsNotSupported',
  FILE_NAME_HAS_INVALID_CHARACTERS:
    'error.theNameOfFileIsNotValidInvalidCharacters',
  YOU_CANNOT_INVITE_USERS_TO_DEMO_ORGANISATION:
    'error.youCannotInviteUsersToDemoOrganization',
  MUST_CONFIRM_DELETION: 'You must confirm deletion of empty groups!',
  ORGANIZATION_WITH_THAT_NAME_ALREADY_EXISTS:
    'error.organizationWithThatNameAlreadyExists',
  ASSEMBLY_NAME_LENGTH_INVALID_EDIT:
    'error.assemblyNameLengthMustBeLessOrEqualTo255',
  ASSEMBLY_NAME_LENGTH_INVALID_CREATE:
    'error.assemblyNameLengthMustBeLessOrEqualTo255',
  INVALID_FILE_NAME: 'error.invalidCharacters',
  EMPTY_GROUP_NAME: 'error.groupNameCannotBeEmpty',
  INVALID_TITLE_LENGTH: 'error.titleLengthMustBeBetween1And255',
  INVALID_DUE_DATE: 'error.invalidDueDate',
  CANNOT_FIND_SHEET_DATA_TO_IMPORT_ERROR: 'error.invalidXslxFile',
  INVALID_FILE_TYPE: 'error.invalidFileType',
  MAX_NUMBER_OF_FILES_EXCEEDED: 'error.onlyNumberFilesCanBeUploaded',
  INVALID_EXCEL_FILE: 'error.invalidXslxFile',
  MUST_BE_XLSX_FILE: 'error.unsupportedFileTypeItMustBeXlsxFile',
  ONLY_XSLX_FILE_SUPPORTED: 'error.onlyXslxFileSupported',
  CAN_NOT_ADD_REQUIRED_NUMERIC_PROPERTIES:
    'error.youCanNotAddRequiredProperties',
  CAN_NOT_ADD_REQUIRED_TEXT_PROPERTIES: 'error.youCanNotAddRequiredProperties',
  CAN_NOT_ADD_REQUIRED_BOOL_PROPERTIES: 'error.youCanNotAddRequiredProperties',
  EMPTY_TEXT_VALUES_ARE_PRESENT_IN_RELATED_PRODUCTS:
    'error.emptyValuesArePresentInRelatedProducts',
  EMPTY_NUMERIC_VALUES_ARE_PRESENT_IN_RELATED_PRODUCTS:
    'error.emptyValuesArePresentInRelatedProducts',
  EMPTY_BOOL_VALUES_ARE_PRESENT_IN_RELATED_PRODUCTS:
    'error.emptyValuesArePresentInRelatedProducts',
  ONLY_ONE_EMPTY_TAG_CAN_BE_ADDED: 'error.onlyOneEmptyTagCanBeAdded',
  PRODUCT_ITEM_IS_PART_OF_ASSEMBLY_ITEM:
    'error.productItemIsPartOfAnAssemblyItem',
  NICKNAME_MUST_BE_UNIQUE_IN_ASSEMBLY: 'error.nicknameMustBeUniqueInAssembly',
  BOX_NOT_FOUND: 'error.productItemCannotBeAddedToTheInactiveBox',
  SOME_OF_PRODUCT_ITEMS_DONT_EXIST_OR_YOU_CAN_NOT_ACCESS_THEM:
    'error.someOfProductItemsDontExistOrYouCanNotAccessThem',
  SOME_PRODUCTS_ARE_NOT_RECONDITIONABLE:
    'error.someProductsAreNotReconditionable',
  PRODUCT_ITEM_CANNOT_BE_ADDED_TO_THE_INACTIVE_BOX:
    'error.productItemCannotBeAddedToTheInactiveBox',
  THIS_PRODUCT_ITEM_IS_ALREADY_ADDED_TO_A_BOX:
    'error.thisProductItemIsAlreadyAddedToABox',
  SOME_PRODUCT_ITEMS_ARE_PART_OF_AN_ASSEMBLY_ITEM:
    'error.someProductItemsArePartOfAnAssemblyItem',
  SPECIAL_REQUEST_CAN_BE_ADDED_ONLY_TO_PRODUCTS_ALREADY_TO_THE_BOX:
    'error.specialRequestCanBeAddedOnlyToProductsAlreadyAddedToTheBox',
  PRODUCT_ITEM_CAN_HAVE_ONLY_ONE_ACTIVE_SPECIAL_REQUEST:
    'error.productItemCanHaveOnlyOneActiveSpecialRequest',
  PRODUCT_ITEM_ADDED_TO_A_BOX_CANT_BE_USED_FOR_ASSEMBLY_ITEM:
    'error.productItemAddedToABoxCantBeUsedForAssemblyItem',
  EMPTY_FILE: 'error.fileIsEmpty',
  BOX_ALREADY_IN_THE_RECONDITIONING_PROCESS_CANT_BE_SENT_FOR_RECONDITIONING:
    'error.boxAlreayInTheReconditioningProcessCantBeSentForReconditioning',
  PRODUCT_IS_NOT_RECONDITIONABLE: 'error.productIsNotReconditionable',
  THE_PRODUCT_DIAMETER_IS_SMALLER_THAN_THE_MINIMUM_ALLOWED_DIAMETER:
    'error.theProductDiameterIsSmallerThanTheMinimumAllowedDiameter',
  THE_PRODUCT_LENGTH_IS_SMALLER_THAN_THE_MINIMUM_ALLOWED_LENGTH:
    'error.theProductLengthIsSmallerThanTheMinimumAllowedLength',
  ONLY_SHIPPED_OR_DELIVERED_BOX_CAN_BE_RESET:
    'error.onlyShippedOrDeliveredBoxCanBeReset',
  MAXIMUM_WEIGHT_EXCEEDED: 'error.maximumWeightExceeded',
  PRODUCT_ITEM_CANNOT_BE_REMOVED_FROM_THE_INACTIVE_BOX:
    'error.productItemCannotBeRemovedFromTheInactiveBox',
  SOME_OF_PRODUCT_ITEMS_DONT_EXIST: 'error.someOfProductItemsDontExist',
  ONLY_INACTIVE_BOXES_CAN_BE_ACTIVATED: 'error.onlyInactiveBoxesCanBeActivated',
  AN_EMPTY_BOX_CAN_NOT_BE_SENT_FOR_RECONDITIONING:
    'error.anEmptyBoxCanNotBeSentForReconditioning',
  SOME_BOXES_DONT_BELONG_TO_THE_USERS_ORGANIZATION:
    'error.someBoxesDontBelongToTheUsersOrganization',
  INVALID_STATUS: 'error.invalidStatus',
  INVALID_ESTIMATED_DATE_VALUE: 'error.invalidEstimatedDateValue',
  INACTIVE_BOX_CANT_BE_ADDED_TO_RECONDITIONING_ORDER:
    'error.inactiveBoxCantBeAddedToReconditioningOrder',
  YOU_MUST_SEND_DATA_MATRIX_CODE: 'error.youMustSendDataMatrixCode',
  PRODUCT_MUST_BE_IN_THE_SAME_GROUP_AS_ASSEMBLY:
    'error.productMustBeInTheSameGroupAsAssembly',
  USER_IS_NOT_MEMBER_OF_TARGET_GROUP: 'error.userIsNotMemberOfTargetGroup',
  THE_ASSEMBLY_NOT_FOUND: 'error.theAssemblyNotFound',
  ASSEMBLY_WITH_THE_SAME_NAME_ALREADY_EXISTS_IN_THE_GROUP_BUT_A_LIST_OF_PRODUCTS_IN_THESE_TWO_ASSEMBLIES_IS_NOT_THE_SAME:
    'error.assemblyWithTheSameNameAlreadyExistsInTheGroupButAListOfProductsInTheseTwoAssembliesIsNotTheSame',
  USER_IS_NOT_TARGET_GROUP_ADMIN: 'error.userIsNotTargetGroupAdmin',
  USER_IS_NOT_SOURCE_GROUP_ADMIN: 'error.userIsNotSourceGroupAdmin',
  THE_ASSEMBLY_ITEM_NOT_FOUND: 'error.theAssemblyItemNotFound',
  INVALID_UNIT: 'error.invalidUnit',
  THIS_TAG_IS_USED_AS_PRODUCT_ITEM_TAG: 'error.thisTagIsUsedAsProductItemTag',
  ASSEMBLY_ITEM_CAN_NOT_HAVE_PROPERTIES_WITH_THE_SAME_NAME:
    'error.assemblyItemCanNotHavePropertiesWithTheSameName',
  INVALID_IMAGE: 'error.invalidImage',
  INSPECTED_BOX_CANNOT_BE_EDITED: 'error.inspectedBoxCannotBeEdited',
  SPECIAL_REQUEST_CANNOT_BE_ACCEPTED_IF_ITEM_IS_NOT_AVAILABLE:
    'error.specialRequestCannotBeAcceptedIfItemIsNotAvailable',
  INVALID_NON_RECONDITIONING_REASON: 'error.invalidNonReconditioningReason',
  QUANTITY_INFO_NOT_AVAILABLE_FOR_ALL_PRODUCT_ITEMS:
    'error.quantityInfoNotAvailableForAllProductItems',
  CODE_AND_NICKNAME_MUST_BE_UNIQUE_IN_GROUP:
    'error.codeAndNicknameMusteBeUniqueInGroup',
  THE_NICKNAME_IS_NOT_UNIQUE_WITHIN_THE_GROUP:
    'error.theNicknameIsNotUniqueWithinTheGroup',
  PRODUCTS_HAVE_DIFFERENT_DATA_PROVIDER:
    'error.productsHaveDifferentDataProvider',
  THIS_TAG_IS_ALREADY_SYNCED_WITH_THIS_PRODUCT_DID_YOU_MEAN_TO_SYNC_IT_WITH_SOMETHING_ELSE:
    'error.thisTagIsAlreadySyncedWithThisProductDidYouMeanToSyncItWithSomethingElse',
  YOU_MUST_PROVIDE_AT_LEAST_TWO_CHARACTER_FOR_SEARCH_TERM:
    'error.youMustProvideAtLeastTwoCharacterForSearchTerm',
  YOU_MUST_SEND_BARCODE: 'error.youMustSendBarcode',
  YOU_ARE_NOT_A_MEMBER_OF_THIS_GROUP: 'error.youAreNotAMemberOfThisGroup',
  INVALID_FILE_SIZE: 'error.invalidFileSize',
  ASSEMBLY_WITH_THIS_NAME_ALREADY_EXISTS_IN_THE_GROUP:
    'error.assemblyWithThisNameAlreadyExistsInTheGroup',
  ASSEMBLY_TAG_WAS_FOUND_IN_PRODUCT_TAG_LIST:
    'error.assemblyTagWasFoundInProductTagList',
  SOME_OF_TAGS_ARE_INVALID: 'error.someOfTagsAreInvalid',
  UNSUPPORTED_FILE_TYPE_FILE_EXTENSION: 'error.unsupportedFileType',
  UNSUPPORTED_FILE_TYPE_FILE_TYPE: 'error.unsupportedFileType',
  INVALID_URL: 'error.invalidUrl',
  TAG_IS_IN_USE: 'error.tagIsInUse',
  STATUS_CODE_IS_NOT_SUPPORTED: 'error.statusCodeIsNotSupported',
  TAGS_ARE_ALREADY_PART_OF_ANOTHER_ASSEMBLY_ITEM:
    'error.tagsAreAlreadyPartOfAnotherAssemblyItemTheyAreConnectedToTheProductsThatAreNotPartOfThisAssemblyOrTheyBelongToAGroupYouHaveNoAccessRights',
  ONLY_EMPTY_BOX_CAN_BE_EDITED: 'error.onlyEmptyBoxesCanBeEdited',
  CANT_ADD_ITEM_IN_THE_BOX_DUE_TO_BOX_CONFIGURATION:
    'error.thisProductCanNotbePlacedInThisBoxDueToTheBoxConfigurationOrProductCharacteristics',
  SOME_PRODUCT_ITEMS_ARE_ALREADY_IN_THE_BOX:
    'error.thisProductItemIsAlreadyInTeBox',
  THE_SPECIFIED_NEW_USERNAME_ALREADY_EXISTS:
    'error.theSpecifiedNewUsernameAlreadyExists',
  USERNAME_SHOULD_HAVE_BETWEEN_1_AND_32_CHARACTERS:
    'error.usernameShouldHaveBetween1And32Characters',
  RISK_CODE_MUST_BE_UNIQUE_PER_ORGANIZATION:
    'error.authenticationRequestForThisProductHasAlreadyBeenSent',
  INVALID_FILES: 'error.invalidFiles',
  CANNOT_DELETE_PRODUCT_SOME_ITEMS_IN_THE_BOX:
    'error.cannotDeleteProductBecauseSomeOfConnectedProductItemsAreInTheBox',
  THE_ORDER_IS_NOT_IN_THE_INSPECTION_STATUS:
    'error.theOrderIsNotInTheInspectionStatus',
  THE_INSTANCE_HAS_ALREADY_BEEN_ADDED_TO_THIS_BOX:
    'error.theInstanceHasAlreadyBeenAddedToThisBox',
  THE_INSTANCE_IS_PART_OF_ANOTHER_BOX: 'error.theInstanceIsPartOfAnotherBox',
  ORDER_NOT_IN_VERIFICATION_STATUS: 'error.orderNotInVerificationStatus',
  VERIFIED_BOX_CANT_BE_EDITED: 'error.verifiedBoxCantBeEdited',
  MISSING_TOOL_CANT_BE_AVAILABLE_IN_THE_BOX:
    'error.missingToolCantBeAvailableInTheBox',
  THE_TOOL_THAT_WASNT_AVAILABLE_IN_THE_INSPECTION_CANNOT_HAVE_NRC:
    'error.theToolThatWasntAvailableInTheInspectionCannotHaveNRC',
  PRODUCT_NOT_RECONDITIONABLE: 'error.productNotReconditionable',
};

export const ERROR_MESSAGES_BACKEND_REGEXES = {
  ASSEMBLY_WITH_THIS_NAME_ALREADY_EXISTS_IN_THE_GROUP:
    '^Assembly with a name (.+) already exists in the group.$',
  ASSEMBLY_TAG_WAS_FOUND_IN_PRODUCT_TAG_LIST:
    '^Assembly tag (.+) was found in product tag list.$',
  SOME_OF_TAGS_ARE_INVALID: '^Some of tags are invalid. Invalid tags: (.+).$',
  UNSUPPORTED_FILE_TYPE_FILE_EXTENSION:
    '^Unsupported file type. File extension: (.+).$',
  UNSUPPORTED_FILE_TYPE_FILE_TYPE: '^Unsupported file type. File type: (.+).$',
  INVALID_URL: '^Invalid URL: (.+).$',
  TAG_IS_IN_USE: '^Tag (.+) is in use.$',
  STATUS_CODE_IS_NOT_SUPPORTED: '^Status code (.+) is not supported.$',
  TAGS_ARE_ALREADY_PART_OF_ANOTHER_ASSEMBLY_ITEM:
    '^Tags are already part of another assembly item, they are connected to the products that are not part of this assembly or they belong to a group You have no access rights. Tags: (.+)$',
};

export const ERROR_MESSAGES_READER = {
  NO_PRODUCT_FOUND: {
    title: 'error.noProductFound',
    subtitle: 'error.thisTagIsNotSyncedToAnyProductPleaseScanAValidTag',
  },
  UNABLE_TO_SCAN: {
    message: 'Unable to access.',
    title: 'error.unableToScan',
    subtitle: 'error.theProductYouTriedToScanBelongsToAnotherGroup',
  },
  UNABLE_TO_SCAN_BOX: {
    message: 'Unable to access the box.',
    title: 'error.unableToScanABox',
    subtitle: 'error.theBoxYouTriedToScanBelongsToAnotherOrganization',
  },
};

export const ERROR_MESSAGES_READER_RC = {
  UNABLE_TO_OPEN: {
    title: 'modal.warning',
    subtitle: 'error.theToolYouScannedIsNotAvailableInAnyOrder',
  },
  EMPTY_TAG: {
    title: 'error.thisTagIsNotAvailableInTheToolsLibrary',
    subtitle: 'error.pleaseContactCustomerOrSupport',
  },
};

export const IMAGE_QUALITY = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
};

export const ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export const THEME_COLORS_NAMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  PRIMARY_ACTIVE: 'primary-active',
  SECONDARY_ACTIVE: 'secondary-active',
  TERTIARY_ACTIVE: 'tertiary-active',
  TEXT_PRIMARY: 'txt-primary',
  TEXT_SECONDARY: 'txt-secondary',
  TEXT_DISABLED: 'txt-disabled',
  BUTTON_PRIMARY: 'btn-primary',
  BUTTON_SECONDARY: 'btn-secondary',
  BUTTON_DISABLED: 'btn-disabled',
  BACKGROUND: 'default-bg',
  BACKGROUND_LIGHT: 'light-bg',
  BACKGROUND_DARK: 'dark-bg',
  BACKGROUND_HIGHLIGHTED: 'highlighted-bg',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  BORDER: 'border',
  WHITE: 'white',
  BLACK: 'black',
};

export const ROOT_THEME_COLORS = {
  SECONDARY: { key: '--secondary', fallbackValue: COLORS.ORANGE },
  SUCCESS: { key: '--success', fallbackValue: COLORS.ORANGE },
  WARNING: { key: '--warning', fallbackValue: COLORS.ORANGE },
};

export const MAP = {
  ZOOM_LEVEL: 12,
  MAX_ZOOM: 19,
  MIN_ZOOM: 3,
  SOUTHWEST_COORDS: [-89.98155760646617, -180],
  NORTHEAST_COORDS: [89.99346179538875, 180],
};

export const SUPPORT_MESSAGE_STATUSES = [
  {
    title: 'components.new',
    code: 10,
  },
  {
    title: 'components.inProgress',
    code: 20,
  },
  {
    title: 'components.onHold',
    code: 30,
  },
  {
    title: 'components.closed',
    code: 40,
  },
];

export const IDEM_STATUSES = {
  UNABLE_TO_DELETE_LAST_ORGANIZATION_ADMIN:
    'unable-to-delete-last-organisation-admin',
  UNABLE_TO_DELETE_LAST_ADMIN: 'unable-to-delete-last-admin',
  CONFIRM_DELETE_EMPTY_GROUP: 'confirm-delete-empty-group',
};

export const IDEM_SUBSCRIPTION_LEVELS = {
  DEMO: 1,
  PRO: 2,
};

export const IDEM_SUBSCRIPTION_TYPES = {
  DEMO: 'DEMO',
  PRO: 'PRO',
};

export const LIMIT_REACHED_TYPE = {
  PRODUCT: 1,
  ASSEMBLY: 2,
  ORGANISATION_MEMBERS: 3,
};

export const ADMIN_RIGHTS_OPTION = {
  ASSIGN: 'ASSIGN',
  REVOKE: 'REVOKE',
};

export const REMOVE_OPTION = {
  REMOVE_ADMIN: 'REMOVE_ADMIN',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
};

export const LEAVE_OPTIONS = {
  LEAVE_GROUP: 'LEAVE_GROUP',
  LEAVE_ADMIN_ROLE: 'LEAVE_ADMIN_ROLE',
};

// tabs constants
export const HORIZONTAL_TAB_QUERY = ORIENTATION.HORIZONTAL;
export const VERTICAL_TAB_QUERY = ORIENTATION.VERTICAL;

export const DEVELOPMENT_ENVIRONMENT = 'development';
export const STAGING_ENVIRONMENT = 'staging';
export const PRODUCTION_ENVIRONMENT = 'production';

export const ROUTES = {
  DATABASE: '/database',
  GROUPS: '/groups',
  ORGANIZATION: '/organization',
  LOGIN: '/login',
  HISTORY: '/history',
  PROFILE: '/profile',
  MESSAGES: '/messages',
  SETTINGS: '/settings',
  DATABASE_ASSEMBLIES: '/database/assemblies',
  DATABASE_PRODUCTS: '/database/products',
  RECONDITIONING_BOX: '/reconditioning/box',
  RECONDITIONING: '/reconditioning',
  RECONDITIONING_ORDER: '/reconditioning/order',
  RC: '/rc',
  RC_INCOMING: '/rc/incoming',
  RC_INSPECTIONS: '/rc/inspections',
  RC_RECONDITIONING: '/rc/reconditioning',
  RC_SHIPPED: '/rc/shipped',
  RC_ARCHIVE: '/rc/archive',
  AUTHENTICATION_REQUESTS: '/authentication-requests',
  LEGAL: '/legal',
  LEGAL_INBOX: '/legal/inbox',
  LEGAL_INSPECTION: '/legal/inspection',
  LEGAL_RESPONSE: '/legal/response',
  LEGAL_ARCHIVE: '/legal/archive',
};

export const DEFAULT_LANGUAGE_CODE = 'en';

export const PROFILE_PARAMS = {
  GIVEN_NAME: 'given_name',
  FAMILY_NAME: 'family_name',
  USERNAME: 'username',
  EMAIL: 'email',
};

export const DEFAULT_AVATAR_PARAMS = {
  background: getThemeHexColor(ROOT_THEME_COLORS.SECONDARY),
  color: COLORS.WHITE,
  'font-size': 0.33,
  size: 512,
  bold: true,
  rounded: true,
};

export const NOTIFICATION_OBJECT_TYPE = {
  CONDITION_RATING: 'Condition rating',
  USAGE_TIME: 'Usage time',
  TARGET_TIME: 'Target time',
  THREAD: 'UserThread',
  RECONDITION_ORDER: 'ReconditionOrder',
};

export const NOTIFICATION_ACTION = {
  CREATE: 'Create',
  DELETE: 'Delete',
  UPDATE: 'Update',
  COMPLETE: 'Complete',
  REMIND: 'Remind',
  ADD: 'Add',
  RESET: 'Reset',
};

export const NOTIFICATION_ACTION_ORDER = {
  PICKUP_REQUESTED: 'PickUpRequested',
  SHIPPING_IN_PROGRESS: 'ShippingInProgress',
  DELIVERED_TO_RC: 'DeliveredToReconditionCenter',
  INSPECTION_IN_PROGRESS: 'InspectionInProgress',
  RECONDITIONING_IN_PROGRESS: 'ReconditioningInProgress',
  VERIFICATION_IN_PROGRESS: 'VerificationInProgress',
  READY_TO_BE_SHIPPED_TO_CUSTOMER: 'ReadyToBeShippedToCustomer',
  SHIPPED_TO_CUSTOMER: 'ShippedToCustomer',
  DELIVERED_TO_CUSTOMER: 'DeliveredToCustomer',
  COMPLETED: 'Completed',
};

export const USER_THREAD_STATUS = {
  TODO: 'ToDo',
  DONE: 'Done',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
};

export const THREAD_TYPES = {
  STANDARD: 'Standard',
  SPECIAL_REQUEST: 'SpecialRequest',
  RC_COMMENT: 'RcComment',
  COUNTERFEIT_RISK: 'CounterfeitRisk',
  COUNTERFEIT_RISK_UPDATE: 'CounterfeitRiskUpdate',
};

export const ACTIVE_USER_THREAD_STATUSES = [
  USER_THREAD_STATUS.TODO,
  USER_THREAD_STATUS.ACCEPTED,
  USER_THREAD_STATUS.REJECTED,
];

export const MAX_NOTIFICATION_NUMBER = 99;

export const MAX_NUMBER_OF_FILES_IN_THREAD = 10;
export const MAX_NUMBER_OF_FILES_FOR_SPECIAL_REQUEST = 3;
export const MAX_NUMBER_OF_FILES_FOR_COUNTERFEIT_REQ = 3;
export const MAX_NUMBER_OF_FILES_FOR_GENERAL_COMMENT = 3;

export const FIREBASE_CREDENTIALS = {
  apiKey: 'AIzaSyB1AbPPWXfhGjS6wT_zrr3lpaeahYk6YpY',
  authDomain: 'bridge-app-ec852.firebaseapp.com',
  databaseURL:
    'https://bridge-app-ec852-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bridge-app-ec852',
  storageBucket: 'bridge-app-ec852.appspot.com',
  messagingSenderId: '983718092425',
};

export const FIREBASE_APP_DEV = {
  appId: '1:983718092425:web:34f7fad46b2ebd7f903058',
  measurementId: 'G-96ZXSVKDND',
};

export const FIREBASE_APP_STAGE = {
  appId: '1:983718092425:web:6d89ffc80dde67a8903058',
  measurementId: 'G-205X1LSXD4',
};

export const FIREBASE_APP_PROD = {
  appId: '1:983718092425:web:b443f7c5b10016ed903058',
  measurementId: 'G-Q1HLKEBC2F',
};

export const FIREBASE_EVENTS = {
  ASSEMBLIES_TAB: 'assemblies_tab',
  ASSEMBLY_EDIT: 'assembly_template_edit',
  ASSEMBLY_TEMPLATE_CREATE: 'assembly_template_create',
  ASSEMBLY_TEMPLATE_DELETE: 'assembly_template_delete',
  ASSEMBLY_VIEW: 'assembly_template_view',
  ASSEMBLY_VIEW_FROM_LIST: 'assembly_template_view_list',
  ASSEMBLY_VIEW_FROM_SCAN: 'assembly_template_view_scan',
  ASSEMBLY_INSTANCE_VIEW: 'assembly_instance_view',
  ASSEMBLY_INSTANCE_VIEW_FROM_SCAN: 'assembly_instance_view_scan',
  ASSEMBLY_INSTANCE_CREATED: 'assembly_instance_create',
  ASSEMBLY_INSTANCE_EDIT: 'assembly_instance_edit',
  ASSEMBLY_INSTANCE_DELETE: 'assembly_instance_delete',
  ASSEMBLY_VIEW_PRODUCT: 'assembly_template_view_product',
  ASSEMBLY_INSTANCES_TAB_VIEW_FROM_ASSEMBLY_SCREEN:
    'location_assembly_instances_feature_from_list',
  ASSEMBLY_INSTANCES_TAB_VIEW_FROM_DATABASE_SCREEN:
    'location_assembly_instances_feature_from_details',

  PRODUCTS_TAB: 'products_tab',
  PRODUCT_INSTANCE_TRANSFER_TAG: 'product_instance_transfer_tag',
  PRODUCT_CREATE: 'product_template_create',
  PRODUCT_EDIT: 'product_template_edit',
  PRODUCT_INSTANCE_CREATE: 'product_instance_create',
  PRODUCT_INSTANCE_EDIT: 'product_instance_edit',
  PRODUCT_INSTANCE_DELETE: 'product_instance_delete',
  PRODUCT_TEMPLATE_DELETE: 'product_template_delete',
  PRODUCT_VIEW: 'product_template_view',
  PRODUCT_INSTANCE_VIEW: 'product_instance_view',
  PRODUCT_INSTANCE_VIEW_FROM_SCAN: 'product_instance_view_scan',
  PRODUCT_INSTANCE_VIEW_FROM_NOTIFICATION: 'product_instance_view_notification',
  PRODUCT_VIEW_FROM_LIST: 'product_template_view_list',
  PRODUCT_VIEW_FROM_SCAN: 'product_template_view_scan',
  PRODUCT_INSTANCES_TAB_VIEW_FROM_PRODUCT_SCREEN:
    'location_product_instances_feature_from_list',
  PRODUCT_INSTANCES_TAB_VIEW_FROM_DATABASE_SCREEN:
    'location_product_instances_feature_from_details',
  PRODUCT_INSTANCE_ACCESS_VIA_PRODUCT_INSTANCE_TAB:
    'product_instance_access_via_product_instance_tab',
  PRODUCT_INSTANCE_ACCESS_VIA_RECONITIONS_TAB:
    'product_instance_access_via_reconditions_tab',
  PRODUCT_TEMPLATE_VIEW_EXTERNAL_WEB: 'product_template_view_external_web',

  THREADS_FEATURE: 'threads_feature',
  PRODUCT_THREADS_ADD: 'product_template_thread_create',
  PRODUCT_INSTANCE_THREADS_ADD: 'product_instance_thread_create',
  PRODUCT_THREADS_EDIT: 'product_template_thread_edit',
  PRODUCT_INSTANCE_THREADS_EDIT: 'product_instance_thread_edit',
  PRODUCT_THREADS_DELETE: 'product_template_thread_delete',
  PRODUCT_INSTANCE_THREADS_DELETE: 'product_instance_thread_delete',
  PRODUCT_THREADS_MARK_AS_DONE: 'product_template_thread_mark_as_done',
  PRODUCT_INSTANCE_THREADS_MARK_AS_DONE: 'product_instance_thread_mark_as_done',
  ASSEMBLY_THREADS_ADD: 'assembly_template_thread_create',
  ASSEMBLY_INSTANCE_THREADS_ADD: 'assembly_instance_thread_create',
  ASSEMBLY_THREADS_EDIT: 'assembly_template_thread_edit',
  ASSEMBLY_INSTANCE_THREADS_EDIT: 'assembly_instance_thread_edit',
  ASSEMBLY_THREADS_DELETE: 'assembly_template_thread_delete',
  ASSEMBLY_INSTANCE_THREADS_DELETE: 'assembly_instance_thread_delete',
  ASSEMBLY_THREADS_MARK_AS_DONE: 'assembly_template_thread_mark_as_done',
  ASSEMBLY_INSTANCE_THREADS_MARK_AS_DONE:
    'assembly_instance_thread_mark_as_done',

  ADD_USAGE_TIME: 'add_usage_time',
  RESET_USAGE_TIME: 'reset_usage_time',
  SET_TARGET_TIME: 'set_target_time',
  RESET_TARGET_TIME: 'reset_target_time',

  PRODUCT_INSTANCE_CONDITION_RATING_DELETE:
    'assembly_instance_condition_rating_add',
  PRODUCT_INSTANCE_CONDITION_RATING_ADD:
    'assembly_instance_condition_rating_add',
  ASSEMBLY_INSTANCE_CONDITION_RATING_ADD:
    'assembly_instance_condition_rating_add',
  ASSEMBLY_INSTANCE_CONDITION_RATING_DELETE:
    'assembly_instance_condition_rating_add',

  GROUP_ADMIN_LEAVE_ROLE: 'group_admin_leave_role',
  GROUP_ADMIN_REVOKE_ROLE: 'group_admin_revoke_role',
  GROUP_ADMIN_ASSIGN_ROLE: 'group_admin_assign_role',
  GROUP_CREATE: 'group_create',
  GROUP_INVITE: 'group_invite_user',
  GROUP_JOIN: 'group_ask_to_join',
  GROUP_LEAVE: 'group_leave',
  GROUP_REMOVE_USER: 'group_remove_user',
  GROUP_LIST_OPEN: 'organization_group_feature',

  LAST_SCANNED_FEATURE: 'last_scanned_feature',
  LAST_SCANNED_INSTANCE_ACCESS: 'last_scanned_instance_access',

  LOGOUT: 'logout',
  LOGIN: 'login',
  USER_EDIT: 'user_edit',
  USER_DELETE: 'user_delete',

  NOTIFICATIONS_MARK_ALL_ITEMS: 'notifications_mark_all_read',
  NOTIFICATIONS_SETTINGS_CHANGED: 'notifications_settings_changed',
  NOTIFICATIONS_TURNED_OFF: 'notifications_turned_off',

  ORGANIZATION_ADMIN_ASSIGN_ROLE: 'organization_admin_assign_role',
  ORGANIZATION_ADMIN_LEAVE_ROLE: 'organization_admin_leave_role',
  ORGANIZATION_ADMIN_REVOKE_ROLE: 'organization_admin_revoke_role',
  ORGANIZATION_INVITE_SENT: 'organization_invite_sent',
  ORGANIZATION_REMOVE_USER: 'organization_remove_user',

  FEEDBACK_SEND: 'feedback_send',

  // Reconditioning - End customer
  BOXES_VIEW: 'reconditioning_boxes_view',
  ORDERS_VIEW: 'reconditioning_orders_view',
  TOOLS_VIEW: 'reconditioning_tools_view',
  REQUEST_NEW_BOX: 'reconditioning_request_new_box',
  CREATE_CUSTOM_BOX: 'reconditioning_create_custom_box',
  ADD_INSTANCES_TO_BOX_BY_SCAN: 'reconditioning_box_add_more_scan',
  ADD_INSTANCES_TO_BOX_BY_SEARCH: 'reconditioning_box_add_more_search',
  ORDER_PICKUP_SENT: 'reconditioning_order_pickup_sent',
  BOX_VIEW_FROM_LIST: 'reconditioning_box_details_from_list',
  BOX_VIEW_FROM_ORDER: 'reconditioning_box_details_from_order',
  BOX_VIEW_FROM_SCAN: 'box_view_from_scan',
  ORDER_VIEW_FROM_LIST: 'reconditioning_order_details_from_list',
  ORDER_VIEW_FROM_BOX: 'reconditioning_order_details_from_box',
  ORDER_VIEW_FROM_NOTIFICATION:
    'reconditioning_order_details_from_notification',
  SPECIAL_REQUEST_ADDED: 'reconditioning_special_request',
  BOX_CONFIGURATION_CHANGED: 'reconditioning_box_configuration_changed',
  BOX_TAG_SYNCED: 'reconditioning_box_tag_synced',
  BOX_NEW_TAG_SYNCED: 'reconditioning_box_new_tag_synced',
  BOX_TAG_UNSYNCED: 'reconditioning_box_tag_unsynced',
  BOX_RESET: 'reconditioning_box_reset',
  ORDER_COMPLETE: 'reconditioning_order_complete',
  RECONDITIONING_INFO_ADDED: 'reconditioning_add_info',
  RECONDITIONING_INFO_EDITED: 'reconditioning_edit_info',

  // RC General
  RC_ORDER_VIEW: 'rc_order_details_view',
  RC_BOX_VIEW: 'rc_box_details_view',
  RC_SPECIAL_REQUEST_VIEW: 'rc_special_request_view',
  RC_EXPORT_INSPECTION_REPORT: 'rc_export_inspection_report',
  RC_EXPORT_VERIFICATION_REPORT: 'rc_export_verification_report',
  RC_SCAN_PRODUCT: 'rc_scan_product',
  RC_SCAN_PRODUCT_NOT_IN_ANY_ORDER: 'rc_scan_product_not_in_any_order',
  RC_SCAN_TAG_DOESNT_EXIST: 'rc_scan_tag_doesnt_exist',
  RC_SCANNED_TAG_OUTSIDE_BOX_WHILE_INSPECTION:
    'rc_scanned_tag_outside_box_while_inspection',
  RC_SCAN_BOX: 'rc_scan_box',
  RC_SEARCHING_THROUGH_ORDERS: 'rc_searching_through_orders',

  // RC Incoming
  RC_REQUESTED_PICKUPS_TAB: 'rc_requested_pickups_tab',
  RC_TRACKING_DATA_EDIT: 'rc_tracking_data_edit',
  RC_ORDER_STATUS_CHANGED_TO_SHIPPED_TO_RC:
    'rc_order_status_changed_to_shipped_to_rc',
  RC_INBOUND_TAB: 'rc_inbound_tab',
  RC_ORDER_STATUS_CHANGED_TO_DELIVERED_TO_RC:
    'rc_order_status_changed_to_delivered_to_rc',
  RC_RECEIVED_TAB: 'rc_received_tab',
  RC_ORDER_STATUS_CHANGED_TO_INSPECTION_IN_PROGRESS:
    'rc_order_status_changed_to_inspection_in_progress',
  RC_TOOLS_TAB: 'rc_tools_tab',
  RC_SPECIAL_REQUEST_VIEW_TOOLS_TAB: 'rc_special_request_view_tools_tab',
  RC_EXPORT_TOOLS_TAB: 'rc_export_tools_tab',

  // RC Inspection
  RC_INSPECTION_IN_PROGRESS_TAB: 'rc_inspection_in_progress_tab',
  RC_INSPECTION_QUANTITY_CHECK_FALSE: 'rc_inspection_quantity_check_false',
  RC_INSPECTION_NRC_SET: 'rc_inspection_nrc_set',
  RC_INSPECTION_NRC_RESET: 'rc_inspection_nrc_reset',
  RC_INSPECTION_SPECIAL_REQ_ACCEPTED: 'rc_inspection_special_req_accepted',
  RC_INSPECTION_SPECIAL_REQ_DECLINED: 'rc_inspection_special_req_declined',
  RC_INSPECTION_BOX_INSPECTED_LOCKED: 'rc_inspection_box_inspected_locked',
  RC_INSPECTION_BOX_INSPECTED_UNLOCKED: 'rc_inspection_box_inspected_unlocked',
  RC_INSPECTION_EXPORT_CURRENT_STATE_REPORT:
    'rc_inspection_export_current_state_report',
  RC_ORDER_STATUS_CHANGED_TO_RECONDITIONING:
    'rc_order_status_changed_to_reconditioning',
  RC_BOX_STATUS_CHANGED_TO_RECONDITIONING:
    'rc_box_status_changed_to_reconditioning',
  RC_INSPECTION_REPORT_GENERATED: 'rc_inspection_report_generated',
  RC_INSPECTION_COMMENT_TO_PRODUCT_EDIT:
    'rc_inspection_comment_to_product_edit',
  RC_PRODUCT_SCANNED_DURING_INSPECTION: 'rc_product_scanned_during_inspection',
  RC_PRODUCT_SEARCHED_DURING_INSPECTION:
    'rc_product_searched_during_inspection',
  RC_INSTANCE_ADDED_DURING_INSPECTION: 'rc_instance_added_during_inspection',

  // RC Reconditioning
  RC_RECONDITIONING_IN_PROGRESS_TAB: 'rc_reconditioning_in_progress_tab',
  RC_RECONDITIONING_EXPORT_REPORT: 'rc_reconditioning_export_report',
  RC_ORDER_STATUS_CHANGED_TO_VERIFICATION_IN_PROGRESS:
    'rc_order_status_changed_to_verification_in_progress',

  // RC Verification
  RC_IN_VERIFICATION_TAB: 'rc_in_verification_tab',
  RC_VERIFICATION_QUANTITY_CHECK_FALSE: 'rc_verification_quantity_check_false',
  RC_VERIFICATION_NRC_SET: 'rc_verification_nrc_set',
  RC_VERIFICATION_NRC_RESET: 'rc_verification_nrc_reset',
  RC_VERIFICATION_BOX_VERIFIED_LOCKED: 'rc_verification_box_verified_locked',
  RC_VERIFICATION_BOX_VERIFIED_UNLOCKED:
    'rc_verification_box_verified_unlocked',
  RC_ORDER_STATUS_CHANGED_TO_READY_TO_BE_SHIPPED:
    'rc_order_status_changed_to_ready_to_be_shipped',
  RC_BOX_STATUS_CHANGED_TO_READY_TO_BE_SHIPPED:
    'rc_box_status_changed_to_ready_to_be_shipped',
  RC_VERIFICATION_COMMENT_TO_PRODUCT_EDIT:
    'rc_verification_comment_to_product_edit',
  RC_VERIFICATION_REPORT_GENERATED: 'rc_verification_report_generated',
  RC_READY_TO_BE_SHIPPED_TAB: 'rc_ready_to_be_shipped_tab',
  RC_ORDER_STATUS_CHANGED_TO_SHIPPED: 'rc_order_status_changed_to_shipped',
  RC_SHIPPED_TAB: 'rc_shipped_tab',

  // RC Archive
  RC_COMPLETED_TAB: 'rc_completed_tab',

  // Product authentication - end customer
  PRODUCT_AUTHENTICATION_REQ_LIST_VIEW: 'product_authentication_requests_list',
  PRODUCT_AUTHENTICATION_REQUEST_SENT: 'product_authentication_request_sent',

  // Product authentication - legal
  PA_SEARCHING_THROUGH_COUNTERFEIT_REQUESTS:
    'pa_searching_through_counterfeit_requests',
  PA_INBOX_TAB: 'pa_inbox_tab',
  PA_INSPECTION_TAB: 'pa_inspection_tab',
  PA_RESPONSE_TAB: 'pa_response_tab',
  PA_ARCHIVE_TAB: 'pa_archive_tab',
  PA_ADDED_COMMENT: 'pa_added_comment',
  PA_EDITED_COMMENT: 'pa_edited_comment',
  PA_DELETED_COMMENT: 'pa_deleted_comment',
};

export const NOTIFICATION_SETTINGS = {
  NEW_THREADS: {
    KEY: 'receiveThreadCreated',
    PERMISSION: PERMISSIONS.NOTIFICATION_THREADS,
  },
  EDIT_THREADS: {
    KEY: 'receiveThreadUpdated',
    PERMISSION: PERMISSIONS.NOTIFICATION_THREADS,
  },
  DELETE_THREADS: {
    KEY: 'receiveThreadDeleted',
    PERMISSION: PERMISSIONS.NOTIFICATION_THREADS,
  },
  COMPLETE_THREADS: {
    KEY: 'receiveThreadCompleted',
    PERMISSION: PERMISSIONS.NOTIFICATION_THREADS,
  },
  REMIND_THREADS: {
    KEY: 'receiveThreadReminder',
    PERMISSION: PERMISSIONS.NOTIFICATION_THREADS,
  },
  CONDITION_RATING: {
    KEY: 'receiveToolCondition',
    PERMISSION: PERMISSIONS.NOTIFICATION_CONDITION_RATING,
  },
  TIME_TRACKER: {
    KEY: 'receiveTimeTracker',
    PERMISSION: PERMISSIONS.NOTIFICATION_TIME_TRACKER,
  },
  ORDERS: {
    KEY: 'receiveOrderNotifications',
    PERMISSION: PERMISSIONS.NOTIFICATION_ORDERS,
  },
};

export const NOTIFICATION_PERMISSIONS = [
  PERMISSIONS.NOTIFICATION_THREADS,
  PERMISSIONS.NOTIFICATION_CONDITION_RATING,
  PERMISSIONS.NOTIFICATION_TIME_TRACKER,
  PERMISSIONS.NOTIFICATION_ORDERS,
];

export const NOTIFICATION_STATUS = {
  READ: 'Read',
  UNREAD: 'New',
  INTERACTED_WITH: 'InteractedWith',
};

export const DEFAULT_AVATAR_NAME = 'default_profile_avatar';

export const URLS = {
  BRIDGE_COOKIES:
    'https://www.home.sandvik/en/about-this-site/cookie-privacy-policy/',
  SECO_TOOLS_ARTICLE: 'https://secotools.com/article/p_',
  TOOLS_UNITED_ARTICLE:
    'https://www.toolsunited.com/App/EN/Article/ArticleDetailsPage/',
  WALTER_ARTICLE:
    'https://www.walter-tools.com/en-us/search/pages/default.aspx/product/',
  SANDVIK_COROMANT_ARTICLE:
    'https://www.sandvik.coromant.com/en-us/products/pages/productdetails.aspx?c=',
  DORMER_PRAMET_ARTICLE: 'https://dormerpramet.com/p/',
};

export const IDEM_COOKIES = ['lang', '_ga', '_ga_EVGRS4ZDTN'];

export const USER_COOKIE = 'bridge-cookies';

export const COOKIE_EXPIRE_DAYS = 365;

export const VIEW_OPTIONS = {
  GRID: 'GRID',
  LIST: 'LIST',
};

export const ENCODING_TYPES = {
  BASE64: 'base64',
  BINARY: 'binary',
};

export const RESPONSE_TYPES = {
  BLOB: 'blob',
};

export const PRODUCTS_TABLE_HEADER_MIN_WIDTH = 25;

export const THREAD_OPTIONS = {
  ADD_THREAD: 'ADD_THREAD',
  EDIT_THREAD: 'EDIT_THREAD',
};

export const SPECIAL_REQUEST_OPTIONS = {
  ADD_SPECIAL_REQUEST: 'ADD_SPECIAL_REQUEST',
  EDIT_SPECIAL_REQUEST: 'EDIT_SPECIAL_REQUEST',
};

export const RC_COMMENT_OPTIONS = {
  ADD_RC_COMMENT: 'ADD_RC_COMMENT',
  EDIT_RC_COMMENT: 'EDIT_RC_COMMENT',
};

export const RC_FEEDBACK_OPTIONS = {
  ADD_RC_FEEDBACK: 'ADD_RC_FEEDBACK',
  EDIT_RC_FEEDBACK: 'EDIT_RC_FEEDBACK',
};

export const ERROR_STATUS = {
  NOT_FOUND: 404,
};

export const REQUIRED_OPTIONS = {
  YES: { name: 'Yes', value: true },
  NO: { name: 'No', value: false },
};

export const FIELD_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  TEXTAREA: 'textarea',
};

export const TEXT_TYPE = {
  ANY: 'Any',
  LINK: 'Link',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone number',
};

export const MAX_NUMBER_OF_CHARACTERS = {
  DESCRIPTION: 255,
  INPUT: 255,
  TEXT_AREA: 800,
  INTEGER_NUMBER: 9,
  PRODUCT_QUANTITY: 2,
  TIME_TRACKER: 6,
  TIME_TRACKER_UNIT: 10,
  GENERAL_COMMENT: 250,
  NAME: 30,
  USERNAME: 32,
  TRACKING_NUMBER: 25,
};

export const DEFAULT_TIME_TRACKER_UNIT = 'minutes';

export const MIN_COUNTER_VALUE = 1;

export const CRIBWISE_CONNECTION_OPTIONS = {
  ADD_CREDENTIALS: 'ADD_CREDENTIALS',
  EDIT_CREDENTIALS: 'EDIT_CREDENTIALS',
};

// Dummy password is being used because backend doesn't return password
// for security issues, but something has to be displayed to user.
export const CRIBWISE_ACCOUNT_DUMMY_PASSWORD = '0000000000';

export const TYPE_OPTIONS = {
  TEXT: { name: 'Text', value: 'text' },
  NUMBER: { name: 'Number', value: 'numeric' },
  BOOLEAN: { name: 'Yes/No', value: 'boolean' },
};

export const YES_NO_OPTIONS = {
  YES: 'sharedComponents.yes',
  NO: 'sharedComponents.no',
};

export const FILE_EXTENSION = {
  XLSX: 'xlsx',
};

export const MIME_TYPE = {
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const BOX_CONSTANTS = {
  WEIGHT_UNIT: 'kg',
  PRICE_UNIT: 'eur',
  DIMENSION_UNIT: 'mm',
};

export const EXTERNAL_PROVIDERS_LOGOS = {
  SECO: secoLogo,
  TOOLS_UNITED: toolsUnitedLogo,
  WALTER: walterLogo,
  SANDVIK_COROMANT: sandvikCoromantLogo,
  SANDVIKCOROMANT: sandvikCoromantLogo,
  COROMANT: sandvikCoromantLogo,
  DORMER_PRAMET: dormerPrametLogo,
  DORMERPRAMET: dormerPrametLogo,
};

export const BRAND_LOGOS_SIDEBAR = {
  Bridge: bridgeLogoSidebar,
  Walter: walterLogoSidebar,
  Seco: secoLogoSidebar,
  DormerPramet: dormerPrametLogoSidebar,
  MasterCam: masterCamLogo,
  Sandvik: sandvikLogo,
};

export const REGEX = {
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  URL: /[(htps)?:/w.a-zA-Z0-9@%_+~#=]{2,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/,
  POSTAL_CODE: /^[A-Za-z0-9][A-Za-z0-9\- ]{0,10}[A-Za-z0-9]$/,
  // eslint-disable-next-line no-useless-escape
  PHONE: /^[+]{0,1}(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/,
  DIMENSIONS_RECON_INFO: /^\d{0,3}(\.\d{0,3})?$/,
};

export const ADD_BOX_MODAL_OPTIONS = {
  ADD_EXISTING_BOX: 'ADD_EXISTING_BOX',
  REQUEST_NEW_BOX: 'REQUEST_NEW_BOX',
};

export const EXISTING_BOX_TYPES = {
  CUSTOM: 'CUSTOM',
  ORDERED: 'ORDERED',
};

export const DATE_FORMATS = {
  TIMESTAMP: 'YYYY-MM-DD HH:mm',
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm',
  YEAR: 'YYYY',
  // date picker crashes if 'YYYY-MM-DD' format is sent
  DATE_PICKER: 'yyyy-MM-dd',
};

export const MIN_WEIGHT_OF_CUSTOM_BOX = 1;
export const MAX_WEIGHT_OF_CUSTOM_BOX = 19.99;
export const MAX_DIMENSION_OF_CUSTOM_BOX = 999;

export const CUSTOM_BOX_PROVIDER = 'CustomBox';

export const SCAN_OPTIONS = {
  WEB_CAMERA: 'WEB_CAMERA',
  SCANNER: 'SCANNER',
};

export const NUMBER_OF_PRODUCT_RECON_DIMENSIONS_DECIMALS = 3;
export const NUMBER_OF_MAX_WEIGHT_CUSTOM_BOX_DECIMALS = 2;

export const BOX_CAPACITY_PERCENTAGE_THRESHOLD = 70;

// recon center tabs
export const RECON_CENTER_DASHBOARD_TAB_LABELS = {
  REQUESTED_PICKUPS: 'requested_pickups',
  INBOUND: 'inbound',
  RECEIVED: 'received',
  TOOLS: 'tools',
  IN_PROGRESS: 'in_progress',
  IN_VERIFICATION: 'in_verification',
  TO_BE_SHIPPED: 'to_be_shipped',
  SHIPPED: 'shipped',
  COMPLETED: 'completed',
};

export const RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS = {
  REQUESTED_PICKUPS: 'containers.requestedPickups',
  INBOUND: 'containers.inbound',
  RECEIVED: 'containers.received',
  TOOLS: 'containers.tools',
  IN_PROGRESS: 'containers.inProgress',
  IN_VERIFICATION: 'containers.inVerification',
  TO_BE_SHIPPED: 'containers.toBeShipped',
  SHIPPED: 'containers.shipped',
  COMPLETED: 'containers.completed',
};

// tabs constants

export const DEFAULT_ROUTE_TABS = {
  [ROUTES.DATABASE]: {
    [HORIZONTAL_TAB_QUERY]: DATABASE_DASHBOARD_TAB_LABELS.PRODUCTS,
  },
  [ROUTES.DATABASE_ASSEMBLIES]: {
    [HORIZONTAL_TAB_QUERY]: TAB_LABELS.PRODUCT_DATA,
    [VERTICAL_TAB_QUERY]: TAB_LABELS.COMPONENTS,
  },
  [ROUTES.DATABASE_PRODUCTS]: {
    [HORIZONTAL_TAB_QUERY]: TAB_LABELS.PRODUCT_DATA,
    [VERTICAL_TAB_QUERY]: TAB_LABELS.TECHNICAL,
  },
  [ROUTES.RECONDITIONING]: {
    [HORIZONTAL_TAB_QUERY]: RECON_DASHBOARD_TAB_LABELS.BOXES,
  },
  [ROUTES.RC_INCOMING]: {
    [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.REQUESTED_PICKUPS,
  },
  [ROUTES.RC_INSPECTIONS]: {
    [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.IN_PROGRESS,
  },
  [ROUTES.RC_SHIPPED]: {
    [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.IN_VERIFICATION,
  },
  [ROUTES.RC_RECONDITIONING]: {
    [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.IN_PROGRESS,
  },
  [ROUTES.RC_ARCHIVE]: {
    [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.COMPLETED,
  },
  // add for all components that are using TabsWrapper component
};

export const TITLE_STORAGE_KEY = 'title';

export const ORGANIZATION_TYPE = {
  CUSTOMER: 'Customer',
  RECON_CENTER: 'ReconCenter',
  SALES: 'Sales',
  LEGAL: 'Legal',
  LEGAL_DIVISION: 'LegalDivision',
};
