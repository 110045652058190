import React from 'react';

import PropTypes from 'prop-types';
import { X } from 'react-feather';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../../assets/icon/iconfinder_triangle-left_293631.svg';
import { COLORS } from '../../../constants/theme';
import { PrimaryButton } from '../../Shared';

const Options = ({
  hasX = false,
  handleCancelModal,
  title,
  subtitle,
  options,
  hasCancel = false,
  handleBack,
}) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column h-100'>
      {hasX && (
        <div className='pr-2 cursor-pointer' onClick={handleCancelModal}>
          <X width={40} height={40} color={COLORS.TERTIARY} />
        </div>
      )}
      {!!handleBack && (
        <BackIcon
          className='icon-tertiary hoverable'
          width={26}
          height={26}
          onClick={handleBack}
        />
      )}
      <div className='modal-title text-center pt-4'>{title}</div>
      <div className='modal-subtitle text-center pt-1 pb-5'>{subtitle}</div>
      <div className='justify-content-around d-flex flex-row pt-5'>
        {options.map(({ handleClick, image, title }) => {
          return (
            <div key={title}>
              <Card
                className='mb-0 cursor-pointer hover-active'
                onClick={handleClick}
              >
                {image}
              </Card>
              <div className='modal-icon-title text-center pt-3'>{title}</div>
            </div>
          );
        })}
      </div>
      {hasCancel && (
        <div className='flex-grow-1 d-flex align-items-end justify-content-between pb-2'>
          <div>
            <PrimaryButton outline onClick={handleCancelModal}>
              {t('button.cancel')}
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

Options.propTypes = {
  hasX: PropTypes.bool,
  hasCancel: PropTypes.bool,
  handleCancelModal: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  handleBack: PropTypes.func,
};

export default Options;
