import React, { useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import Img from 'react-image';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from '../../assets/icon/ic-delete-input.svg';
import { browse } from '../../assets/img';
import PillButton from './PillButton';

const Dropzone = ({ files, setFiles, maxFileSize, allowedFiles }) => {
  const { acceptedFiles, open, getInputProps } = useDropzone({
    noClick: true,
    multiple: false,
    maxSize: maxFileSize,
    accept: allowedFiles,
  });

  useEffect(() => {
    setFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const { t } = useTranslation();

  return (
    <>
      <input {...getInputProps()} />
      <div className='d-flex justify-content-center align-items-center flex-column'>
        <Img width={200} height={175} src={browse} />
        {!files.length ? (
          <PillButton onClick={open}>{t('button.browse')}</PillButton>
        ) : (
          files.map((file) => (
            <div key={file.path} className='file-row'>
              <DeleteIcon
                className='cursor-pointer position-absolute icon-danger mr-2'
                width={26}
                height={26}
                onClick={() => setFiles([])}
              />
              <div className='file-name'>{file.path}</div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

Dropzone.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
  allowedFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Dropzone;
