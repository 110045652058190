import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Carousel, CarouselItem, Col, Container, Row } from 'reactstrap';
import Modal from '../Modal';
import { getCoverImage } from '../../../utils/images';
import { ReactComponent as CloseButtonIcon } from '../../../assets/icon/ic_btn_close.svg';
import { ReactComponent as NextButtonIcon } from '../../../assets/icon/ic_btn_next.svg';
import { ReactComponent as PreviousButtonIcon } from '../../../assets/icon/ic_btn_previous.svg';

import Image from '../../Shared/Image';

const ImageModal = ({
  isOpened,
  handleClose,
  images,
  toggle,
  index: imageIndex,
}) => {
  const [index, setIndex] = useState(imageIndex);
  const [animating, setAnimating] = useState(false);

  const handleCancelModal = () => {
    handleClose();
  };

  useEffect(() => {
    setIndex(imageIndex);
  }, [imageIndex]);

  const handlePreviousClick = () => {
    if (animating || index === -1) {
      return;
    }
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  const handleNextClick = () => {
    if (animating || index === -1) {
      return;
    }
    setIndex(index === images.length - 1 ? 0 : index + 1);
  };

  return (
    <Modal isOpened={isOpened} backdrop toggle={toggle} scrollable={false}>
      <div className='d-flex flex-column h-100'>
        <div className='pr-2 cursor-pointer' onClick={handleCancelModal}>
          <CloseButtonIcon
            className='icon-tertiary icon-hover-secondary'
            width={40}
            height={40}
          />
        </div>
        <div className='d-flex flex-grow-1 align-items-center justify-content-center'>
          <Container className='p-0'>
            <Row>
              <Col
                sm={1}
                className={cs({
                  'cursor-pointer': index !== -1,
                })}
                onClick={handlePreviousClick}
              >
                {index !== -1 && (
                  <div className='d-flex align-items-center h-100'>
                    <PreviousButtonIcon
                      className='icon-tertiary icon-hover-secondary'
                      width={54}
                      height={54}
                    />
                  </div>
                )}
              </Col>
              <Col sm={10}>
                <div className='d-flex align-items-center justify-content-center'>
                  {index === -1 ? (
                    <div>
                      <Image
                        className='image-carousel'
                        src={getCoverImage(images)}
                      />
                    </div>
                  ) : (
                    <Carousel
                      activeIndex={index}
                      next={handleNextClick}
                      previous={handlePreviousClick}
                      interval={false}
                      className='h-100 w-100'
                    >
                      {images.map(({ url }) => (
                        <CarouselItem
                          onExiting={() => setAnimating(true)}
                          onExited={() => setAnimating(false)}
                          key={url}
                        >
                          <div>
                            <Image className='image-carousel' src={url} />
                          </div>
                        </CarouselItem>
                      ))}
                    </Carousel>
                  )}
                </div>
              </Col>
              <Col
                sm={1}
                className={cs({
                  'cursor-pointer': index !== -1,
                })}
                onClick={handleNextClick}
              >
                {index !== -1 && (
                  <div className='d-flex align-items-center h-100'>
                    <NextButtonIcon
                      width={54}
                      height={54}
                      className='icon-tertiary icon-hover-secondary'
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Modal>
  );
};

ImageModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default ImageModal;
