export const removeTrailingSlashes = (path) => path.replace(/\/+$/, '');

export const isRouteActive = (currentPathname, path, additionalPaths = []) =>
  currentPathname.includes(path) ||
  additionalPaths.find((p) => currentPathname.includes(p));

export const navigateWithState = (history, newPath, locationState) => {
  const { location } = history;

  // Check if the current path matches the desired path
  if (location.pathname === newPath) {
    // If so, update the location state
    history.replace(newPath, locationState);
  } else {
    // If not, navigate to the desired path with the location state
    history.push(newPath, locationState);
  }
};
