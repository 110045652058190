import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import StepWithIcon from './StepWithIcon';

const WarningStep = ({ title = '', children, ...props }) => {
  const { t } = useTranslation();
  return (
    <StepWithIcon
      {...props}
      title={title || t('modal.warning')}
      icon={<WarningIcon className='warning-icon icon-danger mb-5' />}
    >
      {children}
    </StepWithIcon>
  );
};

WarningStep.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default WarningStep;
