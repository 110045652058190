import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { NewDone, NewLoading, NewModalStep } from '../Shared';
import { Loader, CustomStepper, Counter } from '../../Shared';
import BoxesTable from '../../ReconditioningComponents/BoxesTable';
import { getMappedErrorMessage } from '../../../utils/error';
import { fetchBoxTemplates } from '../../../client/box';
import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import { ReactComponent as BoxIcon } from '../../../assets/icon/ic_box.svg';
import { requestNewBoxes } from '../../../redux/actions/boxActions';
import ShippingDetailsForm from '../../ProductComponents/ShippingDetailsForm';
import DeliveryOptionItem from '../../ReconditioningComponents/DeliveryOptionItem';
import ShippingInfo from '../../ReconditioningComponents/ShippingInfo';
import { displayBoxProvider } from '../../../utils/box';
import { trimValuesInObject } from '../../../utils/shared';
import { scrollModalContentToTop } from '../../../utils/modal';
import { handleAnalytics } from '../../../utils/analytics';
import { FIREBASE_EVENTS } from '../../../constants';

const debug = _debug('Bridge:RequestNewBox');

const STEPS = {
  CHOOSE_TEMPLATES: 'CHOOSE_TEMPLATES',
  DELIVERY: 'DELIVERY',
  ADDRESS: 'ADDRESS',
  CONFIRM: 'CONFIRM',
  REQUESTING_NEW_BOX: 'REQUESTING_NEW_BOX',
  REQUEST_SENT: 'REQUEST_SENT',
  ERROR: 'ERROR',
};

const REQUEST_DETAILS_STEPS = [
  { label: 'modal.delivery', name: 'delivery' },
  { label: 'modal.address', name: 'address' },
  { label: 'modal.confirm', name: 'confirm' },
];

const RequestNewBox = ({
  handleClose,
  goBack,
  requestNewBoxes,
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(STEPS.CHOOSE_TEMPLATES);
  const [boxTemplates, setBoxTemplates] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [shippingDetails, setShippingDetails] = useState();
  const shippingFormRef = useRef();

  useEffect(() => {
    if (step === STEPS.CHOOSE_TEMPLATES && !boxTemplates.length) {
      try {
        (async () => {
          const { data } = await fetchBoxTemplates();
          setBoxTemplates(
            data.data?.map((template) => ({ ...template, checked: false }))
          );
          setLoading(false);
        })();
      } catch (e) {
        debug(`Couldn't fetch box templates. Reason: ${e}`);
        setError(getMappedErrorMessage(e));
        setStep(STEPS.ERROR);
        setLoading(false);
      }
    }

    scrollModalContentToTop();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleCheckboxClick = ({ id: templateId }) =>
    setBoxTemplates((prevState) =>
      prevState.map((template) =>
        template.id === templateId
          ? { ...template, checked: !template.checked }
          : template
      )
    );

  const getActiveStep = () =>
    REQUEST_DETAILS_STEPS.map((step) => step.name.toUpperCase()).indexOf(step);

  const handleRequestNewBoxClick = async () => {
    try {
      setStep(STEPS.REQUESTING_NEW_BOX);

      const data = {
        isExpressDelivery,
        ...shippingDetails,
        phoneNumber: shippingDetails.phoneNumber || null,
        boxTemplates: boxTemplates
          .filter(({ checked }) => checked)
          .map(({ id, count }) => ({ id, count })),
      };

      await requestNewBoxes(data);
      handleAnalytics(FIREBASE_EVENTS.REQUEST_NEW_BOX);

      setStep(STEPS.REQUEST_SENT);
    } catch (e) {
      debug(`Couldn't request box instances. Reason: ${e}`);
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
    }
  };

  const renderStepper = () => (
    <CustomStepper
      className='w-80'
      steps={REQUEST_DETAILS_STEPS.map(({ label }) => ({ label: t(label) }))}
      activeStep={getActiveStep()}
    />
  );

  const handleOpenDeliveryStep = () => {
    setBoxTemplates((prevState) =>
      prevState.map((template) => ({
        ...template,
        count: template.count || 1,
      }))
    );
    setStep(STEPS.DELIVERY);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.CHOOSE_TEMPLATES:
        return (
          <NewModalStep
            title={t('modal.requestNewBox')}
            subtitle={t('modal.selectTheBoxesYouWantToRequest')}
            rightButtonContent={t('button.requestBox')}
            handleClickRightButton={handleOpenDeliveryStep}
            rightButtonDisabled={
              !boxTemplates.filter(({ checked }) => checked).length
            }
            leftButtonContent={goBack ? t('button.back') : ''}
            handleClickLeftButton={goBack}
            handleCancel={handleClose}
            isCenteredHorizontally
          >
            <div className='pt-4'>
              {loading ? (
                <Loader />
              ) : (
                <BoxesTable
                  handleCheckboxClick={handleCheckboxClick}
                  boxes={boxTemplates}
                />
              )}
            </div>
          </NewModalStep>
        );
      case STEPS.DELIVERY:
        return (
          <NewModalStep
            title={t('modal.requestNewBox')}
            leftButtonContent={t('button.back')}
            rightButtonContent={t('button.nextCapitalized')}
            handleClickLeftButton={() => setStep(STEPS.CHOOSE_TEMPLATES)}
            handleClickRightButton={() => setStep(STEPS.ADDRESS)}
            headerChildren={renderStepper()}
            handleCancel={handleClose}
            bodyClassName='d-flex flex-column w-100'
          >
            <div className='d-flex flex-column'>
              <DeliveryOptionItem
                title={t('modal.regularDelivery')}
                subtitle={t('modal.orderWillBeDeliveredBetween35BusinessDays')}
                value={!isExpressDelivery}
                onChange={() => setIsExpressDelivery(false)}
              />
              <DeliveryOptionItem
                title={t('modal.expressDelivery')}
                subtitle={t(
                  'modal.placeYourOrderBefore6pmAndYourItemsWillBeDeliveredTheNextDay'
                )}
                value={isExpressDelivery}
                onChange={() => setIsExpressDelivery(true)}
              />
            </div>
          </NewModalStep>
        );
      case STEPS.ADDRESS:
        return (
          <NewModalStep
            title={t('modal.requestNewBox')}
            leftButtonContent={t('button.back')}
            rightButtonContent={t('button.nextCapitalized')}
            handleClickLeftButton={() => setStep(STEPS.DELIVERY)}
            handleClickRightButton={() =>
              shippingFormRef.current.requestSubmit()
            }
            handleCancel={handleClose}
            headerChildren={renderStepper()}
          >
            <ShippingDetailsForm
              formRef={shippingFormRef}
              onSubmitSuccess={() => {
                setShippingDetails((prevState) =>
                  trimValuesInObject(prevState)
                );
                setStep(STEPS.CONFIRM);
              }}
              setFormState={setShippingDetails}
              initialValues={shippingDetails}
            />
          </NewModalStep>
        );
      case STEPS.CONFIRM:
        return (
          <NewModalStep
            title={t('modal.requestNewBox')}
            leftButtonContent={t('button.back')}
            rightButtonContent={t('button.confirm')}
            handleClickLeftButton={() => setStep(STEPS.ADDRESS)}
            handleClickRightButton={handleRequestNewBoxClick}
            handleCancel={handleClose}
            headerChildren={renderStepper()}
            bodyClassName='d-flex flex-column w-100'
          >
            <ShippingInfo
              shippingDetails={shippingDetails}
              className='bottom-shadow pl-5 pb-3 mb-4'
              titleClassName='mt-2 pb-4'
            />
            <div className='d-flex flex-column w-100'>
              <p className='font-weight-bold font-size-md text-txt-primary ml-5 mt-2 mb-4'>
                {t('modal.orderList').toUpperCase()}
              </p>
              {boxTemplates.map(({ name, boxProvider, count, checked }, i) =>
                checked ? (
                  <div className='d-flex justify-content-between bottom-shadow w-100'>
                    <div className='d-flex w-100 py-3'>
                      <BoxIcon
                        height={54}
                        width={54}
                        className='mx-5 icon-tertiary'
                      />
                      <div className='d-flex flex-column justify-content-center text-txt-secondary ml-2'>
                        <p className='mb-0 font-weight-bold'>{name}</p>
                        <p className='m-0'>
                          {displayBoxProvider({ boxProvider })}
                        </p>
                      </div>
                    </div>
                    <Counter
                      className='justify-content-end pr-5'
                      counterValue={count}
                      handleCounterValueChange={(value) =>
                        setBoxTemplates((prevState) =>
                          prevState.map((template, ind) =>
                            ind === i ? { ...template, count: value } : template
                          )
                        )
                      }
                    />
                  </div>
                ) : null
              )}
            </div>
          </NewModalStep>
        );
      case STEPS.REQUESTING_NEW_BOX:
        return <NewLoading value={t('modal.sendingRequest')} />;
      case STEPS.REQUEST_SENT:
        return (
          <NewModalStep
            rightButtonContent={t('button.done')}
            handleClickRightButton={() => {
              handleClose();
              onSuccess();
            }}
            handleCancel={() => {
              handleClose();
              onSuccess();
            }}
          >
            <NewDone
              title={t('modal.requestSuccessfullySent')}
              height={96}
              width={96}
            />
          </NewModalStep>
        );
      case STEPS.ERROR:
        return (
          <NewModalStep
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column text-txt-primary pb-5 justify-content-center'
            rightButtonContent={t('button.cancel')}
            handleClickRightButton={handleClose}
          >
            <WarningIcon className='warning-icon icon-danger mb-5' />
            <div className='mb-5'>
              {t(error) || t('error.somethingWentWrong')}
            </div>
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return renderStep();
};

RequestNewBox.propTypes = {
  handleClose: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  requestNewBoxes: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default connect(null, { requestNewBoxes })(RequestNewBox);
