import { READER_STATES, VIEW_OPTIONS } from '../constants';
import { BOX_STATUSES } from '../containers/ReconditioningContainers/constants';

const initialState = {
  auth: {},
  sidebar: {
    isOpen: true,
    devMode: false,
  },
  group: {
    organizationDetails: {},
    organizationAdmins: [],
    organizationMembers: [],
    groups: [],
    isConnectionOk: null,
    cribwiseConnectionCredentials: {},
  },
  reader: {
    state: READER_STATES.GLOBAL,
    scannedTag: null,
    isScanning: false,
    codeType: null,
  },
  product: {
    products: [],
    highlightedIds: [],
    productsToAddToStart: [],
    productIdsToFilterOutWhileFetching: [],
    details: {},
    externalProducts: [],
    secoToolsProducts: [],
    unitedToolsProducts: [],
    walterProducts: [],
    coromantProducts: [],
    dormerPrametProducts: [],
    threads: {
      general: [],
      instance: [],
    },
    supportMessages: [],
    supportMessageCount: 0,
    instance: {},
    instances: [],
    instanceLocations: [],
    instanceAssemblyInstance: {},
    instanceConditionRatings: {
      data: [],
      highlightedId: null,
    },
    instanceTimeTrackerHistory: {
      data: [],
      highlightedId: null,
    },
    productsSearch: '',
    selectedGroups: [],
    productAssemblies: [],
    providerError: null,
    productStockLevel: null,
  },
  tag: {
    searchProducts: [],
    emptyTag: null,
    occupiedTag: null,
    scannedTags: [],
    totalCount: 0,
    productCount: 0,
    isFetching: null,
    error: {
      title: '',
    },
  },
  assembly: {
    assemblies: [],
    highlightedIds: [],
    assembliesToAddToStart: [],
    assemblyIdsToFilterOutWhileFetching: [],
    details: {},
    instance: {},
    instanceLocations: [],
    instanceTimeTrackerHistory: {
      data: [],
      highlightedId: null,
    },
    instanceConditionRatings: {
      data: [],
      highlightedId: null,
    },
    threads: {
      general: [],
      instance: [],
    },
  },
  box: {
    details: {},
    productInstances: [],
    boxes: [],
    displayedBoxesStatuses: [BOX_STATUSES.ALL],
    highlightedIds: [],
    boxesToAddToStart: [],
    boxesIdsToFilterOutWhileFetching: [],
  },
  order: {
    details: {},
    boxes: [],
    orders: [],
    toolsFromOrders: [],
    displayedOrderStatuses: [],
    highlightedIds: [],
    ordersToAddToStart: [],
    ordersIdsToFilterOutWhileFetching: [],
  },
  inspection: {
    productInstances: [],
    inspectionDetails: {},
  },
  counterfeitRisk: {
    requests: [],
    highlightedIds: [],
  },
  user: {
    details: null,
    internalProductsCount: 0,
    externalProductsCount: 0,
    assembliesCount: 0,
    internalProductsLimit: 0,
    externalProductsLimit: 0,
    assembliesLimit: 0,
    settings: {},
    organization: {},
    language: 'en',
  },
  supportMessage: {
    supportMessages: [],
    count: 0,
  },
  application: {
    jobTitles: [],
    countries: [],
    languages: [],
    viewOption: VIEW_OPTIONS.GRID,
    serverMetadataFetched: false,
    invalidFileTypes: [],
    maxFileSizeInBytes: 0,
  },
  notification: {
    notifications: [],
    unreadCount: 0,
    rowCount: 0,
    connected: false,
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
  },
  breadcrumbs: [],
  util: {
    triggerRefetchingOfData: () => {},
  },
};

export default initialState;
