import React from 'react';

import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import PropTypes from 'prop-types';

import { Image, Table } from '../Shared';

const ProductsTable = ({
  products = [],
  setSelectedProduct,
  imageContainerClassName = '',
  className = '',
}) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      name: t('modal.productName'),
      style: { width: '50%' },
      isResizable: true,
      className: 'table-padding-left',
    },
    {
      name: t('modal.productNumber'),
      style: { width: '25%' },
      isResizable: true,
    },
    {
      name: t('components.brand'),
      style: { width: '25%' },
    },
  ];

  return (
    <Table
      data={products.map((product, i) => ({
        rowColumns: [
          <>
            <Image
              src={product.imageUrl}
              width={40}
              height={40}
              className='text-center border border-secondary rounded-xl table-margin-left my-2'
              containerClassName={
                imageContainerClassName
                  ? `image-object-fit-content my-2 ${imageContainerClassName}`
                  : 'image-object-fit-content pl-2 my-2'
              }
            />
            <span className='text-uppercase font-weight-bold ml-4'>
              {product.name}
            </span>
          </>,
          product.code,
          product.brand || t('modal.noData'),
        ],
        rowClassName: cs('cursor-pointer', {
          'border-top': i === 0,
          'border-bottom': i !== products.length,
        }),
        onClick: () => setSelectedProduct(product),
      }))}
      columns={tableColumns}
      striped={false}
      className={`w-100 ${className}`}
      highlightRowOnClick
    />
  );
};

ProductsTable.propTypes = {
  products: PropTypes.array.isRequired,
  imageContainerClassName: PropTypes.string,
  setSelectedProduct: PropTypes.func,
  className: PropTypes.string,
};

export default ProductsTable;
