import * as types from '../constants';
import {
  fetchGroups,
  fetchJoinGroup,
  fetchLeaveGroup,
  fetchOrganizationAdmins,
  fetchOrganizationDetails,
  fetchOrganizationMembers,
  patchGroups,
  postGroups,
  postInvite,
  postInviteToOrganization,
  deleteOrganizationMembers,
  deleteGroupMembers,
  deleteGroupAdminRights,
  patchGroupAdminRights,
  deleteOrganizationAdminRights,
  patchOrganizationAdminRights,
  patchOrganization,
  testLastConnection,
  fetchCribwiseAccount,
  putCribwiseAccount,
} from '../../client/group';
import { getCurrentUserId } from '../../utils/groups';
import { fetchUser } from '../../client/user';
import { deleteProductsOfGroup, deselectGroupSuccess } from './productActions';
import { removeAssemblyOfGroup } from './assemblyActions';

export const getOrganizationDetailsSuccess = (details) => {
  return {
    type: types.GET_ORGANIZATION_DETAILS_SUCCESS,
    payload: {
      details,
    },
  };
};

export const testLastConnectionSuccess = (isConnectionOk) => {
  return {
    type: types.GET_CRIBWISE_STATUS_CONNECTION_SUCCESS,
    payload: {
      isConnectionOk,
    },
  };
};

export const getCribwiseConnectionCredentialsSuccess = (data) => {
  return {
    type: types.GET_CRIBWISE_CREDENTIALS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const editCribwiseConnectionCredentialsSuccess = (data) => {
  return {
    type: types.EDIT_CRIBWISE_CREDENTIALS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateLastConnectionSuccess = (isConnectionOk) => {
  return {
    type: types.UPDATE_CRIBWISE_STATUS_CONNECTION_SUCCESS,
    payload: {
      isConnectionOk,
    },
  };
};

export const getGroupsSuccess = (groups, currentUserId) => {
  return {
    type: types.GET_GROUPS_SUCCESS,
    payload: {
      groups,
      currentUserId,
    },
  };
};

export const highlightGroupDetailsSuccess = (data) => {
  return {
    type: types.HIGHLIGHT_GROUP_DETAILS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createGroupSuccess = (group) => {
  return {
    type: types.CREATE_GROUP_SUCCESS,
    payload: {
      group,
    },
  };
};

export const editGroupSuccess = (group) => {
  return {
    type: types.EDIT_GROUP_SUCCESS,
    payload: {
      group,
    },
  };
};

export const editOrganizationSuccess = (organization) => {
  return {
    type: types.EDIT_ORGANIZATION_SUCCESS,
    payload: {
      organization,
    },
  };
};

export const getOrganizationMembersSuccess = (members) => {
  return {
    type: types.GET_ORGANIZATION_MEMBERS,
    payload: {
      members,
    },
  };
};

export const getOrganizationAdminsSuccess = (members, currentUserId) => {
  return {
    type: types.GET_ORGANIZATION_ADMINS,
    payload: {
      members,
      currentUserId,
    },
  };
};

export const deleteOrganizationMemberSuccess = (members, organizationId) => {
  return {
    type: types.DELETE_ORGANIZATION_MEMBER_SUCCESS,
    payload: {
      members,
      organizationId,
    },
  };
};

export const deleteGroupMemberSuccess = (members, groupId) => {
  return {
    type: types.DELETE_GROUP_MEMBER_SUCCESS,
    payload: {
      members,
      groupId,
    },
  };
};

export const revokeOrganizationAdminRightsSuccess = (
  admins,
  organizationId
) => {
  return {
    type: types.REVOKE_ORGANIZATION_ADMIN_RIGHTS,
    payload: {
      admins,
      organizationId,
    },
  };
};

export const revokeGroupAdminRightsSuccess = (admins, groupId) => {
  return {
    type: types.REVOKE_GROUP_ADMIN_RIGHTS,
    payload: {
      admins,
      groupId,
    },
  };
};
export const assignOrganizationAdminRightsSuccess = (
  members,
  organizationId
) => {
  return {
    type: types.ASSIGN_ORGANIZATION_ADMIN_RIGHTS,
    payload: {
      members,
      organizationId,
    },
  };
};

export const assignGroupAdminRightsSuccess = (members, groupId) => {
  return {
    type: types.ASSIGN_GROUP_ADMIN_RIGHTS,
    payload: {
      members,
      groupId,
    },
  };
};

export const inviteUsersSuccess = (groupId, users) => {
  return {
    type: types.INVITE_USERS_SUCCESS,
    payload: {
      groupId,
      users,
    },
  };
};

export const deleteGroupSuccess = (groupId) => {
  return {
    type: types.DELETE_GROUP_SUCCESS,
    payload: {
      groupId,
    },
  };
};

export const getOrganizationDetails = () => {
  return async (dispatch) => {
    const { data } = await fetchOrganizationDetails();
    dispatch(getOrganizationDetailsSuccess(data));
    return data;
  };
};

export const getGroups = () => {
  return async (dispatch) => {
    const { data } = await fetchGroups();
    const currentUserId = getCurrentUserId();
    dispatch(getGroupsSuccess(data, currentUserId));
    return data;
  };
};

export const sendJoinRequests = (groups) => {
  return async () => {
    await Promise.all(groups.map((group) => fetchJoinGroup(group)));
  };
};

export const highlightGroupDetails = (highlightedData) => {
  return async (dispatch) => {
    dispatch(highlightGroupDetailsSuccess(highlightedData));
  };
};

export const createGroup = ({ name, description }) => {
  return async (dispatch) => {
    const { data } = await postGroups({
      name,
      description,
    });
    const { data: currentUser } = await fetchUser();

    dispatch(
      createGroupSuccess({
        ...data,
        users: [currentUser],
        admins: [currentUser],
      })
    );

    return {
      data,
      highlightedData: {
        highlightedGroupsIds: [data.id],
        isHighlighted: true,
        isFieldHighlighted: false,
      },
    };
  };
};

export const editGroup = (id, name, description) => {
  return async (dispatch) => {
    await patchGroups(id, {
      name,
      description,
    });
    const { data } = await fetchGroups();
    const group = data.find((x) => x.id === id);

    dispatch(editGroupSuccess(group));

    return {
      highlightedData: {
        highlightedGroupsIds: [id],
        isHighlighted: false,
        isFieldHighlighted: true,
      },
    };
  };
};

export const editOrganization = (name) => {
  return async (dispatch) => {
    await patchOrganization({
      name,
    });
    dispatch(
      editOrganizationSuccess({
        name,
      })
    );
  };
};

export const inviteUsers = (groupId, ids, countMembers) => {
  return async (dispatch) => {
    await postInvite(groupId, ids);
    if (countMembers) {
      dispatch(inviteUsersSuccess(groupId, ids.length));
    }
  };
};

export const inviteToOrganization = (emails) => {
  return async () => {
    const { data } = await postInviteToOrganization({ emails: [...emails] });
    return data;
  };
};

export const getOrganizationMembers = () => {
  return async (dispatch) => {
    const { data } = await fetchOrganizationMembers({});
    dispatch(getOrganizationMembersSuccess(data.users));
  };
};

export const getOrganizationAdmins = () => {
  return async (dispatch) => {
    const { data } = await fetchOrganizationAdmins({});
    dispatch(getOrganizationAdminsSuccess(data.users));
  };
};

export const leaveOrganizationAdminRole = (admins, organizationId) => {
  return async (dispatch) => {
    await deleteOrganizationAdminRights(admins);
    dispatch(revokeOrganizationAdminRightsSuccess(admins, organizationId));
  };
};

export const leaveGroupAdminRole = (members, groupId) => {
  return async (dispatch) => {
    await deleteGroupAdminRights(members, groupId);
    dispatch(revokeGroupAdminRightsSuccess(members, groupId));
  };
};

export const removeUserFromOrganization = (
  members,
  organizationId,
  deleteEmptyGroup = false
) => {
  return async (dispatch) => {
    await deleteOrganizationMembers(members, { deleteEmptyGroup });
    dispatch(deleteOrganizationMemberSuccess(members, organizationId));
  };
};

export const removeUserFromGroup = (members, groupId) => {
  return async (dispatch) => {
    await deleteGroupMembers(members, groupId);
    dispatch(deleteGroupMemberSuccess(members, groupId));
  };
};

export const revokeOrganizationAdminRights = (admins, organizationId) => {
  return async (dispatch) => {
    await deleteOrganizationAdminRights(admins);
    dispatch(revokeOrganizationAdminRightsSuccess(admins, organizationId));
  };
};

export const revokeGroupAdminRights = (members, groupId) => {
  return async (dispatch) => {
    await deleteGroupAdminRights(members, groupId);
    dispatch(revokeGroupAdminRightsSuccess(members, groupId));
  };
};

export const assignOrganizationAdminRights = (members, organizationId) => {
  return async (dispatch) => {
    await patchOrganizationAdminRights(members);
    dispatch(assignOrganizationAdminRightsSuccess(members, organizationId));
  };
};

export const assignGroupAdminRights = (members, groupId) => {
  return async (dispatch) => {
    await patchGroupAdminRights(members, groupId);
    dispatch(assignGroupAdminRightsSuccess(members, groupId));
  };
};

export const userLeaveGroup = (members, groupId) => {
  return async (dispatch) => {
    await fetchLeaveGroup(groupId);
    dispatch(deleteGroupMemberSuccess(members, groupId));
  };
};

export const getCribwiseConnection = (id) => {
  return async (dispatch) => {
    const { data } = await testLastConnection(id);
    dispatch(testLastConnectionSuccess(data?.isConnectionOk));
  };
};

export const getCribwiseConnectionCredentials = (organisationId) => {
  return async (dispatch) => {
    const { data } = await fetchCribwiseAccount(organisationId);
    dispatch(getCribwiseConnectionCredentialsSuccess(data));
  };
};

export const editCribwiseConnectionCredentials = (data) => {
  return async (dispatch) => {
    await putCribwiseAccount(data);
    dispatch(editCribwiseConnectionCredentialsSuccess(data));
  };
};

export const updateCribwiseConnection = (isConnectionOk) => {
  return async (dispatch) => {
    dispatch(updateLastConnectionSuccess(isConnectionOk));

    return {
      highlightedData: { isCribwiseHighlighted: true },
    };
  };
};

export const deleteGroup = (id) => {
  return async (dispatch) => {
    await fetchLeaveGroup(id, { deleteEmptyGroup: true });
    dispatch(deselectGroupSuccess(id));
    dispatch(deleteGroupSuccess(id));
    dispatch(removeAssemblyOfGroup(id));
    dispatch(deleteProductsOfGroup(id));
  };
};
