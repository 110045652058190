import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import cs from 'classnames';
import debug from 'debug';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as NumberOfReconsIcon } from '../../assets/icon/ic_recondnumber.svg';
import { ReactComponent as Diameter } from '../../assets/icon/ic_diameter.svg';
import { ReactComponent as ToolLength } from '../../assets/icon/ic_lenght.svg';
import { ReactComponent as SpecialRequestFilledIcon } from '../../assets/icon/special-request-filled.svg';
import { ReactComponent as SpecialRequestNotFilledIcon } from '../../assets/icon/special-request-not-filled.svg';
import { roundValueUpTo } from '../../utils/shared';
import {
  BOX_CONSTANTS,
  DATE_FORMATS,
  NUMBER_OF_PRODUCT_RECON_DIMENSIONS_DECIMALS,
  PERMISSIONS,
  SPECIAL_REQUEST_OPTIONS,
} from '../../constants';
import { Table } from '../Shared';
import { formatDate } from '../../utils/date';
import ReconditioningSpecialRequestModal from '../Modals/ReconditioningModals/ReconditioningSpecialRequestModal';
import { fetchProductInstanceReconditioningDetails } from '../../redux/actions/productActions';
import { getMappedErrorMessage } from '../../utils/error';
import { ReactComponent as LatestReconDataMissingIcon } from '../../assets/icon/ic_edit.svg';
import AddOrEditReconditioningInfoModal from '../Modals/ProductModals/AddOrEditReconditioningInfoModal';
import { displayCalculatedRegrinds } from '../../utils/product';
import { getPermissions } from '../../utils/user';

const ReconditioningDetails = ({
  highlightedIds = [],
  setHighlightedIds = () => {},
  instance,
  handleError = () => {},
  fetchProductInstanceReconditioningDetails,
}) => {
  const history = useHistory();
  const permissions = getPermissions();

  const [selectedRecondition, setSelectedRecondition] = useState({});
  const [
    isEditReconditioningInfoModalOpened,
    setIsEditReconditioningInfoModalOpened,
  ] = useState(false);
  const { t } = useTranslation();

  const [
    productInstanceWithSpecialRequestId,
    setProductInstanceWithSpecialRequestId,
  ] = useState();
  const [
    isViewSpecialRequestModalOpened,
    setIsViewSpecialRequestModalOpened,
  ] = useState(false);

  const tableColumns = [
    {
      name: t('modal.reconNumber'),
      value: 'recon_number',
      isSortable: true,
      style: { width: '25%', textAlign: 'center' },
      className: 'pl-3',
      isResizable: true,
    },
    {
      name: t('modal.reconDate'),
      value: 'recon_date',
      isSortable: true,
      style: { width: '20%', textAlign: 'center' },
      isResizable: true,
    },
    {
      name: (
        <Trans
          i18nKey='modal.toolLength'
          values={{
            unit: BOX_CONSTANTS.DIMENSION_UNIT,
          }}
        />
      ),
      value: 'length',
      isSortable: true,
      style: { width: '25%', textAlign: 'center' },
      isResizable: true,
    },
    {
      name: t('modal.toolDiameter'),
      value: 'diameter',
      isSortable: true,
      style: { width: '30%', textAlign: 'center' },
      className: 'pr-3',
    },
    {
      style: { width: '5%', textAlign: 'center' },
      isHidden: !instance.reconditionData.reconditionHistory.some(
        (reconHistoryData) => reconHistoryData.specialRequestId !== null
      ),
    },
    {
      style: { width: '5%', textAlign: 'center' },
      isHidden:
        !permissions.includes(PERMISSIONS.UPDATE_RECONDITION) ||
        !history.location.search,
    },
  ];

  const onSort = async (columnValue, sortOrder) => {
    try {
      await fetchProductInstanceReconditioningDetails(
        instance.reconditionData.productInstanceId,
        `${columnValue}${sortOrder}`
      );
    } catch (e) {
      const response = getMappedErrorMessage(e);
      debug(`Couldn't fetch tag data. Reason: ${e}`);
      handleError(response);
    }
  };

  const showData = (data) => {
    return (
      <span className='text-truncate'>
        {data
          ? roundValueUpTo(data, NUMBER_OF_PRODUCT_RECON_DIMENSIONS_DECIMALS) +
            BOX_CONSTANTS.DIMENSION_UNIT
          : t('modal.noData')}
      </span>
    );
  };
  return (
    <>
      <div className='w-100 d-flex justify-content-between flex-wrap mt-1'>
        <div className='w-23'>
          <div
            id='reconditioned'
            className={cs('white-card rounded-edges white-details-card', {
              highlighted: highlightedIds?.includes('reconditioned'),
            })}
            onAnimationEnd={() =>
              !!highlightedIds?.length && setHighlightedIds()
            }
          >
            <div
              className={cs(`reconditions-number-card`, {
                'max-limit-reached':
                  instance.reconditionData.reconditionCount >=
                  (instance.reconditionData.calculatedRegrinds || 0),
              })}
            >{`${
              instance.reconditionData.reconditionCount
            }/${displayCalculatedRegrinds(
              instance.reconditionData.calculatedRegrinds
            )}`}</div>
            <NumberOfReconsIcon
              className='icon-txt-primary'
              width={21}
              height={17}
            />
          </div>
        </div>
        <div className='d-flex flex-column w-23'>
          <div
            id='current-dimensions'
            className={cs(
              'white-rounded-card-current-dimensions white-details-card',
              {
                highlighted: highlightedIds?.includes('current-dimensions'),
              }
            )}
            onAnimationEnd={() =>
              !!highlightedIds?.length && setHighlightedIds()
            }
          >
            <div className='white-card-row'>
              <ToolLength
                className='icon-txt-primary mr-2'
                width={22}
                height={31}
              />
              {showData(instance.reconditionData.length)}
            </div>
            <div className='white-card-row'>
              <Diameter
                className='icon-txt-primary mr-2'
                width={22}
                height={21}
              />
              {showData(instance.reconditionData.diameter)}
            </div>
          </div>
        </div>
        <div className='d-flex flex-column w-23'>
          <div className='white-rounded-card-current-dimensions white-details-card'>
            <div className='white-card-row'>
              <ToolLength className='icon-txt-primary mr-2' />
              {showData(instance.reconditionData.minLength)}
            </div>
            <div className='white-card-row'>
              <Diameter className='icon-txt-primary mr-2' />
              {showData(instance.reconditionData.minDiameter)}
            </div>
          </div>
        </div>
        <div className='d-flex flex-column w-23'>
          <div className='white-rounded-card-current-dimensions white-details-card'>
            <div className='white-card-row'>
              <ToolLength
                className='icon-txt-primary mr-2'
                width={22}
                height={31}
              />
              {showData(instance.reconditionData.catalogLength)}
            </div>
            <div className='white-card-row'>
              <Diameter
                className='icon-txt-primary mr-2'
                width={22}
                height={21}
              />
              {showData(instance.reconditionData.catalogDiameter)}
            </div>
          </div>
        </div>
      </div>
      <div className='w-100 text-center d-flex justify-content-between font-size-sm mt-2'>
        <div className='w-23'>{`# ${t('containers.reconditionings')}`}</div>
        <div className='w-23'>{t('modal.currentDimensions')}</div>
        <div className='w-23'>{t('modal.toolLimits')}</div>
        <div className='w-23'>{t('components.catalogDimensions')}</div>
      </div>
      <div className='w-100 mt-3'>
        <div className='white-card rounded-edges pb-0'>
          {instance.reconditionData.reconditionHistory.length ? (
            <Table
              data={instance.reconditionData.reconditionHistory.map(
                (
                  {
                    reconditionNumber,
                    reconditionedAt,
                    length,
                    diameter,
                    specialRequestId,
                    id,
                  },
                  i
                ) => ({
                  rowColumns: [
                    reconditionNumber,
                    formatDate(reconditionedAt, DATE_FORMATS.DATE),
                    length
                      ? roundValueUpTo(
                          length,
                          NUMBER_OF_PRODUCT_RECON_DIMENSIONS_DECIMALS
                        )
                      : t('modal.noData'),
                    <span className='mr-3'>
                      {diameter
                        ? roundValueUpTo(
                            diameter,
                            NUMBER_OF_PRODUCT_RECON_DIMENSIONS_DECIMALS
                          )
                        : t('modal.noData')}
                    </span>,
                    specialRequestId && (
                      <div className='text-right table-padding-right'>
                        {specialRequestId ? (
                          <SpecialRequestFilledIcon
                            height={20}
                            width={20}
                            className='mr-2 icon-tertiary cursor-pointer'
                            onClick={() => {
                              setProductInstanceWithSpecialRequestId({
                                ...instance,
                                specialRequestId,
                              });
                              setIsViewSpecialRequestModalOpened(true);
                            }}
                          />
                        ) : (
                          <SpecialRequestNotFilledIcon
                            height={20}
                            width={20}
                            className='mr-2 icon-tertiary cursor-pointer'
                            onClick={() => {
                              setProductInstanceWithSpecialRequestId({
                                ...instance,
                                specialRequestId,
                              });
                              setIsViewSpecialRequestModalOpened(true);
                            }}
                          />
                        )}
                      </div>
                    ),
                    <LatestReconDataMissingIcon
                      className={cs('cursor-pointer', {
                        'pointer-events-none icon-txt-disabled': instance.boxId,
                        'icon-tertiary': !instance.boxId,
                      })}
                      onClick={() => {
                        setSelectedRecondition(
                          instance.reconditionData.reconditionHistory[i]
                        );
                        setIsEditReconditioningInfoModalOpened(true);
                      }}
                    />,
                  ],
                  rowClassName: cs('border-top py-2 text-center', {
                    'font-weight-bold':
                      reconditionNumber ===
                      instance.reconditionData.reconditionCount,
                    'bg-highlighted-bg':
                      instance.isLatestReconditionDataMissing &&
                      history.location.search &&
                      reconditionNumber ===
                        instance.reconditionData.reconditionCount,
                  }),
                  id,
                })
              )}
              columns={tableColumns}
              highlightedRows={highlightedIds}
              handleHighlightAnimationEnd={() => setHighlightedIds()}
              onSort={(columnValue, sortOrder) =>
                onSort(columnValue, sortOrder)
              }
              striped={false}
            />
          ) : (
            <div className='font-size-md font-weight-bold pt-2 pb-4 text-center'>
              {t('modal.noData')}
            </div>
          )}
        </div>
        <div className='text-center font-size-sm mt-2'>
          {t('components.history')}
        </div>
      </div>

      {isViewSpecialRequestModalOpened && (
        <ReconditioningSpecialRequestModal
          handleClose={() => {
            setIsViewSpecialRequestModalOpened(false);
          }}
          option={SPECIAL_REQUEST_OPTIONS.EDIT_SPECIAL_REQUEST}
          readOnly
          productInstance={productInstanceWithSpecialRequestId}
        />
      )}
      {isEditReconditioningInfoModalOpened && (
        <AddOrEditReconditioningInfoModal
          editInfo
          selectedReconditionData={selectedRecondition}
          handleCancelModal={() =>
            setIsEditReconditioningInfoModalOpened(false)
          }
        />
      )}
    </>
  );
};

ReconditioningDetails.propTypes = {
  highlightedIds: PropTypes.array,
  setHighlightedIds: PropTypes.func,
  instance: PropTypes.object,
  handleError: PropTypes.func,
  fetchProductInstanceReconditioningDetails: PropTypes.func.isRequired,
};

export default connect(
  ({ product }) => ({
    instance: product.instance,
  }),
  {
    fetchProductInstanceReconditioningDetails,
  }
)(ReconditioningDetails);
