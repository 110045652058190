import { useState, useEffect } from 'react';

const useImage = ({ debug, imgSrc }) => {
  const [image, setImage] = useState(imgSrc || null);
  const [imageFile, setImageFile] = useState(null);
  const initialImage = imgSrc || null;

  useEffect(() => {
    setImage(imgSrc);
  }, [imgSrc]);

  const handleImageRemove = (e) => {
    if (e) {
      e.preventDefault();
    }
    setImage(null);
    setImageFile(null);
  };

  const handleImagePreview = async (e) => {
    const file = e.target.files[0];

    if (!file.type.includes('image')) {
      debug(`Upload image failed. Reason: File is not image.`);
      return;
    }

    const url = URL.createObjectURL(file);
    setImage(url);
    setImageFile(file);

    // This prevents browser from caching input file
    // It allows to set same picture twice if removed first
    e.target.value = null;

    return () => URL.revokeObjectURL(url);
  };

  const handleRestartImage = () => {
    setImage(initialImage);
  };

  return {
    image,
    imageFile,
    handleImageRemove,
    handleImagePreview,
    handleRestartImage,
  };
};

export default useImage;
