import React from 'react';

import PropTypes from 'prop-types';
import { ChevronRight, MoreHorizontal } from 'react-feather';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import _debug from 'debug';
import _get from 'lodash.get';
import cs from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import CustomRadioInput from './CustomRadioInput';
import { COLORS } from '../../constants/theme';

// NewListItem is very similar component to ListItem,
// because of different design and some logic it is made to not affect other places where LisItem is used.
// TODO Replace ListItem with NewListItem where it is used when it is needed.

const NewListItem = ({
  name,
  isCurrentUser = false,
  isCheckable = false,
  notGroupAdmin,
  isCurrentGroup,
  checkBox = false,
  handleCheckboxClick,
  id,
  dropdownOptions,
  disableCurrentGroup,
  isProductAvailable,
  disableExistingProducts,
}) => {
  const debug = _debug('Bridge:NewListItem');

  const { t } = useTranslation();

  const handleOptionClick = (handleClick) => {
    try {
      handleClick(id, name);
    } catch (e) {
      debug(
        `Clicked option failed. Reason: ${_get(e, 'response.data.message', e)}`
      );
    }
  };

  const getTextMessageForDisableRow = () => {
    if (isCurrentGroup && disableCurrentGroup) {
      return t('modal.currentGroup');
    }
    if (notGroupAdmin) {
      return t('components.notGroupAdmin');
    }
    if (disableExistingProducts && !isProductAvailable) {
      return t('modal.productAlreadyExistsHere');
    }
  };

  return (
    <div
      className={cs(
        'd-flex justify-content-between align-items-center',
        isCheckable ? 'cursor-pointer border-bottom px-3 py-2' : 'pb-2',
        notGroupAdmin ||
          isCurrentGroup ||
          (disableExistingProducts && !isProductAvailable)
          ? 'row-disabled'
          : ''
      )}
      {...(isCheckable &&
        !notGroupAdmin &&
        (!disableCurrentGroup || (!isCurrentGroup && disableCurrentGroup)) &&
        (!disableExistingProducts ||
          (isProductAvailable && disableExistingProducts)) && {
          onClick: () => handleCheckboxClick(id, !checkBox),
        })}
    >
      <div
        className={cs(
          'd-flex align-items-center align-content-center align-self-center',
          isCheckable ? 'checkable-list-item' : 'main-text'
        )}
      >
        {isCurrentUser && (
          <ChevronRight
            size={34}
            color={COLORS.SECONDARY}
            className='chevron-margin'
          />
        )}
        <div className={cs('d-flex flex-column')}>
          <div className='text-break'>{name?.groupName}</div>
          <div className='text-break'>
            <Trans
              i18nKey='components.adminUsernameOrEmail'
              values={{ name: name?.groupAdmin || name?.admin }}
            />
          </div>
        </div>
      </div>
      {isCheckable ? (
        notGroupAdmin ||
        isCurrentGroup ||
        (!isProductAvailable && disableExistingProducts) ? (
          <div className='min-width row-disabled text-end'>
            {getTextMessageForDisableRow()}
          </div>
        ) : (
          <CustomRadioInput value={checkBox} />
        )
      ) : dropdownOptions.length ? (
        <UncontrolledDropdown>
          <DropdownToggle tag='div'>
            <MoreHorizontal
              stroke={COLORS.TEXT_PRIMARY}
              className='cursor-pointer'
              size={36}
            />
          </DropdownToggle>
          <DropdownMenu right className='dropdown-z-index'>
            {dropdownOptions.map(({ title, handleClick, redText }, index) => (
              <DropdownItem
                key={index}
                onClick={() => handleOptionClick(handleClick)}
                className={redText ? 'text-danger' : ''}
              >
                {title}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <div className='option-missing' />
      )}
    </div>
  );
};

NewListItem.propTypes = {
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  isCurrentUser: PropTypes.bool,
  isCheckable: PropTypes.bool,
  notGroupAdmin: PropTypes.bool,
  isCurrentGroup: PropTypes.bool,
  disableCurrentGroup: PropTypes.bool,
  checkBox: PropTypes.bool,
  handleCheckboxClick: PropTypes.func,
  id: PropTypes.string,
  isProductAvailable: PropTypes.bool,
  disableExistingProducts: PropTypes.bool,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
      redText: PropTypes.bool,
    })
  ),
};

export default NewListItem;
