import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorCrossIcon } from '../../../assets/icon/ic_error_cross.svg';
import UnknownError from './UnknownError';
import ModalStep from './ModalStep';

const Error = ({
  title,
  subtitle,
  handleClose,
  handleTryAgain,
  tryAgainButtonText = 'modal.tryAgain',
}) => {
  const { t } = useTranslation();
  return title === 'error.somethingWentWrong' ? (
    <UnknownError handleClose={handleClose} handleTryAgain={handleTryAgain} />
  ) : (
    <ModalStep
      title={t(title)}
      subtitle={t(subtitle)}
      handleCancel={handleClose}
      handleNext={handleTryAgain}
      nextButtonText={t(tryAgainButtonText)}
      isCentered
    >
      <ErrorCrossIcon className='error-cross-icon icon-danger' />
    </ModalStep>
  );
};

Error.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  handleTryAgain: PropTypes.func,
  tryAgainButtonText: PropTypes.string,
};

export default Error;
