import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import { MAX_NUMBER_OF_CHARACTERS, MIN_COUNTER_VALUE } from '../../constants';
import { ReactComponent as PlusBoxIcon } from '../../assets/icon/new-icons-ic-plus.svg';
import { ReactComponent as MinusBoxIcon } from '../../assets/icon/new-icons-ic-minus.svg';
import { isStringOnlyConsistingNumbers } from '../../utils/validations';
import OutlinedField from './OutlinedField';
import { calculateMaxNumberValueByNumberOfAllowedDigits } from '../../utils/shared';

const Counter = ({
  minCounterValue = MIN_COUNTER_VALUE,
  maxCounterValue = calculateMaxNumberValueByNumberOfAllowedDigits(
    MAX_NUMBER_OF_CHARACTERS.PRODUCT_QUANTITY
  ),
  counterValue = MIN_COUNTER_VALUE,
  handleCounterValueChange = () => {},
  className = '',
}) => {
  const [value, setValue] = useState(counterValue);

  const isCounterDecrementEnabled = () =>
    value !== '' && (minCounterValue === undefined || value > minCounterValue);

  const isCounterIncrementEnabled = () =>
    value !== '' && (maxCounterValue === undefined || value < maxCounterValue);

  const isValueInsideBoundaries = (value) =>
    (minCounterValue === undefined || value >= minCounterValue) &&
    (maxCounterValue === undefined || value <= maxCounterValue);

  const handleCounterInput = (value) => {
    if (
      !value ||
      (isValueInsideBoundaries(value) && isStringOnlyConsistingNumbers(value))
    ) {
      handleCounterValueChange(value);
      setValue(value);
    }
  };

  const handleDecrementCounterValue = () => {
    handleCounterValueChange(Number(value) - 1);
    setValue(Number(value) - 1);
  };

  const handleIncrementCounterValue = () => {
    handleCounterValueChange(Number(value) + 1);
    setValue(Number(value) + 1);
  };

  return (
    <div className={`counter ${className}`}>
      <OutlinedField
        className='w-25'
        inputClassName='product-counter-input pl-1'
        value={counterValue}
        valid={() =>
          counterValue !== '' && isValueInsideBoundaries(counterValue)
        }
        feedback=''
        handleChange={(e) => handleCounterInput(e.target.value)}
        maxNumberOfCharacters={MAX_NUMBER_OF_CHARACTERS.INTEGER_NUMBER}
        onBlur={() => {
          if (!counterValue) {
            handleCounterValueChange(minCounterValue);
            setValue(minCounterValue);
          }
        }}
        noLabel
      />
      <MinusBoxIcon
        width={20}
        height={20}
        className={cs(
          'minus-icon icon-secondary hoverable double-click-disabled m-1',
          {
            disabled: !isCounterDecrementEnabled(),
          }
        )}
        onClick={isCounterDecrementEnabled() && handleDecrementCounterValue}
      />
      <PlusBoxIcon
        className={cs(
          'plus-icon icon-secondary hoverable double-click-disabled',
          {
            disabled: !isCounterIncrementEnabled(),
          }
        )}
        width={20}
        height={20}
        onClick={isCounterIncrementEnabled() && handleIncrementCounterValue}
      />
    </div>
  );
};

Counter.propTypes = {
  className: PropTypes.string,
  handleCounterValueChange: PropTypes.func,
  counterValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minCounterValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxCounterValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Counter;
