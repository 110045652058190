import React from 'react';

import PropTypes from 'prop-types';

import { CustomRadioInput } from '../Shared';
import { THEME_COLORS_NAMES } from '../../constants';

const DeliveryOptionItem = ({
  title = '',
  subtitle = '',
  value = false,
  onChange = () => {},
}) => {
  return (
    <div className='d-flex justify-content-between align-items-center w-100 bottom-shadow p-5'>
      <div className='d-flex flex-column justify-content-center'>
        <p className='mb-0 font-weight-bold text-txt-primary'>{title}</p>
        <p className='m-0 mt-1 text-txt-secondary'>{subtitle}</p>
      </div>
      <CustomRadioInput
        value={value}
        color={THEME_COLORS_NAMES.SECONDARY}
        handleChange={onChange}
      />
    </div>
  );
};

DeliveryOptionItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DeliveryOptionItem;
