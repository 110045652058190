import React from 'react';

import PropTypes from 'prop-types';
import { THEME_COLORS_NAMES } from '../../constants';

const TriangleBadge = ({
  children,
  size = 'md',
  color = THEME_COLORS_NAMES.WARNING,
  borderRadius = 'xl',
  containerClassName = '',
  className = '',
}) => {
  return (
    <div className={`position-relative ${containerClassName}`}>
      <div
        className={`triangle-badge border-rounded-${borderRadius} corner-badge-${color} size-${size} ${className}`}
      />
      {children}
    </div>
  );
};

TriangleBadge.propTypes = {
  children: PropTypes.any,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  color: PropTypes.string,
  borderRadius: PropTypes.oneOf(['sm', 'lg', 'md', 'xl']),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default TriangleBadge;
