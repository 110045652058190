import * as types from '../constants';
import initialState from '../initialState';
import { extractExtension } from '../../utils/file';

export default function reducer(state = initialState.product, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        details: payload.details,
      };
    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products:
          payload.page === 1
            ? [...payload.products]
            : [...state.products, ...payload.products],
      };
    case types.SET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload.data,
        highlightedIds: payload.data?.length ? state.highlightedIds : [],
      };
    case types.SET_PRODUCTS_TO_ADD_TO_START_SUCCESS:
      return {
        ...state,
        productsToAddToStart: [...payload.productsToAddToStart],
      };
    case types.SET_PRODUCT_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS:
      return {
        ...state,
        productIdsToFilterOutWhileFetching: [...payload.ids],
      };
    case types.ADD_PRODUCTS_TO_START_SUCCESS:
      const idsOfProductsToAdd = state.productsToAddToStart.map(({ id }) => id);
      const filteredAlreadyInStateProducts = state.products.filter(
        ({ id }) => !idsOfProductsToAdd.includes(id)
      );
      return {
        ...state,
        products: [
          ...state.productsToAddToStart,
          ...filteredAlreadyInStateProducts,
        ],
        productIdsToFilterOutWhileFetching: [
          ...state.productIdsToFilterOutWhileFetching,
          ...idsOfProductsToAdd,
        ],
        productsToAddToStart: [],
      };
    case types.SYNC_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [
          {
            ...payload.product,
            productInstanceCount: payload.product.productInstances.length,
            isHighlighted: false,
          },
          ...state.products.filter(
            (product) => product.id !== payload.product.id
          ),
        ],
      };
    case types.HIGHLIGHT_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...payload.data.details,
        },
        products: payload.data.highlightedProductsIds
          ? state.products.map((product) => {
              const isProductHighlighted = !!payload.data.highlightedProductsIds.includes(
                product.id
              );
              return {
                ...product,
                isHighlighted:
                  isProductHighlighted && payload.data.isHighlighted,
                isFooterHighlighted:
                  isProductHighlighted && payload.data.isFooterHighlighted,
              };
            })
          : state.products,
        highlightedIds: payload.data.highlightedProductsIds,
        instance: {
          ...state.instance,
          ...payload.data.instance,
        },
      };
    case types.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [
          payload.product,
          ...state.products.filter(({ id }) => id !== payload.product.id),
        ],
      };
    case types.IMPORT_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [
          ...state.products.filter(({ id }) => payload.productIds.includes(id)),
          ...state.products.filter(
            ({ id }) => !payload.productIds.includes(id)
          ),
        ],
      };
    case types.GET_PRODUCT_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: payload.instance,
      };
    case types.GET_PRODUCT_INSTANCES_SUCCESS:
      return {
        ...state,
        instances: payload.instances,
      };
    case types.GET_PRODUCTS_EXTERNAL_SUCCESS:
      if (payload.search?.trim() === state.productsSearch.trim()) {
        return {
          ...state,
          externalProducts:
            payload.pageNumber === 1
              ? payload.externalProducts
              : [...state.externalProducts, ...payload.externalProducts],
        };
      }
      return state;
    case types.CLEAR_EXTERNAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        externalProducts: [],
        secoToolsProducts: [],
        walterProducts: [],
        coromantProducts: [],
        unitedToolsProducts: [],
        dormerPrametProducts: [],
      };
    case types.GET_PRODUCTS_SECO_TOOLS_SUCCESS:
      return {
        ...state,
        secoToolsProducts: payload.secoToolsProducts,
      };
    case types.GET_PRODUCTS_UNITED_TOOLS_SUCCESS:
      return {
        ...state,
        unitedToolsProducts: payload.unitedToolsProducts,
      };
    case types.GET_PRODUCTS_WALTER_SUCCESS:
      return {
        ...state,
        walterProducts: payload.walterProducts,
      };
    case types.GET_PRODUCTS_COROMANT_SUCCESS:
      return {
        ...state,
        coromantProducts: payload.coromantProducts,
      };
    case types.GET_PRODUCTS_DORMER_PRAMET_SUCCESS:
      return {
        ...state,
        dormerPrametProducts: payload.dormerPrametProducts,
      };
    case types.GET_ASSEMBLIES_SUCCESS:
      return {
        ...state,
        assemblies: payload.assemblies,
      };
    case types.GET_STOCK_LEVEL_SUCCESS:
      return {
        ...state,
        productStockLevel: payload.productStockLevel,
      };
    case types.EDIT_PRODUCT_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...payload.templateProperties,
        },
      };
    case types.EDIT_PRODUCT_INSTANCE_DETAILS:
      return {
        ...state,
        instance: {
          ...state.instance,
          ...payload.instanceProperties,
        },
      };
    case types.HIGHLIGHT_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === payload.id
            ? { ...product, isHighlighted: true }
            : product
        ),
      };
    case types.GET_PRODUCT_INSTANCE_LOCATIONS_SUCCESS:
      return {
        ...state,
        instanceLocations: payload.instanceLocations,
      };
    case types.GET_PRODUCT_INSTANCE_CONDITION_RATINGS_SUCCESS:
      return {
        ...state,
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          data: payload.instanceConditionRatings,
        },
      };
    case types.SET_PRODUCT_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID:
      return {
        ...state,
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          highlightedId: payload.highlightedId,
        },
      };
    case types.ADD_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          conditionRating: { id: payload.id, value: payload.value },
        },
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          data: payload.conditionRatings,
        },
      };
    case types.DELETE_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS:
      return {
        ...state,
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          data: payload.conditionRatings,
        },
        instance: {
          ...state.instance,
          conditionRating: payload.newInstanceConditionRating,
        },
      };
    case types.GET_PRODUCT_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS:
      return {
        ...state,
        instanceTimeTrackerHistory: {
          ...state.instanceTimeTrackerHistory,
          data: payload.instanceTimeTrackerHistory,
        },
      };
    case types.SET_PRODUCT_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID:
      return {
        ...state,
        instanceTimeTrackerHistory: {
          ...state.instanceTimeTrackerHistory,
          highlightedId: payload.highlightedId,
        },
      };
    case types.SET_PRODUCT_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS:
      return {
        ...state,
        instanceTimeTrackerHistory: {
          ...state.instanceTimeTrackerHistory,
          data: payload.instanceTimeTrackerHistory,
          highlightedId: payload.instanceTimeTrackerHistory[0].id,
        },
        instance: {
          ...state.instance,
          [payload.propertyName]: {
            id: payload.propertyId,
            value: payload.propertyValue,
          },
          totalTime: payload.totalTimeNewValue,
        },
      };
    case types.GET_PRODUCT_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          ...payload.instanceProperties,
        },
      };
    case types.DELETE_PRODUCTS_OF_GROUP_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          ({ groupId }) => groupId !== payload.groupId
        ),
      };
    case types.GET_PRODUCT_SUPPORT_MESSAGES_SUCCESS:
      return {
        ...state,
        supportMessages: payload.initial
          ? payload.messages
          : [...state.supportMessages, ...payload.messages],
        supportMessageCount: payload.messageCount,
      };
    case types.UPDATE_PRODUCT_SUPPORT_MESSAGE_SUCCESS:
      return {
        ...state,
        supportMessages: state.supportMessages.map((message) =>
          message.id === payload.messageId
            ? { ...message, statusCode: payload.statusCode }
            : message
        ),
      };
    case types.ADD_PRODUCT_SUPPORT_MESSAGE_SUCCESS:
      return {
        ...state,
        supportMessages: [
          {
            ...payload.supportMessages.find(({ id }) => id === payload.id),
            isHighlighted: true,
          },
          ...state.supportMessages,
        ],
      };
    case types.SET_PRODUCTS_SEARCH_SUCCESS:
      return {
        ...state,
        productsSearch: payload.value,
      };
    case types.SET_GROUPS_SEARCH_SUCCESS:
      return {
        ...state,
        selectedGroups: payload.selectedGroups,
      };
    case types.DESELECT_GROUP_SUCCESS:
      return {
        ...state,
        selectedGroups: state.selectedGroups.filter(
          ({ id }) => id !== payload.groupId
        ),
      };
    case types.GET_PRODUCT_ASSEMBLIES_SUCCESS:
      return {
        ...state,
        productAssemblies: payload.productAssemblies,
      };
    case types.CLEAR_PRODUCT_ASSEMBLIES_SUCCESS:
      return {
        ...state,
        productAssemblies: [],
      };
    case types.GET_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS:
      return {
        ...state,
        instanceAssemblyInstance: payload.instanceAssemblyInstance,
      };
    case types.CLEAR_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS:
      return {
        ...state,
        instanceAssemblyInstance: {},
      };
    case types.HIGHLIGHT_PRODUCT_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: state.threads.general.map((thread) => {
            if (payload.id === thread.id) {
              return {
                ...thread,
                isHighlighted: true,
              };
            }
            return { ...thread, isHighlighted: false };
          }),
          instance: state.threads.instance.map((thread) => {
            if (payload.id === thread.id) {
              return {
                ...thread,
                isHighlighted: true,
              };
            }
            return { ...thread, isHighlighted: false };
          }),
        },
      };
    case types.GET_PRODUCT_THREADS_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: payload.threads,
        },
      };
    case types.GET_PRODUCT_INSTANCE_THREADS_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: payload.threads,
        },
      };
    case types.ADD_PRODUCT_GENERAL_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: payload.threads,
        },
      };
    case types.ADD_PRODUCT_INSTANCE_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: payload.threads,
        },
      };
    case types.EDIT_PRODUCT_GENERAL_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: [
            ...state.threads.general.map((thread) =>
              thread.id === payload.id
                ? {
                    ...thread,
                    ...payload.thread,
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.EDIT_PRODUCT_INSTANCE_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: [
            ...state.threads.instance.map((thread) =>
              thread.id === payload.id
                ? {
                    ...thread,
                    ...payload.thread,
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.DELETE_PRODUCT_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: state.threads.general.map((thread) =>
            thread.id === payload.threadId
              ? {
                  ...thread,
                  files: thread.files.filter(({ id }) => id !== payload.fileId),
                }
              : thread
          ),
        },
      };
    case types.DELETE_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: state.threads.instance.map((thread) =>
            thread.id === payload.threadId
              ? {
                  ...thread,
                  files: thread.files.filter(({ id }) => id !== payload.fileId),
                }
              : thread
          ),
        },
      };
    case types.RENAME_PRODUCT_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: [
            ...state.threads.general.map((thread) =>
              thread.id === payload.threadId
                ? {
                    ...thread,
                    files: thread.files.map((file) =>
                      file.id === payload.fileId
                        ? {
                            ...file,
                            name: `${payload.filename}${extractExtension(
                              file.name
                            )}`,
                          }
                        : file
                    ),
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.RENAME_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: [
            ...state.threads.instance.map((thread) =>
              thread.id === payload.threadId
                ? {
                    ...thread,
                    files: thread.files.map((file) =>
                      file.id === payload.fileId
                        ? {
                            ...file,
                            name: `${payload.filename}${extractExtension(
                              file.name
                            )}`,
                          }
                        : file
                    ),
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.DELETE_PRODUCT_GENERAL_THREAD_SUCCESS:
    case types.MARK_PRODUCT_GENERAL_THREAD_AS_DONE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: state.threads.general
            .filter(({ id }) => id !== payload.id)
            .map((thread) => ({
              ...thread,
              isHighlighted: false,
            })),
          instance: state.threads.instance.map((thread) => ({
            ...thread,
            isHighlighted: false,
          })),
        },
      };
    case types.DELETE_PRODUCT_INSTANCE_THREAD_SUCCESS:
    case types.MARK_PRODUCT_INSTANCE_THREAD_AS_DONE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: state.threads.instance
            .filter(({ id }) => id !== payload.id)
            .map((thread) => ({
              ...thread,
              isHighlighted: false,
            })),
          general: state.threads.general.map((thread) => ({
            ...thread,
            isHighlighted: false,
          })),
        },
      };
    case types.UPDATE_PRODUCT_INSTANCE_RECONDITION_COUNT_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          reconditionData: {
            ...state.instance.reconditionData,
            reconditionCount:
              state.instance.reconditionData.reconditionCount + 1,
          },
        },
      };
    case types.FETCH_PRODUCT_INSTANCE_RECONDITIONING_DETAILS_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          reconditionData: {
            ...payload.data,
          },
        },
      };
    case types.CHECK_PRODUCT_INSTANCE_RECONDITION_DATA_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          isLatestReconditionDataMissing: !state.instance
            .isLatestReconditionDataMissing,
        },
      };
    case types.ADD_PRODUCT_INSTANCE_RECONDITIONING_INFO_SUCCESS: {
      const {
        reconditionedAt,
        reconditionedId,
        ...reconditionDetails
      } = payload.data;

      return {
        ...state,
        instance: {
          ...state.instance,
          reconditionData: {
            ...state.instance.reconditionData,
            ...reconditionDetails,
            diameter: reconditionDetails.diameter,
            length: reconditionDetails.length,
            reconditionCount:
              state.instance.reconditionData.reconditionCount + 1,
            reconditionHistory: [
              {
                ...payload.data,
                id: reconditionedId,
                reconditionNumber:
                  state.instance.reconditionData.reconditionHistory.length + 1,
              },
              ...state.instance.reconditionData.reconditionHistory,
            ],
          },
        },
      };
    }
    case types.SET_ERROR:
      return {
        ...state,
        providerError: payload.error,
      };
    default:
      return state;
  }
}
