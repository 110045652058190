import React from 'react';

import { Button as ButtonReactstrap } from 'reactstrap';
import PropTypes from 'prop-types';

const Button = ({
  children,
  onClick,
  color,
  underline,
  size,
  className,
  disabled = false,
  type = 'button',
  ...props
}) => (
  <ButtonReactstrap
    className={className}
    size={size}
    color={color}
    underline={underline}
    onClick={onClick}
    disabled={disabled}
    type={type}
    {...props}
  >
    {children}
  </ButtonReactstrap>
);

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  color: PropTypes.string,
  underline: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
