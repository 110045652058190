import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import { THEME_COLORS_NAMES } from '../../constants';
import Button from './Button';

const LinkButton = ({
  onClick,
  color = THEME_COLORS_NAMES.DANGER,
  underlined = true,
  className = '',
  children,
  ...props
}) => {
  return (
    <Button
      className={cs(`shadow-none link-button px-0 text-${color} ${className}`, {
        'text-decoration-none': !underlined,
      })}
      size='lg'
      color='link'
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.oneOf(THEME_COLORS_NAMES),
  underlined: PropTypes.bool,
};

export default LinkButton;
