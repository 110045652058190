import { useState } from 'react';
import FileSaver from 'file-saver';
import debug from 'debug';
import { fetchFile } from '../../client/file';

const useFileDownload = () => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [downloadingFileId, setDownloadingFileId] = useState('');

  const downloadFile = async (file) => {
    try {
      setDownloadingFileId(file.id);
      setIsDownloadLoading(true);
      const { data } = await fetchFile(file.url);
      await FileSaver.saveAs(data, file.name);
    } catch (e) {
      debug(`Couldn't download file. Reason: ${e}`);
    }
    setIsDownloadLoading(false);
    setDownloadingFileId('');
  };

  return { isDownloadLoading, downloadingFileId, downloadFile };
};

export default useFileDownload;
