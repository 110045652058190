import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.application, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_JOB_TITLES_SUCCESS:
      return {
        ...state,
        jobTitles: payload.data,
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload.data,
      };
    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: payload.data,
      };
    case types.GET_SERVER_METADATA_SUCCESS:
      return {
        ...state,
        serverMetadataFetched: true,
        invalidFileTypes: payload.data.invalidFileTypes,
        maxFileSizeInBytes: payload.data.maxFileSizeInBytes,
        invalidFilenameCharacters: payload.data.invalidFilenameCharacters,
      };
    case types.SET_VIEW_OPTION_SUCCESS:
      return {
        ...state,
        viewOption: payload.viewOption,
      };
    default:
      return state;
  }
}
