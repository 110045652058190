import React from 'react';
import PropTypes from 'prop-types';

const LinearLoader = ({ loaderClassName = '', barClassName = '' }) => (
  <div className={`loader w-100 ${loaderClassName}`}>
    <div className={`bar w-100 position-relative ${barClassName}`} />
  </div>
);

LinearLoader.propTypes = {
  loaderClassName: PropTypes.string,
  barClassName: PropTypes.string,
};

export default LinearLoader;
