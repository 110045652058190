import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import { ReactComponent as Cancel } from '../../../assets/icon/ic-round-cross.svg';
import PillButton from '../../Shared/PillButton';
import { MODAL_CONTENT_ID, THEME_COLORS_NAMES } from '../../../constants';

const NewModalStep = ({
  title,
  subtitle,
  subtitleClassName = '',
  handleCancel,
  handleClickLeftButton = () => {},
  handleClickRightButton = () => {},
  handleClickAdditionalRightButton,
  leftButtonClassName = '',
  rightButtonClassName = '',
  additionalRightButtonClassName = '',
  leftButtonContent,
  rightButtonContent,
  additionalRightButtonContent,
  leftButtonDisabled = false,
  rightButtonDisabled = false,
  additionalRightButtonDisabled = false,
  leftButtonOutline = true,
  rightButtonOutline = false,
  additionalRightButtonOutline = false,
  rightButtonColor = THEME_COLORS_NAMES.BUTTON_PRIMARY,
  leftButtonColor = THEME_COLORS_NAMES.BUTTON_PRIMARY,
  additionalRightButtonColor = THEME_COLORS_NAMES.BUTTON_PRIMARY,
  headerChildren,
  children,
  footerChildren,
  isCenteredVertically = false,
  isCenteredHorizontally = false,
  headerClassName = '',
  bodyClassName = '',
}) => {
  return (
    <div className='new-modal-step h-100 d-flex flex-column'>
      <div className='d-flex flex-column m-4'>
        <div className='d-flex justify-content-end'>
          {!!handleCancel && (
            <Cancel
              className='icon-txt-primary icon-hover-secondary cursor-pointer'
              width={24}
              height={24}
              onClick={handleCancel}
            />
          )}
        </div>
        <div className={headerClassName}>
          {!!title && (
            <div className='new-modal-step-title text-center mt-1 mb-3 text-overflow-x'>
              {title}
            </div>
          )}
          {!!subtitle && (
            <div
              className={`new-modal-step-subtitle text-center ${subtitleClassName}`}
            >
              {subtitle}
            </div>
          )}
          {headerChildren}
        </div>
      </div>
      <div
        className={cs(`flex-grow-1 overflow-auto text-wrap ${bodyClassName}`, {
          'd-flex': isCenteredVertically || isCenteredHorizontally,
          'align-items-center': isCenteredVertically,
          'justify-content-center': isCenteredHorizontally,
        })}
        id={MODAL_CONTENT_ID}
      >
        {children}
      </div>
      {(!!leftButtonContent || !!rightButtonContent) && (
        <div
          className={cs('d-flex align-items-center m-4', {
            'justify-content-between':
              !!leftButtonContent && !!rightButtonContent,
            'justify-content-end': !leftButtonContent && !!rightButtonContent,
          })}
        >
          {!!leftButtonContent && (
            <PillButton
              onClick={(e) => {
                handleClickLeftButton();
                e.target.blur();
              }}
              className={`new-modal-step-btn left ${leftButtonClassName}`}
              disabled={leftButtonDisabled}
              outline={leftButtonOutline}
              color={leftButtonColor}
            >
              {leftButtonContent}
            </PillButton>
          )}
          <div className='text-txt-primary'>{footerChildren}</div>

          {!!rightButtonContent && (
            <div className='d-flex justify-content-end'>
              {additionalRightButtonContent && (
                <PillButton
                  onClick={(e) => {
                    handleClickAdditionalRightButton();
                    e.target.blur();
                  }}
                  className={`new-modal-step-btn mr-3 ${additionalRightButtonClassName}`}
                  disabled={additionalRightButtonDisabled}
                  outline={additionalRightButtonOutline}
                  color={additionalRightButtonColor}
                >
                  {additionalRightButtonContent}
                </PillButton>
              )}
              <PillButton
                onClick={(e) => {
                  handleClickRightButton();
                  e.target.blur();
                }}
                className={`new-modal-step-btn ${rightButtonClassName}`}
                disabled={rightButtonDisabled}
                outline={rightButtonOutline}
                color={rightButtonColor}
                type='submit'
              >
                {rightButtonContent}
              </PillButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

NewModalStep.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleClassName: PropTypes.string,
  handleCancel: PropTypes.func,
  handleClickLeftButton: PropTypes.func,
  handleClickRightButton: PropTypes.func,
  handleClickAdditionalRightButton: PropTypes.func,
  leftButtonClassName: PropTypes.string,
  rightButtonClassName: PropTypes.string,
  additionalRightButtonClassName: PropTypes.string,
  leftButtonContent: PropTypes.any,
  rightButtonContent: PropTypes.any,
  additionalRightButtonContent: PropTypes.any,
  leftButtonDisabled: PropTypes.bool,
  rightButtonDisabled: PropTypes.bool,
  additionalRightButtonDisabled: PropTypes.bool,
  leftButtonOutline: PropTypes.bool,
  rightButtonOutline: PropTypes.bool,
  additionalRightButtonOutline: PropTypes.bool,
  leftButtonColor: PropTypes.string,
  rightButtonColor: PropTypes.string,
  additionalRightButtonColor: PropTypes.string,
  children: PropTypes.any,
  headerChildren: PropTypes.any,
  isCenteredVertically: PropTypes.bool,
  isCenteredHorizontally: PropTypes.bool,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerChildren: PropTypes.any,
};

export default NewModalStep;
