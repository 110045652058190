import {
  activateBoxInstances,
  deleteProductInstanceFromTheBox,
  fetchBox,
  fetchBoxProductInstances,
  fetchBoxes,
  postBoxProductInstances,
  postCustomBox,
  putBox,
  requestBoxInstances,
  resetBox,
  syncBoxCode,
  unsyncBoxCode,
} from '../../client/box';
import { postProductInstanceThread } from '../../client/product';
import { postOrderPickup } from '../../client/order';
import { BOXES_PER_PAGE, BOX_INSTANCES_PER_PAGE } from '../../constants';
import { getResourceFromLocation } from '../../utils/resource';
import * as types from '../constants';
import { BOX_STATUSES } from '../../containers/ReconditioningContainers/constants';

export const getBoxDetailsSuccess = (data) => {
  return {
    type: types.GET_BOX_DETAILS_SUCCESS,
    payload: { data },
  };
};

export const getBoxProductInstancesSuccess = (
  data,
  numberOfInstances,
  page
) => {
  return {
    type: types.GET_BOX_PRODUCT_INSTANCES_SUCCESS,
    payload: { data, numberOfInstances, page },
  };
};

export const setBoxProductInstancesSuccess = (data) => {
  return {
    type: types.SET_BOX_PRODUCT_INSTANCES_SUCCESS,
    payload: { data },
  };
};

export const removeProductInstanceFromTheBoxSuccess = (instanceId) => {
  return {
    type: types.REMOVE_PRODUCT_INSTANCE_FROM_BOX_SUCCESS,
    payload: { instanceId },
  };
};

export const addSpecialRequestToProductInstanceInTheBoxSuccess = (
  instanceId,
  specialRequestId
) => {
  return {
    type: types.ADD_SPECIAL_REQUEST_TO_PRODUCT_INSTANCE_IN_THE_BOX,
    payload: {
      instanceId,
      specialRequestId,
    },
  };
};

export const addProductInstanceToBoxSuccess = (data) => {
  return {
    type: types.ADD_PRODUCT_INSTANCE_TO_THE_BOX_SUCCESS,
    payload: { data },
  };
};

export const getBoxesSuccess = (data, page) => {
  return {
    type: types.GET_BOXES_SUCCESS,
    payload: { data, page },
  };
};

export const setBoxesSuccess = (data) => {
  return {
    type: types.SET_BOXES_SUCCESS,
    payload: { data },
  };
};

export const activateBoxesSuccess = (boxes) => {
  return {
    type: types.ACTIVATE_BOXES_SUCCESS,
    payload: { boxes },
  };
};

export const resetBoxesSuccess = (boxes) => {
  return {
    type: types.RESET_BOXES_SUCCESS,
    payload: { boxes },
  };
};

export const updateBoxSuccess = (boxId, data) => {
  return {
    type: types.UPDATE_BOX_SUCCESS,
    payload: { boxId, data },
  };
};

export const setHighlightedBoxesIdsSuccess = (highlightedIds) => {
  return {
    type: types.SET_HIGHLIGHTED_BOXES_IDS_SUCCESS,
    payload: { highlightedIds },
  };
};

export const setBoxesToAddToStartSuccess = (boxesToAddToStart) => {
  return {
    type: types.SET_BOXES_TO_ADD_TO_START_SUCCESS,
    payload: { boxesToAddToStart },
  };
};

export const addBoxesToStartSuccess = () => {
  return {
    type: types.ADD_BOXES_TO_START_SUCCESS,
    payload: {},
  };
};

export const removeHighlightedIdsSuccess = (idsToRemove) => {
  return {
    type: types.REMOVE_HIGHLIGHTED_IDS_SUCCESS,
    payload: { idsToRemove },
  };
};

export const setDisplayedBoxesStatusesSuccess = (statuses) => {
  return {
    type: types.SET_DISPLAYED_BOXES_STATUSES_SUCCESS,
    payload: { statuses },
  };
};

export const setBoxesIdsToFilterOutWhileFetchingSuccess = (ids) => {
  return {
    type: types.SET_BOXES_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS,
    payload: { ids },
  };
};

export const syncTagToBoxSuccess = (code) => {
  return {
    type: types.SYNC_TAG_TO_BOX_SUCCESS,
    payload: { code },
  };
};

export const removeMultipleProductInstancesFromTheBoxSuccess = (data) => {
  return {
    type: types.REMOVE_MULTIPLE_PRODUCT_INSTANCES_FROM_THE_BOX_SUCCESS,
    payload: { data },
  };
};

export const deleteBoxTagSuccess = () => {
  return {
    type: types.DELETE_BOX_TAG_SUCCESS,
  };
};

export const getBoxDetails = (boxId, orderId) => {
  return async (dispatch) => {
    const { data } = await fetchBox(boxId, { orderId });
    dispatch(getBoxDetailsSuccess(data));
    return data;
  };
};

export const getBoxProductInstances = ({
  BoxId,
  OrderId,
  Sort = 'created_at_desc',
  PageNumber = 1,
  PageSize = BOX_INSTANCES_PER_PAGE,
}) => {
  return async (dispatch) => {
    const { data } = await fetchBoxProductInstances(BoxId, {
      Sort,
      PageNumber,
      PageSize,
      OrderId,
    });
    dispatch(
      getBoxProductInstancesSuccess(data.data, data.rowCount, PageNumber)
    );

    return data;
  };
};

export const setBoxProductInstances = (instances = []) => {
  return async (dispatch) => {
    dispatch(setBoxProductInstancesSuccess(instances));
  };
};

export const removeProductInstanceFromTheBox = (boxId, instanceId) => {
  return async (dispatch) => {
    await deleteProductInstanceFromTheBox(boxId, {
      productInstanceIds: [instanceId],
    });
    dispatch(getBoxDetails(boxId));
    dispatch(removeProductInstanceFromTheBoxSuccess(instanceId));
  };
};

export const removeMultipleProductInstancesFromTheBox = (boxId, data) => {
  return async (dispatch) => {
    await deleteProductInstanceFromTheBox(boxId, data);

    dispatch(getBoxDetails(boxId));
    dispatch(removeMultipleProductInstancesFromTheBoxSuccess(data));
  };
};

export const addSpecialRequestToProductInstanceInTheBox = (
  instanceId,
  data
) => {
  return async (dispatch) => {
    const { headers } = await postProductInstanceThread(instanceId, data);
    const specialRequestId = getResourceFromLocation(headers.location);
    dispatch(
      addSpecialRequestToProductInstanceInTheBoxSuccess(
        instanceId,
        specialRequestId
      )
    );
  };
};

export const addProductInstanceToBox = (boxId, instanceId) => {
  return async (dispatch) => {
    const { data } = await postBoxProductInstances(boxId, [instanceId]);

    dispatch(getBoxDetails(boxId));
    dispatch(addProductInstanceToBoxSuccess(data[0]));
  };
};

export const setHighlightedBoxesIds = (ids = []) => {
  return async (dispatch) => {
    dispatch(setHighlightedBoxesIdsSuccess(ids));
  };
};

export const setBoxesToAddToStart = (boxes = []) => {
  return async (dispatch) => {
    dispatch(setBoxesToAddToStartSuccess(boxes));
  };
};

export const removeHighlightedIds = (ids) => {
  return async (dispatch) => {
    dispatch(removeHighlightedIdsSuccess(ids));
  };
};

export const addBoxesToStart = () => {
  return async (dispatch) => {
    dispatch(addBoxesToStartSuccess());
  };
};

export const getBoxes = ({
  Sort,
  PageNumber = 1,
  PageSize = BOXES_PER_PAGE,
  ...additionalFilters
}) => {
  const { AdditionalFilter, ...otherFilters } = additionalFilters;
  return async (dispatch, getState) => {
    const { data } = await fetchBoxes({
      Sort,
      PageNumber,
      PageSize,
      ExcludeIds: getState().box.boxesIdsToFilterOutWhileFetching,
      statuses: AdditionalFilter,
      ...otherFilters,
    });

    dispatch(getBoxesSuccess(data.data, PageNumber));

    if (PageNumber === 1 && getState().box.boxesToAddToStart.length) {
      data.data = [...getState().box.boxesToAddToStart, ...data.data];
      dispatch(addBoxesToStart());
    }
    return data;
  };
};

export const setBoxes = (boxes = []) => {
  return async (dispatch) => {
    dispatch(setBoxesSuccess(boxes));
  };
};

export const activateBoxes = (boxes) => {
  return async (dispatch) => {
    const boxesIds = boxes.map(({ id }) => id);

    const { data } = await activateBoxInstances(boxesIds);

    dispatch(
      activateBoxesSuccess(
        boxes.map((box) => ({
          ...box,
          status: BOX_STATUSES.ACTIVE_EMPTY,
          isActive: true,
        }))
      )
    );

    return data;
  };
};

export const resetBoxes = (boxesIds = []) => {
  return async (dispatch) => {
    const { data } = await resetBox(boxesIds);

    dispatch(resetBoxesSuccess(data));

    return data;
  };
};

export const updateBox = (boxId, data) => {
  return async (dispatch) => {
    await putBox(boxId, data);

    dispatch(updateBoxSuccess(boxId, data));
  };
};

export const setDisplayedBoxesStatuses = (statuses = [BOX_STATUSES.ALL]) => {
  return async (dispatch) => {
    dispatch(setDisplayedBoxesStatusesSuccess(statuses));
  };
};

export const setBoxesIdsToFilterOutWhileFetching = (ids = []) => {
  return async (dispatch) => {
    dispatch(setBoxesIdsToFilterOutWhileFetchingSuccess(ids));
  };
};

export const requestNewBoxes = (data) => {
  return async (dispatch, getState) => {
    const { data: addedBoxes } = await requestBoxInstances(data);

    if (
      getState().box.displayedBoxesStatuses.includes(BOX_STATUSES.ORDERED) ||
      getState().box.displayedBoxesStatuses.includes(BOX_STATUSES.ALL)
    ) {
      dispatch(setBoxesToAddToStart(addedBoxes));
      dispatch(setHighlightedBoxesIds(addedBoxes.map(({ id }) => id)));
    }
  };
};

export const createCustomBox = (boxData) => {
  return async (dispatch, getState) => {
    const { data } = await postCustomBox({
      ...boxData,
      status: BOX_STATUSES.ACTIVE_EMPTY,
    });
    if (
      getState().box.displayedBoxesStatuses.includes(
        BOX_STATUSES.ACTIVE_EMPTY
      ) ||
      getState().box.displayedBoxesStatuses.includes(BOX_STATUSES.ALL)
    ) {
      dispatch(setBoxesToAddToStart([data[0]]));
      dispatch(setHighlightedBoxesIds([data[0].id]));
    }
  };
};

export const orderPickup = (data) => {
  return async () => {
    const orderPickupData = await postOrderPickup(data);
    return orderPickupData;
  };
};

export const syncTagToBox = (boxId, scannedTag) => {
  return async (dispatch) => {
    const { data } = await syncBoxCode(boxId, scannedTag);
    dispatch(syncTagToBoxSuccess(data.code));
  };
};

export const deleteBoxTag = (boxId) => {
  return async (dispatch) => {
    await unsyncBoxCode(boxId);
    dispatch(deleteBoxTagSuccess());
  };
};
