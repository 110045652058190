import React, { useState } from 'react';

import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import cs from 'classnames';

import { ReactComponent as InfoIcon } from '../../assets/icon/ic_btn_info.svg';
import { ReactComponent as CrossIcon } from '../../assets/icon/new-icons-ic-close-blue.svg';
import { Image } from '.';
import { ReactComponent as Tag } from '../../assets/icon/ic_datamatrix.svg';

const ItemCard = ({
  id,
  title,
  subtitle,
  image,
  handleClick,
  highlighted,
  description,
  topRightCornerElement,
  hasBadge,
  footer,
  selected,
  disabled,
  handleAnimationStart = () => {},
  handleAnimationEnd = () => {},
}) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <Card
      className={cs('mb-0 item-card', {
        selected,
        disabled,
        highlighted,
      })}
      onClick={handleClick}
      onAnimationStart={() => handleAnimationStart(id)}
      onAnimationEnd={() => handleAnimationEnd(id)}
    >
      {showDescription ? (
        <CardBody className='card-flipped'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between'>
              <div className='text-truncate card-flipped-title font-weight-bolder'>
                {title}
              </div>
              <CrossIcon
                className='icon-txt-primary hoverable'
                width={24}
                height={24}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDescription(false);
                }}
              />
            </div>
            <p className='description m-0'>{description}</p>
          </div>
        </CardBody>
      ) : (
        <CardBody className='d-flex justify-content-between'>
          <div className='d-flex min-width-0'>
            <Image
              src={image}
              className='border border-secondary rounded-xl'
              width={75}
              height={75}
            />
            <div className='d-flex flex-column min-width-0 pl-2'>
              <div className='item-card-title font-weight-bolder text-truncate'>
                {title}
              </div>
              <div>{subtitle}</div>
            </div>
          </div>
          {hasBadge && (
            <Tag className='icon-secondary' height={46} width={46} />
          )}
          {description ? (
            <InfoIcon
              className='info-icon icon-txt-primary icon-hover-secondary'
              width={24}
              height={24}
              onClick={(e) => {
                e.stopPropagation();
                setShowDescription(true);
              }}
            />
          ) : (
            topRightCornerElement
          )}
        </CardBody>
      )}
      {footer}
    </Card>
  );
};

ItemCard.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  image: PropTypes.string,
  topRightCornerElement: PropTypes.any,
  handleClick: PropTypes.func,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  footer: PropTypes.any,
  selected: PropTypes.bool,
  hasBadge: PropTypes.bool,
  handleAnimationStart: PropTypes.func,
  handleAnimationEnd: PropTypes.func,
};

export default ItemCard;
