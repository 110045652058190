import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import SyncInternal from '../../ProductComponents/SyncInternal';
import Modal from '../Modal';
import { getPermissions } from '../../../utils/user';
import { ErrorStep, NewLoading, NewModalStep } from '../Shared';
import CreateProductModal from './CreateProductModal';
import {
  INSTANCE_CODE_TYPES,
  PERMISSIONS,
  TEMPLATE_CODE_TYPES,
} from '../../../constants';
import {
  fetchProductByBarcode,
  fetchProductByDataMatrix,
} from '../../../client/tag';
import { setScanning } from '../../../redux/actions/readerActions';
import { setEmptyTag } from '../../../redux/actions/tagActions';
import { Image } from '../../Shared';
import { ReactComponent as MyListIcon } from '../../../assets/icon/new_ic_sync_to_myproducts.svg';
import { ReactComponent as NewProductIcon } from '../../../assets/icon/img-create-menu-img-create-product-card.svg';
import { ReactComponent as DataMatrixScan } from '../../../assets/icon/icDataMatrixScan.svg';
import { ReactComponent as BarcodeScan } from '../../../assets/icon/icBarCodeScan.svg';
import ScannedCodeTypeModal from '../Shared/ScannedCodeTypeModal';

const debug = _debug('Bridge:SyncTag');

const STEPS = {
  SELECT_SYNC_OPTION: 'SELECT_SYNC_OPTION',
  NEW_PRODUCT: 'NEW_PRODUCT',
  MY_PRODUCTS: 'MY_PRODUCTS',
  CREATE_PRODUCT_INSTANCE: 'CREATE_PRODUCT_INSTANCE',
  SELECT_CODE_TYPE: 'SELECT_CODE_TYPE',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  LOADING: 'LOADING',
  EMPTY_TAG: 'EMPTY_TAG',
  ERROR: 'ERROR',
};

const SyncTag = ({
  isOpened,
  handleClose,
  scannedTag,
  codeType,
  setEmptyTag,
}) => {
  const { t } = useTranslation();
  const permissions = getPermissions();
  const [step, setStep] = useState(STEPS.LOADING);
  const [productsByBarcode, setProductsByBarcode] = useState(null);
  const [productByDataMatrix, setProductByDataMatrix] = useState(null);

  useEffect(() => {
    (async () => {
      if (!scannedTag) {
        return;
      }
      setScanning(true);
      let barcodeProducts;
      let dataMatrixProduct;
      if (
        codeType === null ||
        Object.values(TEMPLATE_CODE_TYPES).some((value) => value === codeType)
      ) {
        try {
          const { data } = await fetchProductByBarcode({
            params: {
              code: scannedTag,
            },
          });

          barcodeProducts = data;
        } catch (e) {
          if (e?.response?.status !== 404) {
            setStep(STEPS.ERROR);
            return;
          }
        }
      }
      if (codeType === null || codeType === INSTANCE_CODE_TYPES.DATA_MATRIX) {
        try {
          const { data } = await fetchProductByDataMatrix({
            params: {
              code: scannedTag,
            },
          });
          dataMatrixProduct = { ...data };
        } catch (e) {
          const status = e?.response?.status;
          if (status !== 400 && status !== 404) {
            setStep(STEPS.ERROR);
            return;
          }
        }
      }
      setProductsByBarcode(barcodeProducts);
      setProductByDataMatrix(dataMatrixProduct);
      setScanning(false);

      if (!barcodeProducts?.length && !dataMatrixProduct) {
        setStep(STEPS.EMPTY_TAG);
        setEmptyTag(scannedTag);
      } else if (!barcodeProducts?.length && dataMatrixProduct) {
        setStep(STEPS.CREATE_PRODUCT_INSTANCE);
      } else if (barcodeProducts?.length && !dataMatrixProduct) {
        setStep(STEPS.CREATE_PRODUCT);
      } else {
        setStep(STEPS.SELECT_CODE_TYPE);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedTag]);

  const syncOptions = [
    {
      title: t('modal.aProductInMyList'),
      icon: <MyListIcon height={180} width={180} className='m-3' />,
      handleClick: () => setStep(STEPS.MY_PRODUCTS),
    },
    {
      title: t('modal.aNewProduct'),
      icon: (
        <NewProductIcon
          height={180}
          width={180}
          className='icon-secondary m-3'
        />
      ),
      handleClick: () => {
        setStep(STEPS.NEW_PRODUCT);
      },
      hidden: !permissions.includes(PERMISSIONS.PRODUCT_CREATE),
    },
  ];

  const renderSyncOptions = () => {
    return syncOptions
      .filter((option) => !option.hidden)
      .map(({ handleClick, icon, title }) => {
        return (
          <div key={title} className='d-flex flex-column'>
            <Card
              className='mb-0 cursor-pointer hover-active'
              onClick={handleClick}
            >
              {icon}
            </Card>
            <div className='modal-icon-title text-center pt-3'>{title}</div>
          </div>
        );
      });
  };

  const codeTypeOptions = [
    {
      title: t('modal.barcodeQrcode'),
      icon: (
        <BarcodeScan
          height={180}
          width={180}
          className='barcode-scan-icon m-3'
        />
      ),
      handleClick: () => setStep(STEPS.CREATE_PRODUCT),
    },
    {
      title: t('modal.dataMatrix'),
      icon: (
        <DataMatrixScan
          className='datamatrix-scan-icon m-3'
          height={180}
          width={180}
        />
      ),
      handleClick: () => setStep(STEPS.CREATE_PRODUCT_INSTANCE),
    },
  ];

  const renderCodeTypeOptions = () => {
    return codeTypeOptions.map(({ handleClick, icon, title }) => {
      return (
        <div key={title} className='d-flex flex-column'>
          <Card
            className='mb-0 cursor-pointer hover-active'
            onClick={handleClick}
          >
            {icon}
          </Card>
          <div className='modal-icon-title text-center pt-3'>{title}</div>
        </div>
      );
    });
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.EMPTY_TAG:
        return (
          <NewModalStep
            handleCancel={handleClose}
            {...(permissions.includes(PERMISSIONS.CREATE_SYNC) && {
              handleClickRightButton: () => setStep(STEPS.SELECT_SYNC_OPTION),
              rightButtonContent: t('button.syncCapitalized'),
            })}
            leftButtonContent={t('button.notNow')}
            handleClickLeftButton={handleClose}
            bodyClassName='d-flex flex-column justify-content-center align-items-center mb-5'
          >
            <Image width={96} height={96} className='rounded-circle mb-4' />
            <h1>{t('modal.emptyTag')}</h1>
            <div className='text-center'>
              <Trans
                i18nKey='modal.youHaveScannedATagThatIsEmptyDoYouWantToSyncItToAProduct'
                components={[<br />]}
              />
            </div>
          </NewModalStep>
        );
      case STEPS.SELECT_SYNC_OPTION:
        return (
          <NewModalStep
            title={t('modal.thisTagIsReadyToBeSynchronised')}
            subtitle={t('modal.whatDoYouWantToSyncItTo')}
            handleCancel={handleClose}
            leftButtonContent={t('button.back')}
            handleClickLeftButton={() => setStep(STEPS.EMPTY_TAG)}
            bodyClassName='d-flex flex-column justify-content-center mb-5'
          >
            <div className='d-flex justify-content-evenly'>
              {renderSyncOptions()}
            </div>
          </NewModalStep>
        );
      case STEPS.NEW_PRODUCT:
        return (
          <CreateProductModal
            isSyncable
            handleClose={handleClose}
            syncStep={() => setStep(STEPS.SELECT_SYNC_OPTION)}
          />
        );
      case STEPS.MY_PRODUCTS:
        return (
          <SyncInternal
            goBack={() => setStep(STEPS.SELECT_SYNC_OPTION)}
            handleClose={handleClose}
          />
        );
      case STEPS.SELECT_CODE_TYPE:
        return (
          <NewModalStep
            title={t('modal.codeType')}
            subtitle={t('modal.selectTheCodeTypeYouHaveScanned')}
            handleCancel={handleClose}
            bodyClassName='d-flex flex-column justify-content-center mb-5 pb-5'
          >
            <div className='d-flex justify-content-evenly'>
              {renderCodeTypeOptions()}
            </div>
          </NewModalStep>
        );
      case STEPS.CREATE_PRODUCT:
        return (
          <ScannedCodeTypeModal
            handleClose={handleClose}
            products={productsByBarcode}
          />
        );
      case STEPS.CREATE_PRODUCT_INSTANCE:
        return (
          <ScannedCodeTypeModal
            handleClose={handleClose}
            products={[productByDataMatrix]}
            instance
          />
        );
      case STEPS.ERROR:
        return (
          <ErrorStep
            handleClose={handleClose}
            subtitle={t('error.unableToScanCapitalized')}
          />
        );
      case STEPS.LOADING:
        return <NewLoading value={t('modal.fetchingDetails')} />;
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };
  return (
    <Modal
      isOpened={isOpened}
      className='d-flex flex-column justify-content-between p-0'
    >
      {renderStep()}
    </Modal>
  );
};

SyncTag.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  scannedTag: PropTypes.string,
  codeType: PropTypes.string,
  setEmptyTag: PropTypes.func,
};

export default connect(
  ({ tag, reader }) => ({
    scannedTag: reader.scannedTag,
    codeType: reader.codeType,
    products: tag.searchProducts,
  }),
  { setScanning, setEmptyTag }
)(SyncTag);
