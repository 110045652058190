import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  getLimitReachedImage,
  getLimitReachedSubtitle,
  getLimitReachedTitle,
} from '../../../utils/limitReached';
import { PrimaryButton } from '../../Shared';

const LimitReached = ({ handleClose, limitReachedType }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column h-100 justify-content-between'>
      <div className='modal-title text-center pt-4'>
        {t(getLimitReachedTitle(limitReachedType))}
      </div>
      <div className='text-center pb-5'>
        {getLimitReachedImage(limitReachedType)}
        <div className='pt-3 modal-subtitle w-75 m-auto'>
          {t(getLimitReachedSubtitle(limitReachedType))}
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        <PrimaryButton onClick={handleClose}>{t('button.ok')}</PrimaryButton>
      </div>
    </div>
  );
};

LimitReached.propTypes = {
  handleClose: PropTypes.func.isRequired,
  limitReachedType: PropTypes.number.isRequired,
};

export default LimitReached;
