import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import cs from 'classnames';

import { ReactComponent as LocationIcon } from '../../assets/icon/ic-location-pin-green.svg';
import { ReactComponent as InfoIcon } from '../../assets/icon/ic_btn_info.svg';
import { PERMISSIONS } from '../../constants';
import { getPermissions } from '../../utils/user';

const LocationTagRow = ({
  isSelected = false,
  hasLocation,
  name,
  date,
  handleTagClick,
  tag,
  handleInfoClick,
  column,
}) => {
  const { tag: urlPathTag } = useParams();
  const permissions = getPermissions();

  const handleLocationIconClick = (e) => {
    handleTagClick(tag);
    e.stopPropagation();
  };

  return (
    <Row
      className='m-0 d-flex justify-content-between align-items-center px-3 py-2 border-bottom cursor-pointer new-location-tag-item-row'
      onClick={handleInfoClick}
    >
      <Col xs={8} className='d-flex align-items-center p-0 pl-4 pr-1'>
        {permissions.includes(PERMISSIONS.VIEW_LOCATION) && (
          <LocationIcon
            className={cs('position-absolute location-icon-margin', {
              'location-icon': hasLocation,
              'icon-secondary': isSelected,
              'icon-tertiary': !isSelected,
              'icon-txt-disabled': !hasLocation,
              'cursor-pointer': hasLocation,
            })}
            width={32}
            height={43}
            onClick={(e) =>
              hasLocation ? handleLocationIconClick(e) : undefined
            }
          />
        )}
        <div className='d-flex flex-column'>
          <span className='text-break'>{name}</span>
          <span className='date-label'>{date}</span>
        </div>
      </Col>
      <Col
        xs={urlPathTag ? 4 : 3}
        className='d-flex align-items-center p-0 pr-3 cursor-pointer'
      >
        {column}
      </Col>
      {!urlPathTag && (
        <Col xs={1} className='d-flex align-items-center'>
          <InfoIcon
            className='position-absolute arrow-icon icon-txt-primary icon-hover-secondary'
            width={24}
            height={24}
          />
        </Col>
      )}
    </Row>
  );
};

LocationTagRow.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleTagClick: PropTypes.func.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
  hasLocation: PropTypes.bool.isRequired,
  column: PropTypes.any,
};

export default LocationTagRow;
