import * as types from '../constants';
import {
  fetchCountries,
  fetchJobTitles,
  fetchLanguages,
  fetchServerMetadata,
} from '../../client/application';
import { VIEW_OPTIONS } from '../../constants';
import {
  getLocalStorageViewOption,
  setLocalStorageViewOption,
} from '../../utils/shared';

export const getJobTitlesSuccess = (data) => {
  return {
    type: types.GET_JOB_TITLES_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getCountriesSuccess = (data) => {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getLanguagesSuccess = (data) => {
  return {
    type: types.GET_LANGUAGES_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getServerMetadataSuccess = (data) => {
  return {
    type: types.GET_SERVER_METADATA_SUCCESS,
    payload: { data },
  };
};

export const setViewOptionSuccess = (viewOption) => {
  return {
    type: types.SET_VIEW_OPTION_SUCCESS,
    payload: { viewOption },
  };
};

export const getJobTitles = (culture = 'en') => {
  return async (dispatch) => {
    const { data } = await fetchJobTitles(culture);
    dispatch(getJobTitlesSuccess(data));
  };
};

export const getCountries = (culture = 'en') => {
  return async (dispatch) => {
    const { data } = await fetchCountries(culture);
    dispatch(getCountriesSuccess(data));
  };
};

export const getLanguages = (culture = 'en') => {
  return async (dispatch) => {
    const { data } = await fetchLanguages(culture);
    dispatch(getLanguagesSuccess(data));
  };
};

export const getServerMetadata = () => {
  return async (dispatch) => {
    const { data } = await fetchServerMetadata();
    dispatch(getServerMetadataSuccess(data));
  };
};

export const setViewOption = (viewOption = VIEW_OPTIONS.GRID) => {
  return async (dispatch) => {
    if (
      getLocalStorageViewOption() !== viewOption ||
      !getLocalStorageViewOption()
    ) {
      setLocalStorageViewOption(viewOption);
    }

    dispatch(setViewOptionSuccess(viewOption));
  };
};
