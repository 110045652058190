import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import WarningStep from './WarningStep';

const WarningModal = ({
  title,
  text1 = '',
  text2 = '',
  leftButtonContent = '',
  rightButtonContent = '',
  handleClose,
  handleClickLeftButton = handleClose,
  handleClickRightButton,
  withoutLeftButton = false,
  withoutRightButton = false,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpened
      className='d-flex flex-column justify-content-center add-more-instances-to-box p-0'
    >
      <WarningStep
        handleClose={handleClose}
        title={title}
        text1={text1}
        text2={text2}
        leftButtonContent={
          withoutLeftButton ? '' : leftButtonContent || t('button.cancel')
        }
        handleClickLeftButton={handleClickLeftButton}
        rightButtonContent={
          withoutRightButton ? '' : rightButtonContent || t('button.yes')
        }
        handleClickRightButton={handleClickRightButton}
      />
    </Modal>
  );
};

WarningModal.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  leftButtonContent: PropTypes.string,
  rightButtonContent: PropTypes.string,
  withoutLeftButton: PropTypes.bool,
  withoutRightButton: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleClickLeftButton: PropTypes.func,
  handleClickRightButton: PropTypes.func.isRequired,
};

export default WarningModal;
