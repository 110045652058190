import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  getLimitReachedImage,
  getLimitReachedSubtitle,
  getLimitReachedTitle,
} from '../../../utils/limitReached';
import NewModalStep from './NewModalStep';

const NewLimitReached = ({ handleClose, limitReachedType }) => {
  const { t } = useTranslation();

  return (
    <NewModalStep
      title={t(getLimitReachedTitle(limitReachedType))}
      handleCancel={handleClose}
      handleClickRightButton={handleClose}
      rightButtonContent={t('button.done')}
      bodyClassName='d-flex flex-column align-items-center justify-content-center'
    >
      <>
        <div className='pb-4'>{getLimitReachedImage(limitReachedType)}</div>
        <div className='text-center'>
          {t(getLimitReachedSubtitle(limitReachedType))}
        </div>
      </>
    </NewModalStep>
  );
};

NewLimitReached.propTypes = {
  handleClose: PropTypes.func.isRequired,
  limitReachedType: PropTypes.number.isRequired,
};

export default NewLimitReached;
