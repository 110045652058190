import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import AddExistingBox from './AddExistingBox';
import RequestNewBox from './RequestNewBox';
import { NewModalStep } from '../Shared';
import { ReactComponent as NewBoxIcon } from '../../../assets/icon/ic_new_box.svg';
import { ReactComponent as ExistingBoxIcon } from '../../../assets/icon/existing_box.svg';
import { ADD_BOX_MODAL_OPTIONS, PERMISSIONS } from '../../../constants';
import { getPermissions } from '../../../utils/user';

const debug = _debug('Bridge:AddBoxModal');

const STEPS = {
  INITIAL: 'INITIAL',
  ...ADD_BOX_MODAL_OPTIONS,
};

const AddBoxModal = ({
  isOpened = true,
  handleClose,
  selectedOption,
  preselectedBoxesToActivate,
  existingBoxType,
  onAddExistingBoxSuccess = () => {},
  onRequestNewBoxSuccess = () => {},
  onAddCustomBoxSuccess = () => {},
}) => {
  const { t } = useTranslation();
  const permissions = getPermissions();
  const [step, setStep] = useState(selectedOption || STEPS.INITIAL);

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return (
          <NewModalStep
            title={t('components.addBox')}
            subtitle={t('modal.selectTheBoxYouWantToUse')}
            handleCancel={handleClose}
            bodyClassName='h-75'
            isCenteredVertically
            isCenteredHorizontally
          >
            <div className='d-flex justify-content-center pb-4'>
              {permissions.includes(PERMISSIONS.ORDER_BOX) && (
                <div className='d-flex flex-column align-items-center mr-5'>
                  <NewBoxIcon
                    className='icon-tertiary add-box-icon new rounded-xl cursor-pointer mb-2 modal-big-options-icons'
                    onClick={() => setStep(STEPS.REQUEST_NEW_BOX)}
                  />
                  <p>{t('modal.requestNewBox')}</p>
                </div>
              )}
              <div className='d-flex flex-column align-items-center ml-5'>
                <ExistingBoxIcon
                  className='icon-tertiary add-box-icon existing rounded-xl cursor-pointer mb-2 modal-big-options-icons'
                  onClick={() => setStep(STEPS.ADD_EXISTING_BOX)}
                />
                <p>{t('modal.existingBox')}</p>
              </div>
            </div>
          </NewModalStep>
        );
      case STEPS.REQUEST_NEW_BOX:
        return (
          <RequestNewBox
            handleClose={handleClose}
            {...(!selectedOption && {
              goBack: () => setStep(STEPS.INITIAL),
            })}
            onSuccess={onRequestNewBoxSuccess}
          />
        );
      case STEPS.ADD_EXISTING_BOX:
        return (
          <AddExistingBox
            handleClose={handleClose}
            {...(!selectedOption && {
              goBack: () => setStep(STEPS.INITIAL),
            })}
            selectedOption={selectedOption}
            existingBoxType={existingBoxType}
            preselectedBoxes={preselectedBoxesToActivate}
            onAddExistingBoxSuccess={onAddExistingBoxSuccess}
            onAddCustomBoxSuccess={onAddCustomBoxSuccess}
          />
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal
      className='add-box-modal d-flex flex-column justify-content-between p-0'
      isOpened={isOpened}
    >
      {renderStep()}
    </Modal>
  );
};

AddBoxModal.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  preselectedBoxesToActivate: PropTypes.array,
  onAddExistingBoxSuccess: PropTypes.func,
  onRequestNewBoxSuccess: PropTypes.func,
  onAddCustomBoxSuccess: PropTypes.func,
  existingBoxType: PropTypes.string,
  selectedOption: PropTypes.oneOf(Object.values(ADD_BOX_MODAL_OPTIONS)),
};

export default AddBoxModal;
