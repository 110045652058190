import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../../assets/icon/iconfinder_triangle-left_293631.svg';
import Loader from '../../Shared/Loader';
import { nonHookTranslate } from '../../../i18n';
import SearchInput from '../../Shared/SearchInput';
import { PrimaryButton } from '../../Shared';

const SelectItems = ({
  title,
  subtitle,
  search,
  setSearch,
  searchPlaceholder = nonHookTranslate('modal.enterName'),
  handleBack,
  items,
  handleCancelModal,
  handleNextClick,
  disabledNext = false,
  stepButtons = [],
  loading = false,
  nextButtonText = nonHookTranslate('button.next'),
  noDataMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column h-100'>
      {handleBack && (
        <BackIcon
          className='icon-tertiary hoverable'
          width={26}
          height={26}
          onClick={handleBack}
        />
      )}
      <div className='modal-title text-center pt-4'>{title}</div>
      <div className='modal-subtitle text-center pt-1 pb-5'>{subtitle}</div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div className='w-75'>
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder={searchPlaceholder}
            className='w-100'
          />
        </div>
      </div>
      <div className={`${loading ? '' : 'overflow-auto'} pt-4`}>
        <div className=' d-flex justify-content-center align-items-center'>
          <div className='w-75'>
            {loading ? (
              <Loader />
            ) : items.length ? (
              items
            ) : (
              <div className='d-flex justify-content-center'>
                {noDataMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex-grow-1 d-flex align-items-end justify-content-between pb-2'>
        <div>
          <PrimaryButton outline onClick={handleCancelModal}>
            {t('button.cancel')}
          </PrimaryButton>
        </div>
        <div className='d-flex align-items-center'>
          {stepButtons.map(({ active }, index) => (
            <div
              key={index}
              className={`${active ? 'modal-step-active' : 'modal-step'} ${
                index !== stepButtons.length - 1 ? 'mr-3' : ''
              }`}
            />
          ))}
        </div>
        <div>
          <PrimaryButton disabled={disabledNext} onClick={handleNextClick}>
            {nextButtonText}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

SelectItems.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  handleBack: PropTypes.func,
  items: PropTypes.array.isRequired,
  handleCancelModal: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  disabledNext: PropTypes.bool,
  stepButtons: PropTypes.array,
  nextButtonText: PropTypes.string,
  loading: PropTypes.bool,
  noDataMessage: PropTypes.string,
};

export default SelectItems;
