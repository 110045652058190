import React from 'react';
import { Input as InputReactstrap } from 'reactstrap';
import PropTypes from 'prop-types';

const InputBox = ({
  placeholder,
  value = '',
  onChange,
  rows = 8,
  className = '',
  maxLength,
}) => {
  return (
    <InputReactstrap
      rows={rows}
      type='textarea'
      className={`resize-none ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...(maxLength && { maxLength })}
    />
  );
};

InputBox.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};

export default InputBox;
