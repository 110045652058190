import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.tag, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        searchProducts: payload.initial
          ? payload.products
          : [...state.searchProducts, ...payload.products],
        productCount: payload.totalCount,
      };
    case types.SET_EMPTY_TAG:
      return {
        ...state,
        emptyTag: payload.tag,
      };
    case types.SET_OCCUPIED_TAG:
      return {
        ...state,
        occupiedTag: payload.tag,
      };
    case types.SET_SCANNING_SUCCESS:
      return {
        ...state,
        isScanning: payload.isScanning,
      };
    case types.SET_FETCHING_SUCCESS:
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    case types.GET_SCANNED_TAGS_SUCCESS:
      return {
        ...state,
        scannedTags: payload.initial
          ? payload.scannedTags
          : [...state.scannedTags, ...payload.scannedTags],
        totalCount: payload.totalCount,
      };
    case types.SET_ERROR_SUCCESS:
      return {
        ...state,
        error: payload.error,
      };
    default:
      return state;
  }
}
