import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

const ModalDots = ({ number, active }) => {
  return (
    !!active && (
      <div className='d-flex modal-dots-container'>
        {[...Array(number).keys()].map((i) => (
          <div
            key={i}
            className={cs({
              'modal-dot-active': i < active,
              'modal-dot': i >= active,
              'modal-dot-margin': i !== number - 1,
            })}
          />
        ))}
      </div>
    )
  );
};

ModalDots.propTypes = {
  number: PropTypes.number,
  active: PropTypes.number,
};

export default ModalDots;
