import * as types from '../constants';
import {
  fetchProduct,
  fetchProductInstanceByTag,
  fetchProducts,
  fetchProductsToolsUnited,
  fetchProductsWalter,
  fetchProductsSecoTools,
  postProduct,
  putProductInstance,
  patchTransferTag,
  deleteProductInstance,
  deleteProduct as deleteProductTemplate,
  fetchInstanceLocations,
  fetchProductInstances,
  putProduct,
  postCopyProductToAnotherGroup,
  fetchProductThreads,
  fetchProductInstanceThreads,
  postProductGeneralThread,
  postProductInstanceThread,
  postProductInstanceReconditioningInfo,
  fetchReconditionDetails,
  putProductInstanceReconditioningInfo,
  fetchProductsCoromant,
  fetchProductsDormerPramet,
  checkReconditionData,
} from '../../client/product';
import { deleteFile } from '../../client/file';
import { deleteThread, putThread } from '../../client/thread';
import {
  fetchSupportMessages,
  postSupportMessage,
  updateSupportMessageStatus,
} from '../../client/messages';
import { getResourceFromLocation } from '../../utils/resource';
import {
  ACTIVE_USER_THREAD_STATUSES,
  EXTERNAL_PRODUCTS_PER_PAGE,
  FIREBASE_EVENTS,
  MESSAGES_PER_PAGE,
  PRODUCTS_PER_PAGE,
  PROPERTIES_PER_PAGE,
} from '../../constants';
import {
  fetchAssemblies,
  fetchAssembly,
  fetchAssemblyInstance,
} from '../../client/assembly';
import { getProductStockLevelFromCribwise } from '../../client/group';
import {
  deleteProductInstanceNumericPropertyHistoryEntry,
  fetchProductInstanceNumericPropertyHistory,
  postProductInstanceNumericProperty,
} from '../../client/tag';
import { getPaginationDetails } from './paginationActions';
import { isDatabaseMyProductsTabOpened } from '../../utils/database';
import { isSearchTermLongEnough } from '../../utils/shared';
import { handleAnalytics } from '../../utils/analytics';

export const getProductSuccess = (details) => {
  return {
    type: types.GET_PRODUCT_SUCCESS,
    payload: {
      details,
    },
  };
};

export const getProductsSuccess = (products, page) => {
  return {
    type: types.GET_PRODUCTS_SUCCESS,
    payload: {
      products,
      page,
    },
  };
};

export const setProductsSuccess = (data) => {
  return {
    type: types.SET_PRODUCTS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const addProductsToStartSuccess = () => {
  return {
    type: types.ADD_PRODUCTS_TO_START_SUCCESS,
    payload: {},
  };
};

export const setProductsToAddToStartSuccess = (productsToAddToStart) => {
  return {
    type: types.SET_PRODUCTS_TO_ADD_TO_START_SUCCESS,
    payload: { productsToAddToStart },
  };
};

export const setProductIdsToFilterOutWhileFetchingSuccess = (ids = []) => {
  return {
    type: types.SET_PRODUCT_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS,
    payload: { ids },
  };
};

export const highlightProductDetailsSuccess = (data) => {
  return {
    type: types.HIGHLIGHT_PRODUCT_DETAILS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createProductSuccess = (product) => {
  return {
    type: types.CREATE_PRODUCT_SUCCESS,
    payload: {
      product,
    },
  };
};

export const importProductsSuccess = (productIds) => {
  return {
    type: types.IMPORT_PRODUCTS_SUCCESS,
    payload: {
      productIds,
    },
  };
};

export const getProductsSecoToolsSuccess = (products) => {
  return {
    type: types.GET_PRODUCTS_SECO_TOOLS_SUCCESS,
    payload: {
      secoToolsProducts: products,
    },
  };
};

export const getProductsUnitedToolsSuccess = (products) => {
  return {
    type: types.GET_PRODUCTS_UNITED_TOOLS_SUCCESS,
    payload: {
      unitedToolsProducts: products,
    },
  };
};

export const getProductsWalterSuccess = (products) => {
  return {
    type: types.GET_PRODUCTS_WALTER_SUCCESS,
    payload: {
      walterProducts: products,
    },
  };
};

export const getProductsCoromantSuccess = (products) => {
  return {
    type: types.GET_PRODUCTS_COROMANT_SUCCESS,
    payload: {
      coromantProducts: products,
    },
  };
};

export const getProductsDormerPrametSuccess = (products) => {
  return {
    type: types.GET_PRODUCTS_DORMER_PRAMET_SUCCESS,
    payload: {
      dormerPrametProducts: products,
    },
  };
};

export const getExternalProductsSuccess = (externalProducts, page, search) => {
  return {
    type: types.GET_PRODUCTS_EXTERNAL_SUCCESS,
    payload: {
      externalProducts,
      page,
      search,
    },
  };
};

export const clearProductsExternalSuccess = () => {
  return {
    type: types.CLEAR_EXTERNAL_PRODUCTS_SUCCESS,
  };
};

export const showError = (error) => {
  return {
    type: types.SET_ERROR,
    payload: {
      error,
    },
  };
};

export const getProductInstanceSuccess = (instance) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_SUCCESS,
    payload: {
      instance,
    },
  };
};

export const getProductInstanceConditionRatingsSuccess = (
  instanceConditionRatings
) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_CONDITION_RATINGS_SUCCESS,
    payload: {
      instanceConditionRatings,
    },
  };
};

export const setProductInstanceConditionRatingHighlightedIdSuccess = (
  highlightedId
) => {
  return {
    type: types.SET_PRODUCT_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID,
    payload: {
      highlightedId,
    },
  };
};

export const addProductInstanceConditionRatingSuccess = (
  id,
  value,
  conditionRatings
) => {
  return {
    type: types.ADD_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS,
    payload: {
      id,
      value,
      conditionRatings,
    },
  };
};

export const deleteProductInstanceConditionRatingSuccess = (
  newInstanceConditionRating,
  conditionRatings
) => {
  return {
    type: types.DELETE_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS,
    payload: {
      conditionRatings,
      newInstanceConditionRating,
    },
  };
};

export const getProductInstanceTimeTrackerHistorySuccess = (
  instanceTimeTrackerHistory
) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS,
    payload: {
      instanceTimeTrackerHistory,
    },
  };
};

export const setProductInstanceTimeTrackerHighlightedIdSuccess = (
  highlightedId
) => {
  return {
    type: types.SET_PRODUCT_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID,
    payload: {
      highlightedId,
    },
  };
};

export const setProductInstanceTimeTrackerPropertySuccess = (
  propertyId,
  propertyName,
  propertyValue,
  instanceTimeTrackerHistory,
  totalTimeNewValue
) => {
  return {
    type: types.SET_PRODUCT_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS,
    payload: {
      propertyId,
      propertyName,
      propertyValue,
      instanceTimeTrackerHistory,
      totalTimeNewValue,
    },
  };
};

export const getProductInstancePropertiesFromDetailsSuccess = (
  instanceProperties
) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS,
    payload: {
      instanceProperties,
    },
  };
};

export const getProduct = (id) => {
  return async (dispatch) => {
    const { data } = await fetchProduct(id);
    dispatch(getProductSuccess(data));
  };
};

export const getProductSupportMessagesSuccess = (
  messages,
  messageCount,
  initial
) => {
  return {
    type: types.GET_PRODUCT_SUPPORT_MESSAGES_SUCCESS,
    payload: {
      messages,
      messageCount,
      initial,
    },
  };
};

export const updateProductSupportMessageSuccess = (messageId, statusCode) => {
  return {
    type: types.UPDATE_PRODUCT_SUPPORT_MESSAGE_SUCCESS,
    payload: {
      messageId,
      statusCode,
    },
  };
};

export const editProductTemplateDetailsSuccess = (templateProperties) => {
  return {
    type: types.EDIT_PRODUCT_TEMPLATE_DETAILS_SUCCESS,
    payload: {
      templateProperties,
    },
  };
};

export const editProductInstanceDetailsSuccess = (instanceProperties) => {
  return {
    type: types.EDIT_PRODUCT_INSTANCE_DETAILS,
    payload: {
      instanceProperties,
    },
  };
};

export const highlightProductSuccess = (id) => {
  return {
    type: types.HIGHLIGHT_PRODUCT_SUCCESS,
    payload: { id },
  };
};

export const deleteProductsOfGroupSuccess = (groupId) => {
  return {
    type: types.DELETE_PRODUCTS_OF_GROUP_SUCCESS,
    payload: { groupId },
  };
};

export const getInstanceLocationsSuccess = (locations) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_LOCATIONS_SUCCESS,
    payload: {
      instanceLocations: locations,
    },
  };
};

export const getProductInstancesSuccess = (instances) => {
  return {
    type: types.GET_PRODUCT_INSTANCES_SUCCESS,
    payload: {
      instances,
    },
  };
};

export const addProductSupportMessageSuccess = (supportMessages, id) => {
  return {
    type: types.ADD_PRODUCT_SUPPORT_MESSAGE_SUCCESS,
    payload: { supportMessages, id },
  };
};

export const getProductStockLevelSuccess = (data) => {
  return {
    type: types.GET_STOCK_LEVEL_SUCCESS,
    payload: { productStockLevel: data },
  };
};

export const setProductsSearchSuccess = (value) => {
  return {
    type: types.SET_PRODUCTS_SEARCH_SUCCESS,
    payload: { value },
  };
};

export const setGroupsSearchSuccess = (selectedGroups) => {
  return {
    type: types.SET_GROUPS_SEARCH_SUCCESS,
    payload: { selectedGroups },
  };
};

export const deselectGroupSuccess = (groupId) => {
  return {
    type: types.DESELECT_GROUP_SUCCESS,
    payload: { groupId },
  };
};

export const getProductAssembliesSuccess = (productAssemblies) => {
  return {
    type: types.GET_PRODUCT_ASSEMBLIES_SUCCESS,
    payload: { productAssemblies },
  };
};

export const clearProductAssembliesSuccess = () => {
  return {
    type: types.CLEAR_PRODUCT_ASSEMBLIES_SUCCESS,
  };
};

export const getProductInstanceAssemblyInstanceSuccess = (
  instanceAssemblyInstance
) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS,
    payload: { instanceAssemblyInstance },
  };
};

export const clearProductInstanceAssemblyInstanceSuccess = () => {
  return {
    type: types.CLEAR_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS,
  };
};

export const highlightProductThreadSuccess = (id) => {
  return {
    type: types.HIGHLIGHT_PRODUCT_THREAD_SUCCESS,
    payload: { id },
  };
};

export const getProductThreadsSuccess = (threads) => {
  return {
    type: types.GET_PRODUCT_THREADS_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const getProductInstanceThreadsSuccess = (threads) => {
  return {
    type: types.GET_PRODUCT_INSTANCE_THREADS_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const addProductGeneralThreadSuccess = (threads) => {
  return {
    type: types.ADD_PRODUCT_GENERAL_THREAD_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const addProductInstanceThreadSuccess = (threads) => {
  return {
    type: types.ADD_PRODUCT_INSTANCE_THREAD_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const editProductGeneralThreadSuccess = (id, thread) => {
  return {
    type: types.EDIT_PRODUCT_GENERAL_THREAD_SUCCESS,
    payload: {
      id,
      thread,
    },
  };
};

export const editProductInstanceThreadSuccess = (id, thread) => {
  return {
    type: types.EDIT_PRODUCT_INSTANCE_THREAD_SUCCESS,
    payload: {
      id,
      thread,
    },
  };
};

export const removeProductGeneralThreadSuccess = (id) => {
  return {
    type: types.DELETE_PRODUCT_GENERAL_THREAD_SUCCESS,
    payload: {
      id,
    },
  };
};

export const removeProductInstanceThreadSuccess = (id) => {
  return {
    type: types.DELETE_PRODUCT_INSTANCE_THREAD_SUCCESS,
    payload: {
      id,
    },
  };
};

export const markProductGeneralThreadAsDoneSuccess = (id) => {
  return {
    type: types.MARK_PRODUCT_GENERAL_THREAD_AS_DONE_SUCCESS,
    payload: {
      id,
    },
  };
};

export const markProductInstanceThreadAsDoneSuccess = (id) => {
  return {
    type: types.MARK_PRODUCT_INSTANCE_THREAD_AS_DONE_SUCCESS,
    payload: {
      id,
    },
  };
};

export const renameProductThreadFileSuccess = (fileId, filename, threadId) => {
  return {
    type: types.RENAME_PRODUCT_THREAD_FILE_SUCCESS,
    payload: {
      fileId,
      filename,
      threadId,
    },
  };
};

export const renameProductInstanceThreadFileSuccess = (
  fileId,
  filename,
  threadId
) => {
  return {
    type: types.RENAME_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS,
    payload: {
      fileId,
      filename,
      threadId,
    },
  };
};

export const deleteProductThreadFileSuccess = (threadId, fileId) => {
  return {
    type: types.DELETE_PRODUCT_THREAD_FILE_SUCCESS,
    payload: {
      threadId,
      fileId,
    },
  };
};

export const deleteProductInstanceThreadFileSuccess = (threadId, fileId) => {
  return {
    type: types.DELETE_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS,
    payload: {
      threadId,
      fileId,
    },
  };
};

export const updateProductInstanceReconditionCountSuccess = () => {
  return {
    type: types.UPDATE_PRODUCT_INSTANCE_RECONDITION_COUNT_SUCCESS,
  };
};

export const fetchProductInstanceReconditioningDetailsSuccess = (data) => {
  return {
    type: types.FETCH_PRODUCT_INSTANCE_RECONDITIONING_DETAILS_SUCCESS,
    payload: { data },
  };
};

export const addProductInstanceReconditioningInfoSuccess = (data) => {
  return {
    type: types.ADD_PRODUCT_INSTANCE_RECONDITIONING_INFO_SUCCESS,
    payload: { data },
  };
};

export const checkProductInstanceReconditionDataSuccess = () => {
  return {
    type: types.CHECK_PRODUCT_INSTANCE_RECONDITION_DATA_SUCCESS,
  };
};

export const addProductsToStart = () => {
  return async (dispatch) => {
    dispatch(addProductsToStartSuccess());
  };
};

export const setProductsToAddToStart = (products = []) => {
  return async (dispatch) => {
    dispatch(setProductsToAddToStartSuccess(products));
  };
};

export const setProductIdsToFilterOutWhileFetching = (ids) => {
  return async (dispatch) => {
    dispatch(setProductIdsToFilterOutWhileFetchingSuccess(ids));
  };
};

export const getProducts = ({
  PageSize = PRODUCTS_PER_PAGE,
  PageNumber = 1,
  Sort = '',
  SearchTerm,
}) => {
  return async (dispatch, getState) => {
    const groups = getState().product.selectedGroups;
    const search = getState().product.productsSearch;

    const isAnyFilterActive = !!(groups.length || search || Sort);

    const { data } = await fetchProducts({
      FilterGroups: groups.map((group) => group.id),
      Sort,
      PageNumber,
      PageSize,
      SearchTerm: search || SearchTerm,
      ExcludeIds: !isAnyFilterActive
        ? getState().product.productIdsToFilterOutWhileFetching
        : [],
    });

    dispatch(getProductsSuccess(data?.data, PageNumber));

    if (PageNumber === 1 && getState().product.productsToAddToStart.length) {
      data.data = [...getState().product.productsToAddToStart, ...data.data];
      dispatch(addProductsToStart());
    }

    return data;
  };
};

export const setProducts = (products = []) => {
  return async (dispatch) => {
    dispatch(setProductsSuccess(products));
  };
};

export const getProductsSecoTools = ({
  search,
  PageSize = EXTERNAL_PRODUCTS_PER_PAGE,
  PageNumber = 1,
}) => {
  return async (dispatch, getState) => {
    const lastFetchedNumberOfProducts = getState().product.secoToolsProducts
      ?.length;

    if (
      PageNumber === 1 ||
      lastFetchedNumberOfProducts === EXTERNAL_PRODUCTS_PER_PAGE
    ) {
      try {
        const { data } = await fetchProductsSecoTools({
          SearchTerm: search,
          PageSize,
          PageNumber,
        });
        dispatch(getProductsSecoToolsSuccess(data));
        return data;
      } catch (error) {
        dispatch(getProductsSecoToolsSuccess([]));
        if (error.response.status === 504) {
          dispatch(showError('error.secoIsNotResponding'));
        } else {
          dispatch(showError('error.secoFailed'));
        }
        return [];
      }
    } else {
      getProductsSecoToolsSuccess([]);
      return [];
    }
  };
};

export const getProductsUnitedTools = ({
  search,
  PageSize = EXTERNAL_PRODUCTS_PER_PAGE,
  PageNumber = 1,
}) => {
  return async (dispatch, getState) => {
    const lastFetchedNumberOfProducts = getState().product.unitedToolsProducts
      ?.length;
    if (
      PageNumber === 1 ||
      lastFetchedNumberOfProducts === EXTERNAL_PRODUCTS_PER_PAGE
    ) {
      try {
        const { data } = await fetchProductsToolsUnited({
          SearchTerm: search,
          PageSize,
          PageNumber,
        });

        dispatch(getProductsUnitedToolsSuccess(data));

        return data;
      } catch (error) {
        dispatch(getProductsUnitedToolsSuccess([]));
        if (error.response.status === 504) {
          dispatch(showError('error.toolsUnitedIsNotResponding'));
        } else {
          dispatch(showError('error.toolsUnitedFailed'));
        }
        return [];
      }
    } else {
      dispatch(getProductsUnitedToolsSuccess([]));
      return [];
    }
  };
};

export const getProductsWalter = ({
  search,
  PageSize = EXTERNAL_PRODUCTS_PER_PAGE,
  PageNumber = 1,
}) => {
  return async (dispatch, getState) => {
    const lastFetchedNumberOfProducts = getState().product.walterProducts
      ?.length;
    if (
      PageNumber === 1 ||
      lastFetchedNumberOfProducts === EXTERNAL_PRODUCTS_PER_PAGE
    ) {
      try {
        const { data } = await fetchProductsWalter({
          SearchTerm: search,
          PageSize,
          PageNumber,
        });

        dispatch(getProductsWalterSuccess(data));
        return data;
      } catch (error) {
        dispatch(getProductsWalterSuccess([]));
        if (error.response.status === 504) {
          dispatch(showError('error.walterIsNotResponding'));
        } else {
          dispatch(showError('error.walterFailed'));
        }
        return [];
      }
    } else {
      getProductsWalterSuccess([]);
      return [];
    }
  };
};

export const getProductsCoromant = ({
  search,
  PageSize = EXTERNAL_PRODUCTS_PER_PAGE,
  PageNumber = 1,
}) => {
  return async (dispatch, getState) => {
    const lastFetchedNumberOfProducts = getState().product.coromantProducts
      ?.length;
    if (
      PageNumber === 1 ||
      lastFetchedNumberOfProducts === EXTERNAL_PRODUCTS_PER_PAGE
    ) {
      try {
        const { data } = await fetchProductsCoromant({
          SearchTerm: search,
          PageSize,
          PageNumber,
        });
        dispatch(getProductsCoromantSuccess(data));
        return data;
      } catch (error) {
        dispatch(getProductsCoromantSuccess([]));
        if (error.response.status === 504) {
          dispatch(showError('error.coromantIsNotResponding'));
        } else {
          dispatch(showError('error.coromantFailed'));
        }
        return [];
      }
    } else {
      getProductsCoromantSuccess([]);
      return [];
    }
  };
};

export const getProductsDormerPramet = ({
  search,
  PageSize = EXTERNAL_PRODUCTS_PER_PAGE,
  PageNumber = 1,
}) => {
  return async (dispatch, getState) => {
    const lastFetchedNumberOfProducts = getState().product.dormerPrametProducts
      ?.length;
    if (
      PageNumber === 1 ||
      lastFetchedNumberOfProducts === EXTERNAL_PRODUCTS_PER_PAGE
    ) {
      try {
        const { data } = await fetchProductsDormerPramet({
          SearchTerm: search,
          PageSize,
          PageNumber,
        });
        dispatch(getProductsDormerPrametSuccess(data));
        return data;
      } catch (error) {
        dispatch(getProductsDormerPrametSuccess([]));
        if (error.response.status === 504) {
          dispatch(showError('error.dormerPrametIsNotResponding'));
        } else {
          dispatch(showError('error.dormerPrametFailed'));
        }
        return [];
      }
    } else {
      getProductsDormerPrametSuccess([]);
      return [];
    }
  };
};

export const clearError = () => {
  return async (dispatch) => {
    dispatch(showError(null));
  };
};

export const getProductsExternal = ({ PageNumber, SearchTerm }) => {
  return async (dispatch, getState) => {
    const search = getState().product.productsSearch || SearchTerm;

    const allResponsesArrays = [];
    if (isSearchTermLongEnough(search)) {
      const promises = [
        dispatch(
          getProductsCoromant({
            search,
            PageNumber,
          })
        ),
        dispatch(
          getProductsDormerPramet({
            search,
            PageNumber,
          })
        ),
        dispatch(
          getProductsSecoTools({
            search,
            PageNumber,
          })
        ),
        dispatch(
          getProductsWalter({
            search,
            PageNumber,
          })
        ),
        dispatch(
          getProductsUnitedTools({
            search,
            PageNumber,
          })
        ),
      ];

      const res = await Promise.all(promises);
      allResponsesArrays.push(...res);
    }

    const mergedArrays = allResponsesArrays.flat(1);

    dispatch(getExternalProductsSuccess(mergedArrays, PageNumber, search));

    const isThereMoreProductsToFetch = !!allResponsesArrays.find(
      (providersProducts) =>
        providersProducts.length === EXTERNAL_PRODUCTS_PER_PAGE
    );

    // to be able to use infinite scroll component without changes
    const paginationResponse = {
      currentPage: PageNumber,
      pageCount: isThereMoreProductsToFetch ? PageNumber + 1 : PageNumber,
      pageSize: EXTERNAL_PRODUCTS_PER_PAGE,
      rowCount: isThereMoreProductsToFetch
        ? mergedArrays.length + 1
        : mergedArrays.length,
      lastRowReturned: isThereMoreProductsToFetch
        ? mergedArrays.length + 1
        : mergedArrays.length,
      data: mergedArrays,
    };

    return paginationResponse;
  };
};

export const clearProductsExternal = () => {
  return async (dispatch) => {
    dispatch(clearProductsExternalSuccess([]));
  };
};

export const getProductInstance = (tag) => {
  return async (dispatch) => {
    const { data } = await fetchProductInstanceByTag(tag);
    dispatch(getProductInstanceSuccess(data));
    return data;
  };
};

export const getProductInstanceConditionRatings = (
  PropertyIds,
  HistoryId,
  PageNumber = 1,
  Sort = 'created_at_desc',
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch) => {
    const {
      data: conditionRatings,
    } = await fetchProductInstanceNumericPropertyHistory({
      params: {
        PropertyIds,
        Sort,
        HistoryId,
        PageNumber,
        PageSize,
      },
    });

    const isNotificationEntryDeleted = HistoryId
      ? !conditionRatings.data?.find(({ id }) => id === HistoryId)
      : false;

    dispatch(getProductInstanceConditionRatingsSuccess(conditionRatings.data));
    dispatch(
      getPaginationDetails(
        conditionRatings.currentPage,
        conditionRatings.pageCount
      )
    );
    return {
      conditionRatings: conditionRatings.data,
      isNotificationEntryDeleted,
    };
  };
};

export const setProductInstanceConditionRatingHighlightedId = (id) => {
  return async (dispatch) => {
    dispatch(setProductInstanceConditionRatingHighlightedIdSuccess(id));
  };
};

export const addProductInstanceConditionRating = (
  conditionRatingId,
  value,
  productInstanceId,
  PageNumber = 1,
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch) => {
    await postProductInstanceNumericProperty(conditionRatingId, {
      value,
      productInstanceId,
    });
    const {
      data: conditionRatings,
    } = await fetchProductInstanceNumericPropertyHistory({
      params: {
        PropertyIds: conditionRatingId,
        PageNumber,
        PageSize,
        Sort: 'created_at_desc',
      },
    });
    dispatch(
      addProductInstanceConditionRatingSuccess(
        conditionRatingId,
        value,
        conditionRatings?.data
      )
    );
    dispatch(
      getPaginationDetails(
        conditionRatings?.currentPage,
        conditionRatings?.pageCount
      )
    );

    return conditionRatings.data[0]?.id;
  };
};

export const deleteProductInstanceConditionRating = (
  conditionRatingId,
  productInstanceId,
  tag,
  PageNumber = 1,
  Sort = 'created_at_desc',
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch, getState) => {
    await deleteProductInstanceNumericPropertyHistoryEntry(conditionRatingId, {
      productInstanceId,
    });
    const { data } = await fetchProductInstanceByTag(tag);

    let conditionRatings = getState().product.instanceConditionRatings.data?.filter(
      (conditionRating) => conditionRating?.id !== conditionRatingId
    );

    if (!conditionRatings?.length && PageNumber !== 1) {
      const res = await fetchProductInstanceNumericPropertyHistory({
        params: {
          PropertyIds: conditionRatingId,
          PageNumber: PageNumber - 1,
          PageSize,
          Sort,
        },
      });

      conditionRatings = res.data?.data;

      dispatch(
        getPaginationDetails(res.data?.currentPage, res.data?.pageCount)
      );
    }

    dispatch(
      deleteProductInstanceConditionRatingSuccess(
        data.conditionRating,
        conditionRatings
      )
    );
  };
};

export const getProductInstanceTimeTrackerHistory = (
  PropertyIds,
  HistoryId,
  PageNumber = 1,
  PageSize = PROPERTIES_PER_PAGE,
  Sort = 'created_at_desc'
) => {
  return async (dispatch) => {
    const {
      data: timeTrackerHistory,
    } = await fetchProductInstanceNumericPropertyHistory({
      params: {
        PropertyIds,
        Sort,
        PageNumber,
        PageSize,
        HistoryId,
      },
    });
    dispatch(
      getProductInstanceTimeTrackerHistorySuccess(timeTrackerHistory?.data)
    );
    dispatch(
      getPaginationDetails(
        timeTrackerHistory?.currentPage,
        timeTrackerHistory?.pageCount
      )
    );
    return timeTrackerHistory?.data;
  };
};

export const setProductInstanceTimeTrackerHighlightedId = (id) => {
  return async (dispatch) => {
    await dispatch(setProductInstanceTimeTrackerHighlightedIdSuccess(id));
  };
};

export const setProductInstanceTimeTrackerProperty = (
  propertyId,
  propertyName,
  productInstanceId,
  value,
  valueToAddToState,
  usageTimeId,
  targetTimeId,
  totalTimeNewValue,
  PageNumber = 1,
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch) => {
    await postProductInstanceNumericProperty(propertyId, {
      value,
      productInstanceId,
    });
    const {
      data: timeTrackerHistory,
    } = await fetchProductInstanceNumericPropertyHistory({
      params: {
        PropertyIds: [usageTimeId, targetTimeId],
        Sort: 'created_at_desc',
        PageNumber,
        PageSize,
      },
    });
    dispatch(
      setProductInstanceTimeTrackerPropertySuccess(
        propertyId,
        propertyName,
        valueToAddToState,
        timeTrackerHistory?.data,
        totalTimeNewValue
      )
    );
    dispatch(
      getPaginationDetails(
        timeTrackerHistory?.currentPage,
        timeTrackerHistory?.pageCount
      )
    );
  };
};

export const getProductInstancePropertiesFromDetails = (
  tag,
  propertiesNames
) => {
  return async (dispatch) => {
    const { data } = await fetchProductInstanceByTag(tag);
    const instanceProperties = propertiesNames?.reduce(
      (acc, propertyName) => ({
        ...acc,
        [propertyName]: data[propertyName],
      }),
      {}
    );

    dispatch(
      getProductInstancePropertiesFromDetailsSuccess(instanceProperties)
    );
    return instanceProperties;
  };
};

export const getProductInstances = (id, sort = '') => {
  return async (dispatch) => {
    const { data } = await fetchProductInstances({
      params: {
        FilterProductId: id,
        Sort: sort,
      },
    });
    dispatch(getProductInstancesSuccess(data?.data));
  };
};

export const clearProductInstances = () => {
  return async (dispatch) => {
    dispatch(getProductInstancesSuccess([]));
  };
};

export const clearProductInstance = () => {
  return async (dispatch) => {
    dispatch(getProductInstanceSuccess({}));
  };
};

export const getProductSupportMessages = ({
  productId,
  PageSize = MESSAGES_PER_PAGE,
  PageNumber = 0,
  initial = false,
}) => {
  return async (dispatch) => {
    const { data } = await fetchSupportMessages({
      productId,
      PageNumber: PageNumber + 1,
      PageSize,
    });
    dispatch(
      getProductSupportMessagesSuccess(data?.data, data.rowCount, initial)
    );
  };
};

export const updateProductSupportMessage = (messageId, statusCode) => {
  return async (dispatch) => {
    await updateSupportMessageStatus(messageId, {
      statusCode,
    });
    dispatch(updateProductSupportMessageSuccess(messageId, statusCode));
  };
};

export const highlightProductDetails = (highlightedData) => {
  return async (dispatch) => {
    dispatch(highlightProductDetailsSuccess(highlightedData));
  };
};

export const createProduct = ({
  groupId,
  externalId,
  dataProviderId,
  nickname,
  currentRouterLocation,
}) => {
  return async (dispatch, getState) => {
    const response = await postProduct({
      groupId,
      dataProviderId,
      externalId,
      nickname,
    });

    handleAnalytics(FIREBASE_EVENTS.PRODUCT_CREATE);

    const productId = getResourceFromLocation(response.headers.location);
    const product = await fetchProduct(productId);

    const { productsSearch: search, selectedGroups } = getState().product;

    dispatch(
      setProductsToAddToStart([
        {
          ...product.data,
          productInstanceCount: 0,
        },
      ])
    );

    // if my products component is active and no filters are active (refetching will not be triggered)
    // add product to top
    if (
      isDatabaseMyProductsTabOpened(currentRouterLocation) &&
      !search &&
      !selectedGroups.length
    ) {
      dispatch(addProductsToStart());
    }

    return {
      productId,
      highlightedData: {
        highlightedProductsIds: [productId],
        isFooterHighlighted: false,
        isHighlighted: true,
      },
    };
  };
};

export const editProductInstanceDetails = (id, data) => {
  return async (dispatch, getState) => {
    await putProductInstance(id, data);

    const state = getState().product;
    const highlightedData = {
      instance: {
        nicknameHighlighted:
          data.nickname && data.nickname !== state.instance.nickname,
        descriptionHighlighted:
          data.description && data.description !== state.instance.description,
      },
      details: {
        nameHighlighted: false,
        codeHighlighted: false,
        nicknameHighlighted: false,
        descriptionHighlighted: false,
        detailsHighlighted: [],
      },
    };

    dispatch(editProductInstanceDetailsSuccess(data));

    return { highlightedData };
  };
};

export const transferTag = (data) => {
  return async () => {
    await patchTransferTag(data);
  };
};

export const deleteProduct = (id) => {
  return async () => {
    await deleteProductTemplate(id);
  };
};

export const deleteProductInstanceById = (id) => {
  return async () => {
    await deleteProductInstance(id);
  };
};

export const deleteProductsOfGroup = (groupId) => {
  return async (dispatch) => {
    dispatch(deleteProductsOfGroupSuccess(groupId));
  };
};

const generateHighlightedDataAfterEdit = (data, state) => ({
  details: {
    nameHighlighted: data?.name && data?.name !== state.details?.name,
    codeHighlighted: data?.code !== state.details?.code,
    nicknameHighlighted:
      data?.nickname && data?.nickname !== state.details?.nickname,
    descriptionHighlighted:
      data?.description && data?.description !== state.details?.description,
    detailsHighlighted: [
      ...state.details.textProperties
        ?.filter(
          ({ value }, index) => value !== data.textProperties[index].value
        )
        ?.map(({ id }) => id),
      ...state.details.numericProperties
        ?.filter(
          ({ value }, index) => value !== data.numericProperties[index].value
        )
        ?.map(({ id }) => id),
      ...state.details.boolProperties
        ?.filter(
          ({ value }, index) => value !== data.boolProperties[index].value
        )
        ?.map(({ id }) => id),
    ],
  },
  instance: {
    nicknameHighlighted: false,
    descriptionHighlighted: false,
  },
});

export const editProductTemplateDetails = (id, data) => {
  return async (dispatch, getState) => {
    await putProduct(id, data);

    const product = await fetchProduct(id);

    const highlightedData = generateHighlightedDataAfterEdit(
      product?.data,
      getState().product
    );

    dispatch(editProductTemplateDetailsSuccess(data));

    return { highlightedData };
  };
};

export const highlightProduct = (id) => {
  return async (dispatch) => {
    dispatch(highlightProductSuccess(id));
  };
};

export const getInstanceLocations = (id) => {
  return async (dispatch) => {
    const { data } = await fetchInstanceLocations({
      FilterProduct: id,
    });
    dispatch(getInstanceLocationsSuccess(data));
  };
};

export const clearInstanceLocations = () => {
  return async (dispatch) => {
    dispatch(getInstanceLocationsSuccess([]));
  };
};

export const addProductSupportMessage = (productId, message) => {
  return async (dispatch) => {
    const { headers } = await postSupportMessage(message);
    const id = getResourceFromLocation(headers.location);
    const { data } = await fetchSupportMessages({
      productId,
    });
    dispatch(addProductSupportMessageSuccess(data?.data, id));
  };
};

export const setProductsSearch = (value) => {
  return async (dispatch) => {
    dispatch(setProductsSearchSuccess(value));
  };
};

export const getProductStockLevel = (id) => {
  return async (dispatch) => {
    const { data } = await getProductStockLevelFromCribwise(id);
    dispatch(getProductStockLevelSuccess(data));
  };
};

export const setGroupsSearch = (selectedGroups) => {
  return async (dispatch) => {
    dispatch(setGroupsSearchSuccess(selectedGroups));
  };
};

export const copyProductToAnotherGroup = (productId, targetGroupId) => {
  return async () => {
    const { headers } = await postCopyProductToAnotherGroup(productId, {
      targetGroupId,
      copyProductThreads: true,
    });
    return getResourceFromLocation(headers.location);
  };
};

export const getProductAssemblies = (assemblyIds) => {
  return async (dispatch) => {
    const { data } = await fetchAssemblies({
      FilterAssembly: assemblyIds,
    });
    dispatch(getProductAssembliesSuccess(data?.data));
  };
};

export const clearProductAssemblies = () => {
  return async (dispatch) => {
    dispatch(clearProductAssembliesSuccess());
  };
};

export const getProductInstanceAssemblyInstance = (assemblyInstanceId) => {
  return async (dispatch) => {
    const { data: instanceData } = await fetchAssemblyInstance(
      assemblyInstanceId
    );
    const { data: assemblyData } = await fetchAssembly(instanceData.assemblyId);
    dispatch(
      getProductInstanceAssemblyInstanceSuccess({
        ...instanceData,
        imageUrl: assemblyData.imageUrl,
        groupId: assemblyData.groupId,
        assemblyNickname: assemblyData.nickname,
        assemblyName: assemblyData.name,
        assemblyDescription: assemblyData.description,
      })
    );
  };
};

export const clearProductInstanceAssemblyInstance = () => {
  return async (dispatch) => {
    dispatch(clearProductInstanceAssemblyInstanceSuccess());
  };
};

export const highlightProductThread = (id) => {
  return async (dispatch) => {
    dispatch(highlightProductThreadSuccess(id));
  };
};

export const getProductThreads = (id) => {
  return async (dispatch) => {
    const { data } = await fetchProductThreads(id, {
      status: ACTIVE_USER_THREAD_STATUSES,
    });
    dispatch(getProductThreadsSuccess(data?.data));
  };
};

export const getProductInstanceThreads = (id) => {
  return async (dispatch) => {
    const { data } = await fetchProductInstanceThreads(id, {
      status: ACTIVE_USER_THREAD_STATUSES,
    });
    dispatch(getProductInstanceThreadsSuccess(data?.data));
  };
};

export const addProductGeneralThread = (productId, threadData) => {
  return async (dispatch) => {
    await postProductGeneralThread(productId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    const { data } = await fetchProductThreads(productId, {
      status: ACTIVE_USER_THREAD_STATUSES,
    });
    dispatch(addProductGeneralThreadSuccess(data?.data));
    return data.data[0].id;
  };
};

export const addProductInstanceThread = (instanceId, threadData) => {
  return async (dispatch) => {
    await postProductInstanceThread(instanceId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    const { data } = await fetchProductInstanceThreads(instanceId, {
      status: ACTIVE_USER_THREAD_STATUSES,
    });
    dispatch(addProductInstanceThreadSuccess(data?.data));
    return data.data[0].id;
  };
};

export const editProductGeneralThread = (threadId, threadData) => {
  return async (dispatch) => {
    await putThread(threadId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(editProductGeneralThreadSuccess(threadId, threadData));
  };
};

export const editProductInstanceThread = (threadId, threadData) => {
  return async (dispatch) => {
    await putThread(threadId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(editProductInstanceThreadSuccess(threadId, threadData));
  };
};

export const removeProductGeneralThread = (id) => {
  return async (dispatch) => {
    await deleteThread(id);
    dispatch(removeProductGeneralThreadSuccess(id));
  };
};

export const removeProductInstanceThread = (id) => {
  return async (dispatch) => {
    await deleteThread(id);
    dispatch(removeProductInstanceThreadSuccess(id));
  };
};

export const markProductGeneralThreadAsDone = (threadId, threadData) => {
  return async (dispatch) => {
    await putThread(threadId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(markProductGeneralThreadAsDoneSuccess(threadId));
  };
};

export const markProductInstanceThreadAsDone = (instanceId, threadData) => {
  return async (dispatch) => {
    await putThread(instanceId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(markProductInstanceThreadAsDoneSuccess(instanceId));
  };
};

export const renameProductThreadFile = (fileId, data, threadId) => {
  return async (dispatch) => {
    dispatch(renameProductThreadFileSuccess(fileId, data.name, threadId));
  };
};

export const renameProductInstanceThreadFile = (fileId, data, threadId) => {
  return async (dispatch) => {
    dispatch(
      renameProductInstanceThreadFileSuccess(fileId, data.name, threadId)
    );
  };
};

export const deleteProductThreadFile = (threadId, fileId) => {
  return async (dispatch) => {
    await deleteFile(fileId);
    dispatch(deleteProductThreadFileSuccess(threadId, fileId));
  };
};

export const deleteProductInstanceThreadFile = (threadId, fileId) => {
  return async (dispatch) => {
    await deleteFile(fileId);
    dispatch(deleteProductInstanceThreadFileSuccess(threadId, fileId));
  };
};

export const updateProductInstanceReconditionCount = () => {
  return async (dispatch) => {
    dispatch(updateProductInstanceReconditionCountSuccess());
  };
};

export const fetchProductInstanceReconditioningDetails = (id, Sort) => {
  return async (dispatch) => {
    const { data } = await fetchReconditionDetails({
      ProductInstanceId: id,
      Sort,
    });

    if (data) {
      dispatch(
        fetchProductInstanceReconditioningDetailsSuccess({
          ...data,
          productInstanceId: id,
        })
      );
    }
  };
};

export const addProductInstanceReconditioningInfo = (data) => {
  return async (dispatch) => {
    const dataWithReconId = await postProductInstanceReconditioningInfo(data);
    dispatch(
      addProductInstanceReconditioningInfoSuccess({
        ...data,
        reconditionedId: dataWithReconId.data,
      })
    );
    return dataWithReconId.data;
  };
};

export const checkProductInstanceReconditionData = (productId) => {
  return async (dispatch) => {
    await checkReconditionData(productId);
    dispatch(checkProductInstanceReconditionDataSuccess());
  };
};

export const editProductInstanceReconditioningInfo = (reconditionId, data) => {
  return async (dispatch, getState) => {
    await putProductInstanceReconditioningInfo(reconditionId, data);
    dispatch(
      fetchProductInstanceReconditioningDetails(getState().product.instance?.id)
    );
  };
};
