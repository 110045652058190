import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, de, se, fr, es, ja, zh, it, tr } from './locales';
import { DEFAULT_LANGUAGE_CODE } from './constants';

const resources = {
  en: { translation: en },
  de: { translation: de },
  se: { translation: se },
  fr: { translation: fr },
  es: { translation: es },
  ja: { translation: ja },
  zh: { translation: zh },
  it: { translation: it },
  tr: { translation: tr },
  // enSeco: { translation: en }, - EXAMPLE
  // to support language by brand name it by adding
  // brand.id (from BE - stored in user.organization.brand)
  // to the right of the lang shortcut
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || DEFAULT_LANGUAGE_CODE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: DEFAULT_LANGUAGE_CODE,
  });

export const nonHookTranslate = (stringToTranslate) => {
  return i18n.t(stringToTranslate);
};

export default i18n;
