/* eslint-disable no-restricted-globals */
import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'reactstrap';

import { ErrorStep, NewLoading, NewModalStep } from '../Shared';
import { Button, ControlledOutlinedField } from '../../Shared';
import { getMappedErrorMessage } from '../../../utils/error';
import { createCustomBox } from '../../../redux/actions/boxActions';
import {
  BOX_CONSTANTS,
  FIREBASE_EVENTS,
  MAX_DIMENSION_OF_CUSTOM_BOX,
  MAX_NUMBER_OF_CHARACTERS,
  MAX_WEIGHT_OF_CUSTOM_BOX,
  MIN_WEIGHT_OF_CUSTOM_BOX,
  NUMBER_OF_MAX_WEIGHT_CUSTOM_BOX_DECIMALS,
} from '../../../constants';
import {
  getRegexForLimitingNumberOfDecimals,
  trimValuesInObject,
} from '../../../utils/shared';
import { handleAnalytics } from '../../../utils/analytics';

const debug = _debug('Bridge:AddCustomBox');

const STEPS = {
  DETAILS: 'DETAILS',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const AddCustomBox = ({
  handleClose,
  goBack,
  createCustomBox,
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(STEPS.DETAILS);
  const [error, setError] = useState('');
  const boxDetailsFormRef = useRef();
  const regexForLimitingNumberOfDecimals = getRegexForLimitingNumberOfDecimals(
    NUMBER_OF_MAX_WEIGHT_CUSTOM_BOX_DECIMALS
  );
  const dimensionsValidation = Yup.number()
    .transform((value) => (value && isNaN(value) ? undefined : value))
    .transform((value, originalValue) =>
      /\s/.test(originalValue.trim()) ? NaN : value
    )
    .transform((curr, orig) => (orig.trim() === '' ? null : curr))
    .typeError(t('error.thisFieldMustBeNumerical'))
    .integer(t('error.decimalsAreNotAllowed'))
    .positive(t('error.youMustEnterPositiveNumber'))
    .max(
      MAX_DIMENSION_OF_CUSTOM_BOX,
      `${t('error.maximumValueForThisFieldIs')} ${MAX_DIMENSION_OF_CUSTOM_BOX}.`
    )
    .required(t('error.thisFieldIsRequired'));

  const shippingValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('error.thisFieldIsRequired'))
      .max(
        MAX_NUMBER_OF_CHARACTERS.INPUT,
        `${t('error.maximumNumberOfCharactersForThisFieldIs')} ${
          MAX_NUMBER_OF_CHARACTERS.INPUT
        }.`
      ),
    description: Yup.string()
      .trim()
      .max(
        MAX_NUMBER_OF_CHARACTERS.DESCRIPTION,
        `${t('error.maximumNumberOfCharactersForThisFieldIs')} ${
          MAX_NUMBER_OF_CHARACTERS.DESCRIPTION
        }.`
      ),
    articleNumber: Yup.number()
      .typeError(t('error.thisFieldMustBeNumerical'))
      .integer(t('error.decimalsAreNotAllowed'))
      .positive(t('error.youMustEnterPositiveNumber'))
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr)),
    maxWeight: Yup.number()
      .transform((value) => (value && isNaN(value) ? undefined : value))
      .transform((value, originalValue) =>
        /\s/.test(originalValue.trim()) ? NaN : value
      )
      .transform((curr, orig) => (orig.trim() === '' ? null : curr))
      .typeError(t('error.thisFieldMustBeNumerical'))
      .min(
        MIN_WEIGHT_OF_CUSTOM_BOX,
        `${t('error.minimumValueForThisFieldIs')} ${MIN_WEIGHT_OF_CUSTOM_BOX}.`
      )
      .max(
        MAX_WEIGHT_OF_CUSTOM_BOX,
        `${t('error.maximumValueForThisFieldIs')} ${MAX_WEIGHT_OF_CUSTOM_BOX}.`
      )
      .test(
        'maxDigitsAfterDecimal',
        t('error.valueCanHaveMaximumOfTwoDecimals'),
        (number) =>
          number === null || regexForLimitingNumberOfDecimals.test(number)
      )
      .required(t('error.thisFieldIsRequired')),
    length: dimensionsValidation,
    width: dimensionsValidation,
    height: dimensionsValidation,
  });

  const { handleSubmit, control, getValues } = useForm({
    resolver: yupResolver(shippingValidationSchema),
    mode: 'onChange',
  });

  const addCustomBox = async () => {
    try {
      setStep(STEPS.LOADING);
      await createCustomBox(trimValuesInObject(getValues()));
      handleClose();
      handleAnalytics(FIREBASE_EVENTS.CREATE_CUSTOM_BOX);
      onSuccess();
    } catch (e) {
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
    }
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.DETAILS:
        return (
          <NewModalStep
            title={t('modal.customBox')}
            rightButtonContent={t('button.addBox')}
            handleClickRightButton={() =>
              boxDetailsFormRef.current.requestSubmit()
            }
            leftButtonContent={goBack ? t('button.back') : ''}
            handleClickLeftButton={goBack}
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column'
          >
            <div className='d-flex flex-column w-80 mt-2'>
              <p className='font-weight-bold text-txt-primary my-2'>
                {t('modal.boxDetails').toUpperCase()}
              </p>
              <Form
                onSubmit={handleSubmit(addCustomBox)}
                innerRef={boxDetailsFormRef}
                noValidate
              >
                <ControlledOutlinedField
                  control={control}
                  placeholder={t('components.boxName')}
                  name='name'
                  required
                  className='mb-2'
                />
                <ControlledOutlinedField
                  control={control}
                  placeholder={t('modal.description')}
                  name='description'
                  className='mb-2'
                />
                <div className='d-flex justify-content-between mb-2'>
                  <ControlledOutlinedField
                    control={control}
                    name='articleNumber'
                    placeholder={t('components.articleNumber')}
                    className='w-49'
                  />
                  <ControlledOutlinedField
                    control={control}
                    name='maxWeight'
                    placeholder={`${t('components.maxWeight')} (${
                      BOX_CONSTANTS.WEIGHT_UNIT
                    })`}
                    required
                    className='w-49'
                  />
                </div>
                <div className='d-flex justify-content-between'>
                  <ControlledOutlinedField
                    control={control}
                    name='length'
                    placeholder={`${t('components.length')} (${
                      BOX_CONSTANTS.DIMENSION_UNIT
                    })`}
                    required
                    className='w-32'
                  />
                  <ControlledOutlinedField
                    control={control}
                    name='width'
                    placeholder={`${t('components.width')} (${
                      BOX_CONSTANTS.DIMENSION_UNIT
                    })`}
                    required
                    className='w-32'
                  />
                  <ControlledOutlinedField
                    control={control}
                    name='height'
                    placeholder={`${t('components.height')} (${
                      BOX_CONSTANTS.DIMENSION_UNIT
                    })`}
                    required
                    className='w-32'
                  />
                </div>
                <Button type='submit' className='d-none' />
              </Form>
            </div>
          </NewModalStep>
        );
      case STEPS.LOADING:
        return <NewLoading />;
      case STEPS.ERROR:
        return (
          <ErrorStep
            title={t(error)}
            buttonContent={t('button.cancel')}
            handleButton={handleClose}
            handleClose={handleClose}
          />
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return renderStep();
};

AddCustomBox.propTypes = {
  handleClose: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  requestNewBoxes: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default connect(null, { createCustomBox })(AddCustomBox);
