import React from 'react';

import PropTypes from 'prop-types';
import { X } from 'react-feather';

import { COLORS } from '../../constants/theme';

const EmailItem = ({ email, handleRemove }) => {
  return (
    <div className='pb-2'>
      <div className='d-flex justify-content-between align-items-center email-item'>
        <div className='email-item-text pl-2'>{email}</div>
        <div
          className='pr-2 cursor-pointer'
          onClick={() => handleRemove(email)}
        >
          <X width={30} height={30} color={COLORS.TERTIARY} />
        </div>
      </div>
    </div>
  );
};

EmailItem.propTypes = {
  email: PropTypes.string.isRequired,
  handleRemove: PropTypes.func,
};

export default EmailItem;
