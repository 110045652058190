import * as types from '../constants';
import { fetchProduct, fetchProducts } from '../../client/product';
import {
  postScanTag,
  postSyncProduct,
  fetchScannedTags,
} from '../../client/tag';
import {
  FIREBASE_EVENTS,
  PRODUCTS_PER_PAGE,
  TAGS_PER_PAGE,
} from '../../constants';
import { handleAnalytics } from '../../utils/analytics';

export const getSearchProductsSuccess = (products, totalCount, initial) => {
  return {
    type: types.GET_SEARCH_PRODUCTS_SUCCESS,
    payload: {
      products,
      totalCount,
      initial,
    },
  };
};

export const syncProductSuccess = (id, product) => {
  return {
    type: types.SYNC_PRODUCT_SUCCESS,
    payload: { productId: id, product },
  };
};

export const setEmptyTagSuccess = (tag) => {
  return {
    type: types.SET_EMPTY_TAG,
    payload: {
      tag,
    },
  };
};

export const setOccupiedTagSuccess = (tag) => {
  return {
    type: types.SET_OCCUPIED_TAG,
    payload: {
      tag,
    },
  };
};

export const setErrorSuccess = (error) => {
  return {
    type: types.SET_ERROR_SUCCESS,
    payload: {
      error,
    },
  };
};

export const getScannedTagsSuccess = (scannedTags, totalCount, initial) => {
  return {
    type: types.GET_SCANNED_TAGS_SUCCESS,
    payload: {
      scannedTags,
      totalCount,
      initial,
    },
  };
};

export const getSearchProducts = ({
  search,
  groupId,
  PageSize = PRODUCTS_PER_PAGE,
  PageNumber = 0,
  initial = false,
}) => {
  return async (dispatch) => {
    const { data } = await fetchProducts({
      SearchTerm: search,
      FilterGroups: [groupId],
      PageNumber: PageNumber + 1,
      PageSize,
    });
    dispatch(getSearchProductsSuccess(data?.data, data.rowCount, initial));
    return data;
  };
};

export const syncProduct = (tag, id) => {
  return async (dispatch, getState) => {
    const instanceData = await postSyncProduct({
      tag,
      productId: id,
    });

    handleAnalytics(FIREBASE_EVENTS.PRODUCT_INSTANCE_CREATE);

    const { data } = await fetchProduct(id);
    const groupName = getState().group.groups.find(
      ({ id }) => data.groupId === id
    ).name;

    dispatch(syncProductSuccess(id, { ...data, groupName }));

    const highlightedData = {
      highlightedProductsIds: [id],
      isFooterHighlighted: true,
      isHighlighted: false,
    };

    return { highlightedData, ...instanceData.data };
  };
};

export const getTag = (tag, updateAvailability) => {
  return async () => {
    const { data } = await postScanTag(tag, updateAvailability);
    return data;
  };
};

export const setEmptyTag = (tag) => {
  return async (dispatch) => {
    dispatch(setEmptyTagSuccess(tag));
  };
};

export const setOccupiedTag = (tag) => {
  return async (dispatch) => {
    dispatch(setOccupiedTagSuccess(tag));
  };
};

export const getScannedTags = ({
  PageSize = TAGS_PER_PAGE,
  PageNumber = 0,
  initial = false,
}) => {
  return async (dispatch) => {
    const { data } = await fetchScannedTags({
      params: { PageNumber: PageNumber + 1, PageSize },
    });
    dispatch(getScannedTagsSuccess(data?.data, data.rowCount, initial));
  };
};

export const setError = ({ title, subtitle }) => {
  return (dispatch) => {
    dispatch(setErrorSuccess({ title, subtitle }));
  };
};
