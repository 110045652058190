import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.inspection, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_INSPECTION_BOX_PRODUCT_INSTANCES_SUCCESS:
      return {
        ...state,
        productInstances: payload.data,
      };
    case types.UPDATE_PRODUCT_INSTANCE_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        productInstances: state.productInstances.map((instance) =>
          instance.id === payload.instanceId
            ? { ...instance, inspection: payload.inspectionDetails }
            : instance
        ),
      };
    case types.ADD_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS:
      return {
        ...state,
        productInstances: state.productInstances.map((instance) =>
          instance.id === payload.productInstanceId
            ? { ...instance, rcCommentId: payload.rcCommentId }
            : instance
        ),
      };
    case types.DELETE_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS:
      return {
        ...state,
        productInstances: state.productInstances.map((instance) =>
          instance.id === payload.productInstanceId
            ? { ...instance, rcCommentId: null }
            : instance
        ),
      };
    case types.GET_BOX_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        inspectionDetails: payload.data,
      };
    case types.UPDATE_BOX_STATUS_SUCCESS:
      return {
        ...state,
        inspectionDetails: {
          ...state.inspectionDetails,
          boxStatus: payload.updatedBoxStatus,
        },
      };
    case types.UPDATE_RC_FEEDBACK_INSIDE_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        inspectionDetails: {
          ...state.inspectionDetails,
          rcGeneralComment: payload.data,
        },
      };
    case types.UPDATE_IS_BOX_INSPECTED_SUCCESS:
      return {
        ...state,
        inspectionDetails: {
          ...state.inspectionDetails,
          isBoxInspected: payload.isChecked,
        },
      };
    case types.SET_BOX_INSPECTION_PRODUCT_INSTANCES_SUCCESS:
      return {
        ...state,
        productInstances: [...payload.data],
      };
    case types.UPDATE_PRODUCT_INSTANCE_VERIFICATION_DETAILS_SUCCESS:
      const {
        verifiedCount,
        ...instanceVerificationData
      } = payload.verificationDetails;
      return {
        ...state,
        productInstances: state.productInstances.map((instance) =>
          instance.id === payload.instanceId
            ? {
                ...instance,
                inspection: {
                  ...instance.inspection,
                  ...instanceVerificationData,
                },
              }
            : instance
        ),
        inspectionDetails: {
          ...state.inspectionDetails,
          verifiedCount,
        },
      };
    case types.UPDATE_IS_BOX_VERIFIED_SUCCESS:
      return {
        ...state,
        inspectionDetails: {
          ...state.inspectionDetails,
          isBoxVerified: payload.isChecked,
        },
      };

    default:
      return state;
  }
}
