import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { compareByAscendingOrder } from '../../utils/shared';
import { YES_NO_OPTIONS } from '../../constants';
import { CustomLinkify } from '../Shared';
import LogoRow from '../../containers/ProductContainers/LogoRow';
import Image from '../Shared/Image';

const TechnicalTab = ({
  data,
  details,
  setImageModal,
  setClickedImageIndex,
}) => {
  const { t } = useTranslation();

  const { code, externalId, dataProviderName, images } = details;

  const returnValueByPropretyType = (property) => {
    if (property?.unitName) {
      return `${property?.value} ${property?.unitName}`;
    }
    if (property?.value === true || property?.value === false) {
      return property?.value ? t(YES_NO_OPTIONS.YES) : t(YES_NO_OPTIONS.NO);
    }
    return property?.value;
  };

  const handleSideImageClick = (index) => {
    setImageModal(true);
    setClickedImageIndex(index);
  };

  return (
    <div className='technical-tab-container'>
      <div className='d-flex flex-grow-1'>
        <div className='technical-info'>
          <Row className='m-0 py-3 mb-3 font-weight-bold'>
            <Col sm={3}>{t('components.name')}</Col>
            <Col sm={3}>{t('components.value')}</Col>
            <Col sm={6}>{t('components.description')}</Col>
          </Row>
          <div className='rows'>
            {data
              ?.sort((propertyOne, propertyTwo) =>
                compareByAscendingOrder(
                  propertyOne.orderIndex,
                  propertyTwo.orderIndex
                )
              )
              .map(
                (property, index) =>
                  !!property?.value && (
                    <Row
                      key={index}
                      className={cs(
                        'm-0 py-3 align-items-center break-word border-bottom-row detail-value-linebreak',
                        {
                          highlighted: details.detailsHighlighted?.some(
                            (detail) => detail === property.id
                          ),
                        }
                      )}
                    >
                      <Col sm={3}>{property?.name}</Col>
                      <Col sm={3}>
                        <CustomLinkify>
                          {returnValueByPropretyType(property)}
                        </CustomLinkify>
                      </Col>
                      <Col sm={6}>{property?.description}</Col>
                    </Row>
                  )
              )}
          </div>
        </div>
        <div>
          {images.map((image, index) => (
            <div key={index} className='pb-3'>
              <div
                onClick={() => handleSideImageClick(index)}
                className='d-flex cursor-pointer product-side-image justify-content-center align-items-center'
              >
                <Image
                  src={image.url}
                  height={120}
                  width={120}
                  className='image-object-fit-contain'
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {!!dataProviderName && (
        <LogoRow
          dataProviderName={dataProviderName}
          code={code}
          id={externalId}
        />
      )}
    </div>
  );
};

TechnicalTab.propTypes = {
  data: PropTypes.array.isRequired,
  details: PropTypes.object.isRequired,
  setImageModal: PropTypes.func.isRequired,
  setClickedImageIndex: PropTypes.func.isRequired,
};

export default TechnicalTab;
