import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Content from '../components/Shared/Content';
import Loader from '../components/Shared/Loader';
import Footer from '../components/Footer';
import { getPermissions } from '../utils/user';
import { PERMISSIONS, ROUTES } from '../constants';
import ScanTagInputModal from '../components/Shared/ScanTagInputModal';
import CookieBanner from '../components/UserComponents/CookieBanner';
import Reader from '../components/Reader';

const UserLayout = ({ children, dev, permissionsSet }) => {
  const permissions = getPermissions();

  if (permissionsSet && !permissions.includes(PERMISSIONS.WEBAPP_ACCESS)) {
    return (
      <Redirect
        to={{
          pathname: `${ROUTES.LOGIN}`,
          state: {
            accessError: true,
          },
        }}
      />
    );
  }

  return (
    <>
      {permissionsSet ? (
        <div className='wrapper'>
          <Sidebar dev={dev} />
          <div className='main'>
            <Navbar />
            <Content>{children}</Content>
            <CookieBanner />
            <Footer />
            <Reader />
            <ScanTagInputModal />
          </div>
        </div>
      ) : (
        <Loader height={100} />
      )}
    </>
  );
};

UserLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dev: PropTypes.bool.isRequired,
  permissionsSet: PropTypes.bool.isRequired,
};

export default UserLayout;
