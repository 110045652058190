export const removeHiddenColumns = (allColumns, allData) => {
  const columns = allColumns.filter(({ isHidden }) => !isHidden);

  const indexesOfHiddenColumns = allColumns
    .map(({ isHidden }, index) => (isHidden ? index : -1))
    .filter((index) => index !== -1);

  const data = allData.map((row) => ({
    ...row,
    rowColumns: row.rowColumns.filter(
      (_, index) => !indexesOfHiddenColumns.includes(index)
    ),
  }));

  return { data, columns };
};
