import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { ENV, FIREBASE_CONFIG } from '../config';
import {
  IDEM_SUBSCRIPTION_LEVELS,
  IDEM_SUBSCRIPTION_TYPES,
  PRODUCTION_ENVIRONMENT,
  STAGING_ENVIRONMENT,
} from '../constants';
import { getUserConsent } from './cookies';

let analytics;

export const logToFirebase = (eventName, organizationId, subscriptionType) => {
  logEvent(analytics, eventName, {
    organization:
      subscriptionType === IDEM_SUBSCRIPTION_LEVELS.PRO
        ? organizationId
        : IDEM_SUBSCRIPTION_TYPES.DEMO,
  });
};

export const handleAnalytics = (eventName) => {
  if (ENV === STAGING_ENVIRONMENT || ENV === PRODUCTION_ENVIRONMENT) {
    const consent = getUserConsent();
    if (!consent?.analytic) {
      return;
    }

    const subscriptionType = localStorage.getItem('subscriptionType');
    const id = localStorage.getItem('organizationId');

    if (subscriptionType) {
      logToFirebase(eventName, id, subscriptionType);
    }
  }
};

export const initializeFirebaseAnalytics = () => {
  if (ENV === STAGING_ENVIRONMENT || ENV === PRODUCTION_ENVIRONMENT) {
    const consent = getUserConsent();

    if (consent?.analytic) {
      const app = initializeApp(FIREBASE_CONFIG);
      analytics = getAnalytics(app);
    }
  }
};
