import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Modal from '../Modal';
import { NewDone, NewLoading, NewModalStep } from '../Shared';
import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import { postBoxProductInstances } from '../../../client/box';
import { addSpecialRequestToProductInstanceInTheBox } from '../../../redux/actions/boxActions';
import {
  DATE_FORMATS,
  FIELD_TYPE,
  FIREBASE_EVENTS,
  MAX_NUMBER_OF_CHARACTERS,
  MAX_NUMBER_OF_FILES_FOR_SPECIAL_REQUEST,
  ORGANIZATION_TYPE,
  ROUTES,
  SPECIAL_REQUEST_OPTIONS,
} from '../../../constants';
import { ErrorAlert, OutlinedField } from '../../Shared';
import UploadFiles from '../../Shared/UploadFiles';
import { postProductInstanceFile } from '../../../client/product';
import { getMappedErrorMessage } from '../../../utils/error';
import { formatDate } from '../../../utils/date';
import { fetchThreadDetails, putThread } from '../../../client/thread';
import { hasStringFieldValueBeenChanged } from '../../../utils/shared';
import { addFilesErrorMessage, isFilesArrayChanged } from '../../../utils/file';
import { handleAnalytics } from '../../../utils/analytics';

const debug = _debug('Bridge:ReconditioningSpecialRequestModal');

const STEPS = {
  INITIAL: 'INITIAL',
  ADD_OR_EDIT_SPECIAL_REQUEST: 'ADD_OR_EDIT_SPECIAL_REQUEST',
  LOADING: 'LOADING',
  LOADING_SPECIAL_REQUEST: 'LOADING_SPECIAL_REQUEST',
  ERROR: 'ERROR',
  DONE: 'DONE',
};

const ReconditioningSpecialRequestModal = ({
  handleClose,
  selectedBoxId,
  option,
  isInspection = false,
  readOnly = false,
  withoutButtons = false,
  productInstance: instance,
  organizationDetails,
  addSpecialRequestToProductInstanceInTheBox,
  productInstanceAddedToBox = false,
  handleSpecialRequestInInspection = () => {},
  setHighlightedProductInstanceId = () => {},
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [productInstance, setProductInstance] = useState(instance);
  const [step, setStep] = useState(
    option ? STEPS.ADD_OR_EDIT_SPECIAL_REQUEST : STEPS.INITIAL
  );
  const [notes, setNotes] = useState('');
  const [files, setFiles] = useState([]);
  const [oldSpecialRequest, setOldSpecialRequest] = useState({});
  const [allFilesUploaded, setAllFilesUploaded] = useState(true);
  const [fileErrorMessages, setFileErrorMessages] = useState('');
  const [error, setError] = useState('');

  const readOnlyFields = readOnly || isInspection;

  useEffect(() => setProductInstance(instance), [instance]);

  useEffect(() => {
    (async () => {
      try {
        if (option === SPECIAL_REQUEST_OPTIONS.EDIT_SPECIAL_REQUEST) {
          setStep(STEPS.LOADING);
          const { data } = await fetchThreadDetails(
            productInstance.specialRequestId
          );
          setOldSpecialRequest(data);
          setNotes(data.notes);
          setFiles(data.files || []);
          setStep(STEPS.ADD_OR_EDIT_SPECIAL_REQUEST);
          if (organizationDetails.type === ORGANIZATION_TYPE.RECON_CENTER) {
            handleAnalytics(FIREBASE_EVENTS.RC_SPECIAL_REQUEST_VIEW);
          }
        }
      } catch (e) {
        debug(`Couldn't fetch special request. Reason: ${e}`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  const handleOpenBox = () =>
    history.push(`${ROUTES.RECONDITIONING_BOX}/${selectedBoxId}`, {
      highlightedProductInstanceId: productInstance.id,
    });

  const handleAddProductInstanceToTheBox = async (
    hasSpecialRequest = false
  ) => {
    try {
      setStep(STEPS.LOADING);
      await postBoxProductInstances(selectedBoxId, [productInstance.id]);
      if (!hasSpecialRequest) {
        handleOpenBox();
        handleClose();
      }
      setStep(STEPS.ADD_OR_EDIT_SPECIAL_REQUEST);
    } catch (e) {
      const response = getMappedErrorMessage(e);

      setError(response);

      debug(`Couldn't add product instance to the box. Reason: ${e}`);
      setStep(STEPS.ERROR);
    }
  };

  const handleCreateOrEditSpecialRequest = async () => {
    try {
      setStep(STEPS.LOADING_SPECIAL_REQUEST);
      const data = {
        notes,
        files: files.map(({ id }) => id),
        isSpecialRequest: true,
      };
      if (option === SPECIAL_REQUEST_OPTIONS.ADD_SPECIAL_REQUEST || !option) {
        await addSpecialRequestToProductInstanceInTheBox(
          productInstance.id,
          data
        );
        handleAnalytics(FIREBASE_EVENTS.SPECIAL_REQUEST_ADDED);
      } else {
        await putThread(productInstance.specialRequestId, data);
      }

      setStep(STEPS.DONE);
    } catch (e) {
      const response = getMappedErrorMessage(e);
      setError(response);
      setStep(STEPS.ERROR);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return postProductInstanceFile(productInstance.id, formData);
  };

  const isDoneButtonDisabled = () => {
    if (readOnly) {
      return false;
    }

    if (option === SPECIAL_REQUEST_OPTIONS.EDIT_SPECIAL_REQUEST) {
      return hasStringFieldValueBeenChanged(oldSpecialRequest.notes, notes) ||
        isFilesArrayChanged(oldSpecialRequest.files, files)
        ? !notes.trim()
        : true;
    }

    return !notes.trim() || !allFilesUploaded;
  };

  const handleNotUpdatingSpecialRequest = () => {
    if (productInstanceAddedToBox) {
      handleClose();
      if (!readOnly) {
        setHighlightedProductInstanceId(productInstance?.id);
      }
    } else if (!readOnly) {
      handleAddProductInstanceToTheBox();
    } else {
      handleClose();
    }
  };

  const handleInspectionSpecialRequest = async (value) => {
    try {
      setStep(STEPS.LOADING);
      await handleSpecialRequestInInspection(value, productInstance);
      handleClose();
    } catch (e) {
      const res = getMappedErrorMessage(e);
      setError(res);
      setStep(STEPS.ERROR);
    }
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return (
          <NewModalStep
            title={t('modal.specialRequest')}
            headerClassName='text-txt-primary'
            leftButtonContent={t('button.no')}
            handleClickLeftButton={handleNotUpdatingSpecialRequest}
            handleClickRightButton={() =>
              productInstanceAddedToBox
                ? setStep(STEPS.ADD_OR_EDIT_SPECIAL_REQUEST)
                : handleAddProductInstanceToTheBox(
                    SPECIAL_REQUEST_OPTIONS.ADD_SPECIAL_REQUEST
                  )
            }
            rightButtonContent={t('button.yes')}
            handleCancel={handleNotUpdatingSpecialRequest}
            isCenteredVertically
            isCenteredHorizontally
            bodyClassName='d-flex flex-column pb-5 mb-5 text-center'
          >
            <WarningIcon
              width={130}
              height={130}
              className='warning-icon icon-danger mb-5'
            />
            <div className='text-txt-secondary font-size-md w-69'>
              {t(
                'modal.doYouHaveAnySpecialRequestsForReconditioningOfThisProduct'
              )}
            </div>
          </NewModalStep>
        );
      case STEPS.ADD_OR_EDIT_SPECIAL_REQUEST:
        return (
          <NewModalStep
            title={t('modal.specialRequest')}
            headerClassName='text-txt-primary'
            handleCancel={
              isInspection ? handleClose : handleNotUpdatingSpecialRequest
            }
            handleClickRightButton={
              readOnly
                ? handleClose
                : isInspection
                ? () => handleInspectionSpecialRequest(true)
                : handleCreateOrEditSpecialRequest
            }
            {...(!withoutButtons && {
              rightButtonContent: isInspection
                ? t('button.accept')
                : t('button.done'),

              leftButtonContent: isInspection ? t('button.decline') : '',
            })}
            handleClickLeftButton={() =>
              isInspection && handleInspectionSpecialRequest(false)
            }
            leftButtonDisabled={readOnly}
            rightButtonDisabled={
              isInspection ? readOnly : isDoneButtonDisabled()
            }
            bodyClassName='d-flex flex-column mx-5 p-5 text-txt-primary'
          >
            <div className='d-flex mx-5 flex-column'>
              <div className='font-weight-bold'>{productInstance.brand}</div>
              <div className='mt-1'>
                {formatDate(new Date(), DATE_FORMATS.DATE)}
              </div>
            </div>
            <div className='d-flex mx-5 mt-1'>
              <div className='mr-1 font-weight-bold'>
                {`${t('modal.productNumber')}:`}
              </div>
              {productInstance.code}
            </div>
            <div className='d-flex mx-5 mt-3 font-weight-bold'>
              {t('modal.requestDescription')}
            </div>
            {readOnlyFields ? (
              <div className='text-break px-5 py-2'>{notes}</div>
            ) : (
              <OutlinedField
                placeholder={t('components.notes')}
                value={notes}
                className='px-5'
                fieldType={FIELD_TYPE.TEXTAREA}
                handleClearInput={() => setNotes('')}
                handleChange={(e) => setNotes(e.target.value)}
                hasCharacterCounter
                required
                maxNumberOfCharacters={MAX_NUMBER_OF_CHARACTERS.TEXT_AREA}
              />
            )}
            <UploadFiles
              files={files}
              setFiles={setFiles}
              className='px-5 mt-2 mb-5'
              setAllFilesUploaded={setAllFilesUploaded}
              addErrorMessage={(msg) =>
                addFilesErrorMessage(setFileErrorMessages, msg)
              }
              resetErrorMessages={() => setFileErrorMessages('')}
              uploadFile={uploadFile}
              maxNumberOfFiles={MAX_NUMBER_OF_FILES_FOR_SPECIAL_REQUEST}
              readOnly={readOnlyFields}
            />
            {fileErrorMessages && (
              <ErrorAlert
                error={fileErrorMessages}
                setError={setFileErrorMessages}
                containerClassName='mb-2 w-50'
                className='w-100'
              />
            )}
          </NewModalStep>
        );

      case STEPS.ERROR:
        return (
          <NewModalStep
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column text-txt-primary pb-5 justify-content-center'
            rightButtonContent={t('button.cancel')}
            handleClickRightButton={handleClose}
          >
            <WarningIcon
              width={150}
              height={150}
              className='warning-icon icon-danger mb-5'
            />
            <div className='mb-5 px-3'>
              {t(error) || t('error.somethingWentWrong')}
            </div>
          </NewModalStep>
        );
      case STEPS.LOADING_SPECIAL_REQUEST:
        return (
          <NewLoading
            value={
              option === SPECIAL_REQUEST_OPTIONS.EDIT_SPECIAL_REQUEST
                ? t('components.editingRequest')
                : t('components.creatingRequest')
            }
          />
        );
      case STEPS.LOADING:
        return <NewLoading />;
      case STEPS.DONE:
        return (
          <NewModalStep
            rightButtonContent={t('button.done')}
            handleClickRightButton={() => {
              handleOpenBox();
              handleClose();
              setHighlightedProductInstanceId(productInstance?.id);
            }}
            handleCancel={handleClose}
          >
            <NewDone
              title={
                option === SPECIAL_REQUEST_OPTIONS.EDIT_SPECIAL_REQUEST
                  ? t('components.requestEdited')
                  : t('components.requestCreated')
              }
              height={96}
              width={96}
            />
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal isOpened className='d-flex flex-column justify-content-center p-0'>
      {renderStep()}
    </Modal>
  );
};

ReconditioningSpecialRequestModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedBoxId: PropTypes.string,
  option: PropTypes.string,
  readOnly: PropTypes.bool,
  isInspection: PropTypes.bool,
  productInstance: PropTypes.object.isRequired,
  organizationDetails: PropTypes.object,
  withoutButtons: PropTypes.bool,
  addSpecialRequestToProductInstanceInTheBox: PropTypes.func.isRequired,
  productInstanceAddedToBox: PropTypes.bool,
  handleSpecialRequestInInspection: PropTypes.func,
  setHighlightedProductInstanceId: PropTypes.func,
};

export default connect(
  ({ user }) => ({ organizationDetails: user.organization }),
  {
    addSpecialRequestToProductInstanceInTheBox,
  }
)(ReconditioningSpecialRequestModal);
