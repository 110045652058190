import React from 'react';

import PropTypes from 'prop-types';
import Img from 'react-image';

import { ReactComponent as RightArrow } from '../../assets/icon/ic-arrow-right-blue.svg';
import { Image } from '../Shared';
import { getExternalProductProviderLogo } from '../../utils/product';

const IdemCatalogueRow = ({
  name,
  number,
  brand,
  image,
  handleClick,
  dataProviderName,
}) => {
  return (
    <tr
      className='idem-catalogue-product-row cursor-pointer'
      onClick={handleClick}
    >
      <td>
        <Image
          src={image}
          className='product-image image-object-fit-cover ml-3'
          width={60}
          height={60}
        />
      </td>
      <td className='pl-2'>
        <span className='font-weight-bolder'>{name}</span>
      </td>
      <td />
      <td className='pl-2'>{number}</td>
      <td />
      <td className='pl-2'>{brand}</td>
      <td />
      <td className='pl-2'>
        <Img
          src={getExternalProductProviderLogo(dataProviderName)}
          width={70}
          height={17}
        />
      </td>
      <td>
        <RightArrow className='icon-tertiary' width={24} height={24} />
      </td>
    </tr>
  );
};

IdemCatalogueRow.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  image: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  dataProviderName: PropTypes.string,
};

export default IdemCatalogueRow;
