import cookies from 'js-cookie';
import { COOKIE_EXPIRE_DAYS, IDEM_COOKIES, USER_COOKIE } from '../constants';

export const getUserConsent = () => {
  const consent = cookies.get(USER_COOKIE);
  return consent && JSON.parse(consent);
};

export const setUserConsent = (options) => {
  cookies.set(USER_COOKIE, options, {
    expires: COOKIE_EXPIRE_DAYS,
  });
};

export const acceptCookies = () => {
  cookies.set(
    USER_COOKIE,
    { analytic: true, preferences: true },
    {
      expires: COOKIE_EXPIRE_DAYS,
    }
  );
};

export const rejectCookies = () => {
  cookies.set(
    USER_COOKIE,
    { analytic: false, preferences: false },
    {
      expires: COOKIE_EXPIRE_DAYS,
    }
  );
  IDEM_COOKIES.map((cookie) => cookies.remove(cookie));
};
