import dayjs from 'dayjs';
import { endOfDay, startOfDay } from 'date-fns';

import { DATE_FORMATS } from '../constants';

export const formatDate = (date, format = DATE_FORMATS.TIMESTAMP) => {
  if (!date) {
    return null;
  }
  return dayjs.unix(date / 1000).format(format);
};

export const getDatesRangeForFiltering = (start, end) => {
  let endDate;
  const startDate = start ? startOfDay(start).getTime() : null;

  if (start && !end) {
    // start and end date are same (equivalent to selected one date)
    endDate = endOfDay(start).getTime();
  } else {
    endDate = end ? endOfDay(end).getTime() : null;
  }

  return { startDate, endDate };
};
