import React from 'react';

import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { formatDate } from '../../utils/date';
import { DATE_FORMATS } from '../../constants';

const OrderedBoxInfoCard = ({ box }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column align-items-center font-size-sm my-1'>
      <div className='d-flex flex-column text-center'>
        <span className='font-weight-bolder'>
          {t('components.requestSentOn')}
        </span>
        <span className='text-break-all'>
          {formatDate(box.createdAt, DATE_FORMATS.DATE)}
        </span>
      </div>
      <div className='line bottom-line border-bd-color my-1' />
      <div className='text-center w-85'>
        <span className='text-break'>
          <Trans i18nKey='components.whenTheBoxArrivesClickOnActivateToStartUsingTheBox' />
        </span>
      </div>
    </div>
  );
};

OrderedBoxInfoCard.propTypes = {
  box: PropTypes.object.isRequired,
};

export default OrderedBoxInfoCard;
