import axios from './axiosClient';
import { DB_API_URL } from '../config';

export const putThread = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/UserThread/${id}`, data);
};

export const deleteThread = async (id) => {
  return axios.delete(`${DB_API_URL}/api/v1/UserThread/${id}`);
};

export const fetchThreadDetails = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/UserThread/${id}`);
};

export const createConclusion = async (id, data) => {
  return axios.post(
    `${DB_API_URL}/api/v1/UserThread/CounterfeitRisk/${id}/Conclusion`,
    data
  );
};

export const updateConclusion = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/UserThread/Conclusion/${id}`, data);
};

export const fetchRequestInternalDiscussionThreads = async (params) => {
  return axios.get(
    `${DB_API_URL}/api/v1/UserThread/CounterfeitRisk/${params.id}`,
    {
      params,
    }
  );
};

export const postAuthenticationRequestComment = async (id, data) => {
  return axios.post(
    `${DB_API_URL}/api/v1/UserThread/CounterfeitRisk/${id}`,
    data
  );
};
