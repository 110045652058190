import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as DeleteItemIcon } from '../../assets/icon/ic_delete_item.svg';

const InputDeleteIcon = ({
  negativeMargin = 4,
  height = 20,
  width = 20,
  handleClick,
}) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ml-n${negativeMargin}`}
    >
      <DeleteItemIcon
        className='delete-item-icon cursor-pointer'
        height={height}
        width={width}
        onClick={handleClick}
      />
    </div>
  );
};

InputDeleteIcon.propTypes = {
  negativeMargin: PropTypes.oneOf([1, 2, 3, 4, 5]),
  height: PropTypes.number,
  width: PropTypes.number,
  handleClick: PropTypes.func,
};

export default InputDeleteIcon;
