import React from 'react';

import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import SelectDropdown from './SelectDropdown';

const ControlledSelectDropdown = ({
  control,
  name,
  getSelectedValue = (value) => value,
  dropdownOptions,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, formState: { errors } }) => (
      <SelectDropdown
        dropdownOptions={dropdownOptions.map((option) => ({
          ...option,
          handleClick: () => onChange(option.value),
          isSelected: value && value === option.value,
        }))}
        selectedValue={getSelectedValue(value)}
        error={errors[name]?.message}
        {...props}
      />
    )}
  />
);

ControlledSelectDropdown.propTypes = {
  control: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  getSelectedValue: PropTypes.func,
  dropdownOptions: PropTypes.array,
};

export default ControlledSelectDropdown;
