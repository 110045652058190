import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { NewModalStep } from '.';
import { scanTag } from '../../../redux/actions/readerActions';
import WebCamera from '../../Shared/WebCamera';
import NewLoading from './NewLoading';
import { READER_STATES } from '../../../constants';

const STEPS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
};

const ScanWithWebCamera = ({
  handleClose,
  scanTag,
  isFetching,
  readerState,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEPS.INITIAL);

  const scanResult = (result) => {
    const { format, text } = result;
    scanTag(text, format);
    setStep(STEPS.LOADING);
  };

  useEffect(() => {
    if (
      readerState === READER_STATES.GLOBAL &&
      isFetching !== null &&
      !isFetching
    ) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const renderStep = () => {
    if (step === STEPS.INITIAL) {
      return (
        <NewModalStep
          title={t('modal.scanTheCode')}
          subtitle={t(
            'modal.makeSureYourCodeIsWithinTheWhiteBordersAndClearlyVisible'
          )}
          handleCancel={handleClose}
          bodyClassName='border-top'
        >
          <WebCamera handleScan={scanResult} />
        </NewModalStep>
      );
    }
    return <NewLoading value={t('modal.fetchingDetails')} />;
  };

  return renderStep();
};

ScanWithWebCamera.propTypes = {
  handleClose: PropTypes.func.isRequired,
  scanTag: PropTypes.func,
  isFetching: PropTypes.bool,
};

export default connect(
  ({ tag, reader }) => ({
    isFetching: tag.isFetching,
    readerState: reader.state,
  }),
  { scanTag }
)(ScanWithWebCamera);
