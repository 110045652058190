import _get from 'lodash.get';
import {
  ERROR_MESSAGES_BACKEND,
  ERROR_MESSAGES,
  ERROR_MESSAGES_BACKEND_REGEXES,
} from '../constants';

export const getErrorMessage = (error) => {
  if (!error || !error.response || !error.response.data) {
    return ERROR_MESSAGES.SOMETHING_WENT_WRONG;
  }

  const errorMessage =
    typeof error.response.data === 'string'
      ? error.response.data
      : error.response.data.error;

  switch (errorMessage) {
    case ERROR_MESSAGES_BACKEND.USER_NOT_MEMBER_OF_ANY_GROUP:
      return ERROR_MESSAGES.USER_NOT_MEMBER_OF_ANY_GROUP;
    default:
      return ERROR_MESSAGES.SOMETHING_WENT_WRONG;
  }
};

export const getMappedErrorMessage = (error, translate = null) => {
  const response =
    _get(error, 'response.data.value.errors.Name', null) ||
    _get(error, 'response.data.errors.Text', null) ||
    _get(error, 'response.data.errors.Name', null) ||
    _get(error, 'response.data.errors.Title', null) ||
    _get(error, 'response.data.errors.Description', null) ||
    _get(error, 'response.data.errors.TargetEmail', null) ||
    _get(error, 'response.data.message', null) ||
    _get(error, 'response.data.title', null) ||
    _get(error, 'response.data.error', null) ||
    _get(error, 'response.data', null) ||
    _get(error, 'response', null);

  const errorMessage =
    response &&
    (Object.entries(ERROR_MESSAGES_BACKEND).find(
      (backendMessage) => backendMessage[1] === `${response}`
    ) ||
      Object.entries(ERROR_MESSAGES_BACKEND_REGEXES).find((backendMessage) =>
        `${response}`.match(backendMessage[1])
      ));

  if (errorMessage) {
    return translate
      ? translate(ERROR_MESSAGES[errorMessage[0]])
      : ERROR_MESSAGES[errorMessage[0]];
  }
  return translate
    ? translate(ERROR_MESSAGES.SOMETHING_WENT_WRONG)
    : ERROR_MESSAGES.SOMETHING_WENT_WRONG;
};
