import React from 'react';

import PropTypes from 'prop-types';

const PageHeader = ({
  additionalRow,
  children,
  className = 'px-3 pb-2',
  rowClassName = '',
}) => (
  <div
    className={`details-page-header details-header pt-3 box-shadow d-flex flex-column justify-content-between overflow-y-auto ${className}`}
  >
    <div className={`details height-fit-content d-flex w-100 ${rowClassName}`}>
      {children}
    </div>
    {!!additionalRow && (
      <div
        className={`details height-fit-content d-flex w-100 ${rowClassName}`}
      >
        {additionalRow}
      </div>
    )}
  </div>
);

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  additionalRow: PropTypes.any,
  rowClassName: PropTypes.string,
};

export default PageHeader;
