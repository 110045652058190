import i18next from 'i18next';
import lodash from 'lodash';
import * as Yup from 'yup';

import {
  BOX_CAPACITY_PERCENTAGE_THRESHOLD,
  BOX_CONSTANTS,
  CUSTOM_BOX_PROVIDER,
  EXTERNAL_PROVIDERS_LOGOS,
  THEME_COLORS_NAMES,
} from '../constants';
import { isDefined, roundUpTo, roundValueUpTo } from './shared';
import { BOX_CARD_SIZES } from '../components/Shared/constants';
import { removeTrailingSlashes } from './routes';
import {
  BOX_STATUSES,
  CUSTOMER_ORDER_STATUSES,
} from '../containers/ReconditioningContainers/constants';

export const FALLBACK_MESSAGE_NO_DATA = 'modal.noData';

export const checkIsBoxInOrder = (box) => !!box?.orderNumber;

export const getColorOfBoxFlag = (box) =>
  box.status === BOX_STATUSES.ORDERED || checkIsBoxInOrder(box)
    ? 'transparent'
    : box.percentageOfFilledCapacity < BOX_CAPACITY_PERCENTAGE_THRESHOLD
    ? THEME_COLORS_NAMES.WARNING
    : THEME_COLORS_NAMES.SUCCESS;

export const getProviderLogo = (boxProvider) =>
  boxProvider ? EXTERNAL_PROVIDERS_LOGOS[boxProvider.toUpperCase()] : undefined;

export const displayBoxDimension = ({ length, width, height }) =>
  length && width && height
    ? `${height}x${width}x${length} (${BOX_CONSTANTS.DIMENSION_UNIT})`
    : i18next.t(FALLBACK_MESSAGE_NO_DATA);

export const displayMaxWeight = ({ maxWeight }) => {
  const weight = maxWeight === null || maxWeight === undefined ? 0 : maxWeight;

  return `${Number(roundValueUpTo(weight, 2))} ${BOX_CONSTANTS.WEIGHT_UNIT}`;
};

export const displayCurrentWeight = ({ currentWeight }, displayUnit = true) => {
  const weight =
    currentWeight === null || currentWeight === undefined ? 0 : currentWeight;

  return displayUnit
    ? `${roundUpTo(weight, 3)} ${BOX_CONSTANTS.WEIGHT_UNIT}`
    : roundUpTo(weight, 3);
};

export const displayBoxWeight = ({ currentWeight, maxWeight, status }) =>
  status === BOX_STATUSES.ORDERED
    ? displayMaxWeight({ maxWeight })
    : `${displayCurrentWeight({ currentWeight }, false)}/${displayMaxWeight({
        maxWeight,
      })}`;

export const displayPrice = ({ price }) =>
  price !== null && price !== undefined
    ? `${price.toFixed(2)} ${BOX_CONSTANTS.PRICE_UNIT.toUpperCase()}`
    : i18next.t(FALLBACK_MESSAGE_NO_DATA);

export const displayBoxProvider = ({ boxProvider }) =>
  boxProvider
    ? boxProvider === CUSTOM_BOX_PROVIDER
      ? lodash.upperFirst(lodash.lowerCase(CUSTOM_BOX_PROVIDER))
      : boxProvider
    : i18next.t(FALLBACK_MESSAGE_NO_DATA);

export const displayArticleNumber = ({ articleNumber }) =>
  articleNumber || i18next.t(FALLBACK_MESSAGE_NO_DATA);

export const displayBoxCode = ({ code, articleNumber }) =>
  code || articleNumber || i18next.t(FALLBACK_MESSAGE_NO_DATA);

export const getGridContainerSizes = (size, isSidebarOpened) => {
  switch (size) {
    case BOX_CARD_SIZES.WINDOW_SIZE:
      return {
        xxxl: 2,
        xl: 3,
        lg: 4,
        md: isSidebarOpened ? 6 : 4,
        xs: 6,
      };
    case BOX_CARD_SIZES.TAB_SIZE:
      return {
        xxxl: 2,
        lg: 4,
        md: isSidebarOpened ? 6 : 12,
        xs: 12,
      };
    default:
      return {};
  }
};

export const getBoxOrderRoute = (routeWithBox) => {
  // routeWithBoxShould be .../order/:orderId/box/:boxId

  const cleanedPath = removeTrailingSlashes(routeWithBox);

  // Remove last two slashes (box and boxId)
  const splitPath = cleanedPath.split('/');
  const finalPath = `${splitPath.slice(0, -2).join('/')}/`;

  return finalPath;
};

export const isSelectAllChecked = (options) =>
  !Object.values(options).includes(false);

export const getDefaultOptionsValues = (
  array,
  boxDetails = {},
  selectAllLabel
) => {
  const defaultOptions = array.reduce(
    (acc, option) => ({
      ...acc,
      [option]: isDefined(boxDetails[option]) ? boxDetails[option] : true,
    }),
    {}
  );

  const selectAllActive = isSelectAllChecked(defaultOptions);

  return { ...defaultOptions, [selectAllLabel]: selectAllActive };
};

export const getConditionallyRequiredBooleanSchema = (options) =>
  Yup.boolean().when(options, {
    is: (...args) => args.every((value) => !value),
    then: (schema) =>
      schema.oneOf([true], 'error.youNeedToSelectAtLeastOneOption'),
    otherwise: (schema) => schema,
  });

export const isResetBoxOptionAvailable = (boxStatus) =>
  boxStatus === CUSTOMER_ORDER_STATUSES.SHIPPING_BACK.KEY;

export const getButtonColor = (boxStatus) =>
  boxStatus === BOX_STATUSES.ORDERED || isResetBoxOptionAvailable(boxStatus)
    ? THEME_COLORS_NAMES.BUTTON_SECONDARY
    : THEME_COLORS_NAMES.SECONDARY;

export const getButtonLabel = (boxStatus) => {
  if (boxStatus === BOX_STATUSES.ORDERED) {
    return 'button.activate';
  }

  if (isResetBoxOptionAvailable(boxStatus)) {
    return 'modal.reset';
  }

  // if translation is changed search for it since it is used for some conditional rendering of button
  return 'button.view';
};
