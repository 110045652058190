import { BARCODE_SCANNED } from 'react-usb-barcode-scanner';
import { scanTag } from '../actions/readerActions';
import { isUrlFormat } from '../../utils/validations';
import { openUrlInNewTab } from '../../utils/shared';

const barcodeScannerMiddleware = (store) => (next) => (action) => {
  if (action.type === BARCODE_SCANNED) {
    const barcode = action.payload.data.trim();
    if (barcode.length > 3) {
      if (isUrlFormat(barcode)) {
        openUrlInNewTab(barcode);
        return;
      }
      store.dispatch(scanTag(barcode));
    }
  }
  return next(action);
};

export default barcodeScannerMiddleware;
