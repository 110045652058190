import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  FormFeedback,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DropdownItem from './DropdownItem';
import TriangleUpIcon from './TriangleUp';
import TriangleDownIcon from './TriangleDownIcon';
import { THEME_COLORS_NAMES } from '../../constants';

const SelectDropdown = ({
  className = '',
  toggleClassName = '',
  dropdownOptions = [],
  toggle,
  selectedValue = '',
  direction = 'down',
  roundedDropdown = false,
  roundedToggle = false,
  dropdownHeight = 'sm',
  color = THEME_COLORS_NAMES.WHITE,
  disabled = false,
  caret = true,
  withFloatingLabel = false,
  required = false,
  containerClassName = 'd-flex w-100',
  error,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const hasDropdown = dropdownOptions.length;
  const placeholder = `${toggle || t('sharedComponents.select')}${
    required ? '*' : ''
  }`;
  const caretColor = disabled
    ? THEME_COLORS_NAMES.TEXT_DISABLED
    : error
    ? THEME_COLORS_NAMES.DANGER
    : color === THEME_COLORS_NAMES.WHITE
    ? THEME_COLORS_NAMES.TEXT_SECONDARY
    : THEME_COLORS_NAMES.WHITE;
  const toggleTextColor =
    color === THEME_COLORS_NAMES.WHITE
      ? THEME_COLORS_NAMES.TEXT_SECONDARY
      : THEME_COLORS_NAMES.WHITE;

  return (
    <div className={containerClassName}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        direction={direction}
        className={cs(`select-dropdown ${className}`, {
          'is-invalid': !!error,
        })}
      >
        <DropdownToggle
          className={cs(
            `select-dropdown-toggle px-1 ${className} ${toggleClassName}`,
            {
              'rounded-dropdown': roundedDropdown,
              'rounded-toggle': roundedToggle,
            }
          )}
          color={color}
          disabled={disabled}
        >
          {withFloatingLabel && (
            <span
              className={cs(`floating-field-label text-${toggleTextColor}`, {
                floating: !!selectedValue,
                'bg-transparent': !selectedValue,
              })}
            >
              {placeholder}
            </span>
          )}

          <span className='pl-2'>
            {selectedValue || (!withFloatingLabel ? placeholder : '')}
          </span>
          {caret &&
            (dropdownOpen ? (
              <TriangleUpIcon color={caretColor} />
            ) : (
              <TriangleDownIcon color={caretColor} />
            ))}
        </DropdownToggle>
        {!!hasDropdown && (
          <DropdownMenu
            className={cs('', {
              'rounded-dropdown': roundedDropdown,
            })}
            flip={roundedDropdown}
            positionFixed={roundedDropdown}
          >
            <div className={`select-dropdown-menu-wrapper ${dropdownHeight}`}>
              {dropdownOptions
                .filter(({ hidden }) => !hidden)
                .map(
                  ({
                    value,
                    label,
                    header,
                    handleClick,
                    suffix,
                    isSelected,
                    className,
                    disabled,
                  }) => (
                    <DropdownItem
                      header={header}
                      handleClick={handleClick}
                      label={label}
                      suffix={suffix}
                      disabled={disabled}
                      className={cs(
                        `select-dropdown-item px-3 ${className || ''}`,
                        {
                          selected: !!isSelected,
                        }
                      )}
                      id={value}
                    />
                  )
                )}
            </div>
          </DropdownMenu>
        )}
      </Dropdown>
      {!!error && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
};

SelectDropdown.propTypes = {
  className: PropTypes.string,
  selectedValue: PropTypes.string,
  dropdownOptions: PropTypes.array,
  disabled: PropTypes.bool,
  toggle: PropTypes.any,
  direction: PropTypes.string,
  caret: PropTypes.bool,
  toggleClassName: PropTypes.string,
  roundedDropdown: PropTypes.bool,
  dropdownHeight: PropTypes.string,
  color: PropTypes.string,
  withFloatingLabel: PropTypes.bool,
  required: PropTypes.bool,
  containerClassName: PropTypes.string,
  error: PropTypes.string,
  roundedToggle: PropTypes.bool,
};

export default SelectDropdown;
