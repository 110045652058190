import React from 'react';

import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';

const CustomCheckbox = ({
  id,
  containerId,
  className,
  label,
  handleChange,
  isChecked,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    handleChange(e, !isChecked);
  };

  return (
    <div
      id={containerId}
      className={`custom-checkbox-container double-click-disabled ${className}`}
      onClick={handleClick}
    >
      <CustomInput
        id={id}
        type='checkbox'
        checked={isChecked}
        onClick={handleClick}
        label={label}
      />
    </div>
  );
};

CustomCheckbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  isChecked: PropTypes.bool,
  containerId: PropTypes.string,
};

export default CustomCheckbox;
