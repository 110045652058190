import { useEffect } from 'react';

const useCursorWithSpinner = () => {
  const cursorClassName = 'cursor-progress';

  useEffect(() => {
    return () => {
      document.body.classList.remove(cursorClassName);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIsSpinnerNextToCursor = (isSpinnerNextToCursor) => {
    if (isSpinnerNextToCursor) {
      document.body.classList.add(cursorClassName);
    } else {
      document.body.classList.remove(cursorClassName);
    }
  };

  return { setIsSpinnerNextToCursor };
};

export default useCursorWithSpinner;
