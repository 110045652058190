import React from 'react';

import PropTypes from 'prop-types';

import Img from 'react-image';
import { displayBoxProvider, getProviderLogo } from '../../utils/box';
import { CUSTOM_BOX_PROVIDER } from '../../constants';

const BoxProvider = ({ box, imageHeight = 28, imageWidth = 74 }) => {
  const providerLogo = getProviderLogo(box.boxProvider);

  return box.boxProvider === CUSTOM_BOX_PROVIDER ? (
    <div className='pt-1 font-size-sm'>
      {displayBoxProvider(box).toUpperCase()}
    </div>
  ) : (
    <Img src={providerLogo} height={imageHeight} width={imageWidth} />
  );
};

BoxProvider.propTypes = {
  box: PropTypes.object.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
};

export default BoxProvider;
