import React from 'react';
import {
  Card,
  CardBody,
  CardImgOverlay,
  Col,
  Container,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GroupItemBadge from '../GroupComponents/GroupItemBadge';
import { ReactComponent as GroupsIcon } from '../../assets/icon/ic_menu_groups_black.svg';

const GroupItem = ({
  id,
  name,
  admin,
  adminsCount,
  membersCount,
  badgeText,
  badgeColor,
  onClick = () => {},
  isHighlighted = false,
  isSelected = false,
  hoverable = true,
  isOrganisation = false,
  className = '',
}) => {
  const { t } = useTranslation();

  return (
    <Card
      className={`cursor-pointer h-100 ${className} ${
        hoverable ? 'hover-active' : ''
      } ${isHighlighted ? 'highlighted' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick(id)}
    >
      {badgeText && (
        <CardImgOverlay className='p-0'>
          <div className='d-flex justify-content-end'>
            <GroupItemBadge text={badgeText} color={badgeColor} />
          </div>
        </CardImgOverlay>
      )}
      <CardBody>
        <Container className='h-100 px-1 pt-2'>
          <Row className='h-100'>
            <Col sm='auto' className='py-1 align-self-center pr-5'>
              <Row>
                <Col sm='auto' className='align-self-center pr-2'>
                  <div className='subtitle mb-0 pb-1'>
                    {isOrganisation
                      ? t('sharedComponents.organizationName')
                      : t('sharedComponents.groupName')}
                  </div>
                  <div className='main-text mb-0'>{name}</div>
                </Col>
              </Row>
            </Col>
            <Col sm='auto' className='align-self-center'>
              <Row>
                <Col sm='auto' className='align-self-center pr-3 py-1'>
                  <div className='subtitle mb-0 pb-1 pr-3'>{`${
                    isOrganisation
                      ? t('sharedComponents.organizationAdmin')
                      : t('sharedComponents.groupAdmin')
                  } (${adminsCount})`}</div>
                  <div className='main-text mb-0'>{admin}</div>
                </Col>
                {membersCount ? (
                  <Col sm='auto' className='align-self-center py-1'>
                    <div className='subtitle mb-0 pb-1'>
                      {isOrganisation
                        ? t('sharedComponents.organizationMembers')
                        : badgeText
                        ? t('sharedComponents.groupMembers')
                        : t('sharedComponents.members')}
                    </div>
                    <div className='d-flex align-items-center'>
                      <GroupsIcon
                        className='icon-secondary ml-1'
                        height={22}
                        width={22}
                      />
                      <div className='main-text mb-0'>{membersCount}</div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

GroupItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  admin: PropTypes.string.isRequired,
  adminsCount: PropTypes.number.isRequired,
  membersCount: PropTypes.number,
  badgeText: PropTypes.string,
  badgeColor: PropTypes.oneOf(['master', 'invited', 'pending']),
  onClick: PropTypes.func,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  hoverable: PropTypes.bool,
  isOrganisation: PropTypes.bool,
  className: PropTypes.string,
};

export default GroupItem;
