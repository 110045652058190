import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormFeedback, Input, Label } from 'reactstrap';
import Img from 'react-image';
import cs from 'classnames';

import { FIELD_TYPE, MAX_NUMBER_OF_CHARACTERS } from '../../constants';
import { deleteInput } from '../../assets/icon';

const OutlinedField = ({
  key,
  validateWhenEmpty = false, // old forms require change for validation
  noLabel = false,
  className = '',
  placeholder,
  handleChange = () => {},
  handleClearInput,
  value,
  fieldType = FIELD_TYPE.TEXT,
  valid = () => true,
  feedback = 'Invalid',
  maxNumberOfCharacters = MAX_NUMBER_OF_CHARACTERS.INPUT,
  hasCharacterCounter = false,
  description,
  inputClassName = '',
  onBlur = () => {},
  autoFocus = false,
  name,
  required = false,
  autoComplete = 'on',
  readOnly = false,
}) => {
  const [inputDirty, setInputDirty] = useState(false);

  const handleChangeField = (e) => {
    handleChange(
      maxNumberOfCharacters && e.target.value.length > maxNumberOfCharacters
        ? {
            ...e,
            target: {
              ...e.target,
              value: e.target.value.substring(0, maxNumberOfCharacters),
            },
          }
        : e
    );
    setInputDirty(true);
  };

  return (
    <div key={key} className={className}>
      <div className={`${noLabel ? 'mt-0' : 'mt-3'} outline-field-container`}>
        <Input
          autoFocus={autoFocus}
          className={cs(`outline-field-input ${inputClassName}`, {
            'min-textarea-height': fieldType === FIELD_TYPE.TEXTAREA,
            sm:
              fieldType === FIELD_TYPE.TEXTAREA &&
              maxNumberOfCharacters <= MAX_NUMBER_OF_CHARACTERS.DESCRIPTION,
            'pointer-events-none': readOnly,
          })}
          bsSize='md'
          placeholder={noLabel ? placeholder : ' '}
          onChange={handleChangeField}
          type={fieldType}
          value={value}
          invalid={(validateWhenEmpty || inputDirty) && !valid(value)}
          onBlur={onBlur}
          required={required}
          name={name}
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
        {!noLabel && (
          <Label
            className={cs('floating-field-label', { floating: !!value })}
          >{`${placeholder}${required ? '*' : ''}`}</Label>
        )}
        {feedback && <FormFeedback>{feedback}</FormFeedback>}
        {!!value && handleClearInput && !readOnly && (
          <span>
            <Img
              className='delete-input cursor-pointer'
              src={deleteInput}
              onClick={handleClearInput}
            />
          </span>
        )}
      </div>
      {(hasCharacterCounter || description) && (
        <div
          className={cs('d-flex outline-field-counter', {
            'justify-content-between': hasCharacterCounter && description,
            'justify-content-end': hasCharacterCounter,
          })}
        >
          {description && (
            <span className='pl-3 input-description'>{description}</span>
          )}
          {hasCharacterCounter && (
            <span>{`${value?.length || 0}/${maxNumberOfCharacters}`}</span>
          )}
        </div>
      )}
    </div>
  );
};

OutlinedField.propTypes = {
  key: PropTypes.string,
  noLabel: PropTypes.bool,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  handleClearInput: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fieldType: PropTypes.string,
  valid: PropTypes.func,
  feedback: PropTypes.string,
  maxNumberOfCharacters: PropTypes.number,
  hasCharacterCounter: PropTypes.bool,
  description: PropTypes.string,
  inputClassName: PropTypes.string,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  validateWhenEmpty: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default OutlinedField;
