import decode from 'jwt-decode';
import {
  ADMIN_RIGHTS_OPTION,
  REMOVE_OPTION,
  LEAVE_OPTIONS,
} from '../constants';

export const isUserAdmin = (id, group) => {
  try {
    if (group.organizationId) {
      return !!group.admins.find((admin) => admin.user_id === id);
    }
    return !!group.admins.find((admin) => admin === id);
  } catch (e) {
    // Swallow error
    return false;
  }
};

export const getCurrentUserId = () => {
  try {
    const { sub } = decode(localStorage.getItem('token'));
    return sub;
  } catch (e) {
    // Swallow error
    return null;
  }
};

export const isUserOrganizationAdmin = (id = getCurrentUserId, admins) => {
  try {
    return admins.some((admin) => admin.id === id);
  } catch (e) {
    return false;
  }
};

export const getUserDisplayName = (user) => {
  if (user) {
    if (user.given_name || user.family_name) {
      return `${user.given_name || ''} ${user.family_name || ''}`;
    }
    return user.email;
  }
  return '';
};

export const getAdminRightsParameters = (option, isOrganisation) => {
  switch (option) {
    case ADMIN_RIGHTS_OPTION.ASSIGN:
      return {
        title: 'modal.assignAdminRights',
        subtitleInitial: isOrganisation
          ? 'modal.selectedUserWillBeGivenAdminRightsToThisOrganizationOnceAssigned'
          : 'modal.selectedUserWillBeGivenAdminRightsToThisGroupOnceAssigned',
        subtitleLoading: 'modal.assigningNewAdmin',
        subtitleDone: isOrganisation
          ? 'modal.isNowAdminOfThisOrganization'
          : 'modal.isNowAdminOfThisGroup',
        proceedButtonText: 'button.assign',
        selectedMember: 'modal.selectedMember',
      };
    case ADMIN_RIGHTS_OPTION.REVOKE:
      return {
        title: 'modal.revokeAdminRights',
        subtitleInitial: isOrganisation
          ? 'modal.thisAdminMemberWillLosePermissionsToEditThisOrganization'
          : 'modal.thisAdminMemberWillLosePermissionsToEditThisGroup',
        subtitleLoading: 'modal.revokingRights',
        subtitleDone: isOrganisation
          ? 'modal.isNowARegularMemberOfThisOrganization'
          : 'modal.isNowARegularMemberOfThisGroup',
        proceedButtonText: 'button.revoke',
        selectedMember: 'modal.selectedAdminMember',
      };
    default:
      return {};
  }
};

export const getRemoveParameters = (option, isOrganisation) => {
  switch (option) {
    case REMOVE_OPTION.REMOVE_ADMIN:
      return {
        title: 'modal.removeAdminMember',
        subtitleInitial: isOrganisation
          ? 'modal.areYouSureYouWantToRemoveTheSelectedAdminMemberFromThisOrganization'
          : 'modal.areYouSureYouWantToRemoveTheSelectedAdminMemberFromThisGroup',
        subtitleLoading: 'modal.removingAdmin',
        selectedMember: 'modal.selectedAdminMember',
      };
    case REMOVE_OPTION.REMOVE_MEMBER:
      return {
        title: 'modal.removeMember',
        subtitleInitial: isOrganisation
          ? 'modal.areYouSureYouWantToRemoveTheSelectedMemberFromThisOrganization'
          : 'modal.areYouSureYouWantToRemoveTheSelectedMemberFromThisGroup',
        subtitleLoading: 'modal.removingMember',
        selectedMember: 'modal.selectedMember',
      };
    default:
      return {};
  }
};

export const getLeaveParameters = (option, isOrganisation) => {
  switch (option) {
    case LEAVE_OPTIONS.LEAVE_GROUP:
      return {
        title: 'modal.leaveGroup',
        subtitleInitial: isOrganisation
          ? 'modal.areYouSureYouWantToLeaveThisOrganization'
          : 'modal.areYouSureYouWantToLeaveThisGroup',
        subtitleLoading: 'modal.leaving',
        subtitleDone: isOrganisation
          ? 'modal.youHaveLeftTheOrganization'
          : 'modal.youHaveLeftTheGroup',
      };
    case LEAVE_OPTIONS.LEAVE_ADMIN_ROLE:
      return {
        title: 'modal.leaveAdminRole',
        subtitleInitial: isOrganisation
          ? 'modal.youWillBecomeARegularOrganizationMember'
          : 'modal.youWillBecomeARegularGroupMember',
        subtitleLoading: 'modal.leavingAdminRole',
        subtitleDone: 'modal.youAreNowARegularMemberIn',
      };
    default:
      return {};
  }
};
