import { useState } from 'react';

const useDateRange = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDatesChanges = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleRemoveDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return { startDate, endDate, handleDatesChanges, handleRemoveDates };
};

export default useDateRange;
