import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { NOTIFICATION_URL } from '../config';
import { MAX_NOTIFICATION_NUMBER, NOTIFICATION_SETTINGS } from '../constants';
import { getPermissions } from './user';

export const createConnection = () => {
  const token = localStorage.getItem('token');
  return new HubConnectionBuilder()
    .withUrl(NOTIFICATION_URL, {
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.None)
    .build();
};

export const getNotificationNumber = (count) => {
  return count > MAX_NOTIFICATION_NUMBER
    ? `${MAX_NOTIFICATION_NUMBER}+`
    : count;
};

export const getGeneralNotificationSetting = (settings = {}) => {
  let allNotificationsOn = false;
  const permissions = getPermissions();

  Object.entries(NOTIFICATION_SETTINGS).forEach(([, VALUE]) => {
    if (permissions.includes(VALUE.PERMISSION) && settings[VALUE.KEY]) {
      allNotificationsOn = true;
    }
  });

  return allNotificationsOn;
};
