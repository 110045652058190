export const isLocalStorageValueDefined = (value) =>
  value !== 'undefined' && value !== null;

export const getLocalStorageItem = (key, defaultValue) => {
  const value = localStorage.getItem(key);
  return defaultValue
    ? isLocalStorageValueDefined(value)
      ? value
      : defaultValue
    : value;
};
