import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.auth, action) {
  const { type } = action;

  switch (type) {
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
