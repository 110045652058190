import decode from 'jwt-decode';

import {
  checkProductNumberAvailability,
  deleteImageById,
} from '../client/product';
import {
  DATA_PROVIDERS,
  EXTERNAL_PROVIDERS_LOGOS,
  SUPPORT_MESSAGE_STATUSES,
  URLS,
} from '../constants';
import { isDefined, openUrlInNewTab } from './shared';
import { noImage } from '../assets/img';

export const getMessageStatus = (statusCode) => {
  const messageStatus = SUPPORT_MESSAGE_STATUSES.find(
    ({ code }) => code === statusCode
  );
  if (!messageStatus) {
    return 'components.none';
  }
  return messageStatus.title;
};

export const getCurrentUserIdUUID = () => {
  try {
    const decodedToken = decode(localStorage.getItem('token'));
    // TODO Refactor this
    return decodedToken['https://secure.idemtools.com/user_metadata'].uuid;
  } catch (e) {
    // Swallow error
    return null;
  }
};

export const removeImagesById = async (images) => {
  try {
    await Promise.all([images.map((image) => deleteImageById(image))]);
  } catch (e) {
    // Swallow error
  }
};

export const getMegabytesFromBytes = (size) => {
  return (size / 1000000).toFixed();
};

export const removeWhitespace = (value) => {
  return value.replace(/\s+/g, ' ').trim();
};

export const isProductNumberAvailable = async (groupId, productNumber) => {
  const {
    data: productNumberAvailability,
  } = await checkProductNumberAvailability({
    groupId,
    code: productNumber,
  });

  return productNumberAvailability.isAvailable;
};

export const openExternalProductInNewTab = (code, dataProvider, externalId) => {
  if (dataProvider === DATA_PROVIDERS.SECO) {
    return openUrlInNewTab(`${URLS.SECO_TOOLS_ARTICLE}${code}`);
  }
  if (dataProvider === DATA_PROVIDERS.WALTER) {
    return openUrlInNewTab(`${URLS.WALTER_ARTICLE}${code}`);
  }
  if (dataProvider === DATA_PROVIDERS.SANDVIK_COROMANT) {
    return openUrlInNewTab(`${URLS.SANDVIK_COROMANT_ARTICLE}${code}`);
  }
  if (dataProvider === DATA_PROVIDERS.DORMER_PRAMET) {
    return openUrlInNewTab(`${URLS.DORMER_PRAMET_ARTICLE}${code}`);
  }
  return openUrlInNewTab(`${URLS.TOOLS_UNITED_ARTICLE}${externalId}`);
};

export const getDataProvidersKey = (brandName) => {
  const dataProvider = Object.entries(DATA_PROVIDERS).find(
    ([, value]) => value === brandName
  );

  return dataProvider ? dataProvider[0] : '';
};

export const getExternalProductProviderLogo = (dataProviderName) =>
  dataProviderName
    ? EXTERNAL_PROVIDERS_LOGOS[getDataProvidersKey(dataProviderName)] || noImage
    : noImage;

export const displayCalculatedRegrinds = (value) =>
  isDefined(value) ? value : '-';
