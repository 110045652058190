import { MapLayer, withLeaflet } from 'react-leaflet';
import 'leaflet.markercluster';
import L from 'leaflet';

class MarkerClusterGroup extends MapLayer {
  createLeafletElement(props) {
    // eslint-disable-next-line new-cap
    const el = new L.markerClusterGroup({
      iconCreateFunction(cluster) {
        return new L.DivIcon({
          html: `<div><span>${cluster.getChildCount()}</span></div>`,
          className: 'marker-cluster marker-cluster-medium',
          iconSize: new L.Point(40, 40),
        });
      },
    });
    this.contextValue = {
      ...props.leaflet,
      layerContainer: el,
    };
    return el;
  }
}

export default withLeaflet(MarkerClusterGroup);
