import React, { useState } from 'react';

import { Modal, ModalBody } from 'reactstrap';

import ScanInput from './ScanInput';
import { useEventListener } from '../../hooks/Shared';
import { PERMISSIONS } from '../../constants';
import { getPermissions } from '../../utils/user';
import Button from './Button';

const ScanTagInputModal = () => {
  const [isOpened, setIsOpened] = useState(false);
  const permissions = getPermissions();

  useEventListener('keydown', (e) => {
    // add is devMode check to conditions if scan shortcuts should be removed from staging env
    if (
      permissions.includes(PERMISSIONS.TEST_SCAN) &&
      permissions.includes(PERMISSIONS.SCAN) &&
      ((e.metaKey && e.keyCode === 75) || (e.ctrlKey && e.keyCode === 75))
    ) {
      e.preventDefault();
      setIsOpened(true);
    } else if (
      permissions.includes(PERMISSIONS.TEST_SCAN) &&
      ((e.metaKey && e.keyCode === 73) || (e.ctrlKey && e.keyCode === 73))
    ) {
      e.preventDefault();
    }
  });

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <Modal size='sm' isOpen={isOpened} centered autoFocus={false}>
      <ModalBody>
        <ScanInput handleClose={handleClose} />
        <div className='text-right'>
          <Button size='sm' onClick={handleClose}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ScanTagInputModal;
