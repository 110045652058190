import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { NewModalStep } from '.';
import { scanTag } from '../../../redux/actions/readerActions';
import {
  DEFAULT_ROUTE_TABS,
  FIREBASE_EVENTS,
  PERMISSIONS,
  ROUTES,
} from '../../../constants';
import { getUsernameOrEmail } from '../../../utils/shared';
import { Image, Loader, NewListItem, SelectAndSearchItems } from '../../Shared';
import { openExternalProductInNewTab } from '../../../utils/product';
import { getCurrentUserId } from '../../../utils/groups';
import NewLoading from './NewLoading';
import {
  createProduct,
  highlightProductDetails,
} from '../../../redux/actions/productActions';
import { syncProduct } from '../../../redux/actions/tagActions';
import { getMappedErrorMessage } from '../../../utils/error';
import ErrorStep from './ErrorStep';
import { fetchProducts } from '../../../client/product';
import ProductsTable from '../../ProductComponents/ProductsTable';
import { generateQueryString } from '../../../utils/tabs';
import {
  DATABASE_INTERNAL_PRODUCTS_QUERIES,
  isDatabaseMyProductsTabOpened,
} from '../../../utils/database';
import { handleAnalytics } from '../../../utils/analytics';
import { getPermissions } from '../../../utils/user';
import { getGroups } from '../../../redux/actions/groupActions';

const debug = _debug('Bridge:SyncTag');

const STEPS = {
  INITIAL: 'INITIAL',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SELECT_GROUP: 'CHOOSE_GROUP',
  ADDING_PRODUCT: 'ADDING_PRODUCT',
  ADDED_PRODUCT: 'ADDED_PRODUCT',
  ERROR: 'ERROR',
};

const ScannedCodeTypeModal = ({
  handleClose,
  products,
  groups,
  createProduct,
  instance = false,
  syncProduct,
  scannedTag,
  highlightProductDetails,
  getGroups,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(
    products.length > 1 ? STEPS.SELECT_PRODUCT : STEPS.INITIAL
  );
  const [selectedGroup, setSelectedGroup] = useState(
    groups.length === 1 ? groups[0] : ''
  );
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [groupsWithScannedProduct, setGroupsWithScannedProduct] = useState([]);
  const [productExistsInBridge, setProductExistsInBridge] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);
  const [isNewProductCreated, setIsNewProductCreated] = useState(false);
  const [error, setError] = useState('');
  const [highlightedData, setHighlightedData] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const userId = getCurrentUserId();
  const permissions = getPermissions();
  const history = useHistory();

  const { name, code, dataProviderName, imageUrl, externalId, dataProviderId } =
    selectedProduct || products[0];

  const handleCancelModal = () => {
    handleClose();
    if (highlightedData) {
      highlightProductDetails(highlightedData);
    }
  };

  const checkIfProductExistsInBridge = async (code) => {
    try {
      const { data } = await fetchProducts({
        SearchTerm: code,
      });

      setGroupsWithScannedProduct(data.data.map(({ groupId }) => groupId));
      if (data.data.length > 0) {
        setProductExistsInBridge(true);
      }
    } catch (e) {
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!groups?.length) {
      getGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await checkIfProductExistsInBridge(code);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    (async () => {
      if (selectedProduct) {
        setProductExistsInBridge(false);
        await checkIfProductExistsInBridge(selectedProduct.code);
        setStep(STEPS.INITIAL);
      }
    })();
  }, [selectedProduct]);

  const handleViewExternalProduct = () => {
    handleAnalytics(FIREBASE_EVENTS.PRODUCT_TEMPLATE_VIEW_EXTERNAL_WEB);
    openExternalProductInNewTab(code, dataProviderName, externalId);
  };

  const handleAdd = async () => {
    setStep(STEPS.ADDING_PRODUCT);
    let productId;

    try {
      if (instance) {
        const {
          data: { data },
        } = await fetchProducts({
          FilterGroups: selectedGroup?.id,
          FilterProductCode: code,
        });

        if (data.length) {
          productId = data[0].id;
        } else {
          const { productId: id } = await createProduct({
            groupId: selectedGroup?.id,
            dataProviderId,
            externalId,
            currentRouterLocation: history.location,
          });
          productId = id;
          setIsNewProductCreated(true);
        }

        const { highlightedData } = await syncProduct(scannedTag, productId);
        setHighlightedData(highlightedData);
      } else {
        const { productId: id, highlightedData } = await createProduct({
          groupId: selectedGroup?.id,
          dataProviderId,
          externalId,
          currentRouterLocation: history.location,
        });
        productId = id;
        setHighlightedData(highlightedData);

        // if MyProducts component is active trigger adding them to the list
        if (isDatabaseMyProductsTabOpened) {
          history.push({ state: { productAddedInBackground: true } });
        }
      }
    } catch (e) {
      const response = getMappedErrorMessage(e);
      setError(response);
      setStep(STEPS.ERROR);
    }
    setAddedProduct(productId);
    setStep(STEPS.ADDED_PRODUCT);
  };

  const getKey = () => {
    if (instance) {
      if (isNewProductCreated) {
        return 'modal.addedToGroupAndSyncedWithATag';
      }
      return 'modal.productSyncedWithATag';
    }
    return 'modal.addedToGroupName';
  };

  const handleView = () => {
    if (instance) {
      history.push({
        pathname: `${ROUTES.DATABASE_PRODUCTS}/${addedProduct}/tag/${scannedTag}`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_PRODUCTS]
        ),
      });
    } else {
      history.push({
        pathname: `${ROUTES.DATABASE_PRODUCTS}/${addedProduct}`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_PRODUCTS]
        ),
      });
    }
    handleClose();
  };

  const handleViewInBridge = () => {
    history.push({
      pathname: `${ROUTES.DATABASE}`,
      search: DATABASE_INTERNAL_PRODUCTS_QUERIES,
      state: { searchProduct: code },
    });
    handleCancelModal();
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return loading ? (
          <NewLoading value={t('modal.fetchingDetails')} />
        ) : productExistsInBridge && !instance ? (
          <NewModalStep
            handleCancel={handleCancelModal}
            rightButtonContent={t('button.ok')}
            handleClickRightButton={handleViewInBridge}
            bodyClassName='d-flex flex-column justify-content-center align-items-center mb-5'
          >
            <Image
              src={imageUrl}
              width={96}
              height={96}
              className='rounded-circle border border-tertiary'
              containerClassName='mb-4'
            />
            <h1 className='text-center'>{name}</h1>
            <div className='text-center'>
              <Trans
                i18nKey='modal.theProductIsAvailableInOneOrMoreOfYourGroupsViewAllProductTemplatesInDifferentGroups'
                components={[<br />]}
              />
            </div>
          </NewModalStep>
        ) : (
          <NewModalStep
            handleCancel={handleCancelModal}
            leftButtonContent={t('button.view')}
            leftButtonOutline={false}
            handleClickLeftButton={handleViewExternalProduct}
            {...(permissions.includes(PERMISSIONS.PRODUCT_CREATE) && {
              rightButtonContent: t('button.nextCapitalized'),
              handleClickRightButton: () =>
                groups.length === 1 ? handleAdd() : setStep(STEPS.SELECT_GROUP),
            })}
            bodyClassName='d-flex flex-column justify-content-center align-items-center mb-5'
          >
            <Image
              src={imageUrl}
              width={96}
              height={96}
              className='rounded-circle border border-tertiary'
              containerClassName='mb-4'
              hasBadge={instance}
            />
            <h1 className='text-center'>{name}</h1>
            <div className='text-center'>
              {productExistsInBridge ? (
                <Trans
                  i18nKey='modal.productAlreadyExistsInSomeOfYourGroupsYouCanAddItToAnotherGroupViewProductOrAddItNow'
                  components={[<br />, <br />]}
                />
              ) : (
                <Trans
                  i18nKey='modal.productIsNotAvailableOnYourListViewProductOrAddItNow'
                  components={[<br />]}
                />
              )}
            </div>
          </NewModalStep>
        );
      case STEPS.SELECT_PRODUCT:
        return (
          <NewModalStep
            handleCancel={handleCancelModal}
            bodyClassName='d-flex flex-column'
            headerClassName='mb-5 pb-4'
            title={t('modal.chooseProduct')}
            subtitle={t('modal.selectTheProductYouWantToImport')}
          >
            <ProductsTable
              products={products}
              setSelectedProduct={setSelectedProduct}
            />
          </NewModalStep>
        );
      case STEPS.SELECT_GROUP:
        return loading ? (
          <Loader />
        ) : (
          <NewModalStep
            title={t('components.selectGroup')}
            subtitle={
              instance
                ? t('modal.selectTheGroupToSyncYourProductTo')
                : t('modal.selectTheGroupToAddYourProductTo')
            }
            handleCancel={handleCancelModal}
            handleClickRightButton={handleAdd}
            rightButtonContent={t('button.addCapitalized')}
            leftButtonContent={t('button.back')}
            handleClickLeftButton={() => setStep(STEPS.INITIAL)}
            rightButtonDisabled={!selectedGroup}
          >
            <SelectAndSearchItems
              search={search}
              searchPlaceholder={t('containers.searchGroup')}
              setSearch={setSearch}
              searchClassName='w-75 mb-4'
              items={groups
                .filter((group) =>
                  group.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((group) => (
                  <NewListItem
                    key={group.id}
                    id={group.name}
                    name={{
                      groupName: group?.name,
                      admin: getUsernameOrEmail(group?.admins[0]),
                      groupAdmin: getUsernameOrEmail(
                        group?.admins.find(
                          ({ user_id: adminId }) => adminId === userId
                        )
                      ),
                    }}
                    isCheckable
                    checkBox={group.name === selectedGroup?.name}
                    handleCheckboxClick={() => setSelectedGroup(group)}
                    {...(!instance && {
                      isProductAvailable: !groupsWithScannedProduct.includes(
                        group.id
                      ),
                      disableExistingProducts: true,
                    })}
                  />
                ))}
              noDataMessage={t(
                'modal.noGroupsCanBeFoundMatchingTheSearchTermEntered'
              )}
            />
          </NewModalStep>
        );
      case STEPS.ADDING_PRODUCT:
        return <NewLoading value={t('modal.addingProduct')} />;
      case STEPS.ADDED_PRODUCT:
        return (
          <NewModalStep
            handleCancel={handleCancelModal}
            rightButtonContent={t('button.view')}
            handleClickRightButton={handleView}
            bodyClassName='d-flex flex-column justify-content-center align-items-center mb-5'
          >
            <Image
              src={imageUrl}
              width={96}
              height={96}
              className='rounded-circle border border-tertiary'
              containerClassName='mb-4'
              hasBadge={!!instance}
            />
            <h1 className='text-center'>{name}</h1>
            <div className='text-center'>
              <Trans
                i18nKey={getKey()}
                values={{ groupName: selectedGroup?.name }}
              />
            </div>
          </NewModalStep>
        );
      case STEPS.ERROR:
        return <ErrorStep handleClose={handleClose} subtitle={error} />;
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };
  return renderStep();
};

ScannedCodeTypeModal.propTypes = {
  scanTag: PropTypes.func,
  products: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  createProduct: PropTypes.func,
  highlightProductDetails: PropTypes.func,
  getGroups: PropTypes.func,
};

export default connect(
  ({ tag, group, reader }) => ({
    groups: group.groups,
    emptyTag: tag.emptyTag,
    scannedTag: reader.scannedTag,
  }),
  { scanTag, createProduct, syncProduct, highlightProductDetails, getGroups }
)(ScannedCodeTypeModal);
