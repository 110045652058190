import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cs from 'classnames';

import { VIEW_OPTIONS } from '../../constants';
import { setViewOption } from '../../redux/actions/applicationActions';
import {
  getLocalStorageViewOption,
  setLocalStorageViewOption,
} from '../../utils/shared';
import { ReactComponent as GridIcon } from '../../assets/icon/ic_grid_active.svg';
import { ReactComponent as ListIcon } from '../../assets/icon/ic_list_inactive.svg';

const ViewOptions = ({ viewOption, setViewOption, className = '' }) => {
  useEffect(() => {
    const viewOptionFromLocalStorage = getLocalStorageViewOption();

    if (
      !!viewOptionFromLocalStorage &&
      viewOption !== viewOptionFromLocalStorage
    ) {
      setViewOption(viewOptionFromLocalStorage);
    } else if (!viewOptionFromLocalStorage) {
      setLocalStorageViewOption(viewOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <GridIcon
        height={48}
        width={48}
        className={cs('grid-icon cursor-pointer', {
          active: viewOption === VIEW_OPTIONS.GRID,
        })}
        onClick={() => setViewOption(VIEW_OPTIONS.GRID)}
      />
      <ListIcon
        className={cs('list-icon ml-3 cursor-pointer', {
          active: viewOption === VIEW_OPTIONS.LIST,
        })}
        ight={48}
        width={48}
        onClick={() => setViewOption(VIEW_OPTIONS.LIST)}
      />
    </div>
  );
};

ViewOptions.propTypes = {
  viewOption: PropTypes.string,
  className: PropTypes.string,
  setViewOption: PropTypes.func.isRequired,
};

export default connect(
  ({ application }) => ({
    viewOption: application.viewOption,
  }),
  { setViewOption }
)(ViewOptions);
