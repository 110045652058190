import React, { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import debug from 'debug';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import { NewLoading, NewModalStep } from '../Shared';
import { Loader } from '../../Shared';
import BoxesTable from '../../ReconditioningComponents/BoxesTable';
import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import { getMappedErrorMessage } from '../../../utils/error';
import { fetchBoxes } from '../../../client/box';
import {
  activateBoxes,
  setHighlightedBoxesIds,
} from '../../../redux/actions/boxActions';
import {
  EXISTING_BOX_TYPES,
  BOXES_PER_PAGE,
  PERMISSIONS,
} from '../../../constants';
import AddCustomBox from './AddCustomBox';
import SyncBoxTagModal from './SyncBoxTagModal';
import WarningStep from '../Shared/WarningStep';
import BoxConfigurationStep from './BoxConfiguration';
import { getPermissions } from '../../../utils/user';
import { BOX_STATUSES } from '../../../containers/ReconditioningContainers/constants';

const STEPS = {
  CHOOSE_BOXES: 'CHOOSE_BOXES',
  CUSTOM_BOX: 'CUSTOM_BOX',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  LOADING_ACTIVATION: 'LOADING_ACTIVATION',
  SCAN_BOX_CODE: 'SCAN_BOX_CODE',
  SCAN_ACTIVATION_WARNING: 'SCAN_ACTIVATION_WARNING',
  BOX_CONFIGURATION: 'BOX_CONFIGURATION',
};

const AddExistingBox = ({
  handleClose,
  goBack,
  preselectedBoxes = [],
  activateBoxes,
  selectedOption,
  existingBoxType = EXISTING_BOX_TYPES.ORDERED,
  onAddExistingBoxSuccess = () => {},
  onAddCustomBoxSuccess = () => {},
  setHighlightedBoxesIds,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(
    existingBoxType === EXISTING_BOX_TYPES.ORDERED
      ? preselectedBoxes.length
        ? STEPS.LOADING_ACTIVATION
        : STEPS.CHOOSE_BOXES
      : STEPS.CUSTOM_BOX
  );
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(!!preselectedBoxes.length);
  const [error, setError] = useState('');
  const [checkedBoxes, setCheckedBoxes] = useState(
    preselectedBoxes.length ? preselectedBoxes : []
  );
  const [sortValue, setSortValue] = useState('created_at_desc');
  const [hasMoreBoxesToFetch, setHasMoreBoxesToFetch] = useState(true);
  const [activatedBoxDetails, setActivatedBoxDetails] = useState(null);

  // used for resetting infinite scroll when sorting
  const infiniteScrollRef = useRef();
  const permissions = getPermissions();

  const handleCancel = () => {
    handleClose();
    onAddExistingBoxSuccess();
    setHighlightedBoxesIds(checkedBoxes.map(({ id }) => id));
  };

  const handleBoxesActivation = async () => {
    try {
      setStep(STEPS.LOADING_ACTIVATION);

      const data = await activateBoxes(checkedBoxes);

      if (checkedBoxes.length > 1) {
        handleCancel();
      } else {
        setActivatedBoxDetails(data[0]);
        setStep(STEPS.BOX_CONFIGURATION);
      }
    } catch (e) {
      debug(`Couldn't activate box instances. Reason: ${e}`);
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
    }
  };

  const handleActivateBoxes = async () => {
    if (checkedBoxes.length === 1) {
      setStep(STEPS.SCAN_BOX_CODE);
    } else {
      setStep(STEPS.SCAN_ACTIVATION_WARNING);
    }
  };

  useEffect(() => {
    if (preselectedBoxes.length) {
      handleActivateBoxes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInactiveBoxes = async (
    PageNumber = 1,
    PageSize = BOXES_PER_PAGE
  ) => {
    try {
      setLoading(true);
      const { data } = await fetchBoxes({
        Status: BOX_STATUSES.ORDERED,
        Sort: sortValue,
        PageNumber,
        PageSize,
      });

      if (data.lastRowReturned === data.rowCount) {
        setHasMoreBoxesToFetch(false);
      }

      setBoxes((prevState) => [...prevState, ...data.data]);
      setLoading(false);
    } catch (e) {
      debug(`Couldn't fetch inactive boxes. Reason: ${e}`);
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
      setHasMoreBoxesToFetch(false);
      setLoading(false);
    }
  };

  const handleCheckboxClick = (box) =>
    checkedBoxes.find(({ id }) => id === box.id)
      ? setCheckedBoxes((prevState) =>
          prevState.filter(({ id }) => id !== box.id)
        )
      : setCheckedBoxes((prevState) => [...prevState, box]);

  const triggerRefetchingOfBoxes = () => {
    setLoading(true);

    // resetting infinite scroll
    infiniteScrollRef.current.pageLoaded = 0;
    setBoxes([]);
    setHasMoreBoxesToFetch(true);

    setLoading(false);
  };

  const onSort = async (columnValue, sortOrder) => {
    const sortingValue = `${columnValue}${sortOrder}`;
    setSortValue(sortingValue);
    triggerRefetchingOfBoxes();
  };

  const handleLoadBoxes = async (page) => {
    await fetchInactiveBoxes(page);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.CHOOSE_BOXES:
        return (
          <NewModalStep
            title={t('modal.existingBox')}
            subtitle={t('modal.selectTheBoxYouWantToUse')}
            rightButtonContent={t('button.activate')}
            handleClickRightButton={handleActivateBoxes}
            rightButtonDisabled={!checkedBoxes.length}
            additionalRightButtonContent={
              permissions.includes(PERMISSIONS.CREATE_BOX)
                ? t('button.customBox')
                : ''
            }
            handleClickAdditionalRightButton={() => setStep(STEPS.CUSTOM_BOX)}
            leftButtonContent={goBack ? t('button.back') : ''}
            handleClickLeftButton={goBack}
            handleCancel={handleClose}
          >
            <InfiniteScroll
              pageStart={0}
              ref={infiniteScrollRef}
              loadMore={handleLoadBoxes}
              hasMore={!loading && hasMoreBoxesToFetch}
              useWindow={false}
            >
              <div className='d-flex flex-column'>
                <BoxesTable
                  handleCheckboxClick={
                    permissions.includes(PERMISSIONS.UPDATE_BOX)
                      ? handleCheckboxClick
                      : undefined
                  }
                  boxes={boxes}
                  checkedBoxes={checkedBoxes}
                  onSort={onSort}
                  withHeader={false}
                />
                {loading && <Loader containerClassName='py-3' />}
              </div>
            </InfiniteScroll>
          </NewModalStep>
        );
      case STEPS.CUSTOM_BOX:
        return (
          <AddCustomBox
            {...(!selectedOption && {
              goBack: () => setStep(STEPS.CHOOSE_BOXES),
            })}
            handleClose={handleClose}
            onSuccess={onAddCustomBoxSuccess}
          />
        );
      case STEPS.SCAN_BOX_CODE:
        return (
          <SyncBoxTagModal
            boxId={checkedBoxes[0].id}
            handleClose={handleClose}
            onScanFinished={handleBoxesActivation}
            isOpened
            withSkipOption
            handleSkip={handleBoxesActivation}
          />
        );
      case STEPS.SCAN_ACTIVATION_WARNING:
        return (
          <WarningStep
            text1={t(
              'modal.asYouWantToActivateMultipleBoxesSimultaneouslyYouWillHaveToSynchronizeTagsIndividuallyWithinEachBoxsDetails'
            )}
            text2={t('modal.doYouWantToContinue')}
            handleClose={handleClose}
            leftButtonContent={t('button.back')}
            handleClickLeftButton={() => setStep(STEPS.CHOOSE_BOXES)}
            rightButtonContent={t('button.continueCapitalized')}
            handleClickRightButton={handleBoxesActivation}
          />
        );
      case STEPS.BOX_CONFIGURATION:
        return (
          <BoxConfigurationStep
            handleClose={handleCancel}
            boxDetails={activatedBoxDetails}
            handleError={(e) => {
              setError(e);
              setStep(STEPS.ERROR);
            }}
            startLoading={() => {
              setStep(STEPS.LOADING);
            }}
          />
        );
      case STEPS.LOADING_ACTIVATION:
        return <NewLoading value={t('modal.activatingBox')} />;
      case STEPS.LOADING:
        return <NewLoading />;
      case STEPS.ERROR:
        return (
          <NewModalStep
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column text-txt-primary pb-5 justify-content-center'
            rightButtonContent={t('button.cancel')}
            handleClickRightButton={handleClose}
          >
            <WarningIcon className='warning-icon icon-danger mb-5' />
            <div className='mb-5'>
              {t(error) || t('error.somethingWentWrong')}
            </div>
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return renderStep();
};

AddExistingBox.propTypes = {
  isOpened: PropTypes.bool,
  preselectedBoxes: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  activateBoxes: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  goBack: PropTypes.func,
  selectedOption: PropTypes.string,
  existingBoxType: PropTypes.string,
  onAddExistingBoxSuccess: PropTypes.func,
  onAddCustomBoxSuccess: PropTypes.func,
  setHighlightedBoxesIds: PropTypes.func,
};

export default connect(null, { activateBoxes, setHighlightedBoxesIds })(
  AddExistingBox
);
