import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

const BreadcrumbsHeader = ({
  data = [],
  className = '',
  lastElementWithOpacity = false,
}) => {
  const { t } = useTranslation();
  return (
    !!data.length && (
      <h1 className={`breadcrumbs-container text-truncate m-0 ${className}`}>
        {data.map(({ label, labelSuffix, onClick }, i) => (
          <span
            key={`breadcrumb-${i}`}
            className={cs('', {
              'breadcrumbs-opacity': lastElementWithOpacity
                ? i === data.length - 1
                : i !== data.length - 1,
            })}
          >
            <span
              onClick={onClick}
              className={cs('', {
                'cursor-pointer': !!onClick,
              })}
            >
              {t(label)}
              {!!labelSuffix && labelSuffix}
            </span>

            {i !== data.length - 1 && ' > '}
          </span>
        ))}
      </h1>
    )
  );
};

BreadcrumbsHeader.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  lastElementWithOpacity: PropTypes.bool,
};

export default BreadcrumbsHeader;
