import React, { forwardRef, useState } from 'react';

import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import Img from 'react-image';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { deleteInput } from '../../assets/icon';
import { ReactComponent as CalendarIcon } from '../../assets/icon/ic_calendar.svg';
import { DATE_FORMATS } from '../../constants';

const DatePicker = ({
  value,
  handleUpdateDate,
  handleRemoveDate,
  errorMessage,
  className = '',
  containerClassName = '',
  name,
  minDate,
  maxDate,
  startDate,
  endDate,
  selectsRange = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const renderValue = (value) =>
    isOpen
      ? value
      : selectsRange && !endDate
      ? value.slice(0, -3) // to remove " - " after the start date
      : value;

  // eslint-disable-next-line react/prop-types
  const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => {
    const handleOnClick = (e) => {
      e.preventDefault(); // when component is used in Controller, it triggers submit without this
      onClick(e);
    };

    return (
      <button
        ref={ref}
        className={cs(
          className
            ? `d-flex align-items-center ${className}`
            : 'd-flex align-items-center react-datepicker-custom-input',
          {
            'react-datepicker-container-error-message': errorMessage,
            'react-datepicker-custom-input-opacity': !value,
          }
        )}
        disabled={disabled}
        onClick={handleOnClick}
        id='date-picker-button'
      >
        <div className='flex-grow-1 d-flex'>
          {value ? renderValue(value) : placeholder}
        </div>
        {!!handleRemoveDate && value && (
          <Img
            className='cursor-pointer ml-3'
            src={deleteInput}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveDate(e);
            }}
            id='date-picker-x-icon'
          />
        )}
        <CalendarIcon
          height={24}
          width={24}
          className='icon-txt-primary ml-2'
          onClick={handleOnClick}
        />
      </button>
    );
  });

  return (
    <div className={containerClassName} onClick={(e) => e.preventDefault()}>
      <ReactDatePicker
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        selected={value}
        onChange={handleUpdateDate}
        disabledKeyboardNavigation
        showPopperArrow={false}
        customInput={<CustomInput />}
        placeholderText={
          selectsRange
            ? t('components.selectDateOrRange')
            : t('components.selectDate')
        }
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={DATE_FORMATS.DATE_PICKER}
        showYearDropdown
        scrollableYearDropdown
        name={name}
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        disabled={disabled}
      />
      {errorMessage && (
        <div className='react-datepicker-error-message'>{errorMessage}</div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  handleUpdateDate: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleRemoveDate: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  containerClassName: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  selectsRange: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DatePicker;
