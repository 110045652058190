import React from 'react';

import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { THEME_COLORS_NAMES } from '../../constants';

const CustomRadioInput = ({
  value = false,
  label = '',
  className = '',
  inputClassName = '',
  labelClassName = '',
  color = THEME_COLORS_NAMES.TERTIARY,
  handleChange = () => {},
}) => {
  return (
    <FormGroup className={`custom-radio-input ${className}`} check>
      <Input
        type='radio'
        className={`cursor-pointer ${color} ${inputClassName}`}
        checked={value}
        onClick={() => handleChange(!value)}
      />
      <Label className={labelClassName}>{label}</Label>
    </FormGroup>
  );
};

CustomRadioInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  color: PropTypes.string,
  handleChange: PropTypes.func,
};

export default CustomRadioInput;
