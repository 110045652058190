import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { CustomCheckbox, Image, Table } from '../Shared';
import { ReactComponent as NewTabIcon } from '../../assets/icon/ic_newtab.svg';
import {
  displayArticleNumber,
  displayBoxDimension,
  displayBoxProvider,
  displayMaxWeight,
  getProviderLogo,
} from '../../utils/box';
import { openUrlInNewTab } from '../../utils/shared';
import { CUSTOM_BOX_PROVIDER, ROUTES } from '../../constants';

const BoxesTable = ({
  boxes = [],
  handleCheckboxClick = () => {},
  handleRowClick = handleCheckboxClick,
  checkedBoxes = [],
  onSort,
  openBoxInAnotherTabOptionAvailable = false,
}) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      name: t('components.vendor'),
      value: 'box_provider',
      style: { width: '15%' },
      isResizable: true,
      isSortable: !!onSort,
      className: 'table-padding-left',
    },
    {
      name: t('components.boxName'),
      value: 'name',
      style: { width: '25%' },
      isResizable: true,
      isSortable: !!onSort,
    },
    {
      name: t('components.dimension'),
      value: 'dimension',
      style: { width: '22%' },
      isResizable: true,
    },
    {
      name: t('components.maxWeight'),
      value: 'max_weight',
      style: { width: '18%' },
      isResizable: true,
      isSortable: !!onSort,
    },
    {
      name: t('components.articleNumber'),
      value: 'articleNumber',
      style: { width: '20%' },
      className: 'table-padding-right',
    },
  ];

  const handleOpenBoxInNewTab = (e, id) => {
    e.stopPropagation();
    openUrlInNewTab(`${ROUTES.RECONDITIONING_BOX}/${id}`);
  };

  return (
    <Table
      data={boxes.map((box, i) => {
        const { id, name, boxProvider, articleNumber, checked } = box;

        return {
          rowColumns: [
            <div className='table-margin-left text-truncate font-size-sm'>
              {boxProvider === CUSTOM_BOX_PROVIDER ? (
                displayBoxProvider({ boxProvider })
              ) : (
                <Image
                  src={getProviderLogo(boxProvider)}
                  height={24}
                  width={62}
                />
              )}
            </div>,
            <div className='d-flex justify-content-between align-items-center font-weight-bold text-secondary w-100'>
              <span
                className={
                  openBoxInAnotherTabOptionAvailable
                    ? 'flex-grow-1 text-truncate'
                    : ''
                }
              >
                {name.toUpperCase()}
              </span>
              {openBoxInAnotherTabOptionAvailable && (
                <NewTabIcon
                  className='new-tab-icon icon-secondary cursor-pointer'
                  height={24}
                  width={24}
                  onClick={(e) => handleOpenBoxInNewTab(e, id)}
                />
              )}
            </div>,
            displayBoxDimension(box),
            displayMaxWeight(box),
            <div className='d-flex justify-content-between align-items-center table-margin-right w-100'>
              <span className='flex-grow-1 text-truncate pr-1'>
                {displayArticleNumber({ articleNumber })}
              </span>
              <CustomCheckbox
                isChecked={checked || !!checkedBoxes.find((el) => el.id === id)}
                handleChange={() => handleCheckboxClick(box)}
                id={`checkbox-${i}`}
              />
            </div>,
          ],
          rowClassName: cs('border-top py-3', {
            'border-bottom': i === boxes.length - 1,
            'cursor-pointer': handleRowClick,
          }),
          onClick: () => handleRowClick(box),
        };
      })}
      columns={tableColumns}
      striped={false}
      className='w-100 font-size-md'
      onSort={onSort}
    />
  );
};

BoxesTable.propTypes = {
  boxes: PropTypes.array,
  handleCheckboxClick: PropTypes.func,
  handleRowClick: PropTypes.func,
  checkedBoxes: PropTypes.array,
  onSort: PropTypes.func,
  openBoxInAnotherTabOptionAvailable: PropTypes.bool,
};

export default BoxesTable;
