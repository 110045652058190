import React from 'react';

import PropTypes from 'prop-types';

import { LinkButton, Loader } from '../../Shared';
import { THEME_COLORS_NAMES } from '../../../constants';

const SmallModalStep = ({
  title,
  contentClassName = '',
  isLoading = false,
  handleClickLeftButton,
  handleClickRightButton,
  leftButtonContent,
  rightButtonContent,
  leftButtonDisabled = false,
  rightButtonDisabled = false,
  rightButtonClassname = '',
  children,
}) => {
  return (
    <div className='d-flex flex-column h-100 p-2'>
      <div className='modal-sm-title'>{title}</div>
      <div
        className={`modal-sm-subtitle flex-grow-1 d-flex align-items-center ${contentClassName}`}
      >
        {children}
      </div>
      <div className='d-flex align-items-end justify-content-end'>
        <LinkButton
          className='mr-4'
          underlined={false}
          onClick={handleClickLeftButton}
          disabled={leftButtonDisabled || isLoading}
          color={THEME_COLORS_NAMES.TEXT_PRIMARY}
        >
          {leftButtonContent}
        </LinkButton>
        <div className='d-flex'>
          {!!isLoading && (
            <Loader containerClassName='mr-1 text-txt-secondary' size='sm' />
          )}
          <LinkButton
            className={rightButtonClassname}
            color={THEME_COLORS_NAMES.DANGER}
            underlined={false}
            onClick={handleClickRightButton}
            disabled={rightButtonDisabled || isLoading}
          >
            {rightButtonContent}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

SmallModalStep.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  contentClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  handleClickLeftButton: PropTypes.func,
  handleClickRightButton: PropTypes.func,
  leftButtonContent: PropTypes.any,
  rightButtonContent: PropTypes.any,
  leftButtonDisabled: PropTypes.bool,
  rightButtonDisabled: PropTypes.bool,
  rightButtonClassname: PropTypes.string,
  children: PropTypes.any,
};

export default SmallModalStep;
