import React, { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import 'firebase/analytics';

import Routes from './routes/Routes';
import configureStore from './redux/configureStore';
import { useAuth0 } from './react-auth0-spa';
import Loader from './components/Shared/Loader';
import { fetchMyDetails } from './client/user';
import './i18n';
import { FIREBASE_EVENTS } from './constants';
import {
  handleAnalytics,
  initializeFirebaseAnalytics,
} from './utils/analytics';
import { initializeTheme } from './utils/theme';

const App = () => {
  const {
    loading,
    getTokenSilently,
    getTokenWithPopup,
    isAuthenticated,
  } = useAuth0();
  const [permissionsSet, setPermissionsSet] = useState(false);
  const [userDetailsData, setUserDetailsData] = useState();

  const authorizeApi = async () => {
    try {
      return await getTokenSilently();
    } catch (error) {
      if (
        error.error === 'consent_required' ||
        error.error === 'login_required'
      ) {
        // handle consent required with a getTokenWithPopup
        return getTokenWithPopup();
      }
      throw error;
    }
  };

  useEffect(() => {
    (async () => {
      if (!loading && isAuthenticated) {
        const token = await authorizeApi();
        localStorage.setItem('token', token);
        const { data } = await fetchMyDetails();
        setUserDetailsData(data);
        if (data.organization?.brand) {
          initializeTheme(data.organization.brand?.uiTheme);
        }
        setPermissionsSet(true);
        initializeFirebaseAnalytics();
        handleAnalytics(FIREBASE_EVENTS.LOGIN);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, loading]);

  return loading || (isAuthenticated && !permissionsSet) ? (
    <Loader height={100} />
  ) : (
    <Provider store={configureStore()}>
      <Routes
        userDetailsData={userDetailsData}
        permissionsSet={permissionsSet}
      />
    </Provider>
  );
};

export default App;
