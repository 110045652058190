import React, { useState, Fragment, useEffect } from 'react';

import ColumnResizer from 'react-column-resizer';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Collapse } from 'reactstrap';
import cs from 'classnames';

import { scrollToElementWithId } from '../../utils/shared';
import { removeHiddenColumns } from '../../utils/table';
import TriangleDownIcon from './TriangleDownIcon';
import TriangleUpIcon from './TriangleUp';
import useHighlightArray from '../../hooks/Shared/useHighlightArray';
import TriangleRightIcon from './TriangleRightIcon';

const EXPAND_ICON_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
};

const Table = ({
  columns: allColumns,
  data: allData,
  isExpandable,
  expandData,
  expandHeight = 250,
  expandIconClassName = '',
  expandIconPosition = EXPAND_ICON_POSITIONS.LEFT,
  resizerClassName = '',
  scrollable = false,
  onSort,
  // id needs to be sent in data prop for highlight features to work
  highlightedRows,
  handleHighlightAnimationEnd = () => {},
  expandRowOnHighlight = false,
  expandOnRowClick = true,
  striped = true,
  className = '',
  withHeader = true,
  highlightRowOnClick = false,
}) => {
  const { columns, data } = removeHiddenColumns(allColumns, allData);

  const [expandedRows, setExpandedRows] = useState([]);
  const [sortedColumns, setSortedColumns] = useState(
    columns.map((value) => {
      return { sortOrder: '_asc', value };
    })
  );

  const {
    handleAnimationStart,
    handleAnimationEndAll,
    startedHighlightIds,
  } = useHighlightArray(highlightedRows, handleHighlightAnimationEnd);

  useEffect(() => {
    if (data.length !== expandedRows.length) {
      setExpandedRows((prevState) =>
        data.map((value, i) => ({
          expanded: prevState.length > i ? prevState[i].expanded : false,
          value,
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const numberOfColumns = columns.reduce(
    (previousValue, currentValue) => {
      if (currentValue?.isResizable) {
        return previousValue + 2;
      }
      return previousValue + 1;
    },
    isExpandable ? 1 : 0
  );

  const getColumnValue = (
    value,
    index,
    rowClassName = 'border-bottom py-1',
    columnClassName = ''
  ) => {
    if (index > 0 && columns[index - 1]?.isResizable && withHeader) {
      return (
        <Fragment key={index}>
          <td className={rowClassName} />
          <td className={`${columnClassName} ${rowClassName}`}>{value}</td>
        </Fragment>
      );
    }
    return (
      <td key={index} className={`${columnClassName} ${rowClassName}`}>
        {value}
      </td>
    );
  };

  const isExpanded = (index) => {
    return expandedRows[index]?.expanded;
  };

  const handleExpand = (index, e, value) => {
    if (e) {
      e.stopPropagation();
    }
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index].expanded =
      value !== undefined ? value : !newExpandedRows[index]?.expanded;
    setExpandedRows([...newExpandedRows]);
  };

  const handleSort = (columnValue, index) => {
    const { sortOrder } = sortedColumns[index];

    setSortedColumns((prevState) =>
      prevState.map((value, i) =>
        i === index
          ? { ...value, sortOrder: sortOrder === '_desc' ? '_asc' : '_desc' }
          : value
      )
    );

    onSort(columnValue, sortOrder);
  };

  const handleExpandHighlightedRows = (rowId) => {
    if (rowId) {
      const index = data?.findIndex(({ id }) => id === rowId);
      if (index !== -1) {
        handleExpand(index, null, true);
      }
    }
  };

  const isHighlightedRowWithIndex = (index) =>
    highlightedRows.includes(data[index]?.id);

  useEffect(() => {
    if (startedHighlightIds.length) {
      scrollToElementWithId(startedHighlightIds[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startedHighlightIds]);

  const renderExpandIcon = (index, rowClassName) => (
    <td
      className={`expand-icon-td align-middle text-center ${rowClassName} ${expandIconClassName}`}
    >
      {!!expandData[index] && (
        <div>
          {isExpanded(index) ? (
            <TriangleUpIcon onClick={(e) => handleExpand(index, e)} />
          ) : (
            <TriangleRightIcon onClick={(e) => handleExpand(index, e)} />
          )}
        </div>
      )}
    </td>
  );

  const getOnRowClickMethod = (row, index) =>
    row?.onClick ||
    (expandOnRowClick && isExpandable
      ? (e) => handleExpand(index, e)
      : undefined);

  const getRowClassName = (index, row) =>
    isExpanded(index)
      ? row.rowClassNameIfExpanded || row.rowClassName
      : row.rowClassName;

  return (
    <table
      className={cs(`custom-table ${className}`, { scrollable })}
      id='tableContainer'
    >
      <thead>
        <tr className={cs({ hidden: !withHeader })}>
          {/* Empty header element is used as a placeholder for dropdown arrow in product items */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          {isExpandable &&
            expandIconPosition === EXPAND_ICON_POSITIONS.LEFT && (
              <th style={{ width: '4%' }} aria-label='expand-icon' />
            )}
          {columns.map(
            (
              {
                name,
                value,
                isResizable,
                isSortable,
                className,
                style,
                alignment = 'start',
                suffix,
              },
              index
            ) => {
              return (
                <Fragment key={value}>
                  <th
                    className={`new-resizable-table-column-title ${className}`}
                    style={style}
                  >
                    <div
                      className={`d-flex align-items-center pt-1 justify-content-${alignment}`}
                    >
                      <span className='text-truncate'> {name}</span>
                      {!!suffix && suffix}
                      {isSortable && (
                        <div>
                          {sortedColumns[index].sortOrder === '_desc' ? (
                            <TriangleUpIcon
                              onClick={debounce(
                                () => handleSort(value, index),
                                500
                              )}
                              className='sort-icon pl-1'
                            />
                          ) : (
                            <TriangleDownIcon
                              onClick={debounce(
                                () => handleSort(value, index),
                                500
                              )}
                              className='sort-icon pl-1'
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                  {isResizable && withHeader && (
                    <ColumnResizer
                      className={`resizer ${resizerClassName}`}
                      minWidth={25}
                    />
                  )}
                </Fragment>
              );
            }
          )}
          {isExpandable &&
            expandIconPosition === EXPAND_ICON_POSITIONS.RIGHT && (
              <th style={{ width: '4%' }} aria-label='expand-icon' />
            )}
        </tr>
      </thead>
      <tbody>
        {withHeader && (
          <tr className='empty-row'>
            <td className='py-1' />
          </tr>
        )}
        {data?.map((row, index) => {
          const highlighted = !!row?.id && highlightedRows?.includes(row.id);

          return (
            <Fragment key={row?.id || index}>
              <tr
                id={row?.id}
                key={`row-${row?.id || index}`}
                className={cs('position-relative', {
                  highlighted,
                  striped,
                  'cursor-pointer': !!getOnRowClickMethod(row, index),
                  'secondary-on-focus': highlightRowOnClick,
                })}
                onAnimationStart={() => {
                  if (highlightedRows.includes(row?.id)) {
                    handleAnimationStart(row?.id);
                    if (expandRowOnHighlight) {
                      handleExpandHighlightedRows(row?.id);
                    }
                  }
                }}
                onAnimationEnd={() => handleAnimationEndAll()}
                onClick={getOnRowClickMethod(row, index)}
              >
                {isExpandable &&
                  expandIconPosition === EXPAND_ICON_POSITIONS.LEFT &&
                  renderExpandIcon(index, getRowClassName(index, row))}
                {row?.rowColumns.map((value, i) =>
                  getColumnValue(
                    value,
                    i,
                    getRowClassName(index, row),
                    columns[i]?.columnClassName
                  )
                )}
                {isExpandable &&
                  expandIconPosition === EXPAND_ICON_POSITIONS.RIGHT &&
                  renderExpandIcon(index, getRowClassName(index, row))}
              </tr>
              {isExpandable && (
                <Collapse tag='tr' isOpen={isExpanded(index)}>
                  {isExpanded(index) && (
                    <td
                      style={{ style: expandHeight }}
                      className={cs('p-0', {
                        highlighted: isHighlightedRowWithIndex(index),
                      })}
                      colSpan={numberOfColumns}
                    >
                      {expandData[index]}
                    </td>
                  )}
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rowColumns: PropTypes.array.isRequired,
      className: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  scrollable: PropTypes.bool,
  isExpandable: PropTypes.bool,
  expandData: PropTypes.array,
  resizerClassName: PropTypes.string,
  highlightRowOnClick: PropTypes.string,
  expandHeight: PropTypes.number,
  onSort: PropTypes.func,
  highlightedRows: PropTypes.array,
  handleHighlightAnimationEnd: PropTypes.func,
  expandRowOnHighlight: PropTypes.bool,
  striped: PropTypes.bool,
  className: PropTypes.string,
  withHeader: PropTypes.bool,
  expandOnRowClick: PropTypes.bool,
  expandIconClassName: PropTypes.string,
  expandIconPosition: PropTypes.oneOf(Object.values(EXPAND_ICON_POSITIONS)),
};

export default Table;
