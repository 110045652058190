import * as types from '../constants';
import initialState from '../initialState';
import { NOTIFICATION_STATUS } from '../../constants';

export default function reducer(state = initialState.notification, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload.initial
          ? payload.notifications
          : [...state.notifications, ...payload.notifications],
        rowCount: payload.rowCount,
      };
    case types.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: payload.unreadCount,
      };
    case types.CONNECTION_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        connected: payload.connected,
      };
    case types.UPDATE_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id === payload.notification) {
            return {
              ...notification,
              status: payload.status,
            };
          }
          return notification;
        }),
        unreadCount: payload.unreadCount,
      };
    case types.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          status:
            notification.status === NOTIFICATION_STATUS.INTERACTED_WITH
              ? NOTIFICATION_STATUS.INTERACTED_WITH
              : NOTIFICATION_STATUS.READ,
        })),
        unreadCount: payload.unreadCount,
      };
    default:
      return state;
  }
}
