import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { THEME_COLORS_NAMES } from '../../constants';

const BasicTooltip = ({
  children,
  target,
  placement = 'right',
  bgColor = THEME_COLORS_NAMES.SECONDARY,
  className = '',
}) => {
  return (
    <UncontrolledTooltip
      placement={placement}
      target={target}
      className={`${bgColor}-tooltip ${className}`}
    >
      {children}
    </UncontrolledTooltip>
  );
};

BasicTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  target: PropTypes.string.isRequired,
  placement: PropTypes.string,
  bgColor: PropTypes.oneOf(Object.values(THEME_COLORS_NAMES)),
  className: PropTypes.string,
};

export default BasicTooltip;
