import { useEffect, useState } from 'react';

import { TITLE_STORAGE_KEY } from '../../constants';
import { getLocalStorageItem } from '../../utils/localStorage';
import { DEFAULT_APP_TITLE } from '../../constants/theme';

const usePageTitle = (title) => {
  const defaultAppTitle = getLocalStorageItem(
    TITLE_STORAGE_KEY,
    DEFAULT_APP_TITLE
  );

  const [defaultTitle, setDefaultTitle] = useState(defaultAppTitle);

  useEffect(() => {
    setDefaultTitle(getLocalStorageItem(TITLE_STORAGE_KEY, defaultAppTitle));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage]);

  useEffect(() => {
    document.title = title || defaultTitle;

    return () => {
      document.title = defaultTitle;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const setPageTitle = (title) => {
    document.title = title;
  };

  return { setPageTitle };
};

export default usePageTitle;
