import React from 'react';
import { Input as InputReactstrap } from 'reactstrap';
import PropTypes from 'prop-types';
import InputDeleteIcon from './InputDeleteIcon';

const Input = ({
  placeholder,
  errorMessage,
  value = '',
  onChange,
  darkBorder,
  showDeleteIcon = false,
  handleDeleteIconClick,
  className = '',
  maxLength,
  bsSize,
}) => {
  return (
    <>
      {errorMessage && <div className='error-message pl-2'>{errorMessage}</div>}
      <div className='d-flex w-100'>
        <InputReactstrap
          className={`input-style border-bd-color ${className} ${
            darkBorder && 'border-bd-color'
          }`}
          placeholder={placeholder}
          invalid={!!errorMessage}
          value={value}
          onChange={onChange}
          {...(maxLength && { maxLength })}
          bsSize={bsSize}
        />
        {showDeleteIcon && (
          <InputDeleteIcon handleClick={handleDeleteIconClick} />
        )}
      </div>
    </>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  value: PropTypes.string,
  darkBorder: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
  handleDeleteIconClick: PropTypes.func,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  bsSize: PropTypes.string,
};

export default Input;
