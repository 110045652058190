import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import SmallModalStep from '../Shared/SmallModalStep';

const NotificationError = ({ isOpened, handleClose, title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <Modal
      size='sm'
      contentClassName='modal-height-sm modal-sm-box-shadow'
      isOpened={isOpened}
    >
      <SmallModalStep
        title={t(title)}
        rightButtonContent={t('button.ok')}
        handleClickRightButton={handleClose}
      >
        {t(subtitle)}
      </SmallModalStep>
    </Modal>
  );
};

NotificationError.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  isOpened: PropTypes.bool,
};

export default NotificationError;
