import { BOX_STATUSES } from '../../containers/ReconditioningContainers/constants';
import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.box, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_BOX_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.data,
      };
    case types.GET_BOX_PRODUCT_INSTANCES_SUCCESS:
      return {
        ...state,
        productInstances:
          payload.page === 1
            ? payload.data
            : [...state.productInstances, ...payload.data],
      };
    case types.SET_BOX_PRODUCT_INSTANCES_SUCCESS:
      return {
        ...state,
        productInstances: [...payload.data],
      };
    case types.REMOVE_PRODUCT_INSTANCE_FROM_BOX_SUCCESS:
      return {
        ...state,
        productInstances: state.productInstances.filter(
          ({ id }) => id !== payload.instanceId
        ),
      };
    case types.REMOVE_MULTIPLE_PRODUCT_INSTANCES_FROM_THE_BOX_SUCCESS:
      return {
        ...state,
        productInstances:
          payload.data.productInstanceIds.length < 1 &&
          payload.data.excludeProductInstanceIds.length < 1
            ? []
            : payload.data.excludeProductInstanceIds.length > 0
            ? state.productInstances.filter(({ id }) =>
                payload.data.excludeProductInstanceIds?.includes(id)
              )
            : state.productInstances.filter(
                ({ id }) => !payload.data.productInstanceIds?.includes(id)
              ),
      };
    case types.ADD_PRODUCT_INSTANCE_TO_THE_BOX_SUCCESS:
      return {
        ...state,
        productInstances: [payload.data, ...state.productInstances],
      };
    case types.ADD_SPECIAL_REQUEST_TO_PRODUCT_INSTANCE_IN_THE_BOX:
      return {
        ...state,
        productInstances: state.productInstances.map((productInstance) =>
          productInstance.id === payload.instanceId
            ? { ...productInstance, specialRequestId: payload.specialRequestId }
            : productInstance
        ),
      };
    case types.GET_BOXES_SUCCESS:
      return {
        ...state,
        boxes:
          payload.page === 1
            ? [...payload.data]
            : [...state.boxes, ...payload.data],
      };
    case types.SET_BOXES_SUCCESS:
      return {
        ...state,
        boxes: payload.data,
        boxesToAddToStart: !payload.data.length ? [] : state.boxesToAddToStart,
        boxesIdsToFilterOutWhileFetching: !payload.data.length
          ? []
          : state.boxesIdsToFilterOutWhileFetching,
      };
    case types.ACTIVATE_BOXES_SUCCESS:
      const boxesNotInStateAlready = payload.boxes.filter(
        ({ id: boxId }) => !state.boxes.some(({ id }) => id === boxId)
      );

      const boxesAlreadyInState = payload.boxes.filter(
        ({ id: boxId }) =>
          !boxesNotInStateAlready.some(({ id }) => id === boxId)
      );

      const areActiveEmptyBoxesDisplayed =
        state.displayedBoxesStatuses.includes(BOX_STATUSES.ACTIVE_EMPTY) ||
        state.displayedBoxesStatuses.includes(BOX_STATUSES.ALL);

      return {
        ...state,
        boxes: [
          ...(areActiveEmptyBoxesDisplayed ? boxesNotInStateAlready : []),
          ...(areActiveEmptyBoxesDisplayed
            ? state.boxes.map((box) =>
                boxesAlreadyInState.some(({ id }) => id === box.id)
                  ? {
                      ...box,
                      status: BOX_STATUSES.ACTIVE_EMPTY,
                      isActive: true,
                    }
                  : box
              )
            : state.boxes.filter(
                (box) => !boxesAlreadyInState.some(({ id }) => id === box.id)
              )),
        ],
        boxesIdsToFilterOutWhileFetching: [
          ...state.boxesIdsToFilterOutWhileFetching,
          ...boxesNotInStateAlready.map(({ id }) => id),
        ],
      };
    case types.RESET_BOXES_SUCCESS:
      const resetBoxes = payload.boxes.map(({ id }) => id);

      return {
        ...state,
        boxes: state.boxes.map((box) =>
          resetBoxes.includes(box.id)
            ? { ...box, ...payload.boxes.find(({ id }) => id === box.id) }
            : box
        ),
        details: resetBoxes.includes(state.details?.id)
          ? {
              ...state.details,
              ...payload.boxes.find(({ id }) => id === state.details?.id),
            }
          : state.details,
      };
    case types.UPDATE_BOX_SUCCESS:
      return {
        ...state,
        boxes: state.boxes.map((box) =>
          box.id === payload.boxId ? { ...box, ...payload.data } : box
        ),
        details: { ...state.details, ...payload.data },
      };
    case types.ADD_BOXES_TO_START_SUCCESS:
      const idsOfBoxesToAdd = state.boxesToAddToStart.map(({ id }) => id);
      return {
        ...state,
        boxes: [
          ...state.boxesToAddToStart,
          ...state.boxes.filter(({ id }) => !idsOfBoxesToAdd.includes(id)),
        ],
        boxesIdsToFilterOutWhileFetching: [
          ...state.boxesIdsToFilterOutWhileFetching,
          ...idsOfBoxesToAdd,
        ],
        boxesToAddToStart: [],
      };
    case types.SET_HIGHLIGHTED_BOXES_IDS_SUCCESS:
      return {
        ...state,
        highlightedIds: [...payload.highlightedIds],
      };
    case types.SET_BOXES_TO_ADD_TO_START_SUCCESS:
      return {
        ...state,
        boxesToAddToStart: [...payload.boxesToAddToStart],
      };
    case types.REMOVE_HIGHLIGHTED_IDS_SUCCESS:
      return {
        ...state,
        highlightedIds: state.highlightedIds.filter(({ id }) =>
          payload.idsToRemove.includes(id)
        ),
      };
    case types.SET_DISPLAYED_BOXES_STATUSES_SUCCESS:
      return {
        ...state,
        displayedBoxesStatuses: [...payload.statuses],
      };
    case types.SET_BOXES_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS:
      return {
        ...state,
        boxesIdsToFilterOutWhileFetching: [...payload.ids],
      };
    case types.DELETE_BOX_TAG_SUCCESS:
      return {
        ...state,
        details: { ...state.details, code: null },
      };
    case types.SYNC_TAG_TO_BOX_SUCCESS:
      return {
        ...state,
        details: { ...state.details, code: payload.code },
      };
    default:
      return state;
  }
}
