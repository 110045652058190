import React from 'react';

import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import OutlinedField from './OutlinedField';

const ControlledOutlinedField = ({
  control,
  name,
  autoComplete = 'off',
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, formState: { errors } }) => (
      <OutlinedField
        validateWhenEmpty
        autoComplete={autoComplete}
        name={name}
        value={value}
        handleClearInput={() => onChange('')}
        handleChange={(e) => onChange(e.target.value)}
        valid={() => !errors[name]?.message}
        feedback={errors[name]?.message}
        {...props}
      />
    )}
  />
);
ControlledOutlinedField.propTypes = {
  control: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
};

export default ControlledOutlinedField;
