import React from 'react';

import PropTypes from 'prop-types';

import { THEME_COLORS_NAMES } from '../../constants';
import Button from './Button';

const PillButton = ({
  className = '',
  onClick,
  children,
  disabled = false,
  outline = false,
  type = 'button',
  size,
  color = THEME_COLORS_NAMES.TERTIARY,
  ...props
}) => {
  return (
    <>
      <Button
        className={`btn-pill pill-button ${className}`}
        onClick={onClick}
        disabled={disabled}
        outline={outline}
        color={color}
        type={type}
        size={size}
        {...props}
      >
        {children}
      </Button>
    </>
  );
};

PillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

export default PillButton;
