import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Modal from '../Modal';
import CreateProduct from '../../ProductComponents/CreateProduct';
import { LIMIT_REACHED_TYPE } from '../../../constants';
import { getUsernameOrEmail } from '../../../utils/shared';
import { getCurrentUserId } from '../../../utils/groups';
import { NewModalStep } from '../Shared';
import {
  NewListItem,
  NewLimitReached,
  SelectAndSearchItems,
} from '../../Shared';

const debug = _debug('Bridge:CreateProductModal');

const STEPS = {
  SELECT_GROUP: 'SELECT_GROUP',
  ADD_ASSEMBLY: 'ADD_ASSEMBLY',
  CREATE_EXTERNAL_PRODUCT: 'CREATE_EXTERNAL_PRODUCT',
  PRODUCT_LIMIT: 'PRODUCT_LIMIT',
};

const CreateProductModal = ({
  handleClose,
  openBridgeProducts,
  groups,
  selectedProduct,
  externalProductsCount,
  externalProductsLimit,
  isSyncable = false,
  isTransferOptionActive = false,
  syncStep,
}) => {
  const { t } = useTranslation();
  const isSyncing = isSyncable || isTransferOptionActive;

  const [step, setStep] = useState(
    groups?.length === 1 ? STEPS.CREATE_EXTERNAL_PRODUCT : STEPS.SELECT_GROUP
  );

  const [search, setSearch] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(
    groups.length === 1 ? groups[0] : ''
  );

  const isExternalProductLimitReached =
    !!externalProductsLimit && externalProductsCount >= externalProductsLimit;

  const userId = getCurrentUserId();

  const renderStep = () => {
    switch (step) {
      case STEPS.SELECT_GROUP:
        return (
          <NewModalStep
            title={t('components.selectGroup')}
            subtitle={t('components.selectTheGroupsToAddYourProductsTo')}
            handleCancel={handleClose}
            handleClickRightButton={
              isExternalProductLimitReached
                ? () => setStep(STEPS.PRODUCT_LIMIT)
                : () => setStep(STEPS.CREATE_EXTERNAL_PRODUCT)
            }
            rightButtonContent={t('button.nextCapitalized')}
            rightButtonDisabled={!selectedGroup}
            leftButtonContent={!!isSyncing && t('button.back')}
            handleClickLeftButton={!!isSyncing && syncStep}
          >
            <SelectAndSearchItems
              search={search}
              searchPlaceholder={t('containers.searchGroup')}
              setSearch={setSearch}
              searchClassName='w-75 mb-4'
              items={groups
                .filter((group) =>
                  group.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((group) => (
                  <NewListItem
                    key={group.id}
                    id={group.name}
                    name={{
                      groupName: group?.name,
                      admin: getUsernameOrEmail(group?.admins[0]),
                      groupAdmin: getUsernameOrEmail(
                        group?.admins.find(
                          ({ user_id: adminId }) => adminId === userId
                        )
                      ),
                    }}
                    isCheckable
                    checkBox={group.name === selectedGroup?.name}
                    handleCheckboxClick={() => setSelectedGroup(group)}
                  />
                ))}
              noDataMessage={t(
                'modal.noGroupsCanBeFoundMatchingTheSearchTermEntered'
              )}
            />
          </NewModalStep>
        );
      case STEPS.CREATE_EXTERNAL_PRODUCT:
        return (
          <CreateProduct
            isSyncable={isSyncable}
            isTransferOptionActive={isTransferOptionActive}
            goBack={() => setStep(STEPS.SELECT_GROUP)}
            handleClose={handleClose}
            selectedGroup={selectedGroup}
            openBridgeProducts={openBridgeProducts}
            setProductLimitStep={() => setStep(STEPS.PRODUCT_LIMIT)}
            selectedProduct={selectedProduct}
          />
        );
      case STEPS.PRODUCT_LIMIT:
        return (
          <NewLimitReached
            handleClose={() => setStep(STEPS.INITIAL)}
            limitReachedType={LIMIT_REACHED_TYPE.PRODUCT}
          />
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return isSyncing ? (
    renderStep()
  ) : (
    <Modal isOpened className='p-0 d-flex flex-column justify-content-between'>
      {renderStep()}
    </Modal>
  );
};

CreateProductModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  openBridgeProducts: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  isSyncable: PropTypes.bool,
  isTransferOptionActive: PropTypes.bool,
  syncStep: PropTypes.func,
  groups: PropTypes.array,
  externalProductsCount: PropTypes.number.isRequired,
  externalProductsLimit: PropTypes.number,
};

export default connect(({ user, group }) => ({
  groups: group.groups,
  externalProductsCount: user.externalProductsCount,
  assembliesCount: user.assembliesCount,
  externalProductsLimit: user.externalProductsLimit,
  assembliesLimit: user.assembliesLimit,
}))(CreateProductModal);
