import React from 'react';

import PropTypes from 'prop-types';

import PillButton from './PillButton';
import { THEME_COLORS_NAMES } from '../../constants';

const PrimaryButton = ({
  className = '',
  onClick,
  type = 'button',
  outline = false,
  disabled = false,
  size,
  children,
}) => (
  <PillButton
    onClick={onClick}
    className={className}
    disabled={disabled}
    outline={outline}
    color={THEME_COLORS_NAMES.BUTTON_PRIMARY}
    type={type}
    size={size}
  >
    {children}
  </PillButton>
);

PrimaryButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.string,
};

export default PrimaryButton;
