import * as types from '../constants';
import {
  fetchAssemblies,
  fetchAssembly,
  postAssemblyInstance,
  deleteAssembly,
  postAssembly,
  patchAssembly,
  fetchAssemblyInstance,
  patchAssemblyInstance,
  deleteAssemblyInstance,
  fetchAssemblyInstanceLocations,
  postAssemblyInstanceNumericProperty,
  fetchAssemblyInstanceNumericPropertyHistory,
  deleteAssemblyInstanceNumericPropertyHistoryEntry,
  fetchAssemblyThreads,
  fetchAssemblyInstanceThreads,
  postAssemblyGeneralThread,
  postAssemblyInstanceThread,
} from '../../client/assembly';
import { deleteFile } from '../../client/file';
import { deleteThread, putThread } from '../../client/thread';
import {
  ASSEMBLIES_PER_PAGE,
  PROPERTIES_PER_PAGE,
  USER_THREAD_STATUS,
} from '../../constants';
import { getResourceFromLocation } from '../../utils/resource';
import { getPaginationDetails } from './paginationActions';
import { isDatabaseAssembliesTabOpened } from '../../utils/database';

export const getAssemblySuccess = (details) => {
  return {
    type: types.GET_ASSEMBLY_SUCCESS,
    payload: {
      details,
    },
  };
};

export const getAssemblyInstanceSuccess = (details) => {
  return {
    type: types.GET_ASSEMBLY_INSTANCE_SUCCESS,
    payload: {
      details,
    },
  };
};

export const addAssembliesToStartSuccess = () => {
  return {
    type: types.ADD_ASSEMBLIES_TO_START_SUCCESS,
    payload: {},
  };
};

export const setAssembliesToAddToStartSuccess = (assembliesToAddToStart) => {
  return {
    type: types.SET_ASSEMBLIES_TO_ADD_TO_START_SUCCESS,
    payload: { assembliesToAddToStart },
  };
};

export const setAssemblyIdsToFilterOutWhileFetchingSuccess = (ids = []) => {
  return {
    type: types.SET_ASSEMBLY_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS,
    payload: { ids },
  };
};

export const getAssembliesSuccess = (assemblies, page) => {
  return {
    type: types.GET_ASSEMBLIES_SUCCESS,
    payload: {
      assemblies,
      page,
    },
  };
};

export const setAssembliesSuccess = (assemblies) => {
  return {
    type: types.SET_ASSEMBLIES_SUCCESS,
    payload: {
      assemblies,
    },
  };
};

export const createAssemblyInstanceSuccess = (id) => {
  return {
    type: types.CREATE_ASSEMBLY_INSTANCE_SUCCESS,
    payload: {
      id,
    },
  };
};

export const removeAssemblySuccess = (id) => {
  return {
    type: types.DELETE_ASSEMBLY_SUCCESS,
    payload: {
      id,
    },
  };
};

export const removeAssemblyOfGroupSuccess = (groupId) => {
  return {
    type: types.DELETE_ASSEMBLY_OF_GROUP_SUCCESS,
    payload: {
      groupId,
    },
  };
};

export const createAssemblySuccess = (assembly) => {
  return {
    type: types.CREATE_ASSEMBLY_SUCCESS,
    payload: {
      assembly,
    },
  };
};

export const highlightAssemblyDetailsSuccess = (data) => {
  return {
    type: types.HIGHLIGHT_ASSEMBLY_DETAILS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const editAssemblySuccess = (data) => {
  return {
    type: types.EDIT_ASSEMBLY_SUCCESS,
    payload: {
      data,
    },
  };
};

export const clearAssemblyInstanceSuccess = () => {
  return {
    type: types.CLEAR_ASSEMBLY_INSTANCE_SUCCESS,
  };
};

export const editAssemblyInstanceSuccess = (data) => {
  return {
    type: types.EDIT_ASSEMBLY_INSTANCE_SUCCESS,
    payload: { data },
  };
};

export const highlightAssemblyInstanceEmptyTagSuccess = (
  unhighlightAllProducts
) => {
  return {
    type: types.HIGHLIGHT_ASSEMBLY_INSTANCE_EMPTY_TAG_SUCCESS,
    payload: { unhighlightAllProducts },
  };
};

export const highlightAssemblyInstanceProductsSuccess = (
  products,
  unhighlightEmptyTag
) => {
  return {
    type: types.HIGHLIGHT_ASSEMBLY_INSTANCE_PRODUCTS_SUCCESS,
    payload: { products, unhighlightEmptyTag },
  };
};

export const getAssemblyInstanceLocationsSuccess = (locations) => {
  return {
    type: types.GET_ASSEMBLY_INSTANCE_LOCATIONS_SUCCESS,
    payload: {
      instanceLocations: locations,
    },
  };
};

export const getAssemblyInstanceConditionRatingsSuccess = (
  instanceConditionRatings
) => {
  return {
    type: types.GET_ASSEMBLY_INSTANCE_CONDITION_RATINGS_SUCCESS,
    payload: {
      instanceConditionRatings,
    },
  };
};

export const setAssemblyInstanceConditionRatingHighlightedIdSuccess = (
  highlightedId
) => {
  return {
    type: types.SET_ASSEMBLY_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID,
    payload: {
      highlightedId,
    },
  };
};

export const addAssemblyInstanceConditionRatingSuccess = (
  id,
  value,
  conditionRatings
) => {
  return {
    type: types.ADD_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS,
    payload: {
      id,
      value,
      conditionRatings,
    },
  };
};

export const deleteAssemblyInstanceConditionRatingSuccess = (
  newInstanceConditionRating,
  conditionRatings
) => {
  return {
    type: types.DELETE_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS,
    payload: {
      newInstanceConditionRating,
      conditionRatings,
    },
  };
};

export const getAssemblyInstancePropertiesFromDetailsSuccess = (
  instanceProperties
) => {
  return {
    type: types.GET_ASSEMBLY_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS,
    payload: {
      instanceProperties,
    },
  };
};

export const getAssemblyInstanceTimeTrackerHistorySuccess = (
  instanceTimeTrackerHistory
) => {
  return {
    type: types.GET_ASSEMBLY_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS,
    payload: {
      instanceTimeTrackerHistory,
    },
  };
};

export const setAssemblyInstanceTimeTrackerHighlightedIdSuccess = (
  highlightedId
) => {
  return {
    type: types.SET_ASSEMBLY_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID,
    payload: {
      highlightedId,
    },
  };
};

export const setAssemblyInstanceTimeTrackerPropertySuccess = (
  propertyId,
  propertyName,
  propertyValue,
  instanceTimeTrackerHistory,
  totalTimeNewValue
) => {
  return {
    type: types.SET_ASSEMBLY_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS,
    payload: {
      propertyId,
      propertyName,
      propertyValue,
      instanceTimeTrackerHistory,
      totalTimeNewValue,
    },
  };
};

export const highlightAssemblyThreadSuccess = (id) => {
  return {
    type: types.HIGHLIGHT_ASSEMBLY_THREAD_SUCCESS,
    payload: { id },
  };
};

export const getAssemblyThreadsSuccess = (threads) => {
  return {
    type: types.GET_ASSEMBLY_THREADS_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const getAssemblyInstanceThreadsSuccess = (threads) => {
  return {
    type: types.GET_ASSEMBLY_INSTANCE_THREADS_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const addAssemblyGeneralThreadSuccess = (threads) => {
  return {
    type: types.ADD_ASSEMBLY_GENERAL_THREAD_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const addAssemblyInstanceThreadSuccess = (threads) => {
  return {
    type: types.ADD_ASSEMBLY_INSTANCE_THREAD_SUCCESS,
    payload: {
      threads,
    },
  };
};

export const editAssemblyGeneralThreadSuccess = (id, thread) => {
  return {
    type: types.EDIT_ASSEMBLY_GENERAL_THREAD_SUCCESS,
    payload: {
      id,
      thread,
    },
  };
};

export const editAssemblyInstanceThreadSuccess = (id, thread) => {
  return {
    type: types.EDIT_ASSEMBLY_INSTANCE_THREAD_SUCCESS,
    payload: {
      id,
      thread,
    },
  };
};

export const removeAssemblyGeneralThreadSuccess = (id) => {
  return {
    type: types.DELETE_ASSEMBLY_GENERAL_THREAD_SUCCESS,
    payload: {
      id,
    },
  };
};

export const removeAssemblyInstanceThreadSuccess = (id) => {
  return {
    type: types.DELETE_ASSEMBLY_INSTANCE_THREAD_SUCCESS,
    payload: {
      id,
    },
  };
};

export const markAssemblyGeneralThreadAsDoneSuccess = (id) => {
  return {
    type: types.MARK_ASSEMBLY_GENERAL_THREAD_AS_DONE_SUCCESS,
    payload: {
      id,
    },
  };
};

export const markAssemblyInstanceThreadAsDoneSuccess = (id) => {
  return {
    type: types.MARK_ASSEMBLY_INSTANCE_THREAD_AS_DONE_SUCCESS,
    payload: {
      id,
    },
  };
};

export const renameAssemblyThreadFileSuccess = (fileId, filename, threadId) => {
  return {
    type: types.RENAME_ASSEMBLY_THREAD_FILE_SUCCESS,
    payload: {
      fileId,
      filename,
      threadId,
    },
  };
};

export const renameAssemblyInstanceThreadFileSuccess = (
  fileId,
  filename,
  threadId
) => {
  return {
    type: types.RENAME_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS,
    payload: {
      fileId,
      filename,
      threadId,
    },
  };
};

export const deleteAssemblyThreadFileSuccess = (threadId, fileId) => {
  return {
    type: types.DELETE_ASSEMBLY_THREAD_FILE_SUCCESS,
    payload: {
      threadId,
      fileId,
    },
  };
};

export const deleteAssemblyInstanceThreadFileSuccess = (threadId, fileId) => {
  return {
    type: types.DELETE_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS,
    payload: {
      threadId,
      fileId,
    },
  };
};

export const getAssembly = (id) => {
  return async (dispatch) => {
    const { data } = await fetchAssembly(id);
    dispatch(getAssemblySuccess(data));
  };
};

export const getAssemblyInstance = (id) => {
  return async (dispatch) => {
    const { data } = await fetchAssemblyInstance(id);
    dispatch(getAssemblyInstanceSuccess(data));
  };
};

export const addAssembliesToStart = () => {
  return async (dispatch) => {
    dispatch(addAssembliesToStartSuccess());
  };
};

export const setAssembliesToAddToStart = (assemblies = []) => {
  return async (dispatch) => {
    dispatch(setAssembliesToAddToStartSuccess(assemblies));
  };
};

export const setAssemblyIdsToFilterOutWhileFetching = (ids) => {
  return async (dispatch) => {
    dispatch(setAssemblyIdsToFilterOutWhileFetchingSuccess(ids));
  };
};

export const getAssemblies = ({
  PageSize = ASSEMBLIES_PER_PAGE,
  PageNumber = 1,
  SearchTerm,
  Sort = '',
}) => {
  return async (dispatch, getState) => {
    const groups = getState().product.selectedGroups;
    const search = getState().product.productsSearch;

    const isAnyFilterActive = !!(groups.length || SearchTerm || Sort);

    const { data } = await fetchAssemblies({
      FilterGroups: groups.map((group) => group.id),
      Sort,
      PageNumber,
      PageSize,
      SearchTerm: search || SearchTerm,
      ExcludeIds: !isAnyFilterActive
        ? getState().assembly.assemblyIdsToFilterOutWhileFetching
        : [],
    });

    dispatch(getAssembliesSuccess(data?.data, PageNumber));

    if (PageNumber === 1 && getState().assembly.assembliesToAddToStart.length) {
      data.data = [...getState().assembly.assembliesToAddToStart, ...data.data];
      dispatch(addAssembliesToStart());
    }

    return data;
  };
};

export const setAssemblies = (assemblies = []) => {
  return async (dispatch) => {
    dispatch(setAssembliesSuccess(assemblies));
  };
};

export const removeAssembly = (id) => {
  return async (dispatch) => {
    await deleteAssembly(id, {});
    dispatch(removeAssemblySuccess(id));
  };
};

export const removeAssemblyOfGroup = (groupId) => {
  return async (dispatch) => {
    dispatch(removeAssemblyOfGroupSuccess(groupId));
  };
};

export const createAssemblyInstance = (data) => {
  return async (dispatch) => {
    await postAssemblyInstance(data);

    dispatch(createAssemblyInstanceSuccess(data.assemblyId));

    const highlightedData = {
      highlightedAssembliesIds: [data.assemblyId],
      isFooterHighlighted: true,
      isHighlighted: false,
    };

    return { highlightedData };
  };
};

export const createAssembly = (args) => {
  return async (dispatch, getState) => {
    const { currentRouterLocation, ...data } = args;
    const response = await postAssembly(data);

    const id = getResourceFromLocation(response.headers?.location);
    const { data: assemblyData } = await fetchAssembly(id);

    const { productsSearch: search, selectedGroups } = getState().product;

    dispatch(
      setAssembliesToAddToStart([
        {
          ...assemblyData,
          assemblyInstanceCount: assemblyData.assemblyInstances.length,
          productImages: assemblyData.products.map((product) => ({
            productId: product.id,
            imageUrl: product.imageUrl,
          })),
          productCount: assemblyData.products.reduce(
            (previousValue, currentValue) => previousValue + currentValue.count,
            0
          ),
        },
      ])
    );

    // if assemblies tab is active and no filters are applied (infinite scroll will not be triggered)
    // add assembly to the top of the list
    if (
      isDatabaseAssembliesTabOpened(currentRouterLocation) &&
      !search &&
      !selectedGroups.length
    ) {
      dispatch(addAssembliesToStart());
    }

    const highlightedData = {
      highlightedAssembliesIds: [id],
      isFooterHighlighted: false,
      isHighlighted: true,
    };

    return { highlightedData };
  };
};

export const highlightAssemblyDetails = (highlightedData) => {
  return async (dispatch) => {
    dispatch(highlightAssemblyDetailsSuccess(highlightedData));
  };
};

export const editAssembly = (id, data) => {
  return async (dispatch, getState) => {
    await patchAssembly(id, data);
    const { data: assemblyData } = await fetchAssembly(id);

    const state = getState().assembly;
    const highlightedData = {
      details: {
        nameHighlighted:
          assemblyData.name && assemblyData.name !== state.details.name,
        descriptionHighlighted:
          assemblyData.description &&
          assemblyData.description !== state.details.description,
        highlightedProductsIds: assemblyData.products
          .filter(
            (product) =>
              !state.details.products?.find(({ id }) => id === product.id) ||
              !!state.details.products?.find(
                ({ id, count }) => id === product.id && count !== product.count
              )
          )
          .map(({ id }) => id),
        imageHighlighted: assemblyData.imageUrl !== state.details.imageUrl,
      },
      instance: {
        nicknameHighlighted: false,
        descriptionHighlighted: false,
        emptyTagHighlighted: false,
        highlightedProductsIds: assemblyData.products
          .filter(
            (product) =>
              state.details.products &&
              (!state.details.products.find(({ id }) => id === product.id) ||
                !!state.details.products?.find(
                  ({ id, count }) =>
                    id === product.id && count !== product.count
                ))
          )
          .map(({ id }) => id),
      },
    };

    dispatch(editAssemblySuccess(assemblyData));

    return { highlightedData };
  };
};

export const editAssemblyInstance = (id, data, products) => {
  return async (dispatch, getState) => {
    await patchAssemblyInstance(id, data);

    const state = getState().assembly;
    const highlightedData = {
      instance: {
        nicknameHighlighted:
          data.nickname && state.instance.nickname !== data.nickname,
        descriptionHighlighted:
          data.description && state.instance?.description !== data.description,
        emptyTagHighlighted: data.assemblyTag !== state.instance.assemblyTag,
        highlightedProductsIds: products
          ?.filter(({ isHighlighted }) => isHighlighted)
          .map(({ id }) => id),
      },
      details: {
        ...state.details,
        nameHighlighted: false,
        imageHighlighted: false,
      },
    };

    const instanceProducts = products?.map((prod) => ({
      ...prod,
      isHighlighted: false,
    }));

    dispatch(
      editAssemblyInstanceSuccess(
        products ? { ...data, products: instanceProducts } : data
      )
    );

    return { highlightedData };
  };
};

export const highlightAssemblyInstanceEmptyTag = (
  unhighlightAllProducts = false
) => {
  return async (dispatch) => {
    dispatch(highlightAssemblyInstanceEmptyTagSuccess(unhighlightAllProducts));
  };
};

export const highlightAssemblyInstanceProducts = (
  products,
  unhighlightEmptyTag = false
) => {
  return async (dispatch) => {
    dispatch(
      highlightAssemblyInstanceProductsSuccess(products, unhighlightEmptyTag)
    );
  };
};

export const removeAssemblyInstance = (id) => {
  return async () => {
    await deleteAssemblyInstance(id, {});
  };
};

export const clearAssemblyInstance = () => {
  return async (dispatch) => {
    dispatch(clearAssemblyInstanceSuccess());
  };
};

export const getAssemblyInstanceLocations = (id, sort = '') => {
  return async (dispatch) => {
    const { data } = await fetchAssemblyInstanceLocations({
      FilterAssembly: id,
      Sort: sort,
    });
    dispatch(getAssemblyInstanceLocationsSuccess(data));
  };
};

export const getAssemblyInstanceConditionRatings = (
  PropertyIds,
  HistoryId,
  PageNumber = 1,
  Sort = 'created_at_desc',
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch) => {
    const {
      data: conditionRatings,
    } = await fetchAssemblyInstanceNumericPropertyHistory({
      params: {
        PropertyIds,
        Sort,
        HistoryId,
        PageNumber,
        PageSize,
      },
    });

    const isNotificationEntryDeleted = HistoryId
      ? !conditionRatings.data?.find(({ id }) => id === HistoryId)
      : false;

    dispatch(getAssemblyInstanceConditionRatingsSuccess(conditionRatings.data));
    dispatch(
      getPaginationDetails(
        conditionRatings.currentPage,
        conditionRatings.pageCount
      )
    );
    return {
      conditionRatings: conditionRatings.data,
      isNotificationEntryDeleted,
    };
  };
};

export const setAssemblyInstanceConditionRatingHighlightedId = (id) => {
  return async (dispatch) => {
    dispatch(setAssemblyInstanceConditionRatingHighlightedIdSuccess(id));
  };
};

export const addAssemblyInstanceConditionRating = (
  conditionRatingId,
  value,
  PageNumber = 1,
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch) => {
    await postAssemblyInstanceNumericProperty(conditionRatingId, value);
    const {
      data: conditionRatings,
    } = await fetchAssemblyInstanceNumericPropertyHistory({
      params: {
        PropertyIds: conditionRatingId,
        PageNumber,
        PageSize,
        Sort: 'created_at_desc',
      },
    });
    dispatch(
      addAssemblyInstanceConditionRatingSuccess(
        conditionRatingId,
        value,
        conditionRatings?.data
      )
    );
    dispatch(
      getPaginationDetails(
        conditionRatings?.currentPage,
        conditionRatings?.pageCount
      )
    );

    return conditionRatings?.data[0].id;
  };
};

export const deleteAssemblyInstanceConditionRating = (
  conditionRatingId,
  instanceId,
  PageNumber = 1,
  Sort = 'created_at_desc',
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch, getState) => {
    await deleteAssemblyInstanceNumericPropertyHistoryEntry(conditionRatingId);
    const { data } = await fetchAssemblyInstance(instanceId);

    let conditionRatings = getState().assembly.instanceConditionRatings.data?.filter(
      (conditionRating) => conditionRating?.id !== conditionRatingId
    );

    if (!conditionRatings?.length && PageNumber !== 1) {
      const res = await fetchAssemblyInstanceNumericPropertyHistory({
        params: {
          PropertyIds: conditionRatingId,
          PageNumber: PageNumber - 1,
          PageSize,
          Sort,
        },
      });

      conditionRatings = res.data?.data;

      dispatch(
        getPaginationDetails(res.data?.currentPage, res.data?.pageCount)
      );
    }

    dispatch(
      deleteAssemblyInstanceConditionRatingSuccess(
        data.conditionRating,
        conditionRatings
      )
    );
  };
};

export const getAssemblyInstancePropertiesFromDetails = (
  instanceId,
  propertiesNames
) => {
  return async (dispatch) => {
    const { data } = await fetchAssemblyInstance(instanceId);
    const instanceProperties = propertiesNames?.reduce(
      (acc, propertyName) => ({
        ...acc,
        [propertyName]: data[propertyName],
      }),
      {}
    );

    dispatch(
      getAssemblyInstancePropertiesFromDetailsSuccess(instanceProperties)
    );
    return instanceProperties;
  };
};

export const getAssemblyInstanceTimeTrackerHistory = (
  PropertyIds,
  HistoryId,
  PageNumber = 1,
  PageSize = PROPERTIES_PER_PAGE,
  Sort = 'created_at_desc'
) => {
  return async (dispatch) => {
    const {
      data: timeTrackerHistory,
    } = await fetchAssemblyInstanceNumericPropertyHistory({
      params: {
        PropertyIds,
        Sort,
        PageNumber,
        PageSize,
        HistoryId,
      },
    });
    dispatch(
      getAssemblyInstanceTimeTrackerHistorySuccess(timeTrackerHistory?.data)
    );
    dispatch(
      getPaginationDetails(
        timeTrackerHistory?.currentPage,
        timeTrackerHistory?.pageCount
      )
    );
    return timeTrackerHistory?.data;
  };
};

export const setAssemblyInstanceTimeTrackerHighlightedId = (id) => {
  return async (dispatch) => {
    await dispatch(setAssemblyInstanceTimeTrackerHighlightedIdSuccess(id));
  };
};

export const setAssemblyInstanceTimeTrackerProperty = (
  propertyId,
  propertyName,
  propertyNewValue,
  valueToAddToState,
  usageTimeId,
  targetTimeId,
  totalTimeNewValue,
  PageNumber = 1,
  PageSize = PROPERTIES_PER_PAGE
) => {
  return async (dispatch) => {
    await postAssemblyInstanceNumericProperty(propertyId, propertyNewValue);
    const {
      data: timeTrackerHistory,
    } = await fetchAssemblyInstanceNumericPropertyHistory({
      params: {
        PropertyIds: [usageTimeId, targetTimeId],
        Sort: 'created_at_desc',
        PageNumber,
        PageSize,
      },
    });
    dispatch(
      setAssemblyInstanceTimeTrackerPropertySuccess(
        propertyId,
        propertyName,
        valueToAddToState,
        timeTrackerHistory?.data,
        totalTimeNewValue
      )
    );
    dispatch(
      getPaginationDetails(
        timeTrackerHistory?.currentPage,
        timeTrackerHistory?.pageCount
      )
    );
  };
};

export const highlightAssemblyThread = (id) => {
  return async (dispatch) => {
    dispatch(highlightAssemblyThreadSuccess(id));
  };
};

export const getAssemblyThreads = (id) => {
  return async (dispatch) => {
    const { data } = await fetchAssemblyThreads(id, {
      status: USER_THREAD_STATUS.TODO,
    });
    dispatch(getAssemblyThreadsSuccess(data?.data));
  };
};

export const getAssemblyInstanceThreads = (id) => {
  return async (dispatch) => {
    const { data } = await fetchAssemblyInstanceThreads(id, {
      status: USER_THREAD_STATUS.TODO,
    });
    dispatch(getAssemblyInstanceThreadsSuccess(data?.data));
  };
};

export const addAssemblyGeneralThread = (assemblyId, threadData) => {
  return async (dispatch) => {
    await postAssemblyGeneralThread(assemblyId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    const { data } = await fetchAssemblyThreads(assemblyId, {
      status: USER_THREAD_STATUS.TODO,
    });
    dispatch(addAssemblyGeneralThreadSuccess(data?.data));
    return data.data[0].id;
  };
};

export const addAssemblyInstanceThread = (instanceId, threadData) => {
  return async (dispatch) => {
    await postAssemblyInstanceThread(instanceId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    const { data } = await fetchAssemblyInstanceThreads(instanceId, {
      status: USER_THREAD_STATUS.TODO,
    });
    dispatch(addAssemblyInstanceThreadSuccess(data?.data));
    return data.data[0].id;
  };
};

export const editAssemblyGeneralThread = (threadId, threadData) => {
  return async (dispatch) => {
    await putThread(threadId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(editAssemblyGeneralThreadSuccess(threadId, threadData));
  };
};

export const editAssemblyInstanceThread = (instanceId, threadData) => {
  return async (dispatch) => {
    await putThread(instanceId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(editAssemblyInstanceThreadSuccess(instanceId, threadData));
  };
};

export const removeAssemblyGeneralThread = (id) => {
  return async (dispatch) => {
    await deleteThread(id);
    dispatch(removeAssemblyGeneralThreadSuccess(id));
  };
};

export const removeAssemblyInstanceThread = (id) => {
  return async (dispatch) => {
    await deleteThread(id);
    dispatch(removeAssemblyInstanceThreadSuccess(id));
  };
};

export const markAssemblyGeneralThreadAsDone = (threadId, threadData) => {
  return async (dispatch) => {
    await putThread(threadId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(markAssemblyGeneralThreadAsDoneSuccess(threadId));
  };
};

export const markAssemblyInstanceThreadAsDone = (instanceId, threadData) => {
  return async (dispatch) => {
    await putThread(instanceId, {
      ...threadData,
      files: threadData.files?.map(({ id }) => id),
    });
    dispatch(markAssemblyInstanceThreadAsDoneSuccess(instanceId));
  };
};

export const renameAssemblyThreadFile = (fileId, data, threadId) => {
  return async (dispatch) => {
    dispatch(renameAssemblyThreadFileSuccess(fileId, data.name, threadId));
  };
};

export const renameAssemblyInstanceThreadFile = (fileId, data, threadId) => {
  return async (dispatch) => {
    dispatch(
      renameAssemblyInstanceThreadFileSuccess(fileId, data.name, threadId)
    );
  };
};

export const deleteAssemblyThreadFile = (threadId, fileId) => {
  return async (dispatch) => {
    await deleteFile(fileId);
    dispatch(deleteAssemblyThreadFileSuccess(threadId, fileId));
  };
};

export const deleteAssemblyInstanceThreadFile = (threadId, fileId) => {
  return async (dispatch) => {
    await deleteFile(fileId);
    dispatch(deleteAssemblyInstanceThreadFileSuccess(threadId, fileId));
  };
};
