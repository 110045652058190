import * as types from '../constants';

export const changeReaderStateSuccess = (state) => {
  return {
    type: types.CHANGE_READER_STATE,
    payload: {
      state,
    },
  };
};

export const scanTagSuccess = (tag, type) => {
  return {
    type: types.SCAN_TAG_SUCCESS,
    payload: {
      tag,
      type,
    },
  };
};

export const setScanningSuccess = (isScanning) => {
  return {
    type: types.SET_SCANNING_SUCCESS,
    payload: {
      isScanning,
    },
  };
};

export const setFetchingSuccess = (isFetching) => {
  return {
    type: types.SET_FETCHING_SUCCESS,
    payload: {
      isFetching,
    },
  };
};

export const changeReaderState = (state) => {
  return async (dispatch) => {
    dispatch(changeReaderStateSuccess(state));
  };
};

export const scanTag = (tag, type) => {
  return async (dispatch) => {
    dispatch(scanTagSuccess(tag, type));
  };
};

export const setScanning = (isScanning) => {
  return async (dispatch) => {
    dispatch(setScanningSuccess(isScanning));
  };
};

export const setFetching = (isFetching) => {
  return async (dispatch) => {
    dispatch(setFetchingSuccess(isFetching));
  };
};
