export const getResourceFromLocation = (location) => {
  const resources = location
    .substring(location.lastIndexOf('/') + 1)
    .split('?');
  return resources[0];
};

export const getFileNameFromHeaders = (headers) => {
  const filenameSplitByQuotes = headers['content-disposition']
    .split(';')[1]
    .split('=')[1]
    .split('"');

  // this part is not consistent so we need to check
  return filenameSplitByQuotes[0].trim() === ''
    ? filenameSplitByQuotes[1]
    : filenameSplitByQuotes[0];
};
