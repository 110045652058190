import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';

import { routes, authRoutes } from '.';
import { sidebarRoutes } from './sidebarRoutes';
import UserLayout from '../layouts/UserLayout';
import AuthLayout from '../layouts/Auth';
import { useAuth0 } from '../react-auth0-spa';
import { useEventListener, usePageTitle } from '../hooks/Shared';
import { getPermissions } from '../utils/user';
import {
  getDevMode,
  setDevMode as setDevModeInLocalStorage,
} from '../utils/dev';
import { ORGANIZATION_TYPE, PERMISSIONS, ROUTES } from '../constants';
import { getUsersSidebarRoutes } from '../utils/sidebarRoutes';
import { setMyDetails } from '../redux/actions/userActions';

export const history = createBrowserHistory();

const PrivateRoute = ({
  path,
  component: Component,
  layout: Layout,
  permissionsSet,
  devMode,
  permissions: permissionsForRoute,
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const permissions = getPermissions();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path },
        });
      }
    })();
  }, [isAuthenticated, loginWithRedirect, path]);

  let isAllowed = false;

  if (!permissionsForRoute) {
    isAllowed = true;
  } else if (
    permissionsForRoute.every((permission) => permissions.includes(permission))
  ) {
    isAllowed = true;
  }

  if (!isAllowed) {
    return <Redirect to={getUsersSidebarRoutes(sidebarRoutes)[0]?.path} />;
  }

  return isAuthenticated ? (
    <Route
      path={isAllowed ? path : getUsersSidebarRoutes(sidebarRoutes)[0]?.path}
      exact
    >
      <Layout dev={devMode} permissionsSet={permissionsSet}>
        <Component />
      </Layout>
    </Route>
  ) : null;
};

PrivateRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  layout: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  permissionsSet: PropTypes.bool.isRequired,
  devMode: PropTypes.bool.isRequired,
  permissions: PropTypes.array,
};

const Routes = ({ permissionsSet, userDetailsData, setMyDetails }) => {
  const { isAuthenticated } = useAuth0();
  const [devMode, setDevMode] = useState(getDevMode());
  const allPermissions = getPermissions();
  usePageTitle();

  const { location } = history;
  const parsedQuery = queryString.parse(location.search);

  useEffect(() => {
    if (userDetailsData) {
      setMyDetails(userDetailsData.organization, userDetailsData.language);
    }

    return () => {
      setMyDetails();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsData]);

  // This listener allows user to view certain aspects of app for debugging purposes
  useEventListener('keydown', (e) => {
    if (
      (e.metaKey && e.ctrlKey && e.keyCode === 76) ||
      (e.ctrlKey && e.altKey && e.keyCode === 76)
    ) {
      setDevMode(!devMode);
      setDevModeInLocalStorage(!devMode);
    }
  });

  const getUsersInitialSidebarRoute = () => {
    if (
      userDetailsData.organization.type === ORGANIZATION_TYPE.CUSTOMER &&
      allPermissions.includes(PERMISSIONS.VIEW_RECONDITIONING_IN_SIDEBAR)
    ) {
      return getUsersSidebarRoutes(sidebarRoutes)[1];
    }
    return getUsersSidebarRoutes(sidebarRoutes)[0];
  };

  return (
    <Router history={history}>
      <Switch>
        {routes
          .filter(({ permissions }) =>
            permissions
              ? permissions.every((permission) =>
                  allPermissions.includes(permission)
                )
              : true
          )
          .map(({ component: Component, ...rest }, index) => {
            return (
              <PrivateRoute
                exact
                key={index}
                layout={UserLayout}
                devMode={devMode}
                permissionsSet={permissionsSet}
                component={Component}
                {...rest}
              />
            );
          })}
        {authRoutes.map(({ component: Component, ...rest }, index) => {
          return (
            <Route exact key={index} {...rest}>
              <AuthLayout>
                <Component />
              </AuthLayout>
            </Route>
          );
        })}
        <Route exact path='/*'>
          <Redirect
            to={
              isAuthenticated
                ? {
                    pathname: getUsersInitialSidebarRoute().path,
                    search: getUsersInitialSidebarRoute().search || '',
                  }
                : {
                    pathname: ROUTES.LOGIN,
                    state: parsedQuery,
                  }
            }
          />
        </Route>
      </Switch>
    </Router>
  );
};

Routes.propTypes = {
  permissionsSet: PropTypes.bool.isRequired,
  userDetailsData: PropTypes.object,
  setMyDetails: PropTypes.func,
};

export default connect(null, { setMyDetails })(Routes);
