export const extractExtension = (fileName) => {
  const parts = fileName?.split('.');
  return parts?.length ? `.${parts[parts.length - 1]}` : '';
};

export const extractFileNameWithoutExtension = (fileName) => {
  const parts = fileName?.split('.');
  return parts.reduce(
    (acc, value, index) =>
      parts.length - 1 === index
        ? acc
        : `${acc + value}${index < parts.length - 2 ? '.' : ''}`,
    ''
  );
};

export const renameFile = (originalFile, newName) =>
  new File([originalFile], newName);

export const renameDuplicates = (files) => {
  const map = {};
  const newFileName = (name, n) =>
    `${extractFileNameWithoutExtension(name)}(${n})${extractExtension(name)}`;

  return files.map((file) => {
    let n = map[file.name] || 0;

    map[file.name] = n + 1;

    if (!n) {
      return file;
    }

    while (map[renameFile(file, newFileName(file.name, n)).name]) {
      n += 1;
    }

    const renamedFile = renameFile(file, newFileName(file.name, n));
    map[renamedFile.name] = 1;

    return renamedFile;
  });
};

// used for UploadFiles component
export const addFilesErrorMessage = (setFileErrorMessages, message) =>
  setFileErrorMessages((prevState) =>
    prevState ? `${prevState}, ${message}` : message
  );

export const isFilesArrayChanged = (oldArray, newArray) =>
  oldArray &&
  newArray &&
  (!(oldArray.length === newArray.length) ||
    !oldArray.every(({ id }, index) => id === newArray[index]?.id));
