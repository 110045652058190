import async from '../components/Async';
import { PERMISSIONS, ROUTES } from '../constants';
import { sidebarRoutes } from './sidebarRoutes';

const SignIn = async(() => import('../containers/UserContainers/SignIn'));
const GroupInfo = async(() =>
  import('../containers/GroupContainers/GroupInfo')
);
const ProductDetails = async(() =>
  import('../containers/ProductContainers/ProductDetails')
);
const AssemblyDetails = async(() =>
  import('../containers/AssemblyContainers/AssemblyDetails')
);
const CustomerBox = async(() =>
  import('../containers/ReconditioningContainers/Box')
);
const OrderDetails = async(() =>
  import('../components/ReconditioningComponents/OrderDetails')
);
const ReconCenterOrderDetails = async(() =>
  import('../containers/ReconditioningCenterContainers/OrderDetails')
);
const ReconCenterBox = async(() =>
  import('../containers/ReconditioningCenterContainers/Box')
);
const AuthenticationRequests = async(() =>
  import(
    '../containers/CounterfeitRiskContainers/EndCustomer/AuthenticationRequests'
  )
);
const AuthenticationRequestDetailsEndCustomer = async(() =>
  import(
    '../containers/CounterfeitRiskContainers/EndCustomer/AuthenticationRequestDetails'
  )
);
const AuthenticationRequestDetailsLegal = async(() =>
  import(
    '../containers/CounterfeitRiskContainers/LegalTeam/AuthenticationRequestDetails'
  )
);
const Profile = async(() => import('../containers/UserContainers/Profile'));
const Messages = async(() => import('../containers/UserContainers/Messages'));
const Settings = async(() => import('../containers/UserContainers/Settings'));

export const navbarRoutes = [
  {
    path: `${ROUTES.PROFILE}`,
    name: 'Profile',
    component: Profile,
  },
  {
    path: `${ROUTES.MESSAGES}`,
    name: 'Messages',
    permission: PERMISSIONS.VIEW_SHARE,
    component: Messages,
  },
  {
    path: `${ROUTES.SETTINGS}`,
    name: 'Settings',
    component: Settings,
  },
];

export const authRoutes = [
  {
    path: `${ROUTES.LOGIN}`,
    name: 'Sign In',
    component: SignIn,
  },
];

export const routes = [
  {
    path: [`${ROUTES.GROUPS}/:id`, `${ROUTES.ORGANIZATION}`],
    component: GroupInfo,
  },
  {
    path: [
      `${ROUTES.DATABASE_PRODUCTS}/:id/tag/:tag`,
      `${ROUTES.DATABASE_PRODUCTS}/:id`,
    ],
    component: ProductDetails,
  },
  {
    path: [
      `${ROUTES.DATABASE_ASSEMBLIES}/:id/instance/:instanceId/product/:productId/tag/:tag`,
      `${ROUTES.DATABASE_ASSEMBLIES}/:id/instance/:instanceId/tag/:tag`,
      `${ROUTES.DATABASE_ASSEMBLIES}/:id/instance/:instanceId`,
      `${ROUTES.DATABASE_ASSEMBLIES}/:id`,
    ],
    component: AssemblyDetails,
    permissions: [PERMISSIONS.VIEW_ASSEMBLY],
  },
  {
    path: [
      `${ROUTES.RECONDITIONING_BOX}/:boxId`,
      `${ROUTES.RECONDITIONING_ORDER}/:orderId/box/:boxId`,
    ],

    component: CustomerBox,
  },
  {
    path: [`${ROUTES.RECONDITIONING_ORDER}/:orderId`],
    component: OrderDetails,
    permissions: [PERMISSIONS.VIEW_RECONDITION],
  },
  {
    path: [`${ROUTES.RC}/order/:orderId`],
    component: ReconCenterOrderDetails,
  },
  {
    path: [`${ROUTES.RC}/order/:orderId/box/:boxId`],
    component: ReconCenterBox,
  },
  {
    path: [ROUTES.AUTHENTICATION_REQUESTS],
    component: AuthenticationRequests,
    permissions: [PERMISSIONS.READ_COUNTERFEIT],
  },
  {
    path: [`${ROUTES.AUTHENTICATION_REQUESTS}/:requestId`],
    component: AuthenticationRequestDetailsEndCustomer,
  },
  {
    path: [
      `${ROUTES.LEGAL_INBOX}/request/:requestId`,
      `${ROUTES.LEGAL_INSPECTION}/request/:requestId`,
      `${ROUTES.LEGAL_RESPONSE}/request/:requestId`,
      `${ROUTES.LEGAL_ARCHIVE}/request/:requestId`,
    ],
    component: AuthenticationRequestDetailsLegal,
  },
  ...sidebarRoutes,
  ...navbarRoutes,
];
