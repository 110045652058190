import React, { forwardRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import Img from 'react-image';
import {
  isSameDay,
  startOfToday,
  endOfDay,
  roundToNearestMinutes,
  isBefore,
  addMinutes,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { deleteInput } from '../../assets/icon';
import { ReactComponent as ClockIcon } from '../../assets/icon/ic_clock.svg';
import { DATE_FORMATS } from '../../constants';

const TimePicker = ({
  value = null,
  handleUpdateTime,
  handleRemoveTime,
  errorMessage,
  containerClassName = '',
}) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const offsetMinutes = 2;

  useEffect(() => {
    if (value && isBefore(new Date(value), currentDate)) {
      // adding offset = 2min so the minutes would round on UP value of interval of 5 minutes
      const newTime = addMinutes(currentDate, offsetMinutes);
      handleUpdateTime(
        roundToNearestMinutes(newTime, {
          nearestTo: 5,
          roundingMethod: 'ceil',
        })
      );
    }
  });

  // eslint-disable-next-line react/prop-types
  const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <button
      ref={ref}
      className={cs('d-flex align-items-center react-datepicker-custom-input', {
        'react-datepicker-container-error-message': errorMessage,
        'react-datepicker-custom-input-opacity': !value,
      })}
    >
      <div onClick={onClick} className='flex-grow-1 d-flex'>
        {value || placeholder}
      </div>
      {!!handleRemoveTime && value && (
        <Img
          className='cursor-pointer ml-2'
          src={deleteInput}
          onClick={handleRemoveTime}
        />
      )}
      <ClockIcon
        height={24}
        width={24}
        className={`ml-2 icon-${errorMessage ? 'danger' : 'txt-primary'}`}
      />
    </button>
  ));
  return (
    <div className={containerClassName}>
      <ReactDatePicker
        className='mt-3'
        selected={value ? new Date(value) : null}
        onChange={handleUpdateTime}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        dateFormat={DATE_FORMATS.TIME}
        timeFormat={DATE_FORMATS.TIME}
        minTime={
          isSameDay(new Date(value), currentDate)
            ? addMinutes(currentDate, offsetMinutes)
            : startOfToday()
        }
        maxTime={endOfDay(currentDate)}
        showPopperArrow={false}
        customInput={<CustomInput />}
        placeholderText={t('containers.time')}
        popperClassName='react-datepicker-custom-popper'
      />
      {errorMessage && (
        <div className='react-datepicker-error-message'>{errorMessage}</div>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  value: PropTypes.any,
  handleUpdateTime: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleRemoveTime: PropTypes.func,
  containerClassName: PropTypes.string,
};

export default TimePicker;
