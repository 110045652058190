import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import SmallModalStep from './SmallModalStep';
import { deleteFile } from '../../../client/file';

const debug = _debug('Bridge:DeleteFileModal');

const DeleteFileModal = ({
  fileId,
  isOpened,
  handleClose,
  handleDeleteFile = (id) => deleteFile(id),
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleCancelModal = () => {
    setIsLoading(false);
    handleClose();
  };

  const handleFileDeleting = async () => {
    try {
      setIsLoading(true);
      await handleDeleteFile(fileId);
      onSuccess(fileId);
      handleCancelModal();
    } catch (e) {
      debug(
        `Deleting file failed. Reason: ${_get(e, 'response.data.message', e)}`
      );
    }
    setIsLoading(false);
  };

  return (
    <Modal
      size='sm'
      contentClassName='modal-height-sm modal-sm-box-shadow'
      isOpened={isOpened}
    >
      <SmallModalStep
        title={t('modal.deleteFile')}
        handleClickLeftButton={handleCancelModal}
        leftButtonContent={t('button.cancel').toUpperCase()}
        handleClickRightButton={handleFileDeleting}
        rightButtonContent={t('button.delete').toUpperCase()}
        isLoading={isLoading}
        rightButtonClassname='modal-sm-warning-button'
      >
        {t('modal.areYouSureYouWantToDeleteThisFile')}
      </SmallModalStep>
    </Modal>
  );
};

DeleteFileModal.propTypes = {
  fileId: PropTypes.any.isRequired,
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default DeleteFileModal;
