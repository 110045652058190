import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CheckIcon } from '../../../assets/icon/ic_msg_checkmark.svg';

const Done = ({ title, subtitle, width = 90, height = 90 }) => {
  return (
    <div className='d-flex flex-column h-100'>
      <div className='modal-title text-center pt-5 pb-5'>{title}</div>
      <div className='d-flex flex-column flex-grow-1 justify-content-center'>
        <div className='text-center modal-loader-padding'>
          <CheckIcon className='icon-secondary' width={width} height={height} />
          <div className='modal-title modal-loader-title text-center pt-5'>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
};

Done.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Done;
