import React from 'react';
import { ToastContainer } from 'react-toastify';

const ToastWrapper = () => {
  return (
    <ToastContainer
      position='bottom-center'
      autoClose={3000}
      hideProgressBar
      theme='dark'
      closeOnClick={false}
      rtl={false}
      draggable={false}
    />
  );
};

export default ToastWrapper;
