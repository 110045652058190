import React from 'react';

import { CardFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CircleButton from './CircleButton';
import { ReactComponent as GroupIcon } from '../../assets/icon/ic_group_name.svg';
import { ReactComponent as AssembliesTag } from '../../assets/icon/ic-assemblytag-blue.svg';
import { ReactComponent as DataMatrixIcon } from '../../assets/icon/ic-datamatrix-primary.svg';
import { ReactComponent as ReconditionIcon } from '../../assets/icon/ic_recondition.svg';
import {
  FIREBASE_EVENTS,
  HORIZONTAL_TAB_QUERY,
  PERMISSIONS,
  ROUTES,
  VERTICAL_TAB_QUERY,
} from '../../constants';
import AddNewButton from './AddNewButton';
import { getPermissions } from '../../utils/user';
import { TAB_LABELS } from '../../containers/ProductContainers/const';
import { OutlinedBox } from './index';
import { generateQueryString } from '../../utils/tabs';
import { handleAnalytics } from '../../utils/analytics';

const GroupButton = ({ onClick, label, disabled }) => {
  return (
    <CircleButton
      handleClick={onClick}
      label={label}
      labelClassName='text-truncate'
      disabled={disabled}
    >
      <GroupIcon className='icon-secondary' height={24} width={24} />
    </CircleButton>
  );
};

GroupButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const AddButton = ({ onClick }) => {
  return (
    <AddNewButton
      size={24}
      optionalClassName='add-new-circle-small'
      handleClick={onClick}
    />
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const ComponentsButton = ({
  componentsNumber,
  labelClassName = '',
  onClick = () => {},
}) => {
  return (
    <CircleButton
      disabled={!componentsNumber}
      label={componentsNumber}
      handleClick={onClick}
      labelClassName={`my-auto pl-2 pr-3 ${labelClassName}`}
    >
      <AssembliesTag className='icon-secondary' />
    </CircleButton>
  );
};

ComponentsButton.propTypes = {
  componentsNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  labelClassName: PropTypes.string,
};

export const ReconditionButton = ({
  reconditionId,
  productInstanceCount,
  onClick = () => {},
}) => {
  return (
    <CircleButton
      disabled={!reconditionId || !productInstanceCount}
      optionalClassName='mr-4'
      handleClick={onClick}
    >
      <ReconditionIcon
        height={23}
        width={23}
        className={`icon-${reconditionId ? 'secondary' : 'txt-disabled'}`}
      />
    </CircleButton>
  );
};

ReconditionButton.propTypes = {
  reconditionId: PropTypes.string,
  productInstanceCount: PropTypes.number,
  onClick: PropTypes.func,
};

export const InstanceButton = ({
  instanceCount,
  onClick,
  labelClassName = '',
  optionalClassName = '',
}) => {
  return (
    <CircleButton
      disabled={!instanceCount}
      handleClick={onClick}
      label={instanceCount}
      labelClassName={`pl-2 pr-3 text-nowrap ${labelClassName}`}
      optionalClassName={optionalClassName}
    >
      <DataMatrixIcon className='icon-secondary' height={20} width={20} />
    </CircleButton>
  );
};

InstanceButton.propTypes = {
  instanceCount: PropTypes.number.isRequired,
  labelClassName: PropTypes.string,
  optionalClassName: PropTypes.string,
  onClick: PropTypes.func,
};

const ItemCardFooter = ({ disabled, highlighted, ...rest }) => {
  return (
    <CardFooter
      className={cs('text-secondary card-footer', {
        disabled,
        highlighted,
      })}
      {...rest}
    />
  );
};

ItemCardFooter.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
};

export const ProductCardFooter = ({ product, handleAddButton }) => {
  const {
    id,
    isFooterHighlighted,
    groupId,
    groupName,
    productInstanceCount,
    reconditionId,
  } = product;
  const history = useHistory();
  const permissions = getPermissions();

  return (
    <ItemCardFooter highlighted={isFooterHighlighted}>
      <div className='d-flex justify-content-between'>
        <GroupButton
          label={groupName}
          onClick={() => history.push(`${ROUTES.GROUPS}/${groupId}`)}
          disabled={!permissions.includes(PERMISSIONS.READ_GROUP)}
        />
        <div className='d-flex'>
          <InstanceButton
            onClick={() => {
              history.push({
                pathname: `${ROUTES.DATABASE_PRODUCTS}/${id}`,
                search: generateQueryString({
                  [HORIZONTAL_TAB_QUERY]: TAB_LABELS.ITEM_DETAILS,
                  [VERTICAL_TAB_QUERY]: TAB_LABELS.PRODUCT_ITEMS,
                }),
              });
              handleAnalytics(
                FIREBASE_EVENTS.PRODUCT_INSTANCES_TAB_VIEW_FROM_DATABASE_SCREEN
              );
            }}
            instanceCount={productInstanceCount}
          />
          {permissions.includes(PERMISSIONS.VIEW_RECONDITION) && (
            <ReconditionButton
              reconditionId={reconditionId}
              productInstanceCount={productInstanceCount}
              onClick={() =>
                history.push({
                  pathname: `${ROUTES.DATABASE_PRODUCTS}/${id}`,
                  search: generateQueryString({
                    [HORIZONTAL_TAB_QUERY]: TAB_LABELS.ITEM_DETAILS,
                    [VERTICAL_TAB_QUERY]: TAB_LABELS.RECONDITIONS,
                  }),
                })
              }
            />
          )}
          {permissions.includes(PERMISSIONS.CREATE_SYNC) &&
            permissions.includes(PERMISSIONS.SCAN) && (
              <AddButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddButton(product);
                }}
              />
            )}
        </div>
      </div>
    </ItemCardFooter>
  );
};

ProductCardFooter.propTypes = {
  product: PropTypes.object.isRequired,
  handleAddButton: PropTypes.func.isRequired,
};

export const ExternalProductCardFooter = ({ product, handleAddButton }) => {
  const permissions = getPermissions();

  return (
    permissions.includes(PERMISSIONS.PRODUCT_CREATE) && (
      <ItemCardFooter>
        <div className='d-flex justify-content-end'>
          <AddButton
            onClick={(e) => {
              e.stopPropagation();
              handleAddButton(product);
            }}
          />
        </div>
      </ItemCardFooter>
    )
  );
};

ExternalProductCardFooter.propTypes = {
  product: PropTypes.object.isRequired,
  handleAddButton: PropTypes.func.isRequired,
};

export const ProductAssembliesInstanceCardFooter = ({ assembly }) => {
  const { groupName } = assembly;
  return (
    <ItemCardFooter disabled>
      <GroupButton label={groupName} />
    </ItemCardFooter>
  );
};

ProductAssembliesInstanceCardFooter.propTypes = {
  assembly: PropTypes.object.isRequired,
};

export const ProductAssembliesCardFooter = ({ assembly }) => {
  const { groupName, productCount, assemblyInstanceCount } = assembly;

  return (
    <ItemCardFooter disabled>
      <div className='d-flex justify-content-between'>
        <GroupButton label={groupName} />
        <div className='d-flex'>
          <ComponentsButton componentsNumber={productCount} />
          <InstanceButton instanceCount={assemblyInstanceCount} />
        </div>
      </div>
    </ItemCardFooter>
  );
};

ProductAssembliesCardFooter.propTypes = {
  assembly: PropTypes.object.isRequired,
};

export const AssemblyComponentCardFooter = ({ product }) => {
  const { productInstanceCount, count } = product;
  const { t } = useTranslation();

  return (
    <ItemCardFooter>
      <div className='d-flex justify-content-end align-items-center'>
        {productInstanceCount >= 0 && (
          <OutlinedBox
            value={productInstanceCount}
            label={
              <DataMatrixIcon
                className='icon-secondary'
                height={20}
                width={20}
              />
            }
            className='mr-3'
          />
        )}
        <OutlinedBox value={count} label={t('components.qty')} />
      </div>
    </ItemCardFooter>
  );
};

AssemblyComponentCardFooter.propTypes = {
  product: PropTypes.object.isRequired,
};

export const AssemblyCardFooter = ({ assembly, handleAddButton }) => {
  const {
    id,
    groupName,
    groupId,
    productCount,
    assemblyInstanceCount,
    isFooterHighlighted,
  } = assembly;

  const history = useHistory();
  const permissions = getPermissions();

  return (
    <ItemCardFooter highlighted={isFooterHighlighted}>
      <div className='d-flex justify-content-between'>
        <GroupButton
          label={groupName}
          onClick={(e) => {
            e.stopPropagation();
            if (permissions.includes(PERMISSIONS.READ_GROUP)) {
              history.push(`${ROUTES.GROUPS}/${groupId}`);
            }
          }}
        />

        <div className='d-flex'>
          <ComponentsButton
            componentsNumber={productCount}
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: `${ROUTES.DATABASE_ASSEMBLIES}/${id}`,
                search: generateQueryString({
                  [HORIZONTAL_TAB_QUERY]: TAB_LABELS.PRODUCT_DATA,
                  [VERTICAL_TAB_QUERY]: TAB_LABELS.COMPONENTS,
                }),
              });
            }}
          />
          <InstanceButton
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: `${ROUTES.DATABASE_ASSEMBLIES}/${id}`,
                search: generateQueryString({
                  [HORIZONTAL_TAB_QUERY]: TAB_LABELS.ITEM_DETAILS,
                  [VERTICAL_TAB_QUERY]: TAB_LABELS.ASSEMBLY_ITEMS,
                }),
              });
              handleAnalytics(
                FIREBASE_EVENTS.ASSEMBLY_INSTANCES_TAB_VIEW_FROM_DATABASE_SCREEN
              );
            }}
            instanceCount={assemblyInstanceCount}
          />
          {permissions.includes(PERMISSIONS.CREATE_SYNC) &&
            permissions.includes(PERMISSIONS.SCAN) && (
              <AddButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddButton(id);
                }}
              />
            )}
        </div>
      </div>
    </ItemCardFooter>
  );
};

AssemblyCardFooter.propTypes = {
  assembly: PropTypes.object.isRequired,
  handleAddButton: PropTypes.func.isRequired,
};
