import _debug from 'debug';
import {
  GEOLOCATION_MAX_CACHE_AGE,
  GEOLOCATION_TIMEOUT,
  PERMISSIONS,
} from '../constants';
import { getPermissions } from './user';
import { getDevMode } from './dev';

const debug = _debug('Bridge:Geolocation');

// Geolocation turned off by default. See comment bellow for clarification.
const geolocationActive = false;

export const getMockLocation = () => {
  const mockLocation = JSON.parse(localStorage.getItem('mockLocation')) || {};
  if (
    'lng' in mockLocation &&
    'lat' in mockLocation &&
    'accuracy' in mockLocation
  ) {
    return mockLocation;
  }
  return null;
};

export const getCurrentLocation = async ({
  active = geolocationActive,
} = {}) => {
  return new Promise((resolve) => {
    const mockLocation = getMockLocation();
    if (
      mockLocation &&
      getDevMode() &&
      getPermissions().includes(PERMISSIONS.TEST_SCAN)
    ) {
      return resolve({
        lng: mockLocation.lng,
        lat: mockLocation.lat,
        accuracy: mockLocation.accuracy,
      });
    }

    // Geolocation needs to be turned off due to accuracy reasons
    // Jira ticket TI-7394
    if (!active) {
      return resolve({ lng: null, lat: null, accuracy: null });
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          resolve({
            lng: coords.longitude,
            lat: coords.latitude,
            accuracy: coords.accuracy,
          });
        },
        (err) => {
          debug(`Location error! Reason: ${err}`);
          resolve({ lng: null, lat: null, accuracy: null });
        },
        {
          enableHighAccuracy: true,
          timeout: GEOLOCATION_TIMEOUT,
          maximumAge: GEOLOCATION_MAX_CACHE_AGE, // maximum cached position age
        }
      );
    } else {
      debug(`Location error! No geolocation`);
      resolve({ lng: null, lat: null, accuracy: null });
    }
  });
};
