import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _debug from 'debug';
import { connect } from 'react-redux';

import { MAX_NUMBER_OF_CHARACTERS } from '../../../constants';
import { OutlinedField, CustomForm } from '../../Shared';
import SmallModalStep from './SmallModalStep';
import Modal from '../Modal';
import { isStringValid } from '../../../utils/shared';
import { updateFile } from '../../../client/file';

const debug = _debug('Bridge:RenameFileModal');

const RenameFileModal = ({
  handleCancelModal,
  isOpened,
  fileId,
  oldName,
  onSuccess = () => {},
  invalidFilenameCharacters,
}) => {
  const { t } = useTranslation();

  const [newName, setNewName] = useState(oldName || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleRenameFile = async () => {
    setIsLoading(true);
    const data = { name: newName };
    try {
      await updateFile(fileId, data);
      handleCancelModal();
      onSuccess(fileId, data);
    } catch (e) {
      debug(`Couldn't rename file. Reason: ${e}`);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleRenameFile();
  };

  const getErrorMessage = () => {
    if (!newName.trim()) {
      return t('error.fileNameCannotBeEmpty');
    }
    if (!isStringValid(newName, invalidFilenameCharacters)) {
      return t('error.invalidCharacters', {
        invalidCharacters: invalidFilenameCharacters.toString(),
      });
    }
    return t('error.newValueCantBeTheSameAsTheOldOne');
  };

  const isFileNameValid = (value = '') =>
    value.trim() &&
    value.trim() !== oldName &&
    isStringValid(value, invalidFilenameCharacters);

  return (
    <Modal
      size='sm'
      contentClassName='modal-sm-fit-content modal-sm-box-shadow'
      isOpened={isOpened}
    >
      <SmallModalStep
        title={t('modal.editFilename')}
        isLoading={isLoading}
        handleClickLeftButton={handleCancelModal}
        leftButtonContent={t('button.cancel').toUpperCase()}
        handleClickRightButton={handleRenameFile}
        rightButtonContent={t('button.save').toUpperCase()}
        rightButtonDisabled={!isFileNameValid(newName)}
      >
        <CustomForm className='mt-2 w-100' onSubmit={handleSubmit}>
          <OutlinedField
            placeholder={t('modal.filename')}
            handleChange={(e) => setNewName(e.target.value)}
            handleClearInput={() => setNewName('')}
            value={newName}
            valid={(value) => isFileNameValid(value)}
            maxNumberOfCharacters={MAX_NUMBER_OF_CHARACTERS.DESCRIPTION}
            feedback={getErrorMessage()}
          />
        </CustomForm>
      </SmallModalStep>
    </Modal>
  );
};

RenameFileModal.propTypes = {
  handleCancelModal: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  fileId: PropTypes.string.isRequired,
  oldName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  invalidFilenameCharacters: PropTypes.array,
};

export default connect(({ application }) => ({
  invalidFilenameCharacters: application.invalidFilenameCharacters,
}))(RenameFileModal);
