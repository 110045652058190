import { useEffect, useState } from 'react';

const useHighlight = (
  id,
  removeHighlight = () => {} // this function takes an array as an argument
) => {
  const [wasHighlighted, setWasHighlighted] = useState(false);
  const [wasAnimationEnded, setWasAnimationEnded] = useState(false);

  const handleAnimationEnd = () => {
    removeHighlight([id]);
    setWasAnimationEnded(true);
  };

  const handleAnimationStart = () => setWasHighlighted(true);

  useEffect(() => {
    return () => wasHighlighted && !wasAnimationEnded && handleAnimationEnd();

    // eslint-disable-next-line
  }, [wasHighlighted]);

  return { handleAnimationStart, handleAnimationEnd };
};

export default useHighlight;
