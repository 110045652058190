import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { connect } from 'react-redux';
import { Input, Label, FormGroup, Collapse } from 'reactstrap';

import { changeReaderState, scanTag } from '../../redux/actions/readerActions';
import { ReactComponent as DataMatrixCircleIcon } from '../../assets/icon/ic_datamatrix.svg';
import {
  INSTANCE_CODE_TYPES,
  TEMPLATE_CODE_TYPES,
  THEME_COLORS_NAMES,
} from '../../constants';
import Button from './Button';

const debug = _debug('Bridge:ScanInput');

/**
 * Shared component for simulating scan tag with text input.
 * Used for automated testing.
 */
const ScanInput = ({ scanTag, handleClose }) => {
  const [mockTag, setMockTag] = useState('');
  const [locationOption, setLocationOption] = useState(false);
  const [codeType, setCodeType] = useState(null);
  const [infoMessage, setInfoMessage] = useState('');
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
    accuracy: 10,
  });

  const codeTypes = [
    ...Object.values(TEMPLATE_CODE_TYPES),
    INSTANCE_CODE_TYPES.DATA_MATRIX,
  ];

  const handleScan = (e) => {
    e.preventDefault();
    try {
      debug(`Tag scanned: ${mockTag}`);
      scanTag(mockTag, codeType);
      handleClose();
    } catch (e) {
      debug(`Couldn't scan tag. Reason: ${e}.`);
      debug(`Tag: ${mockTag}. Code type: ${codeType}`);
    }
  };

  const storeFakeLocation = () => {
    setInfoMessage('Stored!');
    localStorage.setItem('mockLocation', JSON.stringify(location));
  };
  const clearFakeLocation = () => {
    setInfoMessage('Cleared!');
    localStorage.removeItem('mockLocation');
  };

  return (
    <div id='simulator-container' className='flex-column d-flex p-1'>
      <div className='flex-row d-flex p-1'>
        <form onSubmit={handleScan} className='w-100'>
          <div className='d-flex mb-2' id='scan-container'>
            <div>
              <Input
                autoFocus
                id='scan-input'
                value={mockTag}
                placeholder='Code'
                onChange={(e) => setMockTag(e.target.value)}
              />
            </div>
            <div className='pl-2 d-flex align-items-center'>
              <FormGroup check>
                <Label check>
                  <Input
                    type='checkbox'
                    onChange={() => setLocationOption(!locationOption)}
                    value={locationOption}
                  />
                  <div>Location</div>
                </Label>
              </FormGroup>
            </div>
            <div className='d-flex justify-content-end flex-grow-1'>
              <DataMatrixCircleIcon
                className='cursor-pointer icon-secondary'
                id='scan-button'
                width={26}
                height={26}
                onClick={handleScan}
              />
            </div>
          </div>
          <Input
            className='w-50'
            type='select'
            name='select'
            id='exampleSelect'
            onChange={(e) => setCodeType(e.target.value)}
          >
            {codeTypes.map((value) => (
              <option>{value}</option>
            ))}
          </Input>
        </form>
      </div>
      <Collapse isOpen={locationOption}>
        <div>
          <div className='text-danger pb-1'>
            Warning! Fake location will be stored until you clear it.
          </div>
          <div className='d-flex justify-content-between'>
            <div className='d-flex pr-1'>
              <div className='d-flex align-items-center font-weight-bolder pr-1'>
                Lat:
              </div>
              <Input
                id='mock-location-latitude'
                value={location.lat}
                onChange={(e) => {
                  setInfoMessage('');
                  setLocation({
                    ...location,
                    lat: e.target.value,
                  });
                }}
              />
            </div>
            <div className='d-flex pr-1 flex-grow-1'>
              <div className='d-flex align-items-center font-weight-bolder pr-1'>
                Long:
              </div>
              <Input
                id='mock-location-longitude'
                value={location.lng}
                onChange={(e) => {
                  setInfoMessage('');
                  setLocation({
                    ...location,
                    lng: e.target.value,
                  });
                }}
              />
            </div>
            <div className='d-flex'>
              <div className='d-flex align-items-center font-weight-bolder pr-1'>
                Acc:
              </div>
              <Input
                id='mock-location-accuracy'
                value={location.accuracy}
                onChange={(e) => {
                  setInfoMessage('');
                  setLocation({
                    ...location,
                    accuracy: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className='d-flex pt-2'>
            <div className='pr-2'>
              <Button
                size='sm'
                color={THEME_COLORS_NAMES.SECONDARY}
                onClick={storeFakeLocation}
              >
                Store fake location
              </Button>
            </div>
            <div>
              <Button size='sm' onClick={clearFakeLocation}>
                Clear fake location
              </Button>
            </div>
            <div className='pl-1'>{infoMessage}</div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

ScanInput.propTypes = {
  scanTag: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(null, {
  changeReaderState,
  scanTag,
})(ScanInput);
