import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'reactstrap';

import { NewModalStep } from '../Shared';
import { Button, ControlledOutlinedField } from '../../Shared';
import { getMappedErrorMessage } from '../../../utils/error';
import { updateBox } from '../../../redux/actions/boxActions';
import {
  DATA_PROVIDERS,
  FIREBASE_EVENTS,
  MAX_NUMBER_OF_CHARACTERS,
  PERMISSIONS,
} from '../../../constants';
import ControlledCustomSwitch from '../../Shared/ControlledCustomSwitch';
import {
  getConditionallyRequiredBooleanSchema,
  getDefaultOptionsValues,
} from '../../../utils/box';
import { getPermissions } from '../../../utils/user';
import { handleAnalytics } from '../../../utils/analytics';
import {
  BOX_CONF_PRODUCT_OPTIONS,
  BOX_CONF_VENDORS,
} from '../../../containers/ReconditioningContainers/constants';

const debug = _debug('Bridge:BoxConfiguration');

const BoxConfiguration = ({
  handleClose,
  boxDetails = {},
  handleError = () => {},
  startLoading = () => {},
  readOnly,
  updateBox,
}) => {
  const { t } = useTranslation();
  const configurationFormRef = useRef();
  const permissions = getPermissions();

  const SELECT_ALL_PRODUCT_TYPES = 'selectAllProductTypesOptions';
  const SELECT_ALL_VENDOR_OPTIONS = 'selectAllToolVendors';

  const vendorOptionsArray = Object.values(BOX_CONF_VENDORS);
  const productTypesOptionsArray = Object.values(BOX_CONF_PRODUCT_OPTIONS);
  const isReadOnly =
    readOnly ||
    !!boxDetails?.productInstanceCount ||
    !permissions.includes(PERMISSIONS.UPDATE_BOX);

  const configurationValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('error.thisFieldIsRequired'))
      .max(
        MAX_NUMBER_OF_CHARACTERS.NAME,
        `${t('error.maximumNumberOfCharactersForThisFieldIs')} ${
          MAX_NUMBER_OF_CHARACTERS.NAME
        }.`
      ),
    [SELECT_ALL_PRODUCT_TYPES]: Yup.boolean(),
    [BOX_CONF_PRODUCT_OPTIONS.DRILLS]: Yup.boolean(),
    [BOX_CONF_PRODUCT_OPTIONS.REAMERS]: Yup.boolean(),
    [BOX_CONF_PRODUCT_OPTIONS.END_MILLS]: Yup.boolean(),
    [BOX_CONF_PRODUCT_OPTIONS.UNSPECIFIED]: getConditionallyRequiredBooleanSchema(
      productTypesOptionsArray.filter(
        (option) => option !== BOX_CONF_PRODUCT_OPTIONS.UNSPECIFIED
      )
    ),
    [SELECT_ALL_VENDOR_OPTIONS]: Yup.boolean(),
    [BOX_CONF_VENDORS.WALTER]: Yup.boolean(),
    [BOX_CONF_VENDORS.SECO]: Yup.boolean(),
    [BOX_CONF_VENDORS.COROMANT]: Yup.boolean(),
    [BOX_CONF_VENDORS.OTHER]: getConditionallyRequiredBooleanSchema(
      vendorOptionsArray.filter((option) => option !== BOX_CONF_VENDORS.OTHER)
    ),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(configurationValidationSchema),
    mode: 'all',
    defaultValues: {
      ...getDefaultOptionsValues(
        productTypesOptionsArray,
        boxDetails,
        SELECT_ALL_PRODUCT_TYPES
      ),
      ...getDefaultOptionsValues(
        vendorOptionsArray,
        boxDetails,
        SELECT_ALL_VENDOR_OPTIONS
      ),
      name: boxDetails.name || '',
    },
  });

  const updateBoxConfiguration = async () => {
    try {
      startLoading();
      const {
        [SELECT_ALL_PRODUCT_TYPES]: selectAllTypes,
        [SELECT_ALL_VENDOR_OPTIONS]: selectAllVendors,
        ...boxConfigurationData
      } = getValues();
      await updateBox(boxDetails.id, boxConfigurationData);
      handleAnalytics(FIREBASE_EVENTS.BOX_CONFIGURATION_CHANGED);
      handleClose();
    } catch (e) {
      debug(`Couldn't update box configuration. Reason: ${e}`);
      handleError(getMappedErrorMessage(e));
    }
  };

  const triggerRevalidationOfProductTypesOptions = () =>
    trigger(BOX_CONF_PRODUCT_OPTIONS.UNSPECIFIED);

  const triggerRevalidationOfToolVendors = () =>
    trigger(BOX_CONF_VENDORS.OTHER);

  const handleSelectAllFieldValue = (
    options,
    selectAllField = SELECT_ALL_PRODUCT_TYPES
  ) => {
    setValue(selectAllField, !options.find((option) => !getValues(option)));
  };

  const handleSelectAllProductTypesOptions = () =>
    handleSelectAllFieldValue(productTypesOptionsArray);

  const handleSelectAllVendorTools = () =>
    handleSelectAllFieldValue(vendorOptionsArray, SELECT_ALL_VENDOR_OPTIONS);

  return (
    <NewModalStep
      title={t('modal.boxConfiguration')}
      subtitle={
        isReadOnly &&
        permissions.includes(PERMISSIONS.UPDATE_BOX) &&
        t('modal.changingTheConfigurationIsOnlyPossibleIfTheBoxIsEmpty')
      }
      subtitleClassName='text-danger font-size-sm'
      rightButtonContent={t('button.confirm')}
      handleClickRightButton={() =>
        configurationFormRef.current.requestSubmit()
      }
      rightButtonDisabled={isReadOnly}
      leftButtonContent={t('button.cancel')}
      handleClickLeftButton={handleClose}
      isCenteredVertically
      bodyClassName='box-configuration-modal'
    >
      <Form
        onSubmit={handleSubmit(() => {
          updateBoxConfiguration();
        })}
        innerRef={configurationFormRef}
        className='mx-4 pt-5 my-5 w-100'
        noValidate
      >
        <p className='my-3 font-weight-bold'>
          {t('modal.boxOption').toUpperCase()}
        </p>

        <ControlledCustomSwitch
          name={SELECT_ALL_PRODUCT_TYPES}
          label='components.selectAll'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfProductTypesOptions}
          handleClick={(value) =>
            productTypesOptionsArray.forEach((option) =>
              setValue(option, value)
            )
          }
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_PRODUCT_OPTIONS.REAMERS}
          label='components.solidCarbideReamers'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfProductTypesOptions}
          handleClick={handleSelectAllProductTypesOptions}
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_PRODUCT_OPTIONS.DRILLS}
          label='components.solidCarbideDrills'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfProductTypesOptions}
          handleClick={handleSelectAllProductTypesOptions}
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_PRODUCT_OPTIONS.END_MILLS}
          label='components.solidCarbideEndMills'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfProductTypesOptions}
          handleClick={handleSelectAllProductTypesOptions}
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_PRODUCT_OPTIONS.UNSPECIFIED}
          label='components.unspecifiedProductClass'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfProductTypesOptions}
          handleClick={handleSelectAllProductTypesOptions}
          error={errors[BOX_CONF_PRODUCT_OPTIONS.UNSPECIFIED]?.message}
          readOnly={isReadOnly}
        />

        <p className='my-3 font-weight-bold'>
          {t('modal.toolVendor').toUpperCase()}
        </p>
        <ControlledCustomSwitch
          name={SELECT_ALL_VENDOR_OPTIONS}
          label='components.selectAll'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfToolVendors}
          handleClick={(value) =>
            vendorOptionsArray.forEach((option) => setValue(option, value))
          }
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_VENDORS.WALTER}
          label={DATA_PROVIDERS.WALTER}
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfToolVendors}
          handleClick={handleSelectAllVendorTools}
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_VENDORS.SECO}
          label={DATA_PROVIDERS.SECO}
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfToolVendors}
          handleClick={handleSelectAllVendorTools}
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_VENDORS.COROMANT}
          label={DATA_PROVIDERS.SANDVIK_COROMANT}
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfToolVendors}
          handleClick={handleSelectAllVendorTools}
          readOnly={isReadOnly}
        />
        <ControlledCustomSwitch
          name={BOX_CONF_VENDORS.OTHER}
          label='components.other'
          containerClassName='option-container'
          control={control}
          trigger={triggerRevalidationOfToolVendors}
          handleClick={handleSelectAllVendorTools}
          error={errors[BOX_CONF_VENDORS.OTHER]?.message}
          readOnly={isReadOnly}
        />

        <ControlledOutlinedField
          className='mt-1'
          control={control}
          placeholder={t('components.name')}
          name='name'
          required
          readOnly={isReadOnly}
        />
        <Button type='submit' className='d-none' />
      </Form>
    </NewModalStep>
  );
};

BoxConfiguration.propTypes = {
  handleClose: PropTypes.func.isRequired,
  boxDetails: PropTypes.object,
  handleError: PropTypes.func,
  startLoading: PropTypes.func,
  readOnly: PropTypes.bool,
  updateBox: PropTypes.func.isRequired,
};

export default connect(null, { updateBox })(BoxConfiguration);
