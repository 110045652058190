import React from 'react';
import PropTypes from 'prop-types';

const TextWithIcon = ({
  icon,
  text,
  className = 'd-flex align-items-center',
}) => {
  return (
    <div className={className}>
      {icon}
      {text}
    </div>
  );
};

TextWithIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default TextWithIcon;
