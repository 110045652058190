import axios from './axiosClient';
import { DB_API_URL } from '../config';
import { getCurrentLocation } from '../utils/geolocation';

export const postScanTag = async (tag, updateAvailability = false) => {
  const { lat, lng, accuracy } = await getCurrentLocation();
  return axios.post(
    `${DB_API_URL}/api/v1/ScanTag`,
    {
      positionLatitude: lat,
      positionLongitude: lng,
      positionAccuracy: accuracy,
      updateAvailability,
    },
    {
      params: {
        saveHistory: true,
        tag,
      },
    }
  );
};

export const fetchProductByBarcode = async ({ params }) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/Barcode`, { params });
};

export const fetchProductByDataMatrix = async ({ params }) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/Datamatrix`, {
    params,
  });
};

export const postSyncProduct = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/ProductInstance/`, data);
};

export const fetchScannedTags = async ({ params }) => {
  return axios.get(`${DB_API_URL}/api/v1/ScanTag`, {
    params,
  });
};

export const patchNewTagToProductInstance = async (id, data) => {
  return axios.patch(
    `${DB_API_URL}/api/v1/ProductInstance/${id}/SyncNewTag`,
    data
  );
};

export const fetchProductInstanceHistory = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/History/ProductInstance`, { params });
};

export const fetchProductInstanceNumericPropertyHistory = async (params) => {
  return axios.get(
    `${DB_API_URL}/api/v1/ProductInstancePropertyHistory/Numeric`,
    params
  );
};

export const deleteProductInstanceNumericPropertyHistoryEntry = async (
  entryId,
  data
) => {
  return axios.delete(
    `${DB_API_URL}/api/v1/ProductInstancePropertyHistory/Numeric/${entryId}`,
    { data }
  );
};

export const postProductInstanceNumericProperty = async (id, params) => {
  return axios.post(
    `${DB_API_URL}/api/v1/ProductInstanceProperty/Numeric/${id}`,
    params
  );
};
