import React from 'react';
import PropTypes from 'prop-types';

const HorizontalLine = ({ className = '', childClassName = '', children }) => (
  <div className={`line ${className}`}>
    <span className={`text ${childClassName}`}>{children}</span>
  </div>
);

HorizontalLine.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  childClassName: PropTypes.string,
};

export default HorizontalLine;
