import * as types from '../constants';

export const setTriggerRefetchingOfDataSuccess = (method) => {
  return {
    type: types.SET_TRIGGER_REFETCHING_OF_DATA,
    payload: { method },
  };
};

export const setTriggerRefetchingOfData = (method = () => {}) => {
  return (dispatch) => {
    dispatch(setTriggerRefetchingOfDataSuccess(method));
  };
};
