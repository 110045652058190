import React from 'react';

import PropTypes from 'prop-types';
import { DropdownItem as DropdownItemReactstrap } from 'reactstrap';

const DropdownItem = ({
  label,
  header = false,
  handleClick = () => {},
  id = '',
  suffix,
  toggle = true,
  className = '',
  disabled = false,
}) => {
  return (
    <DropdownItemReactstrap
      header={header}
      className={`database-dropdown-item ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(id);
      }}
      toggle={toggle}
      disabled={disabled}
    >
      <span className='text-truncate mr-1'>{label}</span>
      {suffix && <div className='d-flex align-items-center'>{suffix}</div>}
    </DropdownItemReactstrap>
  );
};

DropdownItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  header: PropTypes.bool,
  handleClick: PropTypes.func,
  suffix: PropTypes.any,
  toggle: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DropdownItem;
