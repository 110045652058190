import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import { BasicTooltip, Image } from '../Shared';

const ProductTableRows = ({
  products,
  onClick,
  striped = false,
  selectedProduct,
  isProductDisabled = () => false,
  disabledTooltipMessage = '',
  withoutHighlightAnimation = false,
}) => (
  <>
    {products.map((product) => {
      const disabled = isProductDisabled(product);
      return (
        <tr
          key={product.id}
          id={`productRow-${product.id}`}
          className={cs('resizable-table-row-details cursor-pointer', {
            highlighted:
              !withoutHighlightAnimation && selectedProduct?.id === product.id,
            'background-color-secondary':
              withoutHighlightAnimation && selectedProduct?.id === product.id,
            striped,
            disabled,
          })}
          {...(onClick && !disabled && { onClick: () => onClick(product) })}
        >
          <td className='resizable-table-border-bottom new-resizable-table-padding'>
            <Image
              src={product.imageUrl}
              className='resizable-table-row-image'
            />
            <span className='pl-3 font-weight-bolder'>
              {product.nickname || product.name}
            </span>
          </td>
          <td className='resizable-table-border-bottom new-resizable-table-padding' />
          <td className='resizable-table-border-bottom pl-2'>{product.code}</td>
          {!!(disabled && disabledTooltipMessage) && (
            <BasicTooltip target={`productRow-${product.id}`}>
              {disabledTooltipMessage}
            </BasicTooltip>
          )}
        </tr>
      );
    })}
  </>
);

ProductTableRows.propTypes = {
  products: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  selectedProduct: PropTypes.object,
  striped: PropTypes.bool,
  isProductDisabled: PropTypes.func,
  disabledTooltipMessage: PropTypes.any,
  withoutHighlightAnimation: PropTypes.bool,
};

export default ProductTableRows;
