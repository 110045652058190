import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from './SearchInput';
import Loader from './Loader';

const SelectGroupItems = ({
  search,
  setSearch,
  searchPlaceholder,
  searchClassName,
  items,
  loading = false,
  noDataMessage,
}) => {
  return (
    <div className='d-flex flex-column h-100'>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <SearchInput
          value={search}
          setValue={setSearch}
          placeholder={searchPlaceholder}
          className={searchClassName || 'w-100'}
        />
      </div>
      <div className={`${loading ? '' : 'overflow-auto'} pt-4`}>
        <div className=' d-flex justify-content-center align-items-center'>
          <div className='w-100'>
            {loading ? (
              <Loader />
            ) : items.length ? (
              items
            ) : (
              <div className='d-flex justify-content-center'>
                {noDataMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SelectGroupItems.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  searchClassName: PropTypes.string,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  noDataMessage: PropTypes.string,
};

export default SelectGroupItems;
