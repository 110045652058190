import React from 'react';
import PropTypes from 'prop-types';

const GroupItemBadge = ({ text, color = 'master', fullWidth }) => {
  return (
    <div
      className={`item-badge item-badge-${color} ${fullWidth ? 'w-100' : ''}`}
    >
      <p className='text-white mx-1 mb-0 h-100 d-flex justify-content-center align-items-center'>
        {text}
      </p>
    </div>
  );
};

GroupItemBadge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['master', 'invited', 'pending']),
  fullWidth: PropTypes.bool,
};

export default GroupItemBadge;
