import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const useQuery = (label = '') => {
  const history = useHistory();
  const location = useLocation();

  const getQueryParams = () => new URLSearchParams(history.location.search);

  const getQueryParam = (label) => {
    const currentQueryParams = getQueryParams();
    return currentQueryParams.get(label);
  };

  const [query, setQueryState] = useState(getQueryParam(label));

  const setQuery = (newValue, replace = false) => {
    const queryParams = getQueryParams();
    const oldQueryValue = queryParams.get(label);

    if (newValue && newValue !== oldQueryValue) {
      queryParams.set(label, newValue);

      if (replace) {
        history.replace({ search: queryParams.toString() });
      } else {
        history.push({ search: queryParams.toString() });
      }
    }
  };

  useEffect(() => {
    const newQueryValue = getQueryParam(label);

    if (!!newQueryValue && newQueryValue !== query) {
      setQueryState(newQueryValue);
    } else if (!newQueryValue && !!query) {
      setQuery(query, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setMultipleQueries = (queries = [], replace = false) => {
    const queryParams = getQueryParams();

    queries.forEach(({ label: newQuery, value }) => {
      queryParams.set(newQuery, value);
      if (label === newQuery) {
        setQueryState(value);
      }
    });

    if (replace) {
      history.replace({ search: queryParams.toString() });
    } else {
      history.push({ search: queryParams.toString() });
    }
  };

  const removeQuery = () => {
    const queryParams = getQueryParams();
    queryParams.delete(label);
    history.replace({ search: queryParams.toString() });
  };

  return { query, setQuery, removeQuery, setMultipleQueries, getQueryParam };
};

useQuery.propTypes = {
  label: PropTypes.string.isRequired,
};

export default useQuery;
