import React from 'react';

import PropTypes from 'prop-types';

import Modal from '../Modal';
import ScanWithWebCamera from './ScanWithWebCamera';

const ScanModal = ({ isOpened, handleClose }) => {
  return (
    <Modal
      isOpened={isOpened}
      className='p-0 d-flex flex-column justify-content-between'
    >
      <ScanWithWebCamera handleClose={handleClose} />
    </Modal>
  );
};

ScanModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ScanModal;
