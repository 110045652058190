import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.order, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.data,
      };
    case types.ADD_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        details: { ...state.details, ...payload.data },
      };
    case types.UPDATE_RC_FEEDBACK_INSIDE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        details: { ...state.details, rcGeneralComment: payload.data },
      };
    case types.EDIT_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.id === payload.orderId ? { ...order, ...payload.data } : order
        ),
      };
    case types.GET_BOXES_BY_ORDER_SUCCESS:
      return {
        ...state,
        boxes: payload.data,
      };
    case types.SET_ORDER_BOXES_SUCCESS:
      return {
        ...state,
        boxes: payload.data,
      };
    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders:
          payload.page === 1
            ? [...payload.data]
            : [...state.orders, ...payload.data],
      };
    case types.SET_DISPLAYED_ORDERS_STATUSES_SUCCESS:
      return {
        ...state,
        displayedOrdersStatuses: [...payload.statuses],
      };
    case types.GET_RECON_CENTER_ORDERS_SUCCESS:
      return {
        ...state,
        orders:
          payload.page === 1
            ? [...payload.data]
            : [...state.orders, ...payload.data],
      };
    case types.SET_ORDERS_TO_ADD_TO_START_SUCCESS:
      return {
        ...state,
        ordersToAddToStart: [...payload.ordersToAddToStart],
      };
    case types.SET_ORDERS_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS:
      return {
        ...state,
        ordersIdsToFilterOutWhileFetching: [...payload.ids],
      };
    case types.ADD_ORDERS_TO_START_SUCCESS:
      const idsOfOrdersToAdd = state.ordersToAddToStart.map(({ id }) => id);
      const filteredAlreadyInStateOrders = state.orders.filter(
        ({ id }) => !idsOfOrdersToAdd.includes(id)
      );
      return {
        ...state,
        orders: [...state.ordersToAddToStart, ...filteredAlreadyInStateOrders],
        ordersIdsToFilterOutWhileFetching: [
          ...state.ordersIdsToFilterOutWhileFetching,
          ...idsOfOrdersToAdd,
        ],
        ordersToAddToStart: [],
      };
    case types.SET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload.data,
      };
    case types.SET_HIGHLIGHTED_ORDERS_IDS_SUCCESS:
      return {
        ...state,
        highlightedIds: [...payload.highlightedIds],
      };
    case types.GET_TOOLS_FROM_ORDERS:
      return {
        ...state,
        toolsFromOrders:
          payload.page === 1
            ? [...payload.data]
            : [...state.toolsFromOrders, ...payload.data],
      };

    case types.SET_TOOLS_SUCCESS:
      return {
        ...state,
        toolsFromOrders: payload.data,
      };
    default:
      return state;
  }
}
