import { sidebarRoutes } from '../routes/sidebarRoutes';
import { getPermissions } from './user';

export const getUsersSidebarRoutes = (sidebarRoutes) => {
  const permissions = getPermissions();
  const sidebarRoutesCurrentUser = sidebarRoutes.filter(
    ({ permissions: routePermissions }) =>
      !routePermissions ||
      routePermissions.every((permission) => permissions.includes(permission))
  );
  return sidebarRoutesCurrentUser;
};

export const getMatchingSidebarRoute = (pathname) =>
  getUsersSidebarRoutes(sidebarRoutes).find(
    ({ path, additionalPaths }) =>
      pathname.includes(path) ||
      additionalPaths?.find((p) => pathname.includes(p))
  );

export const handleOpenDefaultUsersPage = (history) => {
  const usersSidebarRoutes = getUsersSidebarRoutes(sidebarRoutes);
  history.push({
    pathname: usersSidebarRoutes[0]?.path,
    search: usersSidebarRoutes[0]?.search,
  });
};
