import axios from './axiosClient';
import { DB_API_URL } from '../config';
import { RESPONSE_TYPES } from '../constants';

export const fetchOrderDetails = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Order/${id}`);
};

export const fetchOrders = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Order`, { params });
};

export const postOrderPickup = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Order`, data);
};

export const postPrepareOrder = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Order/Prepare`, data);
};

export const bulkUpdateOrdersStatus = async (data) => {
  return axios.put(`${DB_API_URL}/api/v1/Order/BulkUpdateStatus`, { ...data });
};

export const fetchToolsFromOrders = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Order/Tools`, { params });
};

export const fetchExportToolsFromOrdersData = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Export/Tools`, {
    params,
    responseType: RESPONSE_TYPES.BLOB,
  });
};

export const postGenerateInspectionReport = async (id) => {
  return axios.post(`${DB_API_URL}/api/v1/Order/${id}/InspectionReport`);
};

export const postGenerateVerificationReport = async (id) => {
  return axios.post(`${DB_API_URL}/api/v1/Order/${id}/VerificationReport`);
};

export const getInspectionReport = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/Export/Order/${id}`, {
    params,
    responseType: RESPONSE_TYPES.BLOB,
  });
};

export const updateOrder = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/Order/${id}`, data);
};

export const completeOrder = async (id) => {
  return axios.put(`${DB_API_URL}/api/v1/Order/${id}/Complete`);
};

export const postRcFeedback = async (id, data) => {
  return axios.post(`${DB_API_URL}/api/v1/UserThread/Order/${id}`, data);
};
