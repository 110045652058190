import axios from './axiosClient';
import { DB_API_URL } from '../config';

export const postCustomBox = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Box/Create`, data);
};

export const fetchBox = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/Box/${id}`, { params });
};

export const fetchBoxProductInstances = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/Box/${id}/ProductInstances`, {
    params,
  });
};

export const postBoxProductInstances = async (id, data) => {
  return axios.post(`${DB_API_URL}/api/v1/Box/${id}/ProductInstances`, data);
};

export const fetchBoxes = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Box`, { params });
};

export const deleteProductInstanceFromTheBox = async (boxId, data) => {
  return axios.delete(`${DB_API_URL}/api/v1/Box/${boxId}/ProductInstances`, {
    data,
  });
};

export const deleteBox = async (boxId) => {
  return axios.delete(`${DB_API_URL}/api/v1/Box/${boxId}`);
};

export const fetchBoxTemplates = async () => {
  return axios.get(`${DB_API_URL}/api/v1/Box/Templates`);
};

export const requestBoxInstances = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Box/RequestNew`, data);
};

export const resetBox = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Box/Reset`, data);
};

export const activateBoxInstances = async (data) => {
  return axios.patch(`${DB_API_URL}/api/v1/Box/Activate`, data);
};

export const putBox = async (boxId, data) => {
  return axios.put(`${DB_API_URL}/api/v1/Box/${boxId}`, data);
};

export const syncBoxCode = async (boxId, code) => {
  return axios.patch(`${DB_API_URL}/api/v1/Box/${boxId}/Sync`, { code });
};

export const unsyncBoxCode = async (boxId) => {
  return axios.patch(`${DB_API_URL}/api/v1/Box/${boxId}/UnSync`);
};

export const bulkUpdateBoxStatus = async (data) => {
  return axios.put(`${DB_API_URL}/api/v1/Box/BulkUpdateStatus`, data);
};
