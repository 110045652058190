import React from 'react';

import PropTypes from 'prop-types';
import Img from 'react-image';
import { Col, Row } from 'reactstrap';

import { success } from '../../../assets/icon';

const NewDone = ({
  title,
  subtitle,
  image = success,
  width = 80,
  height = 80,
  imageClassName = '',
}) => {
  return (
    <Row className='m-0 h-100 d-flex align-items-center justify-content-center new-done'>
      <Col className='p-0'>
        <Row className='m-0'>
          <Col className='p-0 text-center'>
            <Img
              src={image}
              width={width}
              height={height}
              className={`mb-4 ${imageClassName}`}
            />
          </Col>
        </Row>
        {title && (
          <Row className='m-0'>
            <Col className='p-0 px-3 mb-3 text-center title'>{title}</Col>
          </Row>
        )}
        {subtitle && (
          <Row className='m-0'>
            <Col className='p-0 px-3 text-center'>{subtitle}</Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

NewDone.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  image: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  imageClassName: PropTypes.string,
};

export default NewDone;
