import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  Input as InputReactstrap,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  InputGroupAddon,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DropdownItem from './DropdownItem';
import { ReactComponent as DeleteInput } from '../../assets/icon/ic-input-delete.svg';
import { ReactComponent as SearchLoupe } from '../../assets/icon/ic-search-white.svg';
import { ReactComponent as FilterIcon } from '../../assets/icon/ic-filter.svg';
import { THEME_COLORS_NAMES } from '../../constants';
import PrimaryButton from './PrimaryButton';

const SearchInput = ({
  value,
  setValue,
  size = 'lg',
  placeholder = '',
  className = '',
  dropdownOptions = [],
  dropdownOptionClassName = '',
  buttonIcon,
  toggleDropdownMenuOnOptionClick,
  toggleColor = THEME_COLORS_NAMES.TERTIARY,
  disabled = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const hasDropdown = dropdownOptions.length;

  return (
    <div className={`search-input-container ${className}`}>
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        className='d-flex'
      >
        <div className='d-flex w-100'>
          <SearchLoupe
            width={25}
            height={25}
            className={cs('icon-txt-secondary search-input-icon')}
          />
          <InputReactstrap
            value={value}
            onChange={(e) => {
              setDropdownOpen(false);
              setValue(e.target.value);
            }}
            placeholder={placeholder}
            bsSize={size}
            className={cs('search-input', {
              'with-button': hasDropdown || buttonIcon,
              'dropdown-opened': dropdownOpen,
            })}
            disabled={disabled}
          />
          {!!value && (
            <DeleteInput
              width={20}
              height={20}
              className={cs(
                'position-absolute align-self-center cursor-pointer delete-input-icon',
                { 'without-button': !hasDropdown && !buttonIcon }
              )}
              onClick={() => setValue('')}
            />
          )}
        </div>
        {!!(!hasDropdown && buttonIcon) && (
          <InputGroupAddon className='cursor-pointer' addonType='append'>
            <PrimaryButton
              className='search-button'
              type='submit'
              disabled={disabled}
            >
              {buttonIcon}
            </PrimaryButton>
          </InputGroupAddon>
        )}
        {!!hasDropdown && (
          <>
            <DropdownToggle
              className='dropdown-toggle p-2'
              data-toggle='dropdown'
              aria-expanded={dropdownOpen}
              disabled={disabled}
              color={toggleColor}
            >
              {buttonIcon || (
                <FilterIcon className='icon-white' height={25} width={25} />
              )}
            </DropdownToggle>
            <DropdownMenu right>
              {dropdownOptions
                .filter(({ hidden }) => !hidden)
                .map(({ key, label, header, handleClick, suffix }) => (
                  <DropdownItem
                    header={header}
                    handleClick={handleClick}
                    label={t(label)}
                    suffix={suffix}
                    className={dropdownOptionClassName}
                    id={key}
                    toggle={toggleDropdownMenuOnOptionClick}
                  />
                ))}
            </DropdownMenu>
          </>
        )}
      </ButtonDropdown>
    </div>
  );
};

SearchInput.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  dropdownOptions: PropTypes.array,
  dropdownOptionClassName: PropTypes.string,
  toggleDropdownMenuOnOptionClick: PropTypes.bool,
  buttonIcon: PropTypes.any,
  disabled: PropTypes.bool,
  toggleColor: PropTypes.string,
};

export default SearchInput;
