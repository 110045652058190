import React from 'react';
import { UncontrolledAlert } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div className='pt-2 mb-n3'>
      {message && (
        <UncontrolledAlert color='danger'>
          <div className='alert-message'>
            <strong>{`${t('components.error')} !`}</strong>
            {message}
          </div>
        </UncontrolledAlert>
      )}
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;
