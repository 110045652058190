import React from 'react';

import PropTypes from 'prop-types';
import { Stepper } from 'react-form-stepper';

import { COLORS } from '../../constants/theme';

const CustomStepper = ({
  steps,
  activeStep = 0,
  styleConfig = {},
  connectorStyleConfig = {},
}) => (
  <Stepper
    className='custom-stepper'
    steps={steps}
    activeStep={activeStep}
    styleConfig={{
      circleFontSize: 0,
      ...styleConfig,
    }}
    connectorStyleConfig={{
      stepSize: '25px',
      disabledColor: COLORS.TEXT_DISABLED,
      ...connectorStyleConfig,
    }}
  />
);

CustomStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  styleConfig: PropTypes.object,
  connectorStyleConfig: PropTypes.object,
};

export default CustomStepper;
