import React from 'react';
import PropTypes from 'prop-types';

const CenteredView = ({ children }) => {
  return (
    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
      {children}
    </div>
  );
};

CenteredView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CenteredView;
