import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import debug from 'debug';
import InfiniteScroll from 'react-infinite-scroller';

import {
  addBoxesToStart,
  getBoxes,
  setBoxes,
  setBoxesIdsToFilterOutWhileFetching,
  setBoxesToAddToStart,
  setDisplayedBoxesStatuses,
} from '../../redux/actions/boxActions';
import { PERMISSIONS, ROUTES, THEME_COLORS_NAMES } from '../../constants';
import { Loader, StickyButton } from '../Shared';
import BoxCard from '../Shared/BoxCard';
import { BOX_CARD_SIZES } from '../Shared/constants';
import AddBoxModal from '../Modals/ReconditioningModals/AddBoxModal';
import { scrollToHighestElement } from '../../utils/shared';
import { getTabElementByLabel } from '../../utils/tabs';
import { TAB_LABELS } from '../../containers/ProductContainers/const';
import { WarningModal } from '../Modals/Shared';
import ReconditioningDetails from '../ReconditioningComponents/ReconditioningDetails';
import { getPermissions } from '../../utils/user';
import AddProductInstanceToBoxFromReconditioningTabModal from '../Modals/ReconditioningModals/AddProductInstanceToBoxFromReconditioningTabModal';
import { BOX_STATUSES } from '../../containers/ReconditioningContainers/constants';

const ReconditioningTab = ({
  instance,
  details,
  boxes,
  addBoxesToStart,
  getBoxes,
  setBoxes,
  setBoxesIdsToFilterOutWhileFetching,
  setBoxesToAddToStart,
  setDisplayedBoxesStatuses,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedBoxId, setSelectedBoxId] = useState();
  const [
    isAddProductInstanceToBoxFromReconditioningTabModalOpened,
    setIsAddProductInstanceToBoxFromReconditioningTabModalOpened,
  ] = useState(false);
  const [
    isMaxNumberOfRegrindsModalOpened,
    setIsMaxNumberOfRegrindsModalOpened,
  ] = useState(false);
  const [isAddBoxModalOpened, setIsAddBoxModalOpened] = useState(false);
  const [highlightedIds, setHighlightedIds] = useState([]);
  const [hasMoreBoxesToFetch, setHasMoreBoxesToFetch] = useState(true);

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const permissions = getPermissions();

  const fetchBoxes = async (PageNumber = 1) => {
    try {
      setLoading(true);

      const data = await getBoxes({
        PageNumber,
        IsActive: true,
        ProductInstanceId: instance.id,
      });

      if (data.lastRowReturned === data.rowCount) {
        setHasMoreBoxesToFetch(false);
      }

      setLoading(false);
    } catch (e) {
      debug(`Couldn't fetch boxes. Reason: ${e}`);
      setHasMoreBoxesToFetch(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDisplayedBoxesStatuses([
      BOX_STATUSES.ACTIVE_NOT_EMPTY,
      BOX_STATUSES.ACTIVE_EMPTY,
    ]);

    return () => {
      setBoxes([]);
      setDisplayedBoxesStatuses([BOX_STATUSES.ALL]);
      setBoxesToAddToStart();
      setBoxesIdsToFilterOutWhileFetching();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state && location.state.highlightedIds) {
      setHighlightedIds(location.state.highlightedIds);

      history.replace({
        ...location,
        state: {
          ...location.state,
          highlightedIds: undefined,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (highlightedIds?.length) {
      scrollToHighestElement(highlightedIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedIds]);

  const handleAddingBoxesToStart = () => {
    addBoxesToStart();
    document.getElementById('boxes-container').scrollIntoView(true);
  };

  const handleNotProceedingReconditioning = () => {
    setIsMaxNumberOfRegrindsModalOpened(false);
    setSelectedBoxId(null);
  };

  const handleProceedWithReconditioning = () => {
    setIsMaxNumberOfRegrindsModalOpened(false);
    setIsAddProductInstanceToBoxFromReconditioningTabModalOpened(true);
  };

  const handleLoadBoxes = async (page) => fetchBoxes(page);

  const handleOpenBox = () =>
    history.push(`${ROUTES.RECONDITIONING_BOX}/${instance.boxId}`, {
      highlightedProductInstanceId: instance.id,
    });

  const handleAddToBoxClick = (e, boxId) => {
    e.stopPropagation();
    setSelectedBoxId(selectedBoxId === boxId ? null : boxId);
  };

  const handleStartReconditioningClick = () =>
    instance.reconditionData.reconditionCount >=
    (instance.reconditionData.calculatedRegrinds || 0)
      ? setIsMaxNumberOfRegrindsModalOpened(true)
      : setIsAddProductInstanceToBoxFromReconditioningTabModalOpened(true);

  const isSelectedBox = (id) => selectedBoxId && selectedBoxId === id;

  const getProductWeight = () =>
    details.numericProperties.find(({ name }) => name === 'Weight')?.value || 0;

  const renderComponent = () => {
    if (details.reconditionId) {
      return (
        <div className='reconditioning-tab h-100 mb-5'>
          <h4 className='text-txt-primary font-weight-bold mb-3'>
            {t('modal.reconditionDetails')}
          </h4>

          <ReconditioningDetails
            highlightedIds={highlightedIds}
            setHighlightedIds={setHighlightedIds}
          />

          {!instance.boxId &&
            permissions.includes(PERMISSIONS.ADD_PRODUCT_TO_BOX) && (
              <div id='boxes-container'>
                <h4 className='text-txt-primary font-weight-bold mb-3'>
                  {t('components.selectBox')}
                </h4>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={handleLoadBoxes}
                  hasMore={!loading && hasMoreBoxesToFetch}
                  useWindow={false}
                  getScrollParent={() =>
                    getTabElementByLabel(TAB_LABELS.RECONDITIONING)
                  }
                >
                  <div className='w-100 d-flex flex-wrap'>
                    {!!boxes.length &&
                      boxes.map((box) => (
                        <BoxCard
                          containerClassName='pr-3 pb-3 p-0'
                          box={box}
                          handleButtonClick={(e) =>
                            handleAddToBoxClick(e, box.id)
                          }
                          buttonColor={
                            isSelectedBox(box.id)
                              ? THEME_COLORS_NAMES.SUCCESS
                              : THEME_COLORS_NAMES.SECONDARY
                          }
                          buttonLabel={
                            isSelectedBox(box.id)
                              ? 'components.boxSelected'
                              : 'components.addToBox'
                          }
                          buttonDisabled={
                            (selectedBoxId && selectedBoxId !== box.id) ||
                            box.currentWeight + getProductWeight() >
                              box.maxWeight
                          }
                          size={BOX_CARD_SIZES.TAB_SIZE}
                        />
                      ))}
                  </div>
                  {loading && <Loader containerClassName='py-3' />}
                  {!loading && !hasMoreBoxesToFetch && !boxes.length && (
                    <p>
                      {t(
                        'components.youDoNotHaveAnyBoxesReadyForReconditioningClickOnAddBoxButtonToRequestNewBoxActivateRequestedBoxOrCreateCustomOne'
                      )}
                    </p>
                  )}
                </InfiniteScroll>
              </div>
            )}

          <div className='sticky-buttons-right'>
            {!instance.boxId ? (
              <>
                {(permissions.includes(PERMISSIONS.CREATE_BOX) ||
                  permissions.includes(PERMISSIONS.ORDER_BOX) ||
                  permissions.includes(PERMISSIONS.UPDATE_BOX)) && (
                  <StickyButton
                    onClick={() => setIsAddBoxModalOpened(true)}
                    className='mr-3 px-4'
                  >
                    {t('components.addBox')}
                  </StickyButton>
                )}
                {permissions.includes(PERMISSIONS.VIEW_RECYCLING) && (
                  <StickyButton disabled className='mr-3'>
                    {t('components.startRecycling')}
                  </StickyButton>
                )}
                {permissions.includes(PERMISSIONS.ADD_PRODUCT_TO_BOX) && (
                  <StickyButton
                    disabled={!selectedBoxId}
                    className='mr-5'
                    onClick={handleStartReconditioningClick}
                  >
                    {t('components.startReconditioning')}
                  </StickyButton>
                )}
              </>
            ) : (
              <StickyButton className='mr-5' onClick={() => handleOpenBox()}>
                {t('components.viewInBox')}
              </StickyButton>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className='font-size-md'>
        {t('modal.thereIsNoReconditioningDataForThisProduct')}
      </div>
    );
  };

  return (
    <>
      {renderComponent()}
      {isAddProductInstanceToBoxFromReconditioningTabModalOpened && (
        <AddProductInstanceToBoxFromReconditioningTabModal
          handleClose={() =>
            setIsAddProductInstanceToBoxFromReconditioningTabModalOpened(false)
          }
          selectedBoxId={selectedBoxId}
          productInstanceId={instance.id}
        />
      )}
      {isAddBoxModalOpened && (
        <AddBoxModal
          handleClose={() => setIsAddBoxModalOpened(false)}
          onAddExistingBoxSuccess={handleAddingBoxesToStart}
          onAddCustomBoxSuccess={handleAddingBoxesToStart}
        />
      )}
      {isMaxNumberOfRegrindsModalOpened && (
        <WarningModal
          text1={t('modal.theMaximumNumberOfRegrindsReached')}
          rightButtonContent={t('button.ok')}
          handleClose={handleNotProceedingReconditioning}
          handleClickRightButton={handleProceedWithReconditioning}
        />
      )}
    </>
  );
};

ReconditioningTab.propTypes = {
  instance: PropTypes.object,
  details: PropTypes.object,
  boxes: PropTypes.array,
  getBoxes: PropTypes.func.isRequired,
  setBoxes: PropTypes.func.isRequired,
  setDisplayedBoxesStatuses: PropTypes.func.isRequired,
  addBoxesToStart: PropTypes.func.isRequired,
  setBoxesIdsToFilterOutWhileFetching: PropTypes.func.isRequired,
  setBoxesToAddToStart: PropTypes.func.isRequired,
};

export default connect(
  ({ product, box }) => ({
    instance: product.instance,
    details: product.details,
    boxes: box.boxes,
  }),
  {
    addBoxesToStart,
    getBoxes,
    setBoxes,
    setBoxesIdsToFilterOutWhileFetching,
    setBoxesToAddToStart,
    setDisplayedBoxesStatuses,
  }
)(ReconditioningTab);
