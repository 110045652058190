import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loader from '../../Shared/Loader';
import NewModalStep from './NewModalStep';

const NewLoading = ({ value }) => {
  const { t } = useTranslation();

  return (
    <NewModalStep bodyClassName='d-flex'>
      <div className='loading-container'>
        <Loader containerClassName='h-0' />
        {value && <div className='loading-title'>{value}</div>}
        <div className='loading-subtitle mt-1'>{t('modal.pleaseWait')}</div>
      </div>
    </NewModalStep>
  );
};

NewLoading.propTypes = {
  value: PropTypes.string,
};

export default NewLoading;
