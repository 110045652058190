import {
  DEFAULT_ROUTE_TABS,
  HORIZONTAL_TAB_QUERY,
  PERMISSIONS,
  RECON_CENTER_DASHBOARD_TAB_LABELS,
  RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS,
  ROUTES,
} from '../../constants';

export const STATUS_COLORS_NAMES = {
  ORANGE: 'status_orange',
  BRIGHT_ORANGE: 'status_bright_orange',
  YELLOW: 'status_yellow',
  TURQUOISE: 'status_turquoise',
  PURPLE: 'status_purple',
  GREEN: 'status_green',
  GRAY: 'btn-disabled',
  PINK: 'status_pink',
  BRIGHT_BLUE: 'status_bright_blue',
  AQUA_BLUE: 'status_aqua_blue',
};

export const RECON_CENTER_SIDEBAR = {
  INCOMING: {
    PATH: ROUTES.RC_INCOMING,
    DEFAULT_TABS: DEFAULT_ROUTE_TABS[ROUTES.RC_INCOMING],
    NAME: 'containers.incoming',
    PERMISSION: PERMISSIONS.VIEW_INCOMING_IN_SIDEBAR,
  },
  INSPECTION: {
    PATH: ROUTES.RC_INSPECTIONS,
    DEFAULT_TABS: DEFAULT_ROUTE_TABS[ROUTES.RC_INSPECTIONS],
    NAME: 'sharedComponents.inspections',
    PERMISSION: PERMISSIONS.VIEW_INSPECTIONS_IN_SIDEBAR,
  },
  RECONDITIONING: {
    PATH: ROUTES.RC_RECONDITIONING,
    DEFAULT_TABS: DEFAULT_ROUTE_TABS[ROUTES.RC_RECONDITIONING],
    NAME: 'modal.reconditioning',
    PERMISSION: PERMISSIONS.VIEW_RC_RECONDITIONING_IN_SIDEBAR,
  },
  SHIPPED: {
    PATH: ROUTES.RC_SHIPPED,
    DEFAULT_TABS: DEFAULT_ROUTE_TABS[ROUTES.RC_SHIPPED],
    NAME: 'sharedComponents.shipping',
    PERMISSION: PERMISSIONS.VIEW_SHIPPED_IN_SIDEBAR,
  },
  ARCHIVE: {
    PATH: ROUTES.RC_ARCHIVE,
    DEFAULT_TABS: DEFAULT_ROUTE_TABS[ROUTES.RC_ARCHIVE],
    NAME: 'sharedComponents.archive',
    PERMISSION: PERMISSIONS.VIEW_ARCHIVE_IN_SIDEBAR,
  },
};

export const RECON_CENTER_ORDER_STATUSES = {
  PICKUP_REQUESTED: {
    KEY: 'PickUpRequested',
    LABEL: 'components.pickupRequested',
    COLOR: STATUS_COLORS_NAMES.AQUA_BLUE,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.INCOMING,
    TAB: {
      [HORIZONTAL_TAB_QUERY]:
        RECON_CENTER_DASHBOARD_TAB_LABELS.REQUESTED_PICKUPS,
    },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.REQUESTED_PICKUPS,
    IS_IN_RC: false,
  },
  SHIPPING_IN_PROGRESS: {
    KEY: 'ShippingInProgress',
    LABEL: 'components.shippingInProgress',
    COLOR: STATUS_COLORS_NAMES.YELLOW,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.INCOMING,
    TAB: { [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.INBOUND },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.INBOUND,
    IS_IN_RC: true,
  },
  DELIVERED_TO_RC: {
    KEY: 'DeliveredToReconditionCenter',
    LABEL: 'components.deliveredToRC',
    COLOR: STATUS_COLORS_NAMES.ORANGE,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.INCOMING,
    TAB: { [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.RECEIVED },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.RECEIVED,
    IS_IN_RC: true,
  },
  INSPECTION_IN_PROGRESS: {
    KEY: 'InspectionInProgress',
    LABEL: 'components.inspectionInProgress',
    COLOR: STATUS_COLORS_NAMES.BRIGHT_ORANGE,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.INSPECTION,
    TAB: {
      [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.IN_PROGRESS,
    },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.IN_PROGRESS,
    IS_IN_RC: true,
  },
  RECONDITIONING_IN_PROGRESS: {
    KEY: 'ReconditioningInProgress',
    LABEL: 'components.reconditioningInProgress',
    COLOR: STATUS_COLORS_NAMES.PURPLE,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.RECONDITIONING,
    TAB: {
      [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.IN_PROGRESS,
    },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.IN_PROGRESS,
    IS_IN_RC: true,
  },
  VERIFICATION_IN_PROGRESS: {
    KEY: 'VerificationInProgress',
    LABEL: 'sharedComponents.verificationInProgress',
    COLOR: STATUS_COLORS_NAMES.PINK,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.SHIPPED,
    TAB: {
      [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.IN_VERIFICATION,
    },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.IN_VERIFICATION,
    IS_IN_RC: true,
  },
  READY_TO_BE_SHIPPED: {
    KEY: 'ReadyToBeShippedToCustomer',
    LABEL: 'sharedComponents.readyForShipping',
    COLOR: STATUS_COLORS_NAMES.BRIGHT_BLUE,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.SHIPPED,
    TAB: {
      [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.TO_BE_SHIPPED,
    },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.TO_BE_SHIPPED,
    IS_IN_RC: true,
  },
  SHIPPED: {
    KEY: 'ShippedToCustomer',
    LABEL: 'sharedComponents.shipped',
    COLOR: STATUS_COLORS_NAMES.TURQUOISE,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.SHIPPED,
    TAB: { [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.SHIPPED },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.SHIPPED,
    IS_IN_RC: true,
  },
  DELIVERED: {
    KEY: 'DeliveredToCustomer',
    LABEL: 'components.delivered',
    COLOR: STATUS_COLORS_NAMES.GREEN,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.SHIPPED,
    TAB: { [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.SHIPPED },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.SHIPPED,
    IS_IN_RC: false,
  },
  COMPLETED: {
    KEY: 'Completed',
    LABEL: 'containers.completed',
    COLOR: STATUS_COLORS_NAMES.GRAY,
    SIDEBAR_OPTION: RECON_CENTER_SIDEBAR.ARCHIVE,
    TAB: {
      [HORIZONTAL_TAB_QUERY]: RECON_CENTER_DASHBOARD_TAB_LABELS.COMPLETED,
    },
    TAB_TRANSL: RECON_CENTER_DASHBOARD_TAB_LABELS_TRANS.COMPLETED,
    IS_IN_RC: false,
  },
};

export const RC_ORDERS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'error.errorLoadingOrders',
    subtitle: 'error.thereWasAnErrorWhileTryingToLoadOrders',
  },
  NO_ITEMS_FOUND: {
    title: 'error.noOrdersAvailable',
    subtitle: 'error.thereAreNoOrdersInThisSection',
  },
  NO_SEARCH_RESULTS: {
    title: 'error.noOrdersFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyKnownOrders',
  },
};

export const RC_TOOLS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'error.errorLoadingTools',
    subtitle: 'error.thereWasAnErrorWhileTryingToLoadTools',
  },
  NO_ITEMS_FOUND: {
    title: 'error.noToolsAvailable',
    subtitle: 'error.thereAreNoToolsInThisSection',
  },
  NO_SEARCH_RESULTS: {
    title: 'error.noToolsFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyToolFromTheOrders',
  },
};

export const QTY_CHECK_STATES = {
  PRODUCT_INSTANCE_MISSING: {
    toggleValue: 1,
    value: false,
  },
  NEUTRAL: {
    toggleValue: 2,
    value: null,
  },
  PRODUCT_INSTANCE_PRESENT: {
    toggleValue: 3,
    value: true,
  },
};

export const TRIPLE_TOGGLE_SWITCH = {
  MIN_VALUE: 1,
  MAX_VALUE: 3,
  NEUTRAL: 2,
};

export const PRODUCTS_IN_THE_BOX_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'containers.couldNotLoadProducts',
    subtitle:
      'containers.thereWasAConnectionErrorWhileTryingToLoadYourProducts',
  },
  NO_ITEMS_FOUND: {
    title: 'containers.noProductsFound',
    subtitle: 'components.thereAreNoProductsInThisBox',
  },
  NO_SEARCH_RESULTS: {
    title: 'containers.noProductsFound',
    subtitle: 'containers.yourSearchQueryDidNotMatchAnyKnownProducts',
  },
};

export const BOX_STATUSES_WITH_INSPECTION = [
  RECON_CENTER_ORDER_STATUSES.INSPECTION_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.RECONDITIONING_IN_PROGRESS.KEY,
];

export const STATUSES_WITH_TRACKING_INFO_INCOMING_TAB = [
  RECON_CENTER_ORDER_STATUSES.PICKUP_REQUESTED.KEY,
  RECON_CENTER_ORDER_STATUSES.SHIPPING_IN_PROGRESS.KEY,
];

export const STATUSES_WITH_TRACKING_INFO_SHIPPING_TAB = [
  RECON_CENTER_ORDER_STATUSES.SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.DELIVERED.KEY,
];

export const POST_INSPECTION_REPORT_BOX_STATUSES = [
  RECON_CENTER_ORDER_STATUSES.RECONDITIONING_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.VERIFICATION_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.READY_TO_BE_SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.DELIVERED.KEY,
  RECON_CENTER_ORDER_STATUSES.COMPLETED.KEY,
];

export const RC_FEEDBACK_AVAILABLE_STATUSES = [
  RECON_CENTER_ORDER_STATUSES.INSPECTION_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.RECONDITIONING_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.VERIFICATION_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.READY_TO_BE_SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.DELIVERED.KEY,
  RECON_CENTER_ORDER_STATUSES.COMPLETED.KEY,
];

export const RC_FEEDBACK_READONLY_STATUSES = [
  RECON_CENTER_ORDER_STATUSES.SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.DELIVERED.KEY,
  RECON_CENTER_ORDER_STATUSES.COMPLETED.KEY,
];

export const SYNC_TAG_TO_BOX_AVAILABLE_STATUSES = [
  RECON_CENTER_ORDER_STATUSES.PICKUP_REQUESTED.KEY,
  RECON_CENTER_ORDER_STATUSES.SHIPPING_IN_PROGRESS.KEY,
  RECON_CENTER_ORDER_STATUSES.DELIVERED_TO_RC.KEY,
  RECON_CENTER_ORDER_STATUSES.READY_TO_BE_SHIPPED.KEY,
  RECON_CENTER_ORDER_STATUSES.SHIPPED.KEY,
];
