import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as TriangleDown } from '../../assets/icon/ic_triangle_down.svg';
import { THEME_COLORS_NAMES } from '../../constants';

const TriangleDownIcon = ({
  onClick,
  className = '',
  color = THEME_COLORS_NAMES.TEXT_PRIMARY,
  id,
}) => (
  <TriangleDown
    className={`icon-${color} cursor-pointer ${className}`}
    onClick={onClick}
    width={30}
    height={30}
    id={id}
  />
);

TriangleDownIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.oneOf(THEME_COLORS_NAMES),
};

export default TriangleDownIcon;
