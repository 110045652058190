import queryString from 'query-string';

export const getSelectedHorizontalTabElement = () =>
  document.getElementsByClassName('horizontal-tab-panel--selected')[0];

export const getSelectedVerticalTabElement = () =>
  document.getElementsByClassName('vertical-tab-panel--selected')[0];

export const getTabElementByLabel = (label) =>
  document.getElementsByClassName(label)[0];

export const getTabIndex = (data, label) => {
  const tabIndex = data.findIndex((tab) => tab.label === label);
  return tabIndex !== -1 ? tabIndex : 0;
};

export const doesTabExist = (data, label) =>
  data.findIndex((tab) => tab.label === label) !== -1;

export const generateQueryString = (queries) => queryString.stringify(queries);

export const filterHiddenTabs = (data) => data.filter((tab) => !tab.hidden);
