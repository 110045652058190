import React from 'react';

import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import debug from 'debug';
import { useTranslation } from 'react-i18next';

import BasicTooltip from './BasicTootip';
import { useCursorWithSpinner } from '../../hooks/Shared';
import { fetchFileById } from '../../client/file';
import { ReactComponent as DownloadIcon } from '../../assets/icon/ic_download.svg';
import { generateRandomInteger } from '../../utils/shared';

const DownloadFilesIcon = ({ files = [] }) => {
  const { t } = useTranslation();
  const { setIsSpinnerNextToCursor } = useCursorWithSpinner();

  // there could be multiple icons in one screen
  const iconId = `download-icon-${generateRandomInteger()}`;

  const downloadFile = async (file) => {
    try {
      const { data } = await fetchFileById(file.id);
      await FileSaver.saveAs(data, file.name);
    } catch (e) {
      debug(`Couldn't download file. Reason: ${e}`);
    }
  };

  const handleDownload = async () => {
    setIsSpinnerNextToCursor(true);

    const promisesToAwait = [];
    files.forEach((file) => {
      promisesToAwait.push(downloadFile(file));
    });
    await Promise.all(promisesToAwait);

    setIsSpinnerNextToCursor(false);
  };

  return (
    !!files.length && (
      <>
        <div>
          <DownloadIcon
            id={iconId}
            className='ml-2 icon-secondary cursor-pointer'
            onClick={handleDownload}
          />
        </div>
        <BasicTooltip target={iconId} placement='bottom'>
          {t('components.downloadAttachedFiles')}
        </BasicTooltip>
      </>
    )
  );
};

DownloadFilesIcon.propTypes = {
  files: PropTypes.array,
};

export default DownloadFilesIcon;
