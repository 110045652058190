import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'reactstrap';

const CustomForm = ({ onSubmit = () => {}, className = '', children }) => (
  <Form className={className} onSubmit={onSubmit}>
    {children}
  </Form>
);

CustomForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default CustomForm;
