import axiosGeneral from 'axios';
import axios from './axiosClient';
import { DB_API_URL, RBAC_API } from '../config';
import { getThemeHexColor } from '../utils/theme';
import { ROOT_THEME_COLORS } from '../constants';

export const fetchUser = async () => {
  return axios.get(`${RBAC_API}/ciam/me`);
};

export const patchUser = async (data) => {
  return axios.patch(`${RBAC_API}/ciam/me`, data);
};

export const fetchImage = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Image/${id}`);
};

export const deleteImage = async (url) => {
  return axios.delete(`${DB_API_URL}/api/v1/Image/${url}/url`);
};

export const fetchMyDetails = async () => {
  const response = await axios.get(`${RBAC_API}/ciam/me`);
  const {
    permissions,
    organization: { subscriptionType, id, uiTheme },
  } = response.data;
  localStorage.setItem('permissions', permissions);
  localStorage.setItem('subscriptionType', subscriptionType);
  localStorage.setItem('organizationId', id);
  localStorage.setItem('title', uiTheme?.title);
  return response;
};

export const fetchUserSettings = async () => {
  return axios.get(`${DB_API_URL}/api/v1/UserSettings`);
};

export const putUserSettings = async (data) => {
  return axios.put(`${DB_API_URL}/api/v1/UserSettings`, data);
};

export const putUserPreferences = async (data) => {
  return axios.put(`${DB_API_URL}/api/v1/UserSettings/Preference`, data);
};

export const fetchAuthorizeMe = async () => {
  return axios.get(`${DB_API_URL}/api/v1/Authorize/me`);
};

export const deleteAccount = async (params) => {
  return axios.delete(`${RBAC_API}/ciam/me`, { params });
};

export const generateAvatar = async ({ params }) => {
  return axiosGeneral.get(`https://eu.ui-avatars.com/api/`, {
    params,
    responseType: 'arraybuffer',
    background: getThemeHexColor(ROOT_THEME_COLORS.SECONDARY),
  });
};
