import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { getSearchProducts, syncProduct } from '../../redux/actions/tagActions';
import { getGroups } from '../../redux/actions/groupActions';
import Loader from '../Shared/Loader';
import { NewLoading, NewModalStep } from '../Modals/Shared';
import {
  HorizontalLine,
  Image,
  NewListItem,
  SearchInput,
  SelectAndSearchItems,
  TableView,
} from '../Shared';
import { getUsernameOrEmail } from '../../utils/shared';
import { getCurrentUserId } from '../../utils/groups';
import { ProductTableRows } from './index';
import ErrorStep from '../Modals/Shared/ErrorStep';
import { getMappedErrorMessage } from '../../utils/error';
import { highlightProductDetails } from '../../redux/actions/productActions';
import { useSearch } from '../../hooks/Shared';

const debug = _debug('Bridge:SyncInternal');

const STEPS = {
  INITIAL: 'INITIAL',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SYNCING: 'SYNCING',
  SYNCED: 'SYNCED',
  ERROR: 'ERROR',
};

const SyncInternal = ({
  goBack,
  handleClose,
  groups,
  getSearchProducts,
  syncProduct,
  products,
  emptyTag,
  productCount,
  highlightProductDetails,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(STEPS.INITIAL);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [highlightedData, setHighlightedData] = useState();
  const [numberOfProductsInGroup, setNumberOfProductsInGroup] = useState(null);
  const { search, setSearch, debouncedSearch } = useSearch();

  const userId = getCurrentUserId();
  const tableColumns = [
    {
      name: 'modal.productName',
      isInitial: true,
      isResizable: true,
    },
    { name: 'components.productNumber' },
  ];

  useEffect(() => {
    (async () => {
      if (step === STEPS.SELECT_PRODUCT) {
        setLoading(true);
        const { rowCount } = await getSearchProducts({
          search,
          initial: true,
          groupId: selectedGroup.id,
        });

        if (numberOfProductsInGroup === null) {
          setNumberOfProductsInGroup(rowCount);
        }

        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, step]);

  const handleCancelModal = () => {
    handleClose();
    if (highlightedData) {
      highlightProductDetails(highlightedData);
    }
  };

  const handleSyncTag = async () => {
    try {
      setStep(STEPS.SYNCING);
      const { highlightedData } = await syncProduct(
        emptyTag,
        selectedProduct.id
      );
      setHighlightedData(highlightedData);
      setStep(STEPS.SYNCED);
    } catch (e) {
      setError(getMappedErrorMessage(e, t));
      setStep(STEPS.ERROR);
    }
  };

  const handleLoadProducts = async (page) => {
    try {
      await getSearchProducts({
        search,
        groupId: selectedGroup.id,
        PageNumber: page,
      });
    } catch (e) {
      setError(getMappedErrorMessage(e));
      setStep(STEPS.ERROR);
    }
  };

  const handleGoToSelectProductStep = () => {
    setSearch('');
    setNumberOfProductsInGroup(null);
    setStep(STEPS.SELECT_PRODUCT);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return (
          <NewModalStep
            title={t('components.selectGroup')}
            subtitle={t(
              'components.theseAreTheGroupsYouAreAMemberOfSelectTheGroupYouWantToAddItTo'
            )}
            handleCancel={handleClose}
            handleClickRightButton={handleGoToSelectProductStep}
            rightButtonContent={t('button.nextCapitalized')}
            leftButtonContent={t('button.back')}
            handleClickLeftButton={goBack}
            rightButtonDisabled={!selectedGroup}
          >
            <SelectAndSearchItems
              search={search}
              searchPlaceholder={t('containers.searchGroup')}
              setSearch={setSearch}
              searchClassName='w-75 mb-4'
              items={groups
                .filter((group) =>
                  group.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((group) => (
                  <NewListItem
                    key={group.id}
                    id={group.name}
                    name={{
                      groupName: group.name,
                      admin: getUsernameOrEmail(group.admins[0]),
                      groupAdmin: getUsernameOrEmail(
                        group.admins.find(
                          ({ user_id: adminId }) => adminId === userId
                        )
                      ),
                    }}
                    isCheckable
                    checkBox={group.name === selectedGroup?.name}
                    handleCheckboxClick={() => setSelectedGroup(group)}
                  />
                ))}
              noDataMessage={t(
                'modal.noGroupsCanBeFoundMatchingTheSearchTermEntered'
              )}
            />
          </NewModalStep>
        );
      case STEPS.SELECT_PRODUCT:
        return (
          <NewModalStep
            title={t('components.syncToAProduct')}
            subtitle={t(
              'modal.useTheSearchFieldToFindTheProductYouWantToSyncTo'
            )}
            handleCancel={handleClose}
            leftButtonContent={t('button.back')}
            handleClickLeftButton={() => {
              setSearch('');
              setStep(STEPS.INITIAL);
            }}
            rightButtonDisabled={!selectedProduct}
            rightButtonContent={t('button.syncCapitalized')}
            handleClickRightButton={handleSyncTag}
            headerChildren={
              <div className='d-flex flex-column justify-content-center align-items-center search-input-margin'>
                <SearchInput
                  className='w-75'
                  value={search}
                  setValue={setSearch}
                  placeholder={t('components.searchInToolsLibrary')}
                  disabled={!numberOfProductsInGroup}
                />
                {!loading && (
                  <HorizontalLine>
                    <Trans
                      i18nKey='components.result'
                      count={products.length}
                      values={{ count: products.length }}
                    />
                  </HorizontalLine>
                )}
              </div>
            }
            bodyClassName='d-flex flex-column mt-4 mb-2'
          >
            {loading ? (
              <Loader containerClassName='mt-4' />
            ) : numberOfProductsInGroup ? (
              <InfiniteScroll
                loadMore={handleLoadProducts}
                hasMore={
                  productCount === null || products.length < productCount
                }
                loader={<Loader containerClassName='mt-4' />}
                useWindow={false}
              >
                {products.length ? (
                  <TableView
                    isModal
                    columns={tableColumns}
                    tableRows={
                      <ProductTableRows
                        products={products}
                        selectedProduct={selectedProduct}
                        onClick={(product) => setSelectedProduct(product)}
                        withoutHighlightAnimation
                      />
                    }
                  />
                ) : (
                  <div className='mx-5 text-center'>
                    {t(
                      'containers.noProductsCanBeFoundMatchingTheSearchTermEntered'
                    )}
                  </div>
                )}
              </InfiniteScroll>
            ) : (
              <div className='mx-5 text-center'>
                {t(
                  'sharedComponents.thereAreNoProductsInTheSelectedGroupIfYouWantToSyncYourDataMatrixToOtherProductGoBackAndSelectDifferentGroup'
                )}
              </div>
            )}
          </NewModalStep>
        );
      case STEPS.SYNCING:
        return <NewLoading value={t('modal.syncingProduct')} />;
      case STEPS.SYNCED:
        return (
          <NewModalStep
            handleCancel={handleCancelModal}
            bodyClassName='d-flex flex-column justify-content-center align-items-center mb-5'
          >
            <Image
              src={selectedProduct.imageUrl}
              width={96}
              height={96}
              className='rounded-circle border border-tertiary'
              containerClassName='mb-4'
              hasBadge
            />
            <h1>{t('modal.productSynced')}</h1>
            <div className='text-center mt-2'>
              <Trans
                i18nKey='modal.tagWasSyncedTo'
                values={{
                  name: selectedProduct.nickname || selectedProduct.name,
                }}
              />
            </div>
          </NewModalStep>
        );
      case STEPS.ERROR:
        return (
          <ErrorStep
            title={t('error.unableToSync')}
            subtitle={error}
            handleClose={handleCancelModal}
          />
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return renderStep();
};

SyncInternal.propTypes = {
  goBack: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  getSearchProducts: PropTypes.func.isRequired,
  syncProduct: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  emptyTag: PropTypes.string.isRequired,
  getGroups: PropTypes.func.isRequired,
  productCount: PropTypes.number.isRequired,
  highlightProductDetails: PropTypes.func,
};

export default connect(
  ({ tag, group }) => ({
    products: tag.searchProducts,
    productCount: tag.productCount,
    emptyTag: tag.emptyTag,
    groups: group.groups,
  }),
  {
    getSearchProducts,
    syncProduct,
    getGroups,
    highlightProductDetails,
  }
)(SyncInternal);
