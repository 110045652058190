import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.supportMessage, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_SUPPORT_MESSAGES_SUCCESS:
      return {
        ...state,
        supportMessages: payload.initial
          ? payload.messages
          : [...state.supportMessages, ...payload.messages],
        count: payload.messageCount,
      };
    case types.UPDATE_SUPPORT_MESSAGE_SUCCESS:
      return {
        ...state,
        supportMessages: state.supportMessages.map((message) =>
          message.id === payload.messageId
            ? { ...message, statusCode: payload.statusCode }
            : message
        ),
      };
    case types.ADD_SUPPORT_MESSAGE_SUCCESS:
      return {
        ...state,
        supportMessages: [
          {
            ...payload.supportMessages.find(({ id }) => id === payload.id),
            isHighlighted: true,
          },
          ...state.supportMessages,
        ],
      };
    default:
      return state;
  }
}
