import React from 'react';

import PropTypes from 'prop-types';

const HeaderItem = ({
  className = '',
  title,
  titleClassName = '',
  titleSuffix,
  children,
}) => {
  return (
    <div className={`mb-3 ${className}`}>
      <div className='d-flex align-items-center font-weight-bold mb-1'>
        <span className={`text-break ${titleClassName}`}>
          {title?.toUpperCase()}
        </span>
        {titleSuffix}
      </div>
      {children}
    </div>
  );
};

HeaderItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  children: PropTypes.any,
  titleSuffix: PropTypes.any,
};

export default HeaderItem;
