import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';

export const MetadataDropdown = ({
  onSelect,
  selected,
  placeholder,
  data,
  fullWidth,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown
      className={fullWidth ? 'w-100' : 'w-50'}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        size='md'
        className='locale-dropdown-toggle w-100 bg-white text-txt-primary border-bd-color rounded-0 mr-5'
      >
        <div className='float-left'>{selected || placeholder}</div>
        <ChevronDown className='float-right' />
      </DropdownToggle>
      <DropdownMenu className='w-100 locale-dropdown-menu overflow-auto'>
        {data.map((item, index) => {
          return (
            <DropdownItem
              key={index}
              value={item.value}
              onClick={() => onSelect(item.value)}
            >
              {item.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
MetadataDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
};

export default MetadataDropdown;
