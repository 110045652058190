import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import Img from 'react-image';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import WarningStep from '../Shared/WarningStep';
import Modal from '../Modal';
import { ErrorStep, NewDone, NewLoading, NewModalStep } from '../Shared';
import { handScan } from '../../../assets/img';
import { FIREBASE_EVENTS, READER_STATES } from '../../../constants';
import { getMappedErrorMessage } from '../../../utils/error';
import { changeReaderState } from '../../../redux/actions/readerActions';
import { syncTagToBox } from '../../../redux/actions/boxActions';
import { handleAnalytics } from '../../../utils/analytics';

const debug = _debug('Bridge:SyncBoxTagModal');

const STEPS = {
  SCAN_THE_CODE: 'SCAN_THE_CODE',
  WARNING: 'WARNING',
  SYNCING_TAG: 'SYNCING_TAG',
  TAG_SYNCED: 'TAG_SYNCED',
  ERROR: 'ERROR',
};

const SyncBoxTagModal = ({
  boxId,
  boxTag,
  handleClose,
  isOpened,
  withSkipOption = false,
  handleSkip = handleClose,
  onScanFinished,
  scannedTag,
  isScanning,
  changeReaderState,
  syncTagToBox,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(STEPS.SCAN_THE_CODE);
  const [error, setError] = useState('');

  useEffect(() => {
    changeReaderState(READER_STATES.SYNC_BOX);

    return () => changeReaderState(READER_STATES.GLOBAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScanFinished =
    onScanFinished || (() => setStep(STEPS.TAG_SYNCED));

  useEffect(() => {
    (async () => {
      if (!scannedTag) {
        return;
      }

      if (scannedTag && !isScanning) {
        setStep(STEPS.SYNCING_TAG);
        try {
          await syncTagToBox(boxId, scannedTag);
          handleAnalytics(
            boxTag
              ? FIREBASE_EVENTS.BOX_NEW_TAG_SYNCED
              : FIREBASE_EVENTS.BOX_TAG_SYNCED
          );
          handleScanFinished();
        } catch (e) {
          if (e.response && e.response.status === 400) {
            setStep(STEPS.WARNING);
            return;
          }

          const response = getMappedErrorMessage(e);
          setError(response);
          debug(`Couldn't fetch tag data. Reason: ${e}`);
          setStep(STEPS.ERROR);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedTag]);

  const renderStep = () => {
    switch (step) {
      case STEPS.SCAN_THE_CODE:
        return (
          <NewModalStep
            title={t('modal.scanTheCode')}
            subtitle={t('modal.useScannerToSyncACodeToThisBox')}
            handleCancel={handleClose}
            bodyClassName='d-flex justify-content-center align-items-center'
            rightButtonContent={withSkipOption ? t('button.skip') : ''}
            handleClickRightButton={withSkipOption ? handleSkip : null}
          >
            <Img src={handScan} className='hand-scan-img' />
          </NewModalStep>
        );
      case STEPS.WARNING:
        return (
          <WarningStep
            handleClose={handleClose}
            text1={t('error.tagIsOccupied')}
            text2={t(
              'modal.theTagYouScannedIsAlreadyInUsePleaseTryAgainWithADifferentTag'
            )}
            rightButtonContent={t('components.tryAgain')}
            handleClickRightButton={() => setStep(STEPS.SCAN_THE_CODE)}
          />
        );
      case STEPS.SYNCING_TAG:
        return <NewLoading value={t('modal.syncingTag')} />;
      case STEPS.TAG_SYNCED:
        return (
          <NewModalStep handleCancel={handleClose}>
            <NewDone
              title={t('modal.tagSuccessfullySyncedToThisBox')}
              height={96}
              width={96}
            />
          </NewModalStep>
        );
      case STEPS.ERROR:
        return (
          <ErrorStep
            handleButton={handleClose}
            handleClose={handleClose}
            title={t(error)}
          />
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal
      className='d-flex flex-column justify-content-between p-0'
      isOpened={isOpened}
    >
      {renderStep()}
    </Modal>
  );
};

SyncBoxTagModal.propTypes = {
  boxId: PropTypes.string.isRequired,
  boxTag: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  syncTagToBox: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  changeReaderState: PropTypes.func.isRequired,
  isScanning: PropTypes.bool.isRequired,
  scannedTag: PropTypes.string.isRequired,
  withSkipOption: PropTypes.bool,
  handleSkip: PropTypes.func,
  onScanFinished: PropTypes.func,
};

export default connect(
  ({ reader }) => ({
    isScanning: reader.isScanning,
    scannedTag: reader.scannedTag,
  }),
  {
    changeReaderState,
    syncTagToBox,
  }
)(SyncBoxTagModal);
