import * as types from '../constants';
import {
  fetchAuthorizeMe,
  fetchUser,
  fetchUserSettings,
  patchUser,
  putUserPreferences,
  putUserSettings,
} from '../../client/user';

export const getUserSuccess = (user) => {
  return {
    type: types.GET_USER_SUCCESS,
    payload: {
      user,
    },
  };
};

export const setMyDetailsSuccess = (organizationData, language) => {
  return {
    type: types.SET_MY_DETAILS_SUCCESS,
    payload: {
      organizationData,
      language,
    },
  };
};

export const patchUserSuccess = (user) => {
  return {
    type: types.PATCH_USER_SUCCESS,
    payload: {
      user,
    },
  };
};

export const getCreationStatisticsSuccess = (creationStatisticsData) => {
  return {
    type: types.GET_CREATION_STATISTICS_SUCCESS,
    payload: {
      creationStatisticsData,
    },
  };
};

export const getUserSettingsSuccess = (settings) => {
  return {
    type: types.GET_USER_SETTINGS_SUCCESS,
    payload: {
      settings,
    },
  };
};

export const updateUserSettingsPreferencesSuccess = (updatePreference) => {
  return {
    type: types.UPDATE_USER_SETTINGS_PREFERENCES_SUCCESS,
    payload: {
      updatePreference,
    },
  };
};

export const getUser = () => {
  return async (dispatch) => {
    const { data } = await fetchUser();
    dispatch(getUserSuccess(data));
    return data;
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    const { data } = await patchUser(user);
    dispatch(patchUserSuccess(data));
  };
};

export const getUserSettings = (user) => {
  return async (dispatch) => {
    const { data } = await fetchUserSettings(user);
    dispatch(getUserSettingsSuccess(data));
  };
};

export const updateUserSettings = (settings) => {
  return async (dispatch, getState) => {
    const oldStateOfSettings = getState().user.settings;

    dispatch(getUserSettingsSuccess(settings));

    try {
      await putUserSettings(settings);
    } catch (e) {
      // in the case update of data fails
      dispatch(getUserSettingsSuccess(oldStateOfSettings));
    }
  };
};

export const updateUserSettingsPreferences = (preferences) => {
  return async (dispatch) => {
    await putUserPreferences(preferences);
    dispatch(updateUserSettingsPreferencesSuccess(preferences));
  };
};

export const getCreationStatistics = () => {
  return async (dispatch) => {
    const { data } = await fetchAuthorizeMe();
    dispatch(
      getCreationStatisticsSuccess({
        internalProductsCount: data.numberOfCreatedInternalProducts,
        externalProductsCount: data.numberOfCreatedExternalProducts,
        assembliesCount: data.numberOfCreatedAssemblies,
        internalProductsLimit: data.internalProductCreationLimit,
        externalProductsLimit: data.externalProductCreationLimit,
        assembliesLimit: data.assembliesCreationLimit,
      })
    );
  };
};

export const setMyDetails = (organizationData = {}, language = 'en') => {
  return async (dispatch) => {
    dispatch(setMyDetailsSuccess(organizationData, language));
  };
};
