import React from 'react';

import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ProductInfoTab = ({ instance }) => {
  const { tag } = useParams();
  const { t } = useTranslation();

  const data = [
    { name: t('components.individualItemNumber'), value: tag },
    {
      name: t('components.batchNumber'),
      value: instance.batchId || '-',
    },
    {
      name: t('components.dateOfProduction'),
      value: instance.dateOfProduction || '-',
    },
    { name: t('components.madeIn'), value: instance.madeIn || '-' },
  ];

  return (
    <div className='product-info-tab-container'>
      <div className='rows'>
        {data.map((property, index) => (
          <Row
            key={index}
            className='m-0 py-3 align-items-center break-word border-bottom-row detail-value-linebreak'
          >
            <Col sm={6}>{property?.name}</Col>
            <Col sm={6}>{property.value}</Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

ProductInfoTab.propTypes = {
  instance: PropTypes.object,
};

export default ProductInfoTab;
