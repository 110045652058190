import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Loader, PrimaryButton } from './index';
import useCameraScanner from '../../hooks/Shared/useCameraScanner';
import { CAMERA_STATE } from '../../constants';
import { ReactComponent as CameraOff } from '../../assets/icon/camera-off.svg';
import { scanTag } from '../../redux/actions/readerActions';

const WebCamera = ({ handleScan }) => {
  const {
    error,
    result,
    cameraState,
    scanningRegionId,
    requestPermission,
    stopCamera,
  } = useCameraScanner();

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      stopCamera();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (result) {
      handleScan(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const noPermission = () => {
    return (
      <div className='text-center mt-5 pt-3'>
        {error && <div className='text-danger'>{error}</div>}
        <CameraOff width={200} height={200} className='icon-secondary' />
        <h2>{t('modal.allowApplicationToUseCamera')}</h2>
        <h4 className='mb-4'>
          {t('modal.thisApplicationRequiresCameraAccessToCaptureYourCode')}
        </h4>
        <PrimaryButton outline onClick={requestPermission}>
          {t('button.requestPermission')}
        </PrimaryButton>
      </div>
    );
  };

  const renderCamera = () => {
    switch (cameraState) {
      case CAMERA_STATE.LOADING:
      case CAMERA_STATE.REQUESTING_PERMISSION:
        return <Loader />;
      case CAMERA_STATE.OFF:
        return (
          <div className='text-center mt-5 pt-3'>
            {error && <div className='text-danger'>{error}</div>}
            <CameraOff width={200} height={200} />
          </div>
        );
      case CAMERA_STATE.NO_PERMISSION:
        return noPermission();
      default:
        return <></>;
    }
  };

  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100 p-3'>
      {renderCamera()}
      <div className='web-camera w-75' id={scanningRegionId} />
    </div>
  );
};

WebCamera.propTypes = {
  handleScan: PropTypes.func,
};

export default connect(null, { scanTag })(WebCamera);
