import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

import CustomSwitch from './CustomSwitch';

const NotificationOptionContainer = ({ title, options, disabled = false }) => {
  return (
    <div className='d-flex flex-column w-100'>
      <div
        className={cs('notification-option-title', {
          'notification-disabled': disabled,
        })}
      >
        {title}
      </div>
      <div
        className={cs('notification-option-hr mb-1', {
          'notification-disabled': disabled,
        })}
      />
      {options.map(({ description, handleToggle, value }) => (
        <Fragment key={description}>
          <div className='d-flex justify-content-between pt-2 pb-1'>
            <div
              className={cs('notification-option-description', {
                'notification-disabled': disabled,
              })}
            >
              {description}
            </div>
            <CustomSwitch
              handleClick={handleToggle}
              disabled={disabled}
              value={value}
            />
          </div>
          <div
            className={cs('notification-option-hr', {
              'notification-disabled': disabled,
            })}
          />
        </Fragment>
      ))}
    </div>
  );
};

NotificationOptionContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      handleToggle: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default NotificationOptionContainer;
