import * as types from '../constants';

export const toggleSidebarSuccess = () => {
  return {
    type: types.SIDEBAR_VISIBILITY_TOGGLE,
  };
};

export const toggleSidebar = () => {
  return (dispatch) => {
    dispatch(toggleSidebarSuccess());
    return Promise.resolve();
  };
};
