import React from 'react';

import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import CustomSwitch from './CustomSwitch';

const ControlledCustomSwitch = ({
  control,
  name,
  error,
  trigger = () => {},
  defaultValue = false,
  handleClick = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Controller
        name={name}
        control={control}
        onClick={(e) => e.preventDefault()}
        error={error}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <CustomSwitch
            value={value}
            handleClick={(value) => {
              onChange({ target: { value, name } });
              handleClick(value);
              trigger();
            }}
            {...props}
          />
        )}
      />
      {error && (
        <FormFeedback className='d-block pl-0 mt-0' valid={false}>
          {t(error)}
        </FormFeedback>
      )}
    </>
  );
};

ControlledCustomSwitch.propTypes = {
  control: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  defaultValue: PropTypes.bool,
  trigger: PropTypes.func,
  handleClick: PropTypes.func,
};

export default ControlledCustomSwitch;
