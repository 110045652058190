import axios from './axiosClient';
import { DB_API_URL } from '../config';
import { NOTIFICATION_STATUS } from '../constants';

export const fetchNotifications = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Notification`, {
    params,
  });
};

export const fetchUnreadNotificationsCount = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Notification/unreadCount`, {
    params,
  });
};

export const patchSetStatus = async ({
  notifications = [],
  status = NOTIFICATION_STATUS.READ,
  ignoreIfCurrentStatusIs,
}) => {
  return axios.patch(`${DB_API_URL}/api/v1/Notification/setStatus`, {
    notifications,
    status,
    ignoreIfCurrentStatusIs,
  });
};
