import React from 'react';

import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { MoreVertical } from 'react-feather';

import { COLORS } from '../../constants/theme';

const NewCustomDropdown = ({ items = [], toggleClassName = '' }) => {
  const renderItems = () => {
    return items
      .filter(({ hidden }) => !hidden)
      .map(
        ({ header = false, disabled = false, className, onClick, value }) => {
          const isBasicItem = !header && !disabled;
          return (
            <>
              <DropdownItem
                key={value}
                header={header}
                disabled={disabled}
                className={`${isBasicItem ? 'item' : ''} ${
                  header ? 'header' : ''
                } ${disabled ? 'disabled' : ''} ${className}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
              >
                {value}
              </DropdownItem>
              {isBasicItem && <DropdownItem divider className='divider' />}
            </>
          );
        }
      );
  };

  return (
    !!items.length && (
      <div onClick={(e) => e.stopPropagation()}>
        <UncontrolledDropdown
          className='new-custom-dropdown'
          onClick={(e) => e.stopPropagation()}
        >
          <DropdownToggle
            tag='div'
            onClick={(e) => e.stopPropagation()}
            className={`toggle d-flex justify-content-end ${toggleClassName}`}
          >
            <MoreVertical
              stroke={COLORS.TEXT_PRIMARY}
              className='cursor-pointer'
              size={24}
            />
          </DropdownToggle>
          <DropdownMenu className='custom-dropdown-menu py-0' right>
            {renderItems()}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    )
  );
};

NewCustomDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  toggleClassName: PropTypes.string,
};

export default NewCustomDropdown;
