import axios from './axiosClient';
import { DB_API_URL } from '../config';

export const fetchAssemblies = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Assembly`, {
    params,
  });
};

export const fetchAssembly = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Assembly/${id}`);
};

export const fetchAssemblyInstance = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/AssemblyInstance/${id}`);
};

export const fetchAssemblyInstanceLocations = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Location/AssemblyInstance`, {
    params,
  });
};

export const postAssemblyInstance = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/AssemblyInstance`, data);
};

export const postAssembly = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Assembly`, data);
};

export const deleteAssembly = async (id, data) => {
  return axios.delete(`${DB_API_URL}/api/v1/Assembly/${id}`, {
    data,
  });
};

export const patchAssembly = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/Assembly/${id}`, data);
};

export const patchAssemblyInstance = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/AssemblyInstance/${id}`, data);
};

export const deleteAssemblyInstance = async (id) => {
  return axios.delete(`${DB_API_URL}/api/v1/AssemblyInstance/${id}`, {
    data: {},
  });
};

export const postAssemblyInstanceNumericProperty = async (id, value) => {
  return axios.post(
    `${DB_API_URL}/api/v1/AssemblyInstanceProperty/Numeric/${id}`,
    { value }
  );
};

export const fetchAssemblyInstanceNumericPropertyHistory = async (params) => {
  return axios.get(
    `${DB_API_URL}/api/v1/AssemblyInstancePropertyHistory/Numeric`,
    params
  );
};

export const deleteAssemblyInstanceNumericPropertyHistoryEntry = async (
  entryId
) => {
  return axios.delete(
    `${DB_API_URL}/api/v1/AssemblyInstancePropertyHistory/Numeric/${entryId}`
  );
};

export const fetchAssemblyThreads = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/UserThread/Assembly/${id}`, {
    params,
  });
};

export const fetchAssemblyInstanceThreads = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/UserThread/AssemblyInstance/${id}`, {
    params,
  });
};

export const postAssemblyGeneralThread = async (id, data) => {
  return axios.post(`${DB_API_URL}/api/v1/UserThread/Assembly/${id}`, data);
};

export const postAssemblyInstanceThread = async (id, data) => {
  return axios.post(
    `${DB_API_URL}/api/v1/UserThread/AssemblyInstance/${id}`,
    data
  );
};

export const postAssemblyGeneralFile = async (id, file) => {
  return axios.post(`${DB_API_URL}/api/v1/File/Upload/Assembly/${id}`, file);
};

export const postAssemblyInstanceFile = async (id, file) => {
  return axios.post(
    `${DB_API_URL}/api/v1/File/Upload/AssemblyInstance/${id}`,
    file
  );
};
