import React from 'react';
import PropTypes from 'prop-types';
import { THEME_COLORS_NAMES } from '../../constants';

const OutlinedBox = ({
  value,
  label,
  labelPosition = 'left',
  size = 'sm',
  className = '',
  color = THEME_COLORS_NAMES.SECONDARY,
  boxClassName = '',
}) => {
  return (
    <div className={`outlined-box-container ${className}`}>
      {label && labelPosition === 'left' && (
        <span className='label-left'>{label}</span>
      )}
      <div
        className={`outlined-box ${size} border-${color} text-${color} ${boxClassName}`}
      >
        {value}
      </div>
      {label && labelPosition === 'right' && (
        <span className='label-right'>{label}</span>
      )}
    </div>
  );
};

OutlinedBox.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['sm']),
  className: PropTypes.string,
  color: PropTypes.string,
  boxClassName: PropTypes.string,
};

export default OutlinedBox;
