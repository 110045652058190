import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.group, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        organizationDetails: payload.details,
      };
    case types.GET_GROUPS_SUCCESS:
      return {
        ...state,
        // TODO Remove given and family name from here when backend prevents creating user without name
        groups: payload.groups
          .filter(({ users }) =>
            users.find(({ user_id: id }) => id === payload.currentUserId)
          )
          .map((group) => ({
            ...group,
            users: group.users.map((user) => ({
              ...user,
              given_name: user.given_name ? user.given_name : '',
              family_name: user.family_name ? user.family_name : '',
            })),
            admins: group.admins.sort(
              ({ user_id: id }) => id === payload.currentUserId
            ),
          })),
      };
    case types.HIGHLIGHT_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        groups: payload.data.highlightedGroupsIds
          ? state.groups.map((group) => ({
              ...group,
              isHighlighted:
                payload.data.isHighlighted &&
                !!payload.data.highlightedGroupsIds?.includes(group.id),
              isFieldHighlighted:
                payload.data.isFieldHighlighted &&
                !!payload.data.highlightedGroupsIds?.includes(group.id),
            }))
          : state.groups,
        isCribwiseHighlighted:
          payload.data.isCribwiseHighlighted !== undefined
            ? payload.data.isCribwiseHighlighted
            : state.isCribwiseHighlighted,
      };
    case types.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: [payload.group, ...state.groups],
      };
    case types.EDIT_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id === payload.group.id ? payload.group : group
        ),
      };
    case types.GET_CRIBWISE_STATUS_CONNECTION_SUCCESS:
      return {
        ...state,
        isConnectionOk: payload.isConnectionOk,
      };

    case types.UPDATE_CRIBWISE_STATUS_CONNECTION_SUCCESS:
      return {
        ...state,
        isConnectionOk: payload.isConnectionOk,
      };
    case types.EDIT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          ...payload.organization,
        },
      };
    case types.GET_ORGANIZATION_MEMBERS:
      return {
        ...state,
        // TODO Remove given and family name from here when backend prevents creating user without name
        organizationMembers: payload.members.map((member) => ({
          ...member,
          given_name: member.given_name ? member.given_name : '',
          family_name: member.family_name ? member.family_name : '',
        })),
      };
    case types.GET_ORGANIZATION_ADMINS:
      return {
        ...state,
        organizationAdmins: payload.members
          // eslint-disable-next-line camelcase
          .sort(({ user_id }) => user_id === payload.currentUserId)
          .map((member) => ({
            ...member,
            given_name: member.given_name ? member.given_name : '',
            family_name: member.family_name ? member.family_name : '',
          })),
      };
    case types.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter(({ id }) => id !== payload.groupId),
      };
    case types.DELETE_ORGANIZATION_MEMBER_SUCCESS:
      return {
        ...state,
        organizationAdmins: state.organizationAdmins.filter((admin) =>
          payload.members.find((memberId) => admin.user_id !== memberId)
        ),
        organizationMembers: state.organizationMembers.filter(
          (organizationMember) =>
            payload.members.find(
              (memberId) => memberId !== organizationMember.user_id
            )
        ),
        organizationDetails: {
          ...state.organizationDetails,
          members: state.organizationDetails.members.filter((id) =>
            payload.members.find((memberId) => memberId !== id)
          ),
        },
      };
    case types.DELETE_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        groups: state.groups
          .map((group) =>
            group.id === payload.groupId
              ? {
                  ...group,
                  users: group.users.filter((user) =>
                    payload.members.find(
                      (memberId) => memberId !== user.user_id
                    )
                  ),
                  admins: group.admins.filter((admin) =>
                    payload.members.find(
                      (memberId) => memberId !== admin.user_id
                    )
                  ),
                }
              : group
          )
          .filter((group) => group.users.length !== 0),
      };
    case types.REVOKE_GROUP_ADMIN_RIGHTS:
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id === payload.groupId
            ? {
                ...group,
                admins: group.admins.filter((admin) =>
                  payload.admins.find((adminId) => adminId !== admin.user_id)
                ),
              }
            : group
        ),
      };
    case types.REVOKE_ORGANIZATION_ADMIN_RIGHTS:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          admins: state.organizationDetails.admins.filter((orgAdminId) =>
            payload.admins.find((adminId) => adminId !== orgAdminId)
          ),
        },
        organizationAdmins: state.organizationAdmins.filter((admin) =>
          payload.admins.find((adminId) => adminId !== admin.user_id)
        ),
      };
    case types.ASSIGN_GROUP_ADMIN_RIGHTS:
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id === payload.groupId
            ? {
                ...group,
                admins: [
                  ...group.admins,
                  ...group.users.filter((groupMember) =>
                    payload.members.find(
                      (memberId) => memberId === groupMember.user_id
                    )
                  ),
                ],
              }
            : group
        ),
      };
    case types.ASSIGN_ORGANIZATION_ADMIN_RIGHTS:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          admins: [...state.organizationDetails.admins, ...payload.members],
        },
        organizationAdmins: [
          ...state.organizationAdmins,
          ...state.organizationMembers.filter((groupMember) =>
            payload.members.find((memberId) => memberId === groupMember.user_id)
          ),
        ],
      };
    case types.INVITE_USERS_SUCCESS:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.groupId) {
            return {
              ...group,
              users: [...group.users, ...new Array(payload.users)],
            };
          }
          return group;
        }),
      };
    case types.GET_CRIBWISE_CREDENTIALS_SUCCESS:
    case types.EDIT_CRIBWISE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        cribwiseConnectionCredentials: payload.data,
      };
    default:
      return state;
  }
}
