import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import SmallModalStep from './SmallModalStep';
import { deleteThread } from '../../../client/thread';

const debug = _debug('Bridge:DeleteCommentModal');

const DeleteCommentModal = ({
  commentId,
  handleDeleteComment = (id) => deleteThread(id),
  onSuccess = () => {},
  isOpened = true,
  handleClose,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleCancelModal = () => {
    setIsLoading(false);
    handleClose();
  };

  const handleDeleteThread = async () => {
    try {
      setIsLoading(true);
      await handleDeleteComment(commentId);
      // handle firebase event in onSuccess method
      onSuccess(commentId);
      handleCancelModal();
    } catch (e) {
      setIsLoading(false);
      debug(
        `Deleting comment failed. Reason: ${_get(
          e,
          'response.data.message',
          e
        )}`
      );
    }
  };

  return (
    <Modal
      size='sm'
      contentClassName='modal-height-sm modal-sm-box-shadow'
      isOpened={isOpened}
    >
      <SmallModalStep
        title={t('modal.deleteComment')}
        handleClickLeftButton={handleCancelModal}
        leftButtonContent={t('button.cancel').toUpperCase()}
        handleClickRightButton={handleDeleteThread}
        rightButtonContent={t('button.delete').toUpperCase()}
        isLoading={isLoading}
        rightButtonClassname='modal-sm-warning-button'
      >
        {t('modal.areYouSureYouWantToDeleteThisComment')}
      </SmallModalStep>
    </Modal>
  );
};

DeleteCommentModal.propTypes = {
  commentId: PropTypes.string.isRequired,
  handleDeleteComment: PropTypes.func,
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default DeleteCommentModal;
