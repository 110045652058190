import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.breadcrumbs, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_BREADCRUMBS_SUCCESS:
      return payload.data;
    default:
      return state;
  }
}
