import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Loader from './Loader';
import { useInfiniteScroll } from '../../hooks/Shared';
import { INFINITE_SCROLL_FEEDBACK_MESSAGES } from './constants';
import { reloadPage } from '../../utils/shared';
import PrimaryButton from './PrimaryButton';
import { setTriggerRefetchingOfData } from '../../redux/actions/utilActions';

const CustomInfiniteScroll = ({
  handleLoadMoreData = () => {},
  className = '',
  pageStart = 0,
  searchValue, // set to debounce value
  sortValue,
  startDate,
  endDate,
  additionalFilter,
  shouldLoadData, // additional check before start of loading data (f.e. search.length > 2)
  children,
  data = [], // used for handling feedback messages when adding items manually to an empty list
  renderFeedbackMessage,
  feedbackMessages = INFINITE_SCROLL_FEEDBACK_MESSAGES, // should have the same keys as INFINITE_SCROLL_FEEDBACK_MESSAGES
  resetData = () => {}, // used for resetting data on sort, search and other filters
  addItems, // used in case of managing items manually (when handleLoadMoreData doesn't handle them)
  loader = <Loader containerClassName='py-3' />,
  useWindow = true,
  getScrollParent = () => {},
  setTriggerRefetchingOfData, // to use it from the parent component
}) => {
  const { t } = useTranslation();
  const [skipInitialSearch, setSkipInitialSearch] = useState(true);
  const [skipInitialSort, setSkipInitialSort] = useState(true);
  const [skipInitialDateRange, setSkipInitialDateRange] = useState(true);
  const [
    skipInitialAdditionalFiltering,
    setSkipInitialAdditionalFiltering,
  ] = useState(true);

  const {
    hasMore,
    isLoading,
    loadMoreData,
    handleSearch,
    handleSort,
    handleFilterByDateRange,
    handleAdditionalFilterChange,
    triggerRefetchingOfItemsFromTheFirstPage,
    ref,
    feedbackMessage,
  } = useInfiniteScroll(
    handleLoadMoreData,
    resetData,
    data,
    shouldLoadData,
    addItems
  );

  useEffect(() => {
    setTriggerRefetchingOfData(triggerRefetchingOfItemsFromTheFirstPage);

    return () => setTriggerRefetchingOfData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (skipInitialSearch) {
      setSkipInitialSearch(false);
    } else {
      handleSearch(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (skipInitialSort) {
      setSkipInitialSort(false);
    } else {
      handleSort(sortValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  useEffect(() => {
    if (skipInitialAdditionalFiltering) {
      setSkipInitialAdditionalFiltering(false);
    } else {
      handleAdditionalFilterChange(additionalFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFilter]);

  useEffect(() => {
    if (skipInitialDateRange) {
      setSkipInitialDateRange(false);
    } else {
      handleFilterByDateRange(startDate, endDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleRenderFeedbackMessage = () => {
    const feedbackMessageContent = feedbackMessages[feedbackMessage];

    if (renderFeedbackMessage) {
      return renderFeedbackMessage(feedbackMessageContent);
    }

    return (
      <div className='d-flex flex-column w-100 modal-subtitle align-items-center pb-3'>
        <div className='pt-5 font-weight-bold text-center'>
          {t(feedbackMessageContent?.title)?.toUpperCase()}
        </div>
        <div className='pt-3 text-center'>
          {t(feedbackMessageContent?.subtitle)}
        </div>
        {feedbackMessage ===
          INFINITE_SCROLL_FEEDBACK_MESSAGES.ERROR_LOADING_ITEMS && (
          <PrimaryButton
            outline
            className='font-weight-bold mt-5 px-5 py-3 refresh-button modal-subtitle w-20'
            onClick={reloadPage}
          >
            {t('button.refresh')}
          </PrimaryButton>
        )}
      </div>
    );
  };

  return (
    <>
      <InfiniteScroll
        pageStart={pageStart}
        loadMore={loadMoreData}
        hasMore={!isLoading && hasMore}
        ref={ref}
        className={`d-flex flex-column ${className}`}
        useWindow={useWindow}
        getScrollParent={getScrollParent}
      >
        {children}
        {isLoading && loader}
      </InfiniteScroll>
      {!!feedbackMessage && !isLoading && handleRenderFeedbackMessage()}
    </>
  );
};

CustomInfiniteScroll.propTypes = {
  resetData: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  sortValue: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  additionalFilter: PropTypes.array,
  children: PropTypes.any,
  renderFeedbackMessage: PropTypes.func,
  feedbackMessages: PropTypes.object,
  handleLoadMoreData: PropTypes.func.isRequired,
  className: PropTypes.string,
  pageStart: PropTypes.number,
  data: PropTypes.array,
  loader: PropTypes.any,
  addItems: PropTypes.func,
  useWindow: PropTypes.bool,
  getScrollParent: PropTypes.func,
  shouldLoadData: PropTypes.bool,
  setTriggerRefetchingOfData: PropTypes.func,
};

export default connect(null, { setTriggerRefetchingOfData })(
  CustomInfiniteScroll
);
