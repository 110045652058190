import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight, MoreHorizontal } from 'react-feather';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import _debug from 'debug';
import _get from 'lodash.get';
import cs from 'classnames';
import { COLORS } from '../../constants/theme';

const ListItem = ({
  name,
  isCurrentUser = false,
  isCheckable = false,
  checkBox = false,
  handleCheckboxClick,
  id,
  dropdownOptions,
}) => {
  const debug = _debug('Bridge:ListItem');

  const handleOptionClick = (handleClick) => {
    try {
      handleClick(id, name);
    } catch (e) {
      debug(
        `Clicked option failed. Reason: ${_get(e, 'response.data.message', e)}`
      );
    }
  };

  return (
    <div className='pb-2'>
      <div
        className={cs('border-bottom d-flex justify-content-between', {
          'cursor-pointer': isCheckable,
        })}
        {...(isCheckable && {
          onClick: () => handleCheckboxClick(id, !checkBox),
        })}
      >
        <div className='main-text d-flex ml-2 align-items-center align-content-center align-self-center'>
          {isCurrentUser && (
            <ChevronRight
              size={34}
              color={COLORS.SECONDARY}
              className='chevron-margin'
            />
          )}
          <div className='text-break'>{name}</div>
        </div>
        {isCheckable ? (
          <div
            className={`mb-2 checkbox ${checkBox ? 'checkbox-active' : ''}`}
          />
        ) : dropdownOptions.length ? (
          <UncontrolledDropdown>
            <DropdownToggle tag='div'>
              <MoreHorizontal
                stroke={COLORS.TEXT_PRIMARY}
                className='cursor-pointer'
                size={36}
              />
            </DropdownToggle>
            <DropdownMenu right className='dropdown-z-index'>
              {dropdownOptions.map(({ title, handleClick, redText }, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => handleOptionClick(handleClick)}
                  className={redText ? 'text-danger' : ''}
                >
                  {title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <div className='option-missing' />
        )}
      </div>
    </div>
  );
};

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  isCurrentUser: PropTypes.bool,
  isCheckable: PropTypes.bool,
  checkBox: PropTypes.bool,
  handleCheckboxClick: PropTypes.func,
  id: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
      redText: PropTypes.bool,
    })
  ),
};

export default ListItem;
