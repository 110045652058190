import { useEffect, useState } from 'react';

const useHighlightArray = (
  ids,
  removeHighlight = () => {} // this function takes an array as an argument
) => {
  const [startedHighlightIds, setStartedHighlightIds] = useState([]);

  const handleAnimationStart = (id) =>
    setStartedHighlightIds((prevState) => [id, ...prevState]);

  const handleAnimationEnd = (id) => {
    removeHighlight([id]);
  };

  const handleAnimationEndAll = () => {
    removeHighlight(ids);
  };

  useEffect(() => {
    return () => removeHighlight(ids);

    // eslint-disable-next-line
  }, []);

  return {
    handleAnimationStart,
    handleAnimationEndAll,
    handleAnimationEnd,
    startedHighlightIds,
  };
};

export default useHighlightArray;
