import axios from './axiosClient';
import { DB_API_URL, RBAC_API } from '../config';

export const fetchJobTitles = async (culture) => {
  return axios.get(`${RBAC_API}/metadata/job-titles/?culture=${culture}`);
};

export const fetchCountries = async (culture) => {
  return axios.get(`${RBAC_API}/metadata/countries/?culture=${culture}`);
};

export const fetchLanguages = async (culture) => {
  return axios.get(`${RBAC_API}/metadata/languages/?culture=${culture}`);
};

export const fetchServerMetadata = async () => {
  return axios.get(`${DB_API_URL}/api/v1/ServerMetadata`);
};

export const postFeedback = async (data) => {
  return axios.post(`${RBAC_API}/ciam/feedback/send`, data);
};
