import {
  bulkUpdateBoxStatus,
  fetchBoxProductInstances,
} from '../../client/box';
import {
  addProductInstanceToBoxInInspection,
  fetchInspectionDetails,
  inspectBox,
  inspectProductInstance,
  verifyBox,
  verifyProductInstance,
} from '../../client/inspection';
import { postProductInstanceThread } from '../../client/product';
import { deleteThread } from '../../client/thread';
import { INSPECTION_PRODUCT_INSTANCES_PER_PAGE } from '../../constants';
import { getResourceFromLocation } from '../../utils/resource';
import * as types from '../constants';

export const getInspectionBoxProductInstancesSuccess = (data) => {
  return {
    type: types.GET_INSPECTION_BOX_PRODUCT_INSTANCES_SUCCESS,
    payload: { data },
  };
};

export const getBoxInspectionDetailsSuccess = (data) => {
  return {
    type: types.GET_BOX_INSPECTION_DETAILS_SUCCESS,
    payload: { data },
  };
};

export const setBoxInspectionProductInstancesSuccess = (data) => {
  return {
    type: types.SET_BOX_INSPECTION_PRODUCT_INSTANCES_SUCCESS,
    payload: { data },
  };
};

export const updateIsBoxInspectedSuccess = (isChecked) => {
  return {
    type: types.UPDATE_IS_BOX_INSPECTED_SUCCESS,
    payload: { isChecked },
  };
};

export const updateIsBoxVerifiedSuccess = (isChecked) => {
  return {
    type: types.UPDATE_IS_BOX_VERIFIED_SUCCESS,
    payload: { isChecked },
  };
};

export const updateProductInstanceInspectionDetailsSuccess = (
  instanceId,
  inspectionDetails
) => {
  return {
    type: types.UPDATE_PRODUCT_INSTANCE_INSPECTION_DETAILS_SUCCESS,
    payload: { instanceId, inspectionDetails },
  };
};

export const updateProductInstanceVerificationDetailsSuccess = (
  instanceId,
  verificationDetails
) => {
  return {
    type: types.UPDATE_PRODUCT_INSTANCE_VERIFICATION_DETAILS_SUCCESS,
    payload: { instanceId, verificationDetails },
  };
};
export const addProductInstanceRcCommentSuccess = (
  productInstanceId,
  rcCommentId
) => {
  return {
    type: types.ADD_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS,
    payload: { productInstanceId, rcCommentId },
  };
};

export const deleteProductInstanceRcCommentSuccess = (productInstanceId) => {
  return {
    type: types.DELETE_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS,
    payload: { productInstanceId },
  };
};

export const updateBoxStatusSuccess = (updatedBoxStatus) => {
  return {
    type: types.UPDATE_BOX_STATUS_SUCCESS,
    payload: { updatedBoxStatus },
  };
};

export const updateRcFeedbackInsideInspectionDetailsSuccess = (data) => {
  return {
    type: types.UPDATE_RC_FEEDBACK_INSIDE_INSPECTION_DETAILS_SUCCESS,
    payload: { data },
  };
};

export const getInspectionBoxProductInstances = ({
  BoxId,
  OrderId,
  PageNumber = 1,
  SearchTerm,
  PageSize = INSPECTION_PRODUCT_INSTANCES_PER_PAGE,
  Sort,
}) => {
  return async (dispatch) => {
    const { data } = await fetchBoxProductInstances(BoxId, {
      PageNumber,
      PageSize,
      OrderId,
      SearchTerm,
      Sort,
    });
    dispatch(getInspectionBoxProductInstancesSuccess(data.data));

    return data;
  };
};

export const getBoxInspectionDetails = (boxId, orderId) => {
  return async (dispatch) => {
    const { data } = await fetchInspectionDetails(boxId, orderId);
    dispatch(getBoxInspectionDetailsSuccess(data));
    return data;
  };
};

export const setBoxInspectionProductInstances = (instances = []) => {
  return async (dispatch) => {
    dispatch(setBoxInspectionProductInstancesSuccess(instances));
  };
};

export const updateProductInstanceInspectionDetails = (
  productInstanceId,
  inspectionData
) => {
  return async (dispatch) => {
    const { data } = await inspectProductInstance(
      productInstanceId,
      inspectionData
    );

    dispatch(getBoxInspectionDetailsSuccess(data.inspection));
    dispatch(
      updateProductInstanceInspectionDetailsSuccess(
        productInstanceId,
        data.productInstance
      )
    );
    return data;
  };
};

export const updateProductInstanceVerificationDetails = (
  productInstanceId,
  verificationData
) => {
  return async (dispatch) => {
    const { data } = await verifyProductInstance(
      productInstanceId,
      verificationData
    );

    dispatch(
      updateProductInstanceVerificationDetailsSuccess(productInstanceId, data)
    );
    return data;
  };
};

export const updateIsBoxInspected = (inspectionId, isChecked) => {
  return async (dispatch) => {
    await inspectBox(inspectionId, { isChecked });
    dispatch(updateIsBoxInspectedSuccess(isChecked));
  };
};

export const updateIsBoxVerified = (inspectionId, isChecked) => {
  return async (dispatch) => {
    await verifyBox(inspectionId, { isChecked });
    dispatch(updateIsBoxVerifiedSuccess(isChecked));
  };
};

export const updateRcFeedbackInsideInspectionDetails = (data) => {
  return async (dispatch) => {
    dispatch(updateRcFeedbackInsideInspectionDetailsSuccess(data));
  };
};

export const addProductInstanceRcComment = (
  productInstanceId,
  rcCommentData
) => {
  return async (dispatch) => {
    const { headers } = await postProductInstanceThread(
      productInstanceId,
      rcCommentData
    );
    const rcCommentId = getResourceFromLocation(headers.location);

    dispatch(
      addProductInstanceRcCommentSuccess(productInstanceId, rcCommentId)
    );
  };
};

export const deleteProductInstanceRcComment = (
  rcCommentId,
  productInstanceId
) => {
  return async (dispatch) => {
    await deleteThread(rcCommentId);

    dispatch(deleteProductInstanceRcCommentSuccess(productInstanceId));
  };
};

export const addToolToBox = (inspectionId, toolToBeAddedData) => {
  return async (dispatch) => {
    const { data } = await addProductInstanceToBoxInInspection(
      inspectionId,
      toolToBeAddedData
    );

    dispatch(getBoxInspectionDetailsSuccess(data.inspection));
    return data.productInstance.id;
  };
};

export const updateBoxStatus = (boxData) => {
  return async (dispatch) => {
    const { data } = await bulkUpdateBoxStatus(boxData);
    dispatch(updateBoxStatusSuccess(data.boxes[0].status));
    return data;
  };
};
