import { useState } from 'react';

import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

const useSearch = (defaultValue = '') => {
  const [search, setSearch] = useState(defaultValue);
  const [debouncedSearch] = useDebounce(search, 500);

  return { search, setSearch, debouncedSearch };
};

useSearch.propTypes = {
  defaultValue: PropTypes.string,
};

export default useSearch;
