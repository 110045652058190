import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PillButton from './PillButton';
import { findStatusDetails } from '../../utils/orders';
import { RECON_CENTER_ORDER_STATUSES } from '../../containers/ReconditioningCenterContainers/constants';

const StatusButton = ({
  className = '',
  onClick = () => {},
  status = '',
  disabled = false,
  allStatusesDetails = RECON_CENTER_ORDER_STATUSES,
}) => {
  const { t } = useTranslation();
  const statusDetails = findStatusDetails(allStatusesDetails, status);
  const statusColor = statusDetails.COLOR;
  const statusLabel = statusDetails.LABEL;

  return (
    <PillButton
      className={`status-button font-weight-bold ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      disabled={disabled}
      color={statusColor}
      id='status-button'
    >
      {t(statusLabel)}
    </PillButton>
  );
};

StatusButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  allStatusesDetails: PropTypes.object,
};

export default StatusButton;
