import { REGEX } from '../constants';

export const isUrlFormat = (url) => {
  return REGEX.URL.test(url);
};

export const isFieldEmpty = (value) => {
  return value === null || value.toString().trim();
};

export const isStringOnlyConsistingNumbers = (value) => /^\d+$/.test(value);

export const isEmailFormat = (email) => REGEX.EMAIL.test(email);
