import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getProductAssemblies,
  clearProductAssemblies,
  getProductInstanceAssemblyInstance,
  clearProductInstanceAssemblyInstance,
} from '../../redux/actions/productActions';
import { handleAnalytics } from '../../utils/analytics';
import { openUrlInNewTab } from '../../utils/shared';
import { DEFAULT_ROUTE_TABS, FIREBASE_EVENTS, ROUTES } from '../../constants';
import { CustomCol, Loader } from '../Shared';
import ItemCard from '../Shared/ItemCard';
import {
  ProductAssembliesCardFooter,
  ProductAssembliesInstanceCardFooter,
} from '../Shared/ItemCardFooter';
import { generateQueryString } from '../../utils/tabs';

const AssembliesTab = ({
  assemblyIds,
  productAssemblies,
  assemblyInstanceId,
  instanceAssemblyInstance,
  getProductAssemblies,
  clearProductAssemblies,
  getProductInstanceAssemblyInstance,
  clearProductInstanceAssemblyInstance,
}) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { tag } = useParams();

  const handleClickAssembly = (id) => {
    openUrlInNewTab(
      `${ROUTES.DATABASE_ASSEMBLIES}/${id}?${generateQueryString(
        DEFAULT_ROUTE_TABS[ROUTES.DATABASE_ASSEMBLIES]
      )}`
    );
    handleAnalytics(FIREBASE_EVENTS.ASSEMBLY_VIEW_FROM_LIST);
  };

  const handleClickAssemblyInstance = () => {
    openUrlInNewTab(
      `${ROUTES.DATABASE_ASSEMBLIES}/${
        instanceAssemblyInstance.assemblyId
      }/instance/${assemblyInstanceId}?${generateQueryString(
        DEFAULT_ROUTE_TABS[ROUTES.DATABASE_ASSEMBLIES]
      )}`
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (tag) {
          await getProductInstanceAssemblyInstance(assemblyInstanceId);
        } else if (assemblyIds.length) {
          await getProductAssemblies(assemblyIds);
        }
      } catch (e) {
        // Swallow error
      }
      setLoading(false);
    })();

    return () => {
      (async () => {
        try {
          if (tag) {
            await clearProductInstanceAssemblyInstance();
          } else {
            await clearProductAssemblies();
          }
        } catch (e) {
          // Swallow error
        }
      })();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='p-4 tab-container-wrapper'>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col xs={12} className='assemblies-tab-title pb-3'>
            {!tag &&
              (productAssemblies.length
                ? t(
                    'components.thisProductCanBeIncludedInTheFollowingAssemblies'
                  )
                : t('components.noAssemblies'))}
            {tag &&
              (assemblyInstanceId
                ? t(
                    'components.thisProductItemIsIncludedInTheFollowingAssemblyItem'
                  )
                : t('components.thisProductItemIsntIncludedInAnyAssemblyItem'))}
          </Col>
          {tag
            ? instanceAssemblyInstance.id && (
                <CustomCol xxxl={6} className='pb-4 z-index-product-card'>
                  <ItemCard
                    title={
                      instanceAssemblyInstance.nickname ||
                      instanceAssemblyInstance.assemblyNickname ||
                      instanceAssemblyInstance.assemblyName
                    }
                    description={
                      instanceAssemblyInstance.description ||
                      instanceAssemblyInstance.assemblyDescription
                    }
                    image={instanceAssemblyInstance.imageUrl}
                    handleClick={handleClickAssemblyInstance}
                    footer={
                      <ProductAssembliesInstanceCardFooter
                        assembly={{
                          groupName: instanceAssemblyInstance.assemblyGroupName,
                        }}
                      />
                    }
                  />
                </CustomCol>
              )
            : productAssemblies.map(
                ({
                  id,
                  groupName,
                  name,
                  imageUrl,
                  description,
                  assemblyInstanceCount,
                  productCount,
                  isHighlighted,
                }) => (
                  <CustomCol
                    xxxl={6}
                    key={id}
                    className='pb-4 z-index-product-card'
                  >
                    <ItemCard
                      title={name}
                      image={imageUrl}
                      description={description}
                      handleClick={() => handleClickAssembly(id)}
                      highlighted={isHighlighted}
                      footer={
                        <ProductAssembliesCardFooter
                          assembly={{
                            groupName,
                            productCount,
                            assemblyInstanceCount,
                          }}
                        />
                      }
                      assemblyInstanceCount={assemblyInstanceCount}
                      productCount={productCount}
                    />
                  </CustomCol>
                )
              )}
        </Row>
      )}
    </div>
  );
};

AssembliesTab.propTypes = {
  assemblyIds: PropTypes.array.isRequired,
  assemblyInstanceId: PropTypes.string.isRequired,
  productAssemblies: PropTypes.array.isRequired,
  instanceAssemblyInstance: PropTypes.object.isRequired,
  getProductAssemblies: PropTypes.func.isRequired,
  clearProductAssemblies: PropTypes.func.isRequired,
  getProductInstanceAssemblyInstance: PropTypes.func,
  clearProductInstanceAssemblyInstance: PropTypes.func,
};

export default connect(
  ({ product }) => ({
    instanceAssemblyInstance: product.instanceAssemblyInstance,
    productAssemblies: product.productAssemblies,
  }),
  {
    getProductAssemblies,
    clearProductAssemblies,
    getProductInstanceAssemblyInstance,
    clearProductInstanceAssemblyInstance,
  }
)(AssembliesTab);
