import queryString from 'query-string';

import {
  SEARCH_FILTER_OPTIONS,
  SELECTED_OPTION_QUERY,
} from '../components/DatabaseComponents/constants';
import { HORIZONTAL_TAB_QUERY, ROUTES } from '../constants';
import { DATABASE_DASHBOARD_TAB_LABELS } from '../containers/DatabaseContainers/constants';
import { isRouteActive } from './routes';
import { generateQueryString } from './tabs';

export const checkIsDatabasePageOpened = (currentLocation) =>
  isRouteActive(currentLocation.pathname, ROUTES.DATABASE);

export const isDatabaseMyProductsTabOpened = (currentLocation) => {
  const isDatabasePageOpened = checkIsDatabasePageOpened(currentLocation);
  const queries = queryString.parse(currentLocation.search);

  return (
    isDatabasePageOpened &&
    queries[HORIZONTAL_TAB_QUERY] === DATABASE_DASHBOARD_TAB_LABELS.PRODUCTS &&
    queries[SELECTED_OPTION_QUERY] ===
      SEARCH_FILTER_OPTIONS.PRODUCTS_BRIDGE_DATABASE.KEY
  );
};

export const isDatabaseAssembliesTabOpened = (currentLocation) => {
  const isDatabasePageOpened = checkIsDatabasePageOpened(currentLocation);
  const queries = queryString.parse(currentLocation.search);

  return (
    isDatabasePageOpened &&
    queries[HORIZONTAL_TAB_QUERY] ===
      DATABASE_DASHBOARD_TAB_LABELS.ASSEMBLIES &&
    queries[SELECTED_OPTION_QUERY] ===
      SEARCH_FILTER_OPTIONS.ASSEMBLIES_BRIDGE_DATABASE.KEY
  );
};

export const DATABASE_INTERNAL_PRODUCTS_QUERIES = generateQueryString({
  [HORIZONTAL_TAB_QUERY]: DATABASE_DASHBOARD_TAB_LABELS.PRODUCTS,
  [SELECTED_OPTION_QUERY]: SEARCH_FILTER_OPTIONS.PRODUCTS_BRIDGE_DATABASE.KEY,
});

export const DATABASE_EXTERNAL_PRODUCTS_QUERIES = generateQueryString({
  [HORIZONTAL_TAB_QUERY]: DATABASE_DASHBOARD_TAB_LABELS.PRODUCTS,
  [SELECTED_OPTION_QUERY]: SEARCH_FILTER_OPTIONS.PRODUCTS_EXTERNAL_DATABASE.KEY,
});

export const DATABASE_ASSEMBLIES_QUERIES = generateQueryString({
  [HORIZONTAL_TAB_QUERY]: DATABASE_DASHBOARD_TAB_LABELS.ASSEMBLIES,
  [SELECTED_OPTION_QUERY]: SEARCH_FILTER_OPTIONS.ASSEMBLIES_BRIDGE_DATABASE.KEY,
});
