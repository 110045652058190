import React from 'react';

import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';

const CustomLinkify = ({ className = '', children }) => (
  <Linkify
    className={className}
    options={{
      attributes: {
        target: '_blank',
        rel: 'noopener noreferrer',
        onClick: (e) => e.stopPropagation(),
      },
    }}
  >
    {children}
  </Linkify>
);

CustomLinkify.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default CustomLinkify;
