import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import cs from 'classnames';

import { ReactComponent as LocationIcon } from '../../assets/icon/ic-location-pin-green.svg';
import { ReactComponent as LatestReconDataMissingIcon } from '../../assets/icon/ic_edit.svg';
import { ReactComponent as BoxIcon } from '../../assets/icon/ic_box_icon.svg';
import { PERMISSIONS } from '../../constants';
import { getPermissions } from '../../utils/user';

const ReconditionableProductInstanceLocationTagRow = ({
  isSelected = false,
  hasLocation,
  name,
  date,
  handleTagClick,
  tag,
  handleInfoClick,
  column,
  isLatestReconditionDataMissing,
  isProductInstanceInBox,
}) => {
  const permissions = getPermissions();

  const handleLocationIconClick = (e) => {
    handleTagClick(tag);
    e.stopPropagation();
  };

  return (
    <Row
      className='m-0 d-flex align-items-center px-3 py-2 border-bottom cursor-pointer new-location-tag-item-row'
      onClick={handleInfoClick}
    >
      <Col xs={8} className='d-flex align-items-center p-0 pl-4 pr-1'>
        {permissions.includes(PERMISSIONS.VIEW_LOCATION) && (
          <LocationIcon
            className={cs('position-absolute location-icon-margin', {
              'location-icon': hasLocation,
              'icon-secondary': isSelected,
              'icon-tertiary': !isSelected,
              'icon-txt-disabled': !hasLocation,
              'cursor-pointer': hasLocation,
            })}
            width={32}
            height={43}
            onClick={(e) =>
              hasLocation ? handleLocationIconClick(e) : undefined
            }
          />
        )}
        <div className='d-flex flex-column'>
          <span className='text-break'>{name}</span>
          <span className='date-label'>{date}</span>
        </div>
      </Col>
      <Col xs={2} className='d-flex align-items-center p-0 cursor-pointer'>
        {column}
        {isLatestReconditionDataMissing &&
          permissions.includes(PERMISSIONS.UPDATE_RECONDITION) && (
            <div>
              <LatestReconDataMissingIcon className='icon-warning' />
            </div>
          )}
      </Col>
      <Col xs={2} className='d-flex justify-content-end p-0 cursor-pointer'>
        {isProductInstanceInBox && <BoxIcon icon-tertiary />}
      </Col>
    </Row>
  );
};

ReconditionableProductInstanceLocationTagRow.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  isLatestReconditionDataMissing: PropTypes.bool.isRequired,
  isProductInstanceInBox: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleTagClick: PropTypes.func.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
  hasLocation: PropTypes.bool.isRequired,
  column: PropTypes.any,
};

export default ReconditionableProductInstanceLocationTagRow;
