import React from 'react';

import PropTypes from 'prop-types';

import NewModalStep from './NewModalStep';

const StepWithIcon = ({
  title = '',
  text1 = '',
  text2 = '',
  icon,
  leftButtonContent,
  handleClickLeftButton,
  rightButtonContent,
  handleClickRightButton,
  handleClose,
  children,
}) => (
  <NewModalStep
    title={title}
    leftButtonContent={leftButtonContent}
    handleClickLeftButton={handleClickLeftButton}
    handleClickRightButton={handleClickRightButton}
    rightButtonContent={rightButtonContent}
    handleCancel={handleClose}
    isCenteredVertically
    isCenteredHorizontally
    bodyClassName='d-flex flex-column align-items-center justify-content-center w-100'
  >
    {icon}
    <div className='d-flex flex-column text-txt-secondary text-center font-size-md font-weight-light w-75'>
      <div>{text1}</div>
      <div className='mt-2'>{text2}</div>
    </div>
    {children}
  </NewModalStep>
);

StepWithIcon.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  icon: PropTypes.any,
  leftButtonContent: PropTypes.string,
  handleClickLeftButton: PropTypes.func,
  rightButtonContent: PropTypes.string,
  handleClickRightButton: PropTypes.func,
  handleClose: PropTypes.func,
  children: PropTypes.any,
};

export default StepWithIcon;
