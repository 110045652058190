import { useEffect, useRef, useState } from 'react';

import { INFINITE_SCROLL_FEEDBACK_MESSAGES } from './constants';
import { getDatesRangeForFiltering } from '../../utils/date';

// fetchData method should handle setting items array if addItems is undefined
// (if you are managing items manually outside of redux)
const useInfiniteScroll = (
  fetchData,
  resetItems = () => {},
  data = [],
  shouldLoadData = true,
  addItems
) => {
  const [hasMore, setHasMore] = useState(shouldLoadData);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState(
    shouldLoadData ? null : INFINITE_SCROLL_FEEDBACK_MESSAGES.NO_ITEMS_FOUND
  );
  const [sort, setSort] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [additionalFilter, setAdditionalFilter] = useState([]);
  const ref = useRef();
  const isAnyFilterActive =
    searchText.trim() || startDate || endDate || additionalFilter?.length;

  const triggerRefetchingOfItems = () => {
    setIsLoading(true);

    resetItems();

    if (shouldLoadData) {
      setFeedbackMessage(null);
      ref.current.pageLoaded = 0;
      setHasMore(true);
    }

    setIsLoading(false);
  };

  const triggerRefetchingOfItemsFromTheFirstPage = () => {
    setIsLoading(true);

    resetItems();

    setFeedbackMessage(null);
    ref.current.pageLoaded = 0;
    setHasMore(true);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!shouldLoadData) {
      setHasMore(false);
      setFeedbackMessage(INFINITE_SCROLL_FEEDBACK_MESSAGES.NO_ITEMS_FOUND);
      setIsLoading(false);
    }
  }, [shouldLoadData]);

  useEffect(() => {
    // if items are manually added to the list after it was empty
    if (
      data.length > 0 &&
      (feedbackMessage ===
        INFINITE_SCROLL_FEEDBACK_MESSAGES.NO_SEARCH_RESULTS ||
        feedbackMessage === INFINITE_SCROLL_FEEDBACK_MESSAGES.NO_ITEMS_FOUND)
    ) {
      setFeedbackMessage(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadMoreData = async (page = 1) => {
    setIsLoading(true);

    try {
      const data = await fetchData({
        Sort: sort,
        PageNumber: page,
        SearchTerm: searchText.trim() || null,
        startDate,
        endDate,
        AdditionalFilter: additionalFilter,
      });

      // in the case that shouldLoadData was set to false while awaiting promise
      if (shouldLoadData) {
        if (data.currentPage === data.pageCount || data.pageCount === 0) {
          setHasMore(false);
        }

        if (addItems) {
          addItems(data.data);
        }

        if (data.data?.length === 0 && page === 1) {
          setFeedbackMessage(
            isAnyFilterActive
              ? INFINITE_SCROLL_FEEDBACK_MESSAGES.NO_SEARCH_RESULTS
              : INFINITE_SCROLL_FEEDBACK_MESSAGES.NO_ITEMS_FOUND
          );
        } else if (feedbackMessage) {
          setFeedbackMessage(null);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedbackMessage(INFINITE_SCROLL_FEEDBACK_MESSAGES.ERROR_LOADING_ITEMS);
      setHasMore(false);
      setIsLoading(false);
    }
  };

  const handleSearch = (searchValue) => {
    if (searchText.trim() !== searchValue.trim()) {
      setSearchText(searchValue);
      triggerRefetchingOfItems();
    }
  };

  const handleSort = (sortValue) => {
    setSort(sortValue);
    triggerRefetchingOfItems();
  };

  const handleFilterByDateRange = (startDate, endDate) => {
    const {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    } = getDatesRangeForFiltering(startDate, endDate);

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    triggerRefetchingOfItems();
  };

  const handleAdditionalFilterChange = (array) => {
    setAdditionalFilter(array);
    triggerRefetchingOfItems();
  };

  return {
    hasMore,
    isLoading,
    loadMoreData,
    searchText,
    handleSearch,
    triggerRefetchingOfItemsFromTheFirstPage,
    sort,
    handleSort,
    feedbackMessage,
    handleFilterByDateRange,
    handleAdditionalFilterChange,
    ref,
  };
};

export default useInfiniteScroll;
