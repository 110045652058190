import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import initialState from './initialState';
import barcodeScannerMiddleware from './middleware/barcodeScannerMiddleware';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 15 });

export default function configureStore() {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, barcodeScannerMiddleware))
  );
}
