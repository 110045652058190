import en from './us.png';
import fr from './fr.png';
import de from './de.png';
import es from './es.png';
import se from './se.png';
import ja from './ja.png';
import zh from './zh.png';
import it from './it.png';
import tr from './tr.png';

export default {
  en,
  fr,
  de,
  es,
  se,
  ja,
  zh,
  it,
  tr,
};
