import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import InfoStep from './InfoStep';

const InfoModal = ({ rightButtonContent, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpened
      className='d-flex flex-column justify-content-center add-more-instances-to-box p-0'
    >
      <InfoStep
        rightButtonContent={rightButtonContent || t('button.ok')}
        {...props}
      >
        {children}
      </InfoStep>
    </Modal>
  );
};

InfoModal.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  leftButtonContent: PropTypes.string,
  rightButtonContent: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleClickLeftButton: PropTypes.func,
  handleClickRightButton: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default InfoModal;
