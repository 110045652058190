import { PERMISSIONS, ROUTES, THEME_COLORS_NAMES } from '../../../constants';

export const STATUS_COLORS_NAMES = {
  SUMMER_SKY: 'status_summer_sky',
  TAHITI_GOLD: 'status_tahiti_gold',
  MOON_YELLOW: 'status_moon_yellow',
  MEDIUM_SEA_GREEN: 'status_medium_sea_green',
  LIGHT_SEA_GREEN: 'status_light_sea_green',
  JAVA: 'status_java',
};

export const PRODUCT_AUTHENTICATION_SIDEBAR = {
  INBOX: {
    PATH: ROUTES.LEGAL_INBOX,
    NAME: 'containers.inbox',
    PERMISSION: PERMISSIONS.VIEW_INBOX_IN_SIDEBAR,
  },
  INSPECTION: {
    PATH: ROUTES.LEGAL_INSPECTION,
    NAME: 'containers.inspection',
    PERMISSION: PERMISSIONS.VIEW_LEGAL_INSPECTION_IN_SIDEBAR,
  },
  RESPONSE: {
    PATH: ROUTES.LEGAL_RESPONSE,
    NAME: 'containers.response',
    PERMISSION: PERMISSIONS.VIEW_RESPONSE_IN_SIDEBAR,
  },
  ARCHIVE: {
    PATH: ROUTES.LEGAL_ARCHIVE,
    NAME: 'containers.archive',
    PERMISSION: PERMISSIONS.VIEW_LEGAL_ARCHIVE_IN_SIDEBAR,
  },
};

export const AUTHENTICATION_REQUEST_STATUSES = {
  NEW: {
    KEY: 'New',
    LABEL: 'components.new',
    COLOR: STATUS_COLORS_NAMES.SUMMER_SKY,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INBOX.PATH,
  },
  TO_INSPECT: {
    KEY: 'ToInspect',
    LABEL: 'components.toInspect',
    COLOR: STATUS_COLORS_NAMES.TAHITI_GOLD,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INBOX.PATH,
  },
  INSPECTION_IN_PROGRESS: {
    KEY: 'InspectionInProgress',
    LABEL: 'components.inProgress',
    COLOR: STATUS_COLORS_NAMES.MOON_YELLOW,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION.PATH,
  },
  INSPECTED: {
    KEY: 'Inspected',
    LABEL: 'components.inspected',
    COLOR: STATUS_COLORS_NAMES.MEDIUM_SEA_GREEN,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION.PATH,
  },
  READY_TO_REPLY: {
    KEY: 'ReadyToReply',
    LABEL: 'components.readyToReply',
    COLOR: STATUS_COLORS_NAMES.LIGHT_SEA_GREEN,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.RESPONSE.PATH,
  },
  REPLIED: {
    KEY: 'Replied',
    LABEL: 'components.replied',
    COLOR: STATUS_COLORS_NAMES.JAVA,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.RESPONSE.PATH,
  },
  CLOSED: {
    KEY: 'Closed',
    LABEL: 'components.closed',
    COLOR: THEME_COLORS_NAMES.BUTTON_DISABLED,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.ARCHIVE.PATH,
  },
};

export const TRIGGER_CONCLUSION_MODAL_STATUSES = [
  AUTHENTICATION_REQUEST_STATUSES.REPLIED.KEY,
  AUTHENTICATION_REQUEST_STATUSES.CLOSED.KEY,
];

export const INTERNAL_DISCUSSION_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'error.errorWhileLoadingComments',
  },
  NO_ITEMS_FOUND: {
    title: 'error.noCommentsYet',
  },
  NO_SEARCH_RESULTS: {
    title: 'error.noCommentsYet',
  },
};

export const LEGAL_DIVISION_READ_ONLY_STATUSES = [
  AUTHENTICATION_REQUEST_STATUSES.READY_TO_REPLY.KEY,
  AUTHENTICATION_REQUEST_STATUSES.REPLIED.KEY,
  AUTHENTICATION_REQUEST_STATUSES.CLOSED.KEY,
];
