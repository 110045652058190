import React from 'react';

import PropTypes from 'prop-types';
import Switch from 'react-switch';
import cs from 'classnames';

import { useTranslation } from 'react-i18next';
import { getThemeHexColor } from '../../utils/theme';
import { COLORS } from '../../constants/theme';
import { ROOT_THEME_COLORS } from '../../constants';

const CustomSwitch = ({
  disabled = false,
  value = false,
  handleClick,
  label = '',
  containerClassName = '',
  readOnly = false,
}) => {
  const { t } = useTranslation();

  const handleOnChange = () => {
    handleClick(!value);
  };

  return (
    <div className={`d-flex justify-content-between ${containerClassName}`}>
      {!!label && (
        <div
          className={cs('', {
            'text-txt-disabled': disabled,
          })}
        >
          {t(label)}
        </div>
      )}
      <Switch
        onChange={handleOnChange}
        checked={value}
        onColor={getThemeHexColor(ROOT_THEME_COLORS.SECONDARY)}
        onHandleColor={getThemeHexColor(ROOT_THEME_COLORS.SECONDARY)}
        offHandleColor={COLORS.WHITE}
        handleDiameter={22}
        uncheckedIcon={false}
        checkedIcon={false}
        disabled={disabled}
        boxShadow='0 1px 4px rgba(0,0,0, 0.2)'
        activeBoxShadow='0 1px 4px rgba(0,0,0, 0.2)'
        height={10}
        width={32}
        className={cs('', { 'pointer-events-none': readOnly })}
      />
    </div>
  );
};

CustomSwitch.propTypes = {
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default CustomSwitch;
