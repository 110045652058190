import { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { useTranslation } from 'react-i18next';
import { CAMERA_STATE } from '../../constants';

const useCameraScanner = () => {
  const { t } = useTranslation();
  const scanningRegionId = 'scan-region';
  let html5QrCode;

  const [devices, setDevices] = useState([]);
  const [error, setError] = useState('');
  const [result, setResult] = useState();
  const [cameraState, setCameraState] = useState(CAMERA_STATE.LOADING);

  const config = {
    fps: 2,
    qrbox: { width: 550, height: 400 },
  };

  const scanSuccess = async (html5QrCode, decodedResult) => {
    const {
      format: { formatName },
      text,
    } = decodedResult.result;
    setResult({ format: formatName, text });
    await html5QrCode.stop();
    setCameraState(CAMERA_STATE.OFF);
  };

  const scanError = (errorMessage) => {
    setError(errorMessage);
  };

  const getDevices = async () => {
    try {
      setError('');
      return await Html5Qrcode.getCameras();
    } catch (e) {
      if (e.message === 'Permission denied') {
        setCameraState(CAMERA_STATE.NO_PERMISSION);
      } else {
        setCameraState(CAMERA_STATE.OFF);
        setError(t('error.aCameraCouldNotBeStarted'));
      }
      return [];
    }
  };

  const startCamera = async (cameraId) => {
    html5QrCode = new Html5Qrcode(scanningRegionId);
    try {
      await html5QrCode.start(
        cameraId,
        config,
        (decodedText, decodedResult) => scanSuccess(html5QrCode, decodedResult),
        scanError
      );
      setCameraState(CAMERA_STATE.ON);
      setError('');
      return true;
    } catch (e) {
      setCameraState(CAMERA_STATE.OFF);
      setError(t('error.aCameraCouldNotBeStarted'));
      return false;
    }
  };

  const getDeviceAndStartCamera = async () => {
    setCameraState(CAMERA_STATE.REQUESTING_PERMISSION);
    const cameras = await getDevices();
    setDevices(cameras);
    let i = 0;
    while (i < cameras.length) {
      // eslint-disable-next-line no-await-in-loop
      const isCameraStarted = await startCamera(cameras[i].id);
      if (isCameraStarted) {
        break;
      }
      i += 1;
    }
  };

  const requestPermission = async () => {
    await getDeviceAndStartCamera();
    if (cameraState === CAMERA_STATE.NO_PERMISSION) {
      setError(t('error.permissionDenied'));
    }
  };

  const stopCamera = async () => {
    try {
      await html5QrCode.stop();
    } catch (e) {
      setError(e);
    }
    setCameraState(CAMERA_STATE.OFF);
  };

  useEffect(() => {
    (async () => {
      await getDeviceAndStartCamera();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    devices,
    startCamera,
    stopCamera,
    cameraState,
    result,
    scanningRegionId,
    requestPermission,
  };
};

export default useCameraScanner;
