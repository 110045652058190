import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Modal as ModalReactstrap } from 'reactstrap';

const Modal = ({
  isOpened,
  children,
  contentClassName = '',
  className = '',
  ...rest
}) => {
  return (
    <ModalReactstrap
      className='modal-dialog-scrollable'
      contentClassName={`modal-size ${contentClassName}`}
      size='lg'
      isOpen={isOpened}
      centered
      {...rest}
    >
      <ModalBody className={className}>{children}</ModalBody>
    </ModalReactstrap>
  );
};

Modal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default Modal;
