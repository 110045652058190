import * as types from '../constants';

export const getPaginationDetailsSuccess = (currentPage, totalPages) => {
  return {
    type: types.GET_PAGINATION_DETAILS_SUCCESS,
    payload: {
      currentPage,
      totalPages,
    },
  };
};

export const resetPaginationDetailsSuccess = () => {
  return {
    type: types.RESET_PAGINATION_DETAILS_SUCCESS,
  };
};

export const setCurrentPageSuccess = (currentPage) => {
  return {
    type: types.SET_CURRENT_PAGE_SUCCESS,
    payload: {
      currentPage,
    },
  };
};

export const setCurrentPage = (currentPage) => {
  return async (dispatch) => {
    dispatch(setCurrentPageSuccess(currentPage));
  };
};

export const getPaginationDetails = (currentPage, totalPages) => {
  return async (dispatch) => {
    dispatch(getPaginationDetailsSuccess(currentPage, totalPages));
  };
};

export const resetPaginationDetails = () => {
  return async (dispatch) => {
    dispatch(resetPaginationDetailsSuccess());
  };
};
