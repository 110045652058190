import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';

import { CustomSwitch, SecondaryButton } from '../Shared';
import { URLS } from '../../constants';
import { openUrlInNewTab } from '../../utils/shared';

const CookieSettings = ({
  isOpened,
  cookieOptions,
  handleSave,
  handleReject,
}) => {
  const { t } = useTranslation();
  const [analytics, setAnalytics] = useState(cookieOptions?.analytics || false);
  const [preferences, setPreferences] = useState(
    cookieOptions?.preferences || false
  );

  const onSave = () => {
    handleSave({ analytics, preferences });
  };

  return (
    <Modal
      contentClassName='cookie-modal-container'
      size='sm'
      isOpen={isOpened}
      centered
      autoFocus={false}
    >
      <ModalBody className='d-flex flex-column h-100 pt-4 pb-2'>
        <div className='cookie-modal-title font-weight-bold text-left px-3 pt-3'>
          {t('modal.cookieSettings')}
        </div>
        <div className='cookie-modal-text text-left px-3 pt-2'>
          <Trans
            i18nKey='modal.weUseCookiesYouCanPersonalizeYourCookieSettingsBelowLearnMore'
            components={[
              <span
                className='text-secondary cursor-pointer'
                onClick={() => openUrlInNewTab(URLS.BRIDGE_COOKIES)}
                rel='noopener noreferrer'
              />,
            ]}
          />
        </div>
        <div className='d-flex flex-column flex-grow-1 px-3'>
          <div className='text-center pb-4'>
            <div className='w-100 pt-2'>
              <div className='d-flex justify-content-around pt-3 pb-1'>
                <div className='cookie-modal-subtitle text-left pr-5'>
                  {t('modal.marketingAndAnalytics')}
                  <div className='cookie-modal-text pt-2'>
                    {t(
                      'modal.theseCookiesArePrimarilyToPersonalizeTheAdsOrOffersThatYouMayReceive'
                    )}
                  </div>
                </div>
                <CustomSwitch
                  handleClick={() => setAnalytics(!analytics)}
                  value={analytics}
                />
              </div>
              <div className='d-flex justify-content-around pt-3 pb-1'>
                <div className='cookie-modal-subtitle text-left pr-5'>
                  {t('modal.preferences')}
                  <div className='cookie-modal-text pt-2'>
                    {t(
                      'modal.toKeepYourExperiencePersonalizedWeUseToolsThatMakeYourWebExperienceBetter'
                    )}
                  </div>
                </div>
                <CustomSwitch
                  handleClick={() => setPreferences(!preferences)}
                  value={preferences}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='cookie-option-hr' />
        <div className='d-flex flex-row w-100 pt-4 pb-3 justify-content-center'>
          <div className='px-2'>
            <SecondaryButton outline onClick={handleReject}>
              {`${t('button.mandatoryOnly')}*`}
            </SecondaryButton>
          </div>
          <div className='px-2'>
            <SecondaryButton
              disabled={!analytics && !preferences}
              onClick={onSave}
            >
              {t('button.saveSettings')}
            </SecondaryButton>
          </div>
        </div>
        <div className='cookie-modal-disclaimer text-left pb-3 px-3'>
          {`* ${t(
            'modal.thisOptionWillOnlyUseNecessaryCookiesNeededForTheServiceToWorkAsIntended'
          )}`}
        </div>
      </ModalBody>
    </Modal>
  );
};

CookieSettings.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  cookieOptions: PropTypes.object,
};

export default CookieSettings;
