import { useState } from 'react';

const useSelectAll = (totalNumberOfItems) => {
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isUncheckedAfterSelectAll, setIsUncheckedAfterSelectAll] = useState(
    false
  );
  const [checkedItemsIds, setCheckedItemsIds] = useState([]);
  const [uncheckedItemsIds, setUncheckedItemsIds] = useState([]);

  const getNumberOfCurrentlySelectedItems = () =>
    isSelectAllChecked
      ? totalNumberOfItems
      : isUncheckedAfterSelectAll
      ? totalNumberOfItems - uncheckedItemsIds.length
      : checkedItemsIds.length;

  const isAnyItemSelected = () =>
    isSelectAllChecked ||
    (isUncheckedAfterSelectAll &&
      uncheckedItemsIds.length !== totalNumberOfItems &&
      totalNumberOfItems !== 0) ||
    checkedItemsIds.length > 0;

  const isItemRowChecked = (id) =>
    isSelectAllChecked ||
    !!checkedItemsIds.find((itemId) => itemId === id) ||
    (isUncheckedAfterSelectAll &&
      !uncheckedItemsIds.find((itemId) => itemId === id));

  const removeValueFromState = (stateUpdateMethod, value) =>
    stateUpdateMethod((prevState) => prevState.filter((id) => id !== value));

  const addValueToState = (stateUpdateMethod, value) =>
    stateUpdateMethod((prevState) => [...prevState, value]);

  const handleCheckboxClick = (itemId, value) => {
    if (isSelectAllChecked) {
      setIsSelectAllChecked(false);
      setIsUncheckedAfterSelectAll(true);
      setUncheckedItemsIds([itemId]);
    } else if (isUncheckedAfterSelectAll) {
      if (value) {
        removeValueFromState(setUncheckedItemsIds, itemId);
      } else {
        addValueToState(setUncheckedItemsIds, itemId);
      }
    } else if (!value) {
      removeValueFromState(setCheckedItemsIds, itemId);
    } else {
      addValueToState(setCheckedItemsIds, itemId);
    }
  };

  const handleSelectAllCheckboxClick = (value) => {
    setCheckedItemsIds([]);
    setUncheckedItemsIds([]);
    setIsUncheckedAfterSelectAll(false);
    setIsSelectAllChecked(value);
  };

  const clearCheckedItemsState = () => {
    setCheckedItemsIds([]);
    setUncheckedItemsIds([]);
    setIsSelectAllChecked(false);
    setIsUncheckedAfterSelectAll(false);
  };

  return {
    getNumberOfCurrentlySelectedItems,
    isAnyItemSelected,
    isItemRowChecked,
    handleCheckboxClick,
    handleSelectAllCheckboxClick,
    clearCheckedItemsState,
    isSelectAllChecked,
    checkedItemsIds,
    uncheckedItemsIds,
    isUncheckedAfterSelectAll,
  };
};

export default useSelectAll;
