import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  removeProductInstanceThread,
  removeProductGeneralThread,
} from '../../../redux/actions/productActions';
import {
  removeAssemblyInstanceThread,
  removeAssemblyGeneralThread,
} from '../../../redux/actions/assemblyActions';
import { handleAnalytics } from '../../../utils/analytics';
import { FIREBASE_EVENTS } from '../../../constants';
import Modal from '../Modal';
import SmallModalStep from './SmallModalStep';

const debug = _debug('Bridge:DeleteThreadModal');

const DeleteThreadModal = ({
  threadId,
  isGeneralThread,
  isAssembly = false,
  isOpened,
  handleClose,
  removeProductInstanceThread,
  removeProductGeneralThread,
  removeAssemblyInstanceThread,
  removeAssemblyGeneralThread,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleCancelModal = () => {
    setIsLoading(false);
    handleClose();
  };

  const handleDeleteThread = async () => {
    try {
      setIsLoading(true);
      if (isGeneralThread) {
        if (isAssembly) {
          await removeAssemblyGeneralThread(threadId);
          handleAnalytics(FIREBASE_EVENTS.ASSEMBLY_THREADS_DELETE);
        } else {
          await removeProductGeneralThread(threadId);
          handleAnalytics(FIREBASE_EVENTS.PRODUCT_THREADS_DELETE);
        }
      } else if (isAssembly) {
        await removeAssemblyInstanceThread(threadId);
        handleAnalytics(FIREBASE_EVENTS.ASSEMBLY_INSTANCE_THREADS_DELETE);
      } else {
        await removeProductInstanceThread(threadId);
        handleAnalytics(FIREBASE_EVENTS.PRODUCT_INSTANCE_THREADS_DELETE);
      }
      handleCancelModal();
    } catch (e) {
      setIsLoading(false);
      debug(
        `Deleting thread failed. Reason: ${_get(e, 'response.data.message', e)}`
      );
    }
  };

  return (
    <Modal
      size='sm'
      contentClassName='modal-height-sm modal-sm-box-shadow'
      isOpened={isOpened}
    >
      <SmallModalStep
        title={t('modal.deleteNote')}
        handleClickLeftButton={handleCancelModal}
        leftButtonContent={t('button.cancel').toUpperCase()}
        handleClickRightButton={handleDeleteThread}
        rightButtonContent={t('button.delete').toUpperCase()}
        isLoading={isLoading}
        rightButtonClassname='modal-sm-warning-button'
      >
        {t('modal.areYouSureYouWantToDeleteThisNote')}
      </SmallModalStep>
    </Modal>
  );
};

DeleteThreadModal.propTypes = {
  threadId: PropTypes.string.isRequired,
  isAssembly: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isGeneralThread: PropTypes.bool,
  removeProductInstanceThread: PropTypes.func,
  removeProductGeneralThread: PropTypes.func,
  removeAssemblyInstanceThread: PropTypes.func,
  removeAssemblyGeneralThread: PropTypes.func,
};

export default connect(null, {
  removeProductInstanceThread,
  removeProductGeneralThread,
  removeAssemblyInstanceThread,
  removeAssemblyGeneralThread,
})(DeleteThreadModal);
