export const compareByLastChange = (a, b) => {
  return (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt);
};

export const compareByAscendingOrder = (a, b) => {
  return (b !== null) - (a !== null) || a - b;
};

export const getUsernameOrEmail = (user) => {
  // eslint-disable-next-line camelcase
  if (user?.given_name && user?.family_name) {
    // eslint-disable-next-line camelcase
    return `${user?.given_name} ${user?.family_name}`;
  }
  return user?.email;
};

export const openUrlInNewTab = (url) => {
  window.open(url, '_blank', 'noopener, noreferrer');
};

export const scrollToElementWithId = (id) => {
  if (id && document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({ block: 'center' });
  }
};

export const scrollElementWithIdToTop = (id) => {
  if (id && document.getElementById(id)) {
    document.getElementById(id).scrollTo(0, 0);
  }
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const calculateMaxNumberValueByNumberOfAllowedDigits = (
  numberOfDigits
) => Number('9'.repeat(numberOfDigits));

export const bytesToMB = (bytes) => bytes / 1048576;

export const isStringValid = (string, forbiddenCharacters = []) =>
  !forbiddenCharacters.some((character) => string?.includes(character));

export const roundUpTo = (number, numberOfDecimals) =>
  Math.ceil(number * 10 ** numberOfDecimals) / 10 ** numberOfDecimals;

export const roundValueUpTo = (number, numberOfDecimals) =>
  Number(number)?.toFixed(numberOfDecimals);

export const scrollToHighestElement = (elements) => {
  const distances = elements.map((element) => ({
    id: element,
    distance: Math.abs(
      document.getElementById(element)?.getBoundingClientRect().top
    ),
  }));

  distances.sort((a, b) => a.distance - b.distance);

  scrollToElementWithId(distances[0].id);
};

export const trimValuesInObject = (object) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value?.trim() }),
    {}
  );

export const reloadPage = () => window.location.reload(false);

export const getLocalStorageViewOption = () =>
  localStorage.getItem('view-option');

export const setLocalStorageViewOption = (viewOption) =>
  localStorage.setItem('view-option', viewOption);

export const getRegexForLimitingNumberOfDecimals = (numberOfDecimals) =>
  new RegExp(`^\\d+(\\.\\d{0,${numberOfDecimals}})?$`);

export const hasStringFieldValueBeenChanged = (oldValue, newValue) =>
  oldValue ? oldValue !== newValue.trim() : !!newValue?.trim();

export const isDefined = (value) => value !== null && value !== undefined;

export const isSearchTermLongEnough = (search) =>
  !!search && search.trim().length > 2;

export const generateRandomInteger = (min = 0, max = Number.MAX_SAFE_INTEGER) =>
  Math.floor(Math.random() * (max - min) + min);
