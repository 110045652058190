import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';

const CircleButton = ({
  handleClick,
  optionalClassName = '',
  disabled = false,
  children,
  label,
  labelClassName = '',
}) => {
  const button = (
    <button
      disabled={disabled}
      className={cs(
        `p-0 d-flex justify-content-center align-items-center locate-circle circle-btn ${optionalClassName}`,
        {
          'hover-active cursor-pointer': !disabled,
        }
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled && handleClick) {
          handleClick(e);
        }
      }}
    >
      {children}
    </button>
  );

  return label !== undefined ? (
    <div className='d-flex align-items-center min-width-0'>
      {button}
      <span className={`pl-2 ${labelClassName}`}>{label}</span>
    </div>
  ) : (
    button
  );
};

CircleButton.propTypes = {
  handleClick: PropTypes.func,
  optionalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any.isRequired,
  label: PropTypes.any,
  labelClassName: PropTypes.string,
};

export default CircleButton;
