export const INFINITE_SCROLL_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'error.errorLoadingItems',
    subtitle: 'error.thereWasAnErrorWhileTryingToLoadItems',
  },
  NO_ITEMS_FOUND: {
    subtitle: 'error.noItemsAvailable',
  },
  NO_SEARCH_RESULTS: {
    title: 'error.noItemsFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyKnownItems',
  },
};

export const BOX_CARD_SIZES = {
  WINDOW_SIZE: 'WINDOW_SIZE',
  TAB_SIZE: 'TAB_SIZE',
};

export const SUPPORTED_IMAGE_EXTENSIONS = [
  '.jpeg',
  '.gif',
  '.png',
  '.apng',
  '.svg',
  '.bmp',
  '.ico',
  '.jpg',
];
