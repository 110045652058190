import axios from './axiosClient';
import { DB_API_URL } from '../config';
import { RESPONSE_TYPES } from '../constants';

export const fetchProducts = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Product`, {
    params,
  });
};

export const fetchProduct = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/Product/${id}`);
};

export const fetchProductInstanceByTag = async (tag) => {
  return axios.get(`${DB_API_URL}/api/v1/ProductInstance/Details`, {
    params: { tag },
  });
};

export const fetchProductInstanceById = async (id) => {
  return axios.get(`${DB_API_URL}/api/v1/ProductInstance/${id}`);
};

export const fetchProductInstances = async ({ params }) => {
  return axios.get(`${DB_API_URL}/api/v1/ProductInstance/`, {
    params,
  });
};

export const fetchReconditionDetails = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Recondition`, {
    params,
  });
};

export const postProductInstanceReconditioningInfo = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Recondition`, data);
};

export const putProductInstanceReconditioningInfo = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/Recondition/${id}`, data);
};

export const checkReconditionData = async (id) => {
  return axios.put(
    `${DB_API_URL}/api/v1/Recondition/CheckReconditionData/${id}`
  );
};

export const postImageUpload = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Image/uploadToStorage`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const checkProductNumberAvailability = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Product/checkNameAvailability`, data);
};

export const postProduct = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Product`, data);
};

export const putProduct = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/Product/${id}`, data);
};

export const fetchProductsToolsUnited = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/ToolsUnited`, { params });
};

export const fetchProductsWalter = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/Walter`, { params });
};

export const fetchProductsCoromant = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/Coromant`, { params });
};

export const fetchInstanceLocations = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Location/ProductInstance`, { params });
};

export const fetchProductsSecoTools = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/SecoTools`, { params });
};

export const fetchProductsDormerPramet = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Search/DormerPramet`, { params });
};

export const putProductInstance = async (id, data) => {
  return axios.put(`${DB_API_URL}/api/v1/ProductInstance/${id}`, data);
};

export const patchTransferTag = async (data) => {
  return axios.patch(`${DB_API_URL}/api/v1/ProductInstance/TransferTag`, data);
};

export const deleteProductInstance = async (instanceId) => {
  return axios.delete(`${DB_API_URL}/api/v1/ProductInstance/${instanceId}`);
};

export const deleteProduct = async (id) => {
  return axios.delete(`${DB_API_URL}/api/v1/Product/${id}`);
};

export const postImportProducts = async (groupId, formData, params) => {
  return axios.post(
    `${DB_API_URL}/api/v1/Group/${groupId}/import/products`,
    formData,
    { params, responseType: RESPONSE_TYPES.BLOB }
  );
};

export const postCompatibilityCheck = async (data) => {
  return axios.post(`${DB_API_URL}/api/v1/Product/CompatibilityCheck`, data);
};

export const fetchExportProductData = async (params) => {
  return axios.get(`${DB_API_URL}/api/v1/Export/Product`, {
    params,
    responseType: RESPONSE_TYPES.BLOB,
  });
};

export const deleteImageById = async (id) => {
  return axios.delete(`${DB_API_URL}/api/v1/Image/${id}`);
};

export const postCopyProductToAnotherGroup = async (id, data) => {
  return axios.post(`${DB_API_URL}/api/v1/Product/${id}/copy`, data);
};

export const fetchProductThreads = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/UserThread/Product/${id}`, { params });
};

export const fetchProductInstanceThreads = async (id, params) => {
  return axios.get(`${DB_API_URL}/api/v1/UserThread/ProductInstance/${id}`, {
    params,
  });
};

export const postProductGeneralThread = async (id, data) => {
  return axios.post(`${DB_API_URL}/api/v1/UserThread/Product/${id}`, data);
};

export const postProductInstanceThread = async (id, data) => {
  return axios.post(
    `${DB_API_URL}/api/v1/UserThread/ProductInstance/${id}`,
    data
  );
};

export const postProductGeneralFile = async (id, file) => {
  return axios.post(`${DB_API_URL}/api/v1/File/Upload/Product/${id}`, file);
};

export const postProductInstanceFile = async (id, file) => {
  return axios.post(
    `${DB_API_URL}/api/v1/File/Upload/ProductInstance/${id}`,
    file
  );
};
