export const getNameForAvatar = (firstName, lastName, email) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName || lastName || email;
};

export const findMetadata = (data, metadata) => {
  const found = data.find(({ value }) => value === metadata);
  return found ? found.label : '';
};

export const getPermissions = () => {
  const permissions = localStorage.getItem('permissions');
  return !permissions || !permissions.length ? [] : permissions.split(',');
};
